import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { AppointmentState } from 'model';

// REDUCER ACTION
import { changeAppointmentLoading, setAppointmentData, changeShowSomethingWentWrong } from './reducers';

export const initialState: AppointmentState = {
  loading: true,
  data: undefined,
  showSomethingWentWrong: false
};

export const appointmentSlice = createSlice({
  name: Domain.Appointment,
  initialState,
  reducers: {
    changeAppointmentLoading,
    setAppointmentData,
    changeShowSomethingWentWrong
  }
});

export default appointmentSlice.reducer;
