import { RootState } from 'redux/type';

export const selectEnabledProductCatalog = (state: RootState): boolean =>
  state.featureFlagReducer.ENABLED_PRODUCT_CATALOG;

export const selectEnabledDenticare = (): boolean => false;

export const selectEnabledDenticareServiceRegistration = (): boolean => false;

export const selectEnabledAppTour = (state: RootState): boolean => state.featureFlagReducer.ENABLED_APP_TOUR;

export const selectEnabledActivitySelection = (state: RootState): boolean =>
  state.featureFlagReducer.ENABLED_ACTIVITY_SELECTION;

export const selectEnabledMhq = (state: RootState): boolean => state.featureFlagReducer.ENABLED_MHQ;

export const selectEnabledHealthDeclaration = (state: RootState): boolean =>
  state.featureFlagReducer.ENABLED_HEALTH_DECLARATION;

export const selectEnabledFaq = (state: RootState): boolean => state.featureFlagReducer.ENABLED_FAQ;

export const selectEnabledEGiro = (state: RootState): boolean => state.featureFlagReducer.ENABLED_E_GIRO;

export const selectEnabledEGiroArrengement = (state: RootState): boolean =>
  state.featureFlagReducer.ENABLED_E_GYRO_ARRANGEMENT;

export const selectEnabledNewDenticare = (): boolean => false;

export const selectEnabledCenterFiltered = (state: RootState): boolean =>
  state.featureFlagReducer.ENABLED_CENTER_FILTERED;

export const selectEnabledNewScanQRMessage = (state: RootState): boolean =>
  state.featureFlagReducer.ENABLED_NEW_SCAN_QR_MESSAGE;

export const selectEnabledErrorMessageForLinkPass = (state: RootState): boolean =>
  state.featureFlagReducer.ENABLED_ERROR_MESSAGE_FOR_LINK_PASS;
