import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { ContactState, Contact } from 'model';

// ACTION TO CHANGE STATE
export const changeContactLoading: CaseReducer<ContactState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setContact: CaseReducer<ContactState, PayloadAction<Contact>> = (state, action) => {
  state.single = action.payload;
};

export const setManyContacts: CaseReducer<ContactState, PayloadAction<Contact[]>> = (state, action) => {
  state.many = action.payload;
};
