import React, { FC, useState } from 'react';
import { Div, Container, Button, Typography, Avatar, Grid, Collapse } from 'ui-kit';
import { useTranslation, Trans } from 'react-i18next';
import { Add, Remove } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Router } from 'constant';
import { Helmet } from 'react-helmet';
import NTUCHealthLogoWhite from 'assets/egiro/ntuc-health-logo-white-hd.png';
import EGiroStep1 from 'assets/egiro/egiro_step_1.png';
import EGiroStep2 from 'assets/egiro/egiro_step_2.png';
import EGiroStep3 from 'assets/egiro/egiro_step_3.png';
import IconFeature1 from 'assets/egiro/ico_feature_1.svg';
import IconFeature2 from 'assets/egiro/ico_feature_2.svg';
import IconFeature3 from 'assets/egiro/ico_feature_3.svg';
import IconCall from 'assets/egiro/ico_call.svg';
import IconEmail from 'assets/egiro/ico_email.svg';

import { EGiroLandingPageProps } from '../interface';
import { EGiroLandingWrapper, TranslationBoldWrapper } from '../styles';
import { FAQData } from './data';

const EGiroLandingPage: FC<EGiroLandingPageProps> = ({
  isAuthenticated,
  userName,
  userAvatar,
  onLogin,
  onBrandClick,
  onUserClick
}) => {
  const { t: locale } = useTranslation('egiro');
  const history = useHistory();

  const onApplyForEGiro = () => {
    history.push(Router.eGiroApply);
  };

  const Header = () => (
    <Div className="header-background">
      <Helmet>
        <title>NTUC Health - eGIRO Payment Service</title>
        <meta
          content="Convenient, fast and secure - arrange your bill payments to NTUC Health with eGIRO. Get approval within 24 hours."
          name="description"
        />
      </Helmet>
      <Container isSmall={false}>
        <Div padding={{ top: 44, bottom: 30 }}>
          <Div alignItems="center" display="flex" flex={1} flexDirection="row" justifyContent="space-between">
            <Div onCLick={onBrandClick}>
              <img alt="NTUC logo" className="nh-logo" src={NTUCHealthLogoWhite} />
            </Div>
            <Div alignItems="center" display="flex" justifyContent="flex-end">
              {!isAuthenticated && (
                <Button className="login-btn" variant="primary" onClick={onLogin}>
                  Login
                </Button>
              )}

              {isAuthenticated && (
                <Div alignItems="center" display="flex" flexDirection="row" onCLick={onUserClick}>
                  <Div margin={{ right: 10 }}>
                    <Typography bold align="center" color="white" variant="body2">
                      {userName?.toUpperCase()}
                    </Typography>
                  </Div>
                  <Avatar size="sm" url={userAvatar} />
                </Div>
              )}
            </Div>
          </Div>
          <Div display="flex" justifyContent="center" margin={{ top: 44 }}>
            <Typography bold align="center" className="header-title" variant="h1">
              {locale('egiro:landingPageTitle')}
            </Typography>
            <Div margin={{ top: 10 }}>
              <Typography align="center" className="header-sub-title" color="white" variant="body1">
                {locale('egiro:landingPageSubTitle')}
              </Typography>
            </Div>
            <Div margin={{ top: 20 }}>
              <Grid container justifyContent="center">
                <Grid item sm={5} xs={12}>
                  <Div className="apply-btn" onCLick={onApplyForEGiro}>
                    <Typography className="apply-text" variant="body1">
                      {locale('egiro:applyForEGiro')}
                    </Typography>
                  </Div>
                </Grid>
              </Grid>
            </Div>
          </Div>
        </Div>
      </Container>
    </Div>
  );

  const EGiroExplain = () => (
    <Div padding={{ top: 30, bottom: 30 }}>
      <Container isSmall={false}>
        <Typography className="section-title" variant="body1">
          {locale('egiro:whatIsGiro')}
        </Typography>
        <Div margin={{ top: 10 }}>
          <Typography className="section-content" variant="body1">
            {locale('egiro:eGiroExplainContent')}
          </Typography>
        </Div>
      </Container>
    </Div>
  );

  const Steps = () => (
    <Div>
      <Typography className="section-title" variant="body1">
        {locale('egiro:featureAndBenefits')}
      </Typography>
      <Div margin={{ top: 10 }}>
        <Grid container spacing={1}>
          <Grid item md={4} sm={12} xs={12}>
            <Div alignItems="flex-start" className="item-section-container" display="flex" flexDirection="row">
              <Div alignItems="center" display="inline-flex" justifyContent="center">
                <img alt="Egiro feature" src={IconFeature1} />
              </Div>
              <Div margin={{ left: 10 }}>
                <Typography className="item-section-title">{locale('egiro:featureTitle1')}</Typography>

                <Div margin={{ top: 6 }}>
                  <Typography className="item-section-content" variant="body1">
                    {locale('egiro:featureContent1')}
                  </Typography>
                </Div>
              </Div>
            </Div>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Div alignItems="flex-start" className="item-section-container" display="flex" flexDirection="row">
              <Div alignItems="center" display="inline-flex" justifyContent="center">
                <img alt="Egiro feature" src={IconFeature2} />
              </Div>
              <Div margin={{ left: 10 }}>
                <Typography className="item-section-title">{locale('egiro:featureTitle2')}</Typography>

                <Div margin={{ top: 6 }}>
                  <Typography className="item-section-content" variant="body1">
                    {locale('egiro:featureContent2')}
                  </Typography>
                </Div>
              </Div>
            </Div>
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Div alignItems="flex-start" className="item-section-container" display="flex" flexDirection="row">
              <Div alignItems="center" display="inline-flex" justifyContent="center">
                <img alt="Egiro feature" src={IconFeature3} />
              </Div>
              <Div margin={{ left: 10 }}>
                <Typography className="item-section-title">{locale('egiro:featureTitle3')}</Typography>

                <Div margin={{ top: 6 }}>
                  <Typography className="item-section-content" variant="body1">
                    {locale('egiro:featureContent3')}
                  </Typography>
                </Div>
              </Div>
            </Div>
          </Grid>
        </Grid>
      </Div>

      <Div margin={{ top: 30, bottom: 16 }}>
        <Typography className="section-title" variant="body1">
          {locale('egiro:howToApply')}
        </Typography>
      </Div>

      <Grid container spacing={1}>
        <Grid item md={4} sm={12} xs={12}>
          <Div className="item-section-container">
            <Div className="section-step-title-container">
              <Typography className="item-section-title green-color" variant="body1">
                {locale('egiro:eGiroStep1Title')}
              </Typography>
            </Div>
            <Div alignItems="center" display="flex" justifyContent="center" margin={{ top: 10 }}>
              <img alt="Egiro step 1" src={EGiroStep1} />
            </Div>
            <Div margin={{ top: 20 }}>
              <Typography className="item-section-content" variant="body1">
                <Trans components={{ bold: <TranslationBoldWrapper /> }} i18nKey="egiro:eGiroStep1Content" />
              </Typography>
            </Div>
          </Div>
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <Div className="item-section-container">
            <Div className="section-step-title-container">
              <Typography className="item-section-title green-color" variant="body1">
                {locale('egiro:eGiroStep2Title')}
              </Typography>
            </Div>
            <Div alignItems="center" display="flex" justifyContent="center" margin={{ top: 10 }}>
              <img alt="Egiro step 2" src={EGiroStep2} />
            </Div>
            <Div margin={{ top: 20 }}>
              <Typography className="item-section-content" variant="body1">
                <Trans components={{ bold: <TranslationBoldWrapper /> }} i18nKey="egiro:eGiroStep2Content" />
              </Typography>
            </Div>
          </Div>
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <Div className="item-section-container">
            <Div className="section-step-title-container">
              <Typography className="item-section-title green-color" variant="body1">
                {locale('egiro:eGiroStep3Title')}
              </Typography>
            </Div>
            <Div alignItems="center" display="flex" justifyContent="center" margin={{ top: 10 }}>
              <img alt="Egiro step 3" src={EGiroStep3} />
            </Div>
            <Div margin={{ top: 20 }}>
              <Typography className="item-section-content" variant="body1">
                {locale('egiro:eGiroStep3Content')}
              </Typography>
            </Div>
          </Div>
        </Grid>
      </Grid>
    </Div>
  );

  const Interested = () => (
    <Div margin={{ top: 30 }}>
      <Typography className="item-section-content" variant="body1">
        {locale('egiro:explainNoticeEmail')}
      </Typography>

      <Div className="interested-container" margin={{ top: 30 }} padding={{ all: 30 }}>
        <Grid container spacing={2}>
          <Grid item sm={8} xs={12}>
            <Div display="flex" height="100%" justifyContent="center">
              <Typography className="interested-content" variant="body1">
                <Trans components={{ bold: <TranslationBoldWrapper /> }} i18nKey="egiro:interestedContent" />
              </Typography>
            </Div>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Div className="apply-btn" onCLick={onApplyForEGiro}>
              <Typography className="apply-text" variant="body1">
                {locale('egiro:applyEgiroToday')}
              </Typography>
            </Div>
          </Grid>
        </Grid>
      </Div>
    </Div>
  );

  const FAQS = () => {
    const [activeFAQ, setActiveFAQ] = useState<number[]>([]);

    const handleClick = (index: number) => {
      const isActive = activeFAQ.includes(index);

      setActiveFAQ((prev) => (isActive ? prev?.filter((i) => i !== index) : [...prev, index]));
    };

    return (
      <Div margin={{ top: 30 }}>
        <Typography className="section-title" variant="body1">
          {locale('egiro:faq')}
        </Typography>
        <Div margin={{ top: 20 }} width="100%">
          {FAQData.map((item, index) => (
            <Div
              key={index}
              borderColor={{ bottom: 'grey' }}
              borderWidth={{ bottom: 2 }}
              className={activeFAQ.includes(index) ? 'faq-active' : 'faq-inactive'}
              padding={{ top: 16, bottom: 10, left: 25, right: 25 }}
            >
              <Div
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                onCLick={() => handleClick(index)}
              >
                <Typography style={{ fontWeight: 700, fontSize: 14 }}>{item.question}</Typography>
                {activeFAQ.includes(index) ? <Remove /> : <Add />}
              </Div>
              <Div margin={{ top: 5 }}>
                <Collapse isOpen={activeFAQ.includes(index)}>
                  <Typography style={{ marginBlock: 6, fontWeight: 400, fontSize: 14 }}>{item.answer}</Typography>
                </Collapse>
              </Div>
            </Div>
          ))}
        </Div>
      </Div>
    );
  };

  const EGiroSteps = () => (
    <Div className="section-step-container" padding={{ top: 30, bottom: 30 }}>
      <Container isSmall={false}>
        <Steps />
        <Interested />
        <FAQS />
      </Container>
    </Div>
  );

  const HowToReachUs = () => (
    <Div className="section-step-container" padding={{ top: 30, bottom: 30 }}>
      <Container isSmall={false}>
        <Typography className="section-title" variant="body1">
          {locale('egiro:howToReachUs')}
        </Typography>
        <Div margin={{ top: 10 }}>
          <Typography className="item-section-content" variant="body1">
            {locale('egiro:howToReachUsContent')}
          </Typography>
        </Div>
        <Div margin={{ top: 20 }}>
          <Grid container spacing={1}>
            <Grid item md={6} sm={12} xs={12}>
              <Div
                alignItems="center"
                className="item-section-container"
                display="flex"
                flexDirection="row"
                onCLick={() => window.open('mailto:payments@ntuchealth.sg')}
              >
                <Div alignItems="center" display="inline-flex" justifyContent="center">
                  <img alt="Email icon" src={IconEmail} />
                </Div>
                <Div margin={{ left: 10 }}>
                  <Typography className="item-section-title">{locale('egiro:clickHereToSendEmail')}</Typography>
                  <Div margin={{ top: 5 }}>
                    <Typography className="item-section-content">payments@ntuchealth.sg</Typography>
                  </Div>
                </Div>
              </Div>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Div
                alignItems="center"
                className="item-section-container"
                display="flex"
                flexDirection="row"
                onCLick={() => window.open('tel:65904348')}
              >
                <Div alignItems="center" display="inline-flex" justifyContent="center">
                  <img alt="Call icon" src={IconCall} />
                </Div>
                <Div margin={{ left: 10 }}>
                  <Typography className="item-section-title">{locale('egiro:clickHereToCall')}</Typography>
                  <Div margin={{ top: 5 }}>
                    <Typography className="item-section-content">6590 4348</Typography>
                  </Div>
                </Div>
              </Div>
            </Grid>
          </Grid>

          <Div margin={{ top: 20 }}>
            <Typography className="item-section-title">{locale('egiro:operatingHours')}</Typography>
          </Div>
        </Div>
      </Container>
    </Div>
  );

  const Footer = () => (
    <Div className="footer" margin={{ top: 20 }}>
      <Container isSmall={false}>
        <Div padding={{ top: 20, bottom: 20 }}>
          <Div alignItems="center" display="flex" flex={1} flexDirection="row" justifyContent="space-between">
            <Div onCLick={onBrandClick}>
              <img alt="NTUC logo" src={NTUCHealthLogoWhite} />
            </Div>
          </Div>
        </Div>
      </Container>
    </Div>
  );

  return (
    <EGiroLandingWrapper>
      <Header />
      <EGiroExplain />
      <EGiroSteps />
      <HowToReachUs />
      <Footer />
    </EGiroLandingWrapper>
  );
};

export default EGiroLandingPage;
