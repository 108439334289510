import React, { FC } from 'react';

import { Div, Button } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import InfoItem from 'components/InfoItem';

// HELPERS
import { generateAddress } from 'helpers/string/generation';

// STYLE
import { AddressWrapper } from '../styles';

// INTERFACE
import { AddressItemProps } from './interface';

export const AddressItem: FC<AddressItemProps> = ({
  onSelect,
  onOpenConfirmation,
  index,
  item,
  viewType = 'userProfile'
}) => {
  const { t: locale } = useTranslation('common');

  return (
    <AddressWrapper data-testid={`address-view-${index}`}>
      <Div
        key={`${item.id}-${index}`}
        className={viewType === 'userProfile' ? 'withoutBorderContainer' : 'borderContainer'}
        testId={item.id}
      >
        <Div
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          margin={{
            top: viewType === 'userProfile' ? 0 : 20,
            left: viewType === 'userProfile' ? 0 : 20,
            right: viewType === 'userProfile' ? 0 : 20
          }}
        >
          <InfoItem content="" title={item.type} />
          <Div className="rightContainer">
            {onSelect && (
              <Button
                className="editButton"
                size="small"
                testId={`editAddressBtn-${index}`}
                variant="secondary"
                onClick={() => onSelect(item)}
              >
                {locale('common:edit')}
              </Button>
            )}
            {onOpenConfirmation && (
              <Button
                className="deleteButton"
                size="small"
                testId={`deleteAddressBtn-${index}`}
                variant="secondary"
                onClick={() => onOpenConfirmation(item)}
              >
                {locale('common:delete')}
              </Button>
            )}
          </Div>
        </Div>
        {viewType === 'registerService' && <Div className="divider" />}
        <Div margin={{ all: viewType === 'userProfile' ? 0 : 20 }}>
          <InfoItem content={generateAddress(item)} title="" />
        </Div>
      </Div>
    </AddressWrapper>
  );
};
