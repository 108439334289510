import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { FAQType, FaqState } from 'model';

// ACTION TO CHANGE STATE
export const changeActiveTab: CaseReducer<FaqState, PayloadAction<FAQType>> = (state, action) => {
  state.currentActiveTab = action.payload;
};

export const changeSearchText: CaseReducer<FaqState, PayloadAction<string>> = (state, action) => {
  state.searchText = action.payload;
};
