import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRight as ArrowRightIcon } from '@material-ui/icons';

// UIKit
import { Typography, Div } from 'ui-kit';

// COMPONENTS
import { FormNavButton } from 'components/FormNavButton';

// VIEW COMPONENTS
import { RegisterServiceContainer } from 'page/RegisterService/styles';
import ReviewAddress from './ReviewAddress';
import ReviewDetails from './ReviewDetails';
import ReviewEmergencyContact from './ReviewEmergency';
import ReviewFooter from './ReviewFooter';
import ReviewServiceSelection from './ReviewServiceSelection';

const ReviewInformation: FC = () => {
  const { t: locale } = useTranslation(['registerService', 'common']);

  return (
    <RegisterServiceContainer>
      <Typography variant="body1">{locale('registerService:reviewTitle')}</Typography>

      <Div margin={{ top: 20 }} testId="review-service-selection">
        <ReviewServiceSelection />
      </Div>

      <Div margin={{ top: 20 }} testId="review-user-details-selection">
        <ReviewDetails />
      </Div>
      <Div margin={{ top: 20 }} testId="review-address-selection">
        <ReviewAddress />
      </Div>
      <Div margin={{ top: 20 }} testId="review-emergency-contact-selection">
        <ReviewEmergencyContact />
      </Div>
      <Div>
        <ReviewFooter />
      </Div>
      <Div testId="review-payment-online">
        <FormNavButton
          isShowingBackButton
          isEnabledPromp={false}
          isLoading={false}
          nextIcon={<ArrowRightIcon />}
          nextTitle={locale('payment:payOnline')}
          onBack={() => {}}
          onExit={() => {}}
          onNext={() => {}}
          onSubmit={() => {}}
        />
      </Div>
      <Div padding={{ bottom: 50 }} />
    </RegisterServiceContainer>
  );
};

export default ReviewInformation;
