import { isEmpty } from 'lodash';

export const DenticareBookAppointmentAPI = {
  getTreatmentsList: '/services/list?businessUnit=DENTICARE',
  getClinicsList: (serviceId: string) => `/denticare/client/centers?serviceId=${serviceId}`,
  getDentistsList: (serviceId: string, centerId: string) =>
    `/denticare/client/resources?serviceId=${serviceId}&centerId=${centerId}`,
  bookAppointment: '/denticare/client/appointment',
  rescheduleAppointment: (appointmentId: string) => `/denticare/client/appointment/${appointmentId}/reschedule`,
  getUpcomingAppointment: '/denticare/client/appointment',
  getDenticareAppointmentHistory: '/denticare/client/appointment/history',
  retrieveEmptySlot: '/denticare/client/appointment/availableslot',
  cancelAppointment: (appointmentId: string) => `/denticare/client/appointment/${appointmentId}`,
  newGetCenters: (clientSessionId: string) =>
    `/denticare/public/v1/centers?service=SCALING_AND_POLISHING&clientSessionId=${clientSessionId}`,
  newGetDoctors: (clientSessionId: string, centerId: string, serviceId: string) =>
    `/denticare/public/v1/resources?serviceId=${serviceId}&centerId=${centerId}&clientSessionId=${clientSessionId}`,
  newGetMonths: (clientSessionId: string, centerId: string, serviceId: string, resourceId: string) =>
    isEmpty(resourceId)
      ? `/denticare/public/v1/appointment/available-months?serviceId=${serviceId}&centerId=${centerId}&clientSessionId=${clientSessionId}`
      : `/denticare/public/v1/appointment/available-months?serviceId=${serviceId}&centerId=${centerId}&resourceId=${resourceId}&clientSessionId=${clientSessionId}`,
  newGetDates: (
    clientSessionId: string,
    centerId: string,
    serviceId: string,
    resourceId: string,
    selectedMonth: string
  ) =>
    isEmpty(resourceId)
      ? `/denticare/public/v1/appointment/available-dates?serviceId=${serviceId}&centerId=${centerId}&clientSessionId=${clientSessionId}&selectedMonth=${selectedMonth}`
      : `/denticare/public/v1/appointment/available-dates?serviceId=${serviceId}&centerId=${centerId}&resourceId=${resourceId}&clientSessionId=${clientSessionId}&selectedMonth=${selectedMonth}`,
  newGetAvailableSlots: (
    clientSessionId: string,
    centerId: string,
    serviceId: string,
    resourceId: string,
    selectedMonth: string,
    selectedDate: string
  ) =>
    isEmpty(resourceId)
      ? `/denticare/public/v1/appointment/available-times?serviceId=${serviceId}&centerId=${centerId}&clientSessionId=${clientSessionId}&selectedMonth=${selectedMonth}&selectedDate=${selectedDate}`
      : `/denticare/public/v1/appointment/available-times?serviceId=${serviceId}&centerId=${centerId}&resourceId=${resourceId}&clientSessionId=${clientSessionId}&selectedMonth=${selectedMonth}&selectedDate=${selectedDate}`,
  getPublicAppointment: (clientSessionId: string) =>
    `/denticare/public/v1/appointment?clientSessionId=${clientSessionId}`,
  getPublicClient: (clientSessionId: string) =>
    `/denticare/public/v1/client/session?clientSessionId=${clientSessionId}`,
  createPublicAppointment: '/denticare/public/v1/appointment',
  updatePublicAppointment: '/denticare/public/v1/appointment',
  createPublicClient: '/denticare/public/v1/client/session',
  updatePublicClient: '/denticare/public/v1/client/session',
  shareFeedback: '/denticare/public/v1/feedback',
  reviewConfirm: '/denticare/public/v1/appointment/review/confirm',
  getCancellationData: (hash: string) => `/denticare/public/v1/appointment/cancel/${hash}`,
  cancelAppointmentRevamp: 'denticare/public/v1/appointment/cancel'
};
