import { RootState } from 'redux/type';

// MODEL
import { Cart, CheckoutInfo, GSTConfig } from 'model';

// INITIAL STATE
import { initialSingleCart } from './slice';

export const selectCartLoadingStatus = (state: RootState): boolean => state.cartReducer.loading;
export const selectCart = (state: RootState): Cart => state.cartReducer.single || initialSingleCart;
export const selectCartCheckoutInfo = (state: RootState): CheckoutInfo | undefined => state.cartReducer.checkoutInfo;
export const selectGSTConfig = (state: RootState): GSTConfig | undefined => state.cartReducer.gstConfig;
