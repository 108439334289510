import { KeyResource } from 'interface';
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ProductType } from 'ui-kit';

// MODEL
import { ServiceState, Service, ServiceByCategory, ServiceTerm, ServiceTabType } from 'model';

// ACTION TO CHANGE STATE
export const changeServiceLoading: CaseReducer<ServiceState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setService: CaseReducer<ServiceState, PayloadAction<Service | undefined>> = (state, action) => {
  state.single = action.payload;
};

export const setSelectedTerm: CaseReducer<ServiceState, PayloadAction<ServiceTerm | undefined>> = (state, action) => {
  state.selectedTerm = action.payload;
};

export const setManyServices: CaseReducer<ServiceState, PayloadAction<KeyResource<Service>>> = (state, action) => {
  state.many = action.payload;
};

export const setManyServicesWithStatus: CaseReducer<ServiceState, PayloadAction<KeyResource<Service>>> = (
  state,
  action
) => {
  state.manyByStatus = action.payload;
};

export const setUpComingServices: CaseReducer<ServiceState, PayloadAction<string[]>> = (state, action) => {
  state.upComingServices = action.payload;
};

export const setOnGoingServices: CaseReducer<ServiceState, PayloadAction<string[]>> = (state, action) => {
  state.onGoingServices = action.payload;
};

export const setExpiredServices: CaseReducer<ServiceState, PayloadAction<string[]>> = (state, action) => {
  state.expiredServices = action.payload;
};

export const setCompletedServices: CaseReducer<ServiceState, PayloadAction<string[]>> = (state, action) => {
  state.completedServices = action.payload;
};

export const setManyServicesByProductId: CaseReducer<ServiceState, PayloadAction<KeyResource<Service>>> = (
  state,
  action
) => {
  state.manyByProductId = action.payload;
};

export const setServiceGroupByCategory: CaseReducer<ServiceState, PayloadAction<ServiceByCategory[]>> = (
  state,
  action
) => {
  state.groupByCategory = action.payload;
};

export const setHealthConditionChanged: CaseReducer<ServiceState, PayloadAction<boolean>> = (state, action) => {
  state.healthConditionChanged = action.payload;
};

export const setSelectedServiceType: CaseReducer<ServiceState, PayloadAction<ProductType | undefined>> = (
  state,
  action
) => {
  state.selectedServiceType = action.payload;
};

export const setSelectedServiceTab: CaseReducer<ServiceState, PayloadAction<ServiceTabType>> = (state, action) => {
  state.selectedServiceTab = action.payload;
};
