import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { CardState, Card } from 'model';

// ACTION TO CHANGE STATE
export const changeCardLoading: CaseReducer<CardState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setCard: CaseReducer<CardState, PayloadAction<Card>> = (state, action) => {
  state.single = action.payload;
};

export const setManyCards: CaseReducer<CardState, PayloadAction<Card[]>> = (state, action) => {
  state.many = action.payload;
};

export const setValidCards: CaseReducer<CardState, PayloadAction<Card[]>> = (state, action) => {
  state.validCards = action.payload;
};

export const setPaymentDefault: CaseReducer<CardState, PayloadAction<boolean>> = (state, action) => {
  state.isDefaultPayment = action.payload;
};

export const setIsRefresh: CaseReducer<CardState, PayloadAction<boolean>> = (state, action) => {
  state.isRefresh = action.payload;
};
