import { NHColor } from 'ui-kit';
import styled from 'styled-components';

// INTERFACE
import { TranslationWithStyleProps } from './interface';

export const TranslationWithColorWrapper = styled.span`
  color: ${NHColor.green600};

  &:hover {
    cursor: ${(props: Pick<TranslationWithStyleProps, 'onClick'>) => props.onClick && 'pointer'};
    text-decoration: ${(props: Pick<TranslationWithStyleProps, 'onClick'>) => props.onClick && 'underline'};
  }
`;

export const TranslationWithBoldWrapper = styled.span`
  font-weight: 600;
`;
