import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { DenticareClientState, DenticareClientStatus } from 'model';

export const changeDenticareClientLoading: CaseReducer<DenticareClientState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.loading = action.payload;
};

export const setDenticareAssociationId: CaseReducer<DenticareClientState, PayloadAction<string | null>> = (
  state,
  action
) => {
  state.denticareAssociationId = action.payload;
};

export const setDenticareClientId: CaseReducer<DenticareClientState, PayloadAction<string | null>> = (
  state,
  action
) => {
  state.denticareClientId = action.payload;
};

export const setDenticareBlackListed: CaseReducer<DenticareClientState, PayloadAction<boolean>> = (state, action) => {
  state.blackListed = action.payload;
};

export const setDenticareSessionId: CaseReducer<DenticareClientState, PayloadAction<string>> = (state, action) => {
  state.denticareSessionId = action.payload;
};

export const setDenticareClientStatus: CaseReducer<DenticareClientState, PayloadAction<DenticareClientStatus>> = (
  state,
  action
) => {
  state.denticareClientId = action.payload.denticareClientId;
  state.denticareAssociationId = action.payload.denticareAssociationId;
  state.blackListed = action.payload.blackListed;
};
