import { Select } from 'ui-kit';
import { Option } from 'model';
import { groupBy } from 'lodash';

// BASE
import { MainEntity, KeyResource } from 'interface';

export const mappingOptionsToSelects = (options?: Option[]) =>
  options ? options.map<Select>((v) => ({ label: v.name, value: v.id! })) : [];

export function pushData<T extends MainEntity>(currentArr: T[], data: T, formatFn?: (data: T) => T): T[] {
  return currentArr.concat(formatFn ? formatFn(data) : data);
}

export function removeData<T extends MainEntity>(currentArr: T[], data: T): T[] {
  const immutableArr = currentArr.map((v) => ({
    ...v
  }));

  return immutableArr.filter((v) => v.id !== data.id);
}

export function changeValueOfItemFromArr<T extends MainEntity>(
  currentArr: T[],
  data: T,
  formatFn?: (data: T) => T
): T[] {
  if (currentArr.length === 0) {
    return [data];
  }

  const immutableArr = currentArr.map((v) => ({
    ...v
  }));
  const immutableArrLength = immutableArr.length;

  for (let i = 0; i < immutableArrLength; i++) {
    if (immutableArr[i].id === data.id) {
      const newData = formatFn ? formatFn(data) : data;

      immutableArr[i] = {
        ...newData
      };
    }
  }

  return immutableArr;
}

export function formatItemArrByFn<T extends MainEntity>(currentArr: T[], formatFn: (data: T) => T): T[] {
  const immutableArr = currentArr.map((v) => ({
    ...v
  }));
  const immutableArrLength = immutableArr.length;

  for (let i = 0; i < immutableArrLength; i++) {
    const newData = formatFn(immutableArr[i]);

    immutableArr[i] = {
      ...newData
    };
  }

  return immutableArr;
}

export function generateMapObjFromArr<T extends MainEntity>(
  currentArr: T[],
  accessor: string,
  secondAccessor?: string
): KeyResource<T> {
  if (!Array.isArray(currentArr)) return {};

  if (currentArr.length === 0) return {};

  let result: KeyResource<T> = {};
  const currentArrLength = currentArr.length;

  for (let i = 0; i < currentArrLength; i++) {
    let accessID = currentArr[i][accessor];

    if (secondAccessor) {
      accessID = `${currentArr[i][secondAccessor]}_${accessID}`;
    }

    result = {
      ...result,
      [accessID]: currentArr[i]
    };
  }

  return result;
}

// K: Response Type
export function groupByAccessor<T extends MainEntity, K>(
  currentArr: T[],
  accessor: string,
  callBack: (key: string, data: T[]) => K
): K[] {
  if (!Array.isArray(currentArr)) return [];

  if (currentArr.length === 0) return [];

  const grouped = groupBy<T>(currentArr, (v) => v[accessor]);

  const result: K[] = [];

  Object.keys(grouped).forEach((v) => {
    const data = callBack(v, grouped[v]);

    result.push(data);
  });

  return result;
}

export const mergeArraysWithKey = (array1: any[], array2: any[], key: string) => {
  const mergedArray = array1.map((item1) => {
    const matchingItem = array2.find((item2) => item2[`${key}`] === item1[`${key}`]);

    if (matchingItem) {
      return { ...item1, ...matchingItem };
    }

    return item1;
  });

  return mergedArray;
};
