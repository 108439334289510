import { Auth0Client } from '@auth0/auth0-spa-js';

// LINKID
import linkIdConfiguration from 'config/linkId';

// HELPERS
import { removeKey } from 'helpers/localstorage';

class Auth0Service {
  private readonly auth0?: Auth0Client;

  constructor() {
    if (!navigator.userAgent.includes('jsdom')) {
      this.auth0 = new Auth0Client({
        domain: linkIdConfiguration.domain,
        client_id: linkIdConfiguration.clientId,
        audience: linkIdConfiguration.audience,
        scope: linkIdConfiguration.scope,
        redirect_uri: linkIdConfiguration.redirectUri,
        cacheLocation: linkIdConfiguration.cacheLocation,
        useRefreshTokens: linkIdConfiguration.useRefreshTokens
      });
    }
  }

  async generateNewToken(ignoreCache: boolean): Promise<{ token: string }> {
    try {
      const token = await this.auth0!.getTokenSilently({ ignoreCache });

      return {
        token
      };
    } catch (error) {
      return {
        token: ''
      };
    }
  }

  async logOut(): Promise<void> {
    removeKey('@latestDateShowConfirmMHQDialog');

    return this.auth0!.logout({ returnTo: window.location.origin });
  }

  async logInWithRedirect(router: string): Promise<void> {
    return this.auth0!.loginWithRedirect({ appState: { returnTo: router } });
  }
}

export const authService = new Auth0Service();
