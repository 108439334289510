import React, { FC } from 'react';

import { Button } from '@material-ui/core';
import { Grid, Typography } from 'ui-kit';
import { trackEvent } from 'helpers/ga';

import { FooterWrapper } from './styles';

const Footer: FC = () => (
  <FooterWrapper container spacing={2}>
    <Grid item className="footer-content" md={12} xs={12}>
      <Typography className="footer-item">© 2023 NTUC Health Co-operative Ltd. All Rights Reserved</Typography>
      <br />
      <div className="footer-menu-wrapper">
        <Button
          className="footer-item no-padding"
          onClick={() => {
            trackEvent({
              action: 'Click',
              category: 'E-appointment',
              label: 'footer'
            });

            setTimeout(() => {
              window.open(`https://ntuchealth.sg/privacy-policy`, '__blank');
            }, 200);
          }}
        >
          Privacy Policy
        </Button>
        <div>|</div>
        <Button
          className="footer-item"
          onClick={() => {
            trackEvent({
              action: 'Click',
              category: 'E-appointment',
              label: 'footer'
            });

            setTimeout(() => {
              window.open(`https://ntuchealth.sg/feedback`, '__blank');
            }, 200);
          }}
        >
          Feedback
        </Button>
        <div>|</div>
        <Button
          className="footer-item"
          onClick={() => {
            trackEvent({
              action: 'Click',
              category: 'E-appointment',
              label: 'footer'
            });

            setTimeout(() => {
              window.open(`https://ntuchealth.sg/forms`, '__blank');
            }, 200);
          }}
        >
          Forms
        </Button>
        <div>|</div>
        <Button
          className="footer-item"
          onClick={() => {
            trackEvent({
              action: 'Click',
              category: 'E-appointment',
              label: 'footer'
            });

            setTimeout(() => {
              window.open(`https://ntuchealth.sg/brochures`, '__blank');
            }, 200);
          }}
        >
          Brochures
        </Button>
        <div>|</div>
        <Button
          className="footer-item"
          onClick={() => {
            trackEvent({
              action: 'Click',
              category: 'E-appointment',
              label: 'footer'
            });

            setTimeout(() => {
              window.open(`https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme/`, '__blank');
            }, 200);
          }}
        >
          Report Vulnerability
        </Button>
      </div>
    </Grid>
  </FooterWrapper>
);

export default Footer;
