import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { DenticareClientState } from 'model';

// REDUCER ACTION
import {
  changeDenticareClientLoading,
  setDenticareAssociationId,
  setDenticareBlackListed,
  setDenticareClientId,
  setDenticareClientStatus,
  setDenticareSessionId
} from './reducers';

export const initialState: DenticareClientState = {
  denticareAssociationId: null,
  denticareClientId: null,
  blackListed: false,
  loading: true,
  denticareSessionId: ''
};

export const denticareClientSlice = createSlice({
  name: Domain.DenticareClient,
  initialState,
  reducers: {
    changeDenticareClientLoading,
    setDenticareAssociationId,
    setDenticareBlackListed,
    setDenticareClientId,
    setDenticareClientStatus,
    setDenticareSessionId
  }
});

export default denticareClientSlice.reducer;
