/* eslint-disable camelcase */
import { BaseState } from './base';

export enum MedicalHistoryProgressEnum {
  HealthStatus = 0,
  HealthCondition,
  ReviewInformation,
  Result
}

export interface MedicalHistory {
  mhqId?: string;
  isHospitalisedBefore?: boolean;
  hospitalisedBeforeRemarks?: string;
  isAllergy?: boolean;
  allergyRemarks?: string;
  isHealthChanged?: boolean;
  healthChangedRemarks?: string;
  isUnderTreatment?: boolean;
  underTreatmentRemarks?: string;
  isTakingMedication?: boolean;
  takingMedicationRemarks?: string;
  isKidneyTreatment?: boolean;
  isBloodTransfusion?: boolean;
  isExcessiveBleeding?: boolean;
  isPregnant?: boolean;
  deliveryDate?: string;
  isHeartDefect?: boolean;
  isHighBlood?: boolean;
  isHighbloodControlled?: boolean;
  isRheumaticFever?: boolean;
  isHeartMurmur?: boolean;
  isMitralProlapse?: boolean;
  isAnginalPain?: boolean;
  isStroke?: boolean;
  isBloodDisease?: boolean;
  isHaemophilia?: boolean;
  isEpilepsy?: boolean;
  isThyroidDisease?: boolean;
  isRheumatoid?: boolean;
  isKidneyDisease?: boolean;
  isG6PD?: boolean;
  isJaundice?: boolean;
  isHepatitis?: boolean;
  isLiverDisease?: boolean;
  isGastricProblem?: boolean;
  isTumours?: boolean;
  isDiabetes?: boolean;
  isDiabetesControlled?: boolean;
  isTuberculosis?: boolean;
  isRespiratoryDisease?: boolean;
  isAsthma?: boolean;
  isSinusProblem?: boolean;
  isSystemicLupus?: boolean;
  isAids?: boolean;
  othersRemarks?: string;
  isCall?: boolean;
  isSms?: boolean;
  isEmail?: boolean;
  signature?: string;
  signatureDate?: string;
  isPatient?: boolean;
  isParent?: boolean;
  isLegalGuardian?: boolean;
  name?: string;
  contactNo?: string;
  createdDate?: string;
  updatedDate?: string;
  isValid?: boolean;
}

export interface HealthStatus
  extends Partial<
    Pick<
      MedicalHistory,
      | 'isHospitalisedBefore'
      | 'hospitalisedBeforeRemarks'
      | 'isAllergy'
      | 'allergyRemarks'
      | 'isHealthChanged'
      | 'healthChangedRemarks'
      | 'isUnderTreatment'
      | 'underTreatmentRemarks'
      | 'isTakingMedication'
      | 'takingMedicationRemarks'
      | 'isKidneyTreatment'
      | 'isBloodTransfusion'
      | 'isExcessiveBleeding'
      | 'isPregnant'
      | 'deliveryDate'
    >
  > {}

export interface HealthCondition
  extends Partial<
    Pick<
      MedicalHistory,
      | 'isHeartDefect'
      | 'isHighBlood'
      | 'isHighbloodControlled'
      | 'isRheumaticFever'
      | 'isHeartMurmur'
      | 'isMitralProlapse'
      | 'isAnginalPain'
      | 'isStroke'
      | 'isBloodDisease'
      | 'isHaemophilia'
      | 'isEpilepsy'
      | 'isThyroidDisease'
      | 'isRheumatoid'
      | 'isKidneyDisease'
      | 'isG6PD'
      | 'isJaundice'
      | 'isHepatitis'
      | 'isLiverDisease'
      | 'isGastricProblem'
      | 'isTumours'
      | 'isDiabetes'
      | 'isDiabetesControlled'
      | 'isTuberculosis'
      | 'isRespiratoryDisease'
      | 'isAsthma'
      | 'isSinusProblem'
      | 'isSystemicLupus'
      | 'isAids'
      | 'othersRemarks'
    >
  > {}

export interface MedicalHistoryReviewInformation
  extends Partial<Pick<MedicalHistory, 'signature' | 'signatureDate' | 'name' | 'contactNo'>> {
  isAcceptTerm: boolean;
}

export interface MedicalHistoryUpdateResponse {
  mhq_id: string;
}

export interface MedicalHistoryState extends BaseState<MedicalHistory> {
  currentProgress: MedicalHistoryProgressEnum;
  isAcceptTerm: boolean;
  isSigned: boolean;
  previousMedicalHistory?: MedicalHistory;
}

export interface MedicalHistoryFormRef {
  onSaveForm: () => void;
}

export interface MedicalHistoryPayload {
  data: MedicalHistory;
  progress: MedicalHistoryProgressEnum;
}
