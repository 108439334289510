import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { Contact, ContactState } from 'model';

// REDUCER ACTION
import { changeContactLoading, setContact, setManyContacts } from './reducers';

export const initialSingleContact: Contact = {
  id: '',
  name: '',
  relationship: '',
  mobileNumber: '',
  homeNumber: '',
  email: '',
  payer: false,
  emergency: false,
  otherRelationship: ''
};

export const initialState: ContactState = {
  single: initialSingleContact,
  many: [],
  loading: false
};

export const contactSlice = createSlice({
  name: Domain.Contact,
  initialState,
  reducers: {
    changeContactLoading,
    setContact,
    setManyContacts
  }
});

export default contactSlice.reducer;
