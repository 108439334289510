import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// INTERFACE
import { KeyResource } from 'interface';

// MODEL
import { Event, EventState } from 'model';

// ACTION TO CHANGE STATE
export const changeEventLoading: CaseReducer<EventState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setEvent: CaseReducer<EventState, PayloadAction<Event | undefined>> = (state, action) => {
  state.single = action.payload;
};

export const setManyEvents: CaseReducer<EventState, PayloadAction<Event[]>> = (state, action) => {
  state.many = action.payload;
};

export const setEventsMaps: CaseReducer<EventState, PayloadAction<KeyResource<Event>>> = (state, action) => {
  state.eventsMap = action.payload;
};
