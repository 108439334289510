const addFullStop = (msg: string) => {
  const lastChar = msg.slice(-1);
  const stopSymbols = [',', '!', '?'];

  if (stopSymbols.includes(lastChar)) return msg;

  return lastChar === '.' ? msg : `${msg}.`;
};

export const SUPPORT_ERROR_MSG = (msg: string) =>
  `<div>${
    msg !== '' ? `${addFullStop(msg)} ` : ''
  }Please contact <span style="color:green; text-decoration: underline">support@ntuchealth.sg</span> for assistance.</div>`;
