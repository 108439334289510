// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// SERVICE
import { authService } from 'config/auth';

// ROUTER
import { ApiAuth, Router } from 'constant';

// ACTION TYPE
import { GenerateAssociation } from './type';

// SLICE
import { appSlice } from './slice';

// ACTIONS
const { changeAppLoading, setAppMessage, setAssociationCreated, setAppConfiguration, setOpenModalToRefreshApp } =
  appSlice.actions;

let numberOfRetryLogin = 0;

const forgotPasswordCallback = (): AppThunk => async () => {
  const pathName = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);

  if (
    pathName === '/login' &&
    searchParams.has('email') &&
    searchParams.has('success') &&
    searchParams.has('message') &&
    Boolean(searchParams.has('success'))
  ) {
    authService.logInWithRedirect(Router.Home);
  }
};

const copyToClipBoard =
  (data: string, customSuccess?: string): AppThunk =>
  async (dispatch) => {
    navigator.clipboard.writeText(data).then(() => {
      dispatch(
        setAppMessage({
          type: 'success',
          content: customSuccess || 'Copied to clipboard'
        })
      );
    });
  };

const generateAssociation = (): AppThunk => async (dispatch) => {
  dispatch(
    httpAction<unknown, unknown, GenerateAssociation>(
      {
        method: 'POST',
        url: ApiAuth.GenerateAssociation,
        needAuth: true,
        hideToast: true
      },
      async (res) => {
        if (res.httpCode === 200 && res.data && res.data.statusCode === 1000) {
          dispatch(setAssociationCreated(true));

          return;
        }

        // Generate a new token if the status code is 1001
        if (res.httpCode === 200 && res.data && res.data.statusCode === 1001) {
          await authService.generateNewToken(true);

          dispatch(setAssociationCreated(true));

          return;
        }

        if (res.statusCode === 1003 && numberOfRetryLogin < 3) {
          numberOfRetryLogin++;
          await authService.generateNewToken(true);

          dispatch(generateAssociation());
        }

        if (res.statusCode === 1003 && numberOfRetryLogin === 3) {
          dispatch(
            setAppMessage({
              type: 'error',
              ignoreEmailSupportingMessage: true,
              content: 'Error in login, Please re-try!'
            })
          );

          setTimeout(async () => {
            await authService.logOut();
          }, 2000);
        }

        // Logout user if status code is 1005
        if (res.statusCode === 1005) {
          dispatch(
            setAppMessage({
              type: 'error',
              ignoreEmailSupportingMessage: true,
              content: 'Client account does not exist, Please sign-up'
            })
          );

          setTimeout(async () => {
            await authService.logOut();
          }, 2000);
        }

        // Logout user if status code is 1006
        if (res.statusCode === 1006) {
          dispatch(
            setAppMessage({
              type: 'error',
              ignoreEmailSupportingMessage: true,
              content: 'Something went wrong, Please re-login!'
            })
          );

          setTimeout(async () => {
            await authService.logOut();
          }, 2000);
        }

        // Logout user if status code is 1007
        if (res.statusCode === 1007) {
          dispatch(
            setAppMessage({
              type: 'error',
              ignoreEmailSupportingMessage: true,
              content: 'Error in creating user account, Please re-try'
            })
          );

          setTimeout(async () => {
            await authService.logOut();
          }, 2000);
        }

        // Logout user if status code is 1009
        if (res.statusCode === 1009) {
          dispatch(
            setAppMessage({
              type: 'error',
              ignoreEmailSupportingMessage: true,
              content:
                'Oops! It looks like your login session has expired, please refresh the page or log in again to continue.'
            })
          );

          setTimeout(async () => {
            await authService.logOut();
          }, 2000);
        }
      }
    )
  );
};

export const processDownload = async (file: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(
    new Blob([file], {
      type: 'application/pdf'
    })
  );
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const handleDownloadLink =
  (url: string, fileName: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      httpAction<unknown, unknown, Blob>(
        {
          method: 'GET',
          url,
          needAuth: true,
          responseType: 'blob'
        },
        async (res) => {
          if (res.data) {
            await processDownload(res.data, fileName);
          }
        }
      )
    );
  };

export {
  generateAssociation,
  changeAppLoading,
  setAppMessage,
  forgotPasswordCallback,
  handleDownloadLink,
  setAppConfiguration,
  setOpenModalToRefreshApp,
  copyToClipBoard
};
