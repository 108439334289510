const baseUrl = 'cart';

export const CartApi = {
  cart: `${baseUrl}`,
  checkout: `${baseUrl}/checkout`,
  getCheckoutInfo: `${baseUrl}/checkout-info`,
  applyPromotion: `${baseUrl}/applyCoupon`,
  removePromotion: `${baseUrl}/removeCoupon`,
  getGSTConfig: (date: string) => `/gst-configuration/${date}`
};
