// BASE
import { HealthDeclarationForm } from 'model';
import { BaseState } from './base';
import { User } from './user';
import { Contact } from './contact';

export enum LovedOneType {
  MySelf = 'my_self',
  New = 'new_loved_one'
}

export enum RegistrationServiceProgressEnum {
  ServiceSelection = 0,
  YourDetails,
  YourAddress,
  EmergencyContact,
  ReviewInformation,
  CheckoutInformation,
  HealthDeclaration
}

export interface StatusChecks {
  termsAndConditionsAgreed: boolean;
}

export interface Option {
  id: string;
  name: string;
  enabled?: boolean;
  subcategories?: Option[];
  billingCycle?: string;
  code?: string;
  currency?: string;
  description?: string;
  price?: number;
  abbreviation?: string;
  phone?: string;
  pricing?: Option;
  productId?: string;
  sessions?: number;
  bookingEnabled?: boolean;
  alreadyBooked?: boolean;
}

export interface Programme {
  id: number;
  category: string;
  product: string;
  offer: string;
  center: string;
  isExpanded: boolean;
}

export interface ServiceSelection {
  lovedOne: string;
  category: string;
  catergoryName: string;
  marketingChanel: string;
  sourceOthers?: string;
  programmes: Programme[];
}

export interface ServiceRegistration {
  serviceSelection: ServiceSelection;
  lovedOneDetail: User;
  emergencyContact: Contact;
}

export interface ServiceRegistrationState extends BaseState<ServiceRegistration> {
  currentProgress?: RegistrationServiceProgressEnum;
  healthDeclaration?: HealthDeclarationForm;
  isShowWelcomeDialog?: boolean;
  isValidLovedOneDetail?: boolean;
  isValidAddress?: boolean;
  isValidContact?: boolean;
  isValidServiceSelection?: boolean;
  isValidHealthDeclaration?: boolean;
  isHealthDeclarationLoading?: boolean;
  isValidReview?: boolean;
  isValidTermsAndConditions?: boolean;
}

export interface ServiceLink {
  id: string;
  key: string;
  value: string;
}

export interface ServiceLinkResult {
  content: ServiceLink[];
}
