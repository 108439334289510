import React, { FC, useState } from 'react';
import { Div, Grid, Typography } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// COMPONENT

import PaymentCard from 'components/PaymentCard';

import { PaymentSelectionWrapper } from 'views/Payment/PaymentSelection/styles';

// DATA
import { order } from './data';

const PaymentSelection: FC<{}> = () => {
  const { t: locale } = useTranslation(['payment', 'common']);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<'PayNow' | 'AddNew' | undefined>();

  return (
    <PaymentSelectionWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">{locale('payment:payment')}</Typography>
        </Grid>
        <Grid item testId="payment-information-summary" xs={12}>
          <Div
            borderRadius={{
              all: 4
            }}
            padding={{
              all: 16
            }}
            shadowLevel={2}
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="h4">{locale('payment:items')}</Typography>
              </Grid>
              <Grid item xs={9}>
                {order?.items.map((x, index) => (
                  <Typography key={index} variant="body1">
                    {index + 1}.{` `}
                    {x.description}
                  </Typography>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <Typography variant="h4">{locale('payment:totalPayable')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h2">${order?.payableAmount.toFixed(2)}</Typography>
              </Grid>
            </Grid>
          </Div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2">{locale('payment:selectPaymentMethod')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={3} testId="payment-selection-paynow" xs={12}>
              <PaymentCard
                selected={selectedPaymentMethod === 'PayNow'}
                type="PayNow"
                onClick={() => setSelectedPaymentMethod('PayNow')}
              />
            </Grid>
            <Grid item md={3} testId="payment-selection-credit-debit-card" xs={12}>
              <PaymentCard
                selected={selectedPaymentMethod === 'AddNew'}
                type="AddNew"
                onClick={() => setSelectedPaymentMethod('AddNew')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaymentSelectionWrapper>
  );
};

export default PaymentSelection;
