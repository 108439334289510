const spacing = (...factors: number[]) => {
  const spaceBasis = 8;
  const spaces = factors.slice(0, 4).map((f) => {
    const m = Math.abs(f % 1);
    let fx = f;

    // check if decimal number is not exactly 0.5, do ceil or floor;
    if (m !== 0.5) {
      fx = m < 0.5 ? Math.floor(f) : Math.ceil(f);
    }

    return `${fx * spaceBasis}px`;
  });

  return spaces.join(' ');
};

export default spacing;
