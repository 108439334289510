import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { MedicalHistoryState, MedicalHistoryProgressEnum } from 'model';

// REDUCER ACTION
import {
  setMedicalHistory,
  setMedicalHistoryLoading,
  setCurrentProgress,
  setIsAcceptTerm,
  setIsSigned,
  setPreviousMedicalHistory
} from './reducers';

export const initialState: MedicalHistoryState = {
  single: undefined,
  many: [],
  loading: false,
  currentProgress: MedicalHistoryProgressEnum.HealthStatus,
  isAcceptTerm: false,
  isSigned: false,
  previousMedicalHistory: undefined
};

export const medicalHistorySlice = createSlice({
  name: Domain.MedicalHistory,
  initialState,
  reducers: {
    setMedicalHistory,
    setMedicalHistoryLoading,
    setCurrentProgress,
    setIsAcceptTerm,
    setIsSigned,
    setPreviousMedicalHistory,
    reset: () => initialState
  }
});

export default medicalHistorySlice.reducer;
