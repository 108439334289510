import styled from 'styled-components';

export const YourDetailsFormWrapper = styled.div`
  .divider {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;
  }

  .editContainer {
    margin-top: 20px;
  }

  .errorMessage {
    color: red;
    font-size: 14px;
  }
`;
