import React, { FC } from 'react';

import { Typography } from 'ui-kit';

import { InfoItemWrapper } from './styles';

type Props = {
  title?: string;
  content?: string;
};

const InfoItem: FC<Props> = ({ title, content }: Props) => (
  <InfoItemWrapper>
    {title && (
      <Typography className="InforItem-title" fontWeight="bold" testId={`title-${title}`} variant="body1">
        {title}
      </Typography>
    )}
    {content && (
      <Typography className="InforItem-content" testId={`content-${content}`} variant="body1">
        {content}
      </Typography>
    )}
  </InfoItemWrapper>
);

InfoItem.defaultProps = {
  title: '',
  content: ''
};

export default InfoItem;
