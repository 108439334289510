/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Div, Typography, Checkbox } from 'ui-kit';

// CONSTANT
import { LINKS, Router } from 'constant';

// STYLES
import { ReviewFooterWrapper } from 'views/RegisterService/ReviewInformation/styles';

const ReviewFooter: FC = () => {
  const { t: locale } = useTranslation(['registerService', 'common']);

  return (
    <ReviewFooterWrapper>
      <Div testId="footerConfirmCustomerName">
        <Div margin={{ top: 20 }}>
          <Div alignItems="center" display="flex" flexDirection="row" testId="footerCustomerNameAndCheckbox">
            <Checkbox />
            <Typography variant="body1">I, Nhat Nguyen - B883412354</Typography>
          </Div>
        </Div>
        <Div margin={{ left: 40 }}>
          <Typography testId="footerTermAndCondition" variant="body1">
            • {locale('registerService:shallBeDeemedToHaveAgreed')}{' '}
            <a href={`${Router.NewTermAndCondition}`} target="_blank">
              <b className="linkText">{locale('registerService:newTermsAndConditions')}</b>
            </a>{' '}
            {locale('registerService:onTheDate', { date: dayjs().format('DD/MM/YYYY') })}
          </Typography>
        </Div>
        <Div margin={{ left: 40, top: 10 }}>
          <Typography testId="footerConfirmInformation" variant="body1">
            • {locale('registerService:herebyConfirmThatAllInformation')}
          </Typography>
        </Div>
        <Div margin={{ left: 40, top: 10 }}>
          <Typography testId="footerConfirmEmergencyContact" variant="body1">
            • {locale('registerService:herebyAuthoriseTheEmergencyContact')}
          </Typography>
        </Div>
      </Div>

      <Div margin={{ top: 10 }}>
        <Div alignItems="flex-start" display="flex" flexDirection="row" testId="footerConfirmToGivePersonalData">
          <Checkbox />
          <Div margin={{ top: 5 }}>
            <Typography variant="body1">
              {locale('registerService:reviewFooterConfirmMessage1')}{' '}
              <a href={`${LINKS.PDPA}`} target="_blank">
                <b className="linkText">{locale('registerService:ntucHealthPolicy')}</b>
              </a>{' '}
              {locale('registerService:reviewFooterConfirmMessage2')}{' '}
            </Typography>
          </Div>
        </Div>
      </Div>
      <Div margin={{ top: 10 }}>
        <Div alignItems="flex-start" display="flex" flexDirection="row" testId="footerConfirmMarketingConsent">
          <Checkbox style={{ top: -8 }} />
          <Typography variant="body1">{locale('registerService:martketingConsent')}</Typography>
        </Div>
      </Div>
    </ReviewFooterWrapper>
  );
};

export default ReviewFooter;
