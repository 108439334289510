import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { AppState, AppMessage, AppConfiguration } from 'model';

// ACTION TO CHANGE STATE
export const changeAppLoading: CaseReducer<AppState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setAppMessage: CaseReducer<AppState, PayloadAction<AppMessage | undefined>> = (state, action) => {
  state.message = action.payload;
};

export const setAssociationCreated: CaseReducer<AppState, PayloadAction<boolean>> = (state, action) => {
  state.associationCreated = action.payload;
};

export const setAppConfiguration: CaseReducer<AppState, PayloadAction<AppConfiguration>> = (state, action) => {
  state.appConfiguration = action.payload;
};

export const setOpenModalToRefreshApp: CaseReducer<AppState, PayloadAction<boolean>> = (state, action) => {
  state.openModalToRefreshApp = action.payload;
};
