import { RootState } from 'redux/type';

// MODEL
import { Address } from 'model';

// INITIAL STATE
import { initialSingleAddress } from './slice';

export const selectAddressLoadingStatus = (state: RootState): boolean => state.addressReducer.loading;
export const selectAddress = (state: RootState): Address => state.addressReducer.single || initialSingleAddress;
export const selectManyAddresses = (state: RootState): Address[] => state.addressReducer.many || [];
export const selectAddressByType =
  (type: 'RESIDENTIAL' | 'BILLING' | 'SERVICE') =>
  (state: RootState): Address | undefined =>
    state.addressReducer.many!.find((v) => v.type === type);

export const selectListAddNewAddresses = (state: RootState): Address[] => state.addressReducer.listAddNewAddreses || [];

export const selectListUpdateAddresses = (state: RootState): Address[] =>
  state.addressReducer.listUpdatedAddreses || [];
