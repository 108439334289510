import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { SessionState, Session, VacantSlot } from 'model';

// ACTION TO CHANGE STATE
export const changeSessionLoading: CaseReducer<SessionState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setSession: CaseReducer<SessionState, PayloadAction<Session | undefined>> = (state, action) => {
  state.single = action.payload;
};

export const setManySessions: CaseReducer<SessionState, PayloadAction<Session[]>> = (state, action) => {
  state.many = action.payload;
};

export const setCompletedSessions: CaseReducer<SessionState, PayloadAction<Session[]>> = (state, action) => {
  state.completedSessions = action.payload;
};

export const setCancelledSessions: CaseReducer<SessionState, PayloadAction<Session[]>> = (state, action) => {
  state.cancelledSessions = action.payload;
};

export const setCancelledWithChargeSessions: CaseReducer<SessionState, PayloadAction<Session[]>> = (state, action) => {
  state.cancelledWithChargeSessions = action.payload;
};

export const setCancelledWithoutChargeSessions: CaseReducer<SessionState, PayloadAction<Session[]>> = (
  state,
  action
) => {
  state.cancelledWithoutChargeSessions = action.payload;
};

export const setScheduledSessions: CaseReducer<SessionState, PayloadAction<Session[]>> = (state, action) => {
  state.scheduledSessions = action.payload;
};

export const setPreClassSessions: CaseReducer<SessionState, PayloadAction<Session[]>> = (state, action) => {
  state.preClassSessions = action.payload;
};

export const setVacantSlots: CaseReducer<SessionState, PayloadAction<VacantSlot[]>> = (state, action) => {
  state.vacantSlots = action.payload;
};

export const setTotalAvailableSlots: CaseReducer<SessionState, PayloadAction<number>> = (state, action) => {
  state.totalAvailableSlots = action.payload;
};

export const setTotalTimeSlots: CaseReducer<SessionState, PayloadAction<number>> = (state, action) => {
  state.totalTimeSlots = action.payload;
};
