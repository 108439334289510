const intervalAction = (dispatchAction: () => void) => {
  let i = 0;

  if (window.intervalId) {
    clearInterval(window.intervalId);
  }

  const intervalId = setInterval(() => {
    if (i < 5) {
      i += 1;

      dispatchAction();
    }

    if (i === 5) {
      clearInterval(intervalId);
    }
  }, 3000);

  window.intervalId = intervalId;
};

export { intervalAction };
