import React, { FC, ChangeEvent } from 'react';
import { Grid, TextField } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// CONSTANT
import { relationshipList } from 'constant/data';

// PROPS
import { NextOfKinsInputProps } from './interface';

export const NextOfKinsInputForm: FC<NextOfKinsInputProps> = ({ formik, couldChangeEmergency = true, readOnly }) => {
  const { t: locale } = useTranslation(['profile', 'common']);

  const emergencyList: Item<Emergency>[] = [
    { label: locale('common:yes'), value: 'yes' },
    { label: locale('common:no'), value: 'no' }
  ];

  const onChangeRelationship = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === 'Others') {
      formik.setFieldValue('otherRelationship', '');
    }

    formik.setFieldValue('relationship', value);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={5} xs={12}>
          <TextField
            fullWidth
            required
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.errors.name}
            label={locale('profile:preferredName')}
            name="name"
            placeholder={locale('profile:placeholderPreferredName')}
            readOnly={readOnly}
            testId="nokNameField"
            type="text"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={5} xs={12}>
          <TextField
            fullWidth
            required
            error={formik.touched.relationship && Boolean(formik.errors.relationship)}
            helperText={formik.errors.relationship}
            label={locale('profile:relationshipToYou')}
            name="relationship"
            placeholder={locale('profile:placeholderRelationshiptoyou')}
            readOnly={readOnly}
            selectItems={relationshipList}
            testId="nokRelationshipField"
            type="select"
            value={formik.values.relationship}
            onBlur={formik.handleBlur}
            onChange={onChangeRelationship}
          />
        </Grid>
        {formik.values.relationship === 'Others' && (
          <Grid item sm={5} xs={12}>
            <TextField
              fullWidth
              required
              error={formik.touched.otherRelationship && Boolean(formik.errors.otherRelationship)}
              helperText={formik.errors.otherRelationship}
              label={locale('profile:otherRelationship')}
              name="otherRelationship"
              placeholder={locale('profile:otherRelationship')}
              readOnly={readOnly}
              testId="nokOtherRelationshipField"
              type="text"
              value={formik.values.otherRelationship}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={5} xs={12}>
          <TextField
            fullWidth
            required
            error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
            helperText={formik.errors.mobileNumber}
            label={locale('profile:mobileNumber')}
            name="mobileNumber"
            placeholder={locale('profile:placeholderPhoneNumber')}
            readOnly={readOnly}
            testId="nokMobileNumberField"
            type="phone"
            value={formik.values.mobileNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            fullWidth
            error={formik.touched.homeNumber && Boolean(formik.errors.homeNumber)}
            helperText={formik.errors.homeNumber}
            label={`${locale('profile:homeNumber')}`}
            name="homeNumber"
            placeholder={locale('profile:placeholderPhoneNumber')}
            readOnly={readOnly}
            testId="nokHomeNumberField"
            type="phone"
            value={formik.values.homeNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={5} xs={12}>
          <TextField
            fullWidth
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.errors.email}
            label={`${locale('common:email')}`}
            name="email"
            placeholder={locale('profile:placeholderEmail')}
            readOnly={readOnly}
            testId="nokEmailField"
            type="text"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item hidden={!couldChangeEmergency} sm={5} xs={12}>
          <TextField
            fullWidth
            required
            disabled={!couldChangeEmergency}
            error={formik.touched.emergency && Boolean(formik.errors.emergency)}
            helperText={formik.errors.emergency}
            label={locale('profile:emergencyContact')}
            name="emergency"
            readOnly={readOnly}
            selectItems={emergencyList}
            testId="nokEmergencyField"
            type="select"
            value={formik.values.emergency ? 'yes' : 'no'}
            onBlur={formik.handleBlur}
            onChange={(v) => formik.setFieldValue('emergency', v.target.value === 'yes')}
          />
        </Grid>
      </Grid>
    </>
  );
};
