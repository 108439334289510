export const common = {
  address: 'Address',
  apply: 'Apply',
  cancel: 'Cancel',
  create: 'Create',
  clients: "Client's",
  delete: 'Delete',
  edit: 'Edit',
  summit: 'Summit',
  email: 'Email',
  inbox: 'Inbox',
  logOut: 'Log Out',
  back: 'Back',
  message: '{{status}} messages',
  no: 'No',
  optional: 'Optional',
  others: 'Others',
  returnToMyServices: 'Return to my services',
  save: 'Save',
  unread: 'Unread',
  upcommingStatus: 'Upcoming ({{totalUpcoming}})',
  completedStatus: 'Completed ({{totalCompleted}})',
  cancelledStatus: 'Cancelled ({{totalCancelled}})',
  forfeited: 'Forfeited ({{totalForfeited}})',
  view: 'View',
  add: 'Add',
  personal: 'Personal',
  nok: 'Next of Kin',
  personalDataProtectionPolicy: 'Personal Data Protection Policy',
  formContentNok1: 'We will only contact your next of kin if an emergecy happens.',
  formContentNok2:
    'Please enter their preferred name, relationship to you and mobile number if you intend to include a next of kin contact.',
  formContent:
    "This form will take you about 5 to 10 minutes to complete. All information that you provide will be kept strictly confidental in accordance with NTUC Health's",
  // FORM TITLE
  formTitle: '{{action}} {{domain}} Details',
  viewAllMessages: 'View all messages',
  yes: 'Yes',
  centre: 'Centre',
  returnToPaymentHistory: 'Return To Payment History',
  youMayReturnToPage: 'You may return to <green>{{page}}</green> page to',
  errorMsg: "We're sorry, Something went wrong. Please try again!",
  retry: 'Retry',
  selectDate: 'Select date',
  addToCalendar: 'Add to Calendar',
  exitFormMessage: 'Your changes will not be saved',
  deleteData: 'Yes, Delete data',
  next: 'Next',
  appNotificationTitle: 'App Notification',
  appConfirmation: 'App Confirmation',
  appNewVersionBody: 'There is newer version, Click Refresh to upgrade now?',
  refresh: 'Refresh',
  deleteConfirmation: 'Delete Confirmation',
  yourSessionExpired: 'Sorry!! Your session has expired',
  pleaseRefresh: 'Please refresh the page. Don’t worry, we kept all of your filter in place.',
  submit: 'Submit',
  linkPass: 'Linkpass'
};
