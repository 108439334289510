import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { EInvoiceResponse, EInvoiceState } from 'model';

export const changeEInvoiceLoading: CaseReducer<EInvoiceState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const changeShowSomethingWentWrong: CaseReducer<EInvoiceState, PayloadAction<boolean>> = (state, action) => {
  state.showSomethingWentWrong = action.payload;
};

export const changeShowLinkExpired: CaseReducer<EInvoiceState, PayloadAction<boolean>> = (state, action) => {
  state.linkExpired = action.payload;
};

export const changeShowAlreadyPaid: CaseReducer<EInvoiceState, PayloadAction<boolean>> = (state, action) => {
  state.alreadyPaid = action.payload;
};

export const setEInvoiceData: CaseReducer<EInvoiceState, PayloadAction<EInvoiceResponse | undefined>> = (
  state,
  action
) => {
  state.data = action.payload;
};
