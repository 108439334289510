import styled from 'styled-components';

const AnimationButton = styled.button<{ show?: boolean }>`
  /**
		* We're going to use multiple box-shadows to make the button throb. we can only
		* have one "box-shadow" property on a class; but, each "box-shadow" property can
		* contain multiple shadows, each of which can be animated independently(ish).
		*/

  animation-duration: 1750ms;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: button-shadow-throb;
  animation-timing-function: linear;
  height: 60px;
  width: 60px;
  border-radius: 50%;

  display: ${(props) => (props.show ? 'block' : 'none')};

  .MuiSvgIcon-fontSizeLarge {
    font-size: 40px;
  }

  &:hover {
    cursor: pointer;
  }

  /**
		* The box-shadow property takes a comma-delimited list of shadows. To make the
		* button "throb", each shadow is going to define a "ring" around the button that
		* grows outward using the "spread-radius" (4th short-hand value). Our three
		* shadows define three rings that have a staggered outward animation.
		*/
  @keyframes button-shadow-throb {
    0% {
      box-shadow: 0px 0px 0px 0px rgb(0, 156, 166), /* Ring three - hidden. */ 0px 0px 0px 0px rgb(0, 156, 166),
        /* Ring two - hidden. */ 0px 0px 0px 0px rgb(0, 156, 166) /* Ring one - hidden. */;
    }
    15% {
      box-shadow: 0px 0px 0px 0px rgb(0, 156, 166), 0px 0px 0px 0px rgb(0, 156, 166),
        0px 0px 0px 5px rgb(0, 156, 166) 0 /* Ring one - enter. */;
    }
    30% {
      box-shadow: 0px 0px 0px 0px rgb(0, 156, 166), 0px 0px 0px 5px rgb(0, 156, 166),
        /* Ring two - enter. */ 0px 0px 0px 10px #007aa6;
    }
    45% {
      box-shadow: 0px 0px 0px 5px rgb(0, 156, 166), /* Ring three - enter. */ 0px 0px 0px 10px #007aa6,
        0px 0px 0px 15px #007cff20;
    }
    /**
			* Once each ring reaches its outer spread-radius, it's going to fade out using
			* the alpha-channel on the RGB(A) hex color definition. Notice that the alpha-
			* channels go from "80" to "00" over the next couple of keyframes.
			*/
    60% {
      box-shadow: 0px 0px 0px 10px #007aa6, 0px 0px 0px 15px #007cff20, 0px 0px 15px 15px #007cff00;
    }
    75% {
      box-shadow: 0px 0px 0px 15px #007cff20, 0px 0px 15px 15px #007cff00, 0px 0px 15px 15px #007cff00;
    }
    90% {
      box-shadow: 0px 0px 15px 15px #007cff00, 0px 0px 15px 15px #007cff00, 0px 0px 15px 15px #007cff00;
    }
    100% {
      box-shadow: 0px 0px 15px 15px #007cff00, 0px 0px 15px 15px #007cff00, 0px 0px 15px 15px #007cff00;
    }
  }
`;

export default AnimationButton;
