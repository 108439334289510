import { useState, useEffect, useCallback } from 'react';
import { isEmpty, isArray } from 'lodash';
import dayjs from 'dayjs';

// HOOKS
import { useAppDispatch } from 'hooks/redux';

// MODEL
import { Product } from 'model';

// redux
import { getProductDetails } from 'redux/product/actions';
import { getAllIntakes } from 'redux/intake/actions';
import { getEventByProduct } from 'redux/event/actions';
import { getTrainingDaysForFixedSession } from 'redux/service-registration/actions';
import { useAppSelector } from 'hooks';
import { getSelectedTerm } from 'redux/service/selector';

export interface Props {
  productId: string;
  centerId: string;
  offerId: string;
  productName: string;
  centerName: string;
}

export interface ProductInfo {
  title: string;
  contents: string[];
  productDetails?: Product;
}

export const useProductInfo = ({
  productId,
  centerId,
  offerId,
  productName,
  centerName
}: Props): ProductInfo | undefined => {
  const dispatch = useAppDispatch();
  const selectedTerm = useAppSelector(getSelectedTerm);

  const [info, setInfo] = useState<ProductInfo>();
  const [productDetails, setProductDetails] = useState<Product>();

  const fetchProductDetails = useCallback(() => {
    dispatch(
      getProductDetails(productId, (data) => {
        setProductDetails(data);
      })
    );
  }, [productId, dispatch]);

  const getEvents = useCallback(() => {
    dispatch(
      getEventByProduct(productId, (data) => {
        if (isArray(data)) {
          setInfo({ title: 'Available Activities', contents: data.map((v, index) => `${index + 1}. ${v.name}`) });
        }
      })
    );
  }, [productId, dispatch]);

  const getAvailableRunsOffProduct = useCallback(() => {
    if (offerId.length > 0 && centerId.length > 0) {
      dispatch(
        getAllIntakes({ termId: selectedTerm?.id, center: centerId, offering: offerId, limit: 2 }, (data) => {
          if (isArray(data)) {
            setInfo({
              title: 'Available Runs',
              contents: data.map(
                (v, index) =>
                  `${index + 1}. ${dayjs(v.fromDate).format('DD MMM YYYY')} - ${dayjs(v.toDate).format(
                    'DD MMM YYYY'
                  )}\n${v.recurrence}`
              )
            });
          }
        })
      );
    }
  }, [centerId, offerId, dispatch, selectedTerm]);

  const getTrainingDays = useCallback(() => {
    if (!isEmpty(productName) && !isEmpty(centerName)) {
      dispatch(
        getTrainingDaysForFixedSession(productName, centerName, (data) => {
          setInfo({ title: 'Training days', contents: [data?.value ?? ''] });
        })
      );
    }
  }, [dispatch, productName, centerName]);

  useEffect(() => {
    if (!isEmpty(productId)) {
      fetchProductDetails();
    }
  }, [fetchProductDetails, productId]);

  useEffect(() => {
    if (productDetails) {
      const { programContentType, fixedSessions } = productDetails;

      if (programContentType === 'SESSIONS') {
        if (fixedSessions) {
          getTrainingDays();
        } else {
          getEvents();
        }
      } else if (programContentType === 'INTAKES') {
        getAvailableRunsOffProduct();
      }
    }
  }, [getAvailableRunsOffProduct, getEvents, productDetails, getTrainingDays]);

  if (!info) return undefined;

  return {
    title: info.title,
    contents: info.contents,
    productDetails
  };
};
