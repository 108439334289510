export const baseUrl = 'linkpay';

export const ApiLinkPay = {
  GetCards: `${baseUrl}/cards`,
  AddCard: `${baseUrl}/cards`,
  DeleteCard: (cardId: number) => `${baseUrl}/cards/${cardId}`,
  SetCardDefault: (cardId: number) => `${baseUrl}/cards/${cardId}`,
  AuthenticateCard: (cardId: number) => `${baseUrl}/cards/${cardId}/authenticate`,
  CreateNewPayment: `${baseUrl}/payments`,
  AuthenticatePayment: (paymentId: number) => `${baseUrl}/payments/${paymentId}/authenticate`
};
