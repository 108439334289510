import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// INTERFACE
import { KeyResource } from 'interface';

// MODEL
import { Offer, OfferState } from 'model';

// ACTION TO CHANGE STATE
export const changeOfferLoading: CaseReducer<OfferState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setOffer: CaseReducer<OfferState, PayloadAction<Offer>> = (state, action) => {
  state.single = action.payload;
};

export const setManyOffers: CaseReducer<OfferState, PayloadAction<Offer[]>> = (state, action) => {
  state.many = action.payload;
};

export const setOffersMaps: CaseReducer<OfferState, PayloadAction<KeyResource<Offer>>> = (state, action) => {
  state.offersMap = action.payload;
};
