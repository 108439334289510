import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { ServiceRegistrationState, RegistrationServiceProgressEnum } from 'model';

// SLICE
import { initialSingleUser } from 'redux/user/slice';
import { initialSingleContact } from 'redux/contact/slice';

// REDUCER ACTION
import {
  changeLoading,
  changeServiceSelection,
  setShowWelcomeDialog,
  setProgress,
  changeProgramme,
  changeLovedOneDetailStatus,
  changeAddressStatus,
  changeContactStatus,
  changeLovedOneDetail,
  changeReviewStatus,
  changeTermsAndConditionsStatus,
  changeEmergencyContact,
  changeHealthDeclarationStatus,
  setHealthDeclaration,
  setHealthDeclarationLoading
} from './reducers';

export const initialProgramme = {
  id: 1,
  category: '',
  center: '',
  product: '',
  offer: '',
  isExpanded: true
};

export const initialServiceSelection = {
  lovedOne: '',
  category: '',
  catergoryName: '',
  marketingChanel: '',
  sourceOthers: '',
  programmes: []
};

export const initialServiceRegistration = {
  serviceSelection: initialServiceSelection,
  lovedOneDetail: initialSingleUser,
  emergencyContact: initialSingleContact
};

export const initialState: ServiceRegistrationState = {
  single: initialServiceRegistration,
  healthDeclaration: {
    healthConditions: [],
    healthConditionsOther: null,
    injureAnyOfTheseBodyParts: [],
    injureAnyOfTheseBodyPartsOther: null,
    timesExerciseAWeek: '',
    whyJoinOurExerciseProgramme: [],
    whyJoinOurExerciseProgrammeOther: null
  },
  loading: false,
  isShowWelcomeDialog: false,
  currentProgress: RegistrationServiceProgressEnum.ServiceSelection,
  isValidLovedOneDetail: false,
  isValidAddress: false,
  isValidContact: false,
  isValidServiceSelection: false,
  isValidHealthDeclaration: false,
  isHealthDeclarationLoading: false,
  isValidReview: false,
  isValidTermsAndConditions: false
};

export const serviceRegistrationSlice = createSlice({
  name: Domain.RegisterService,
  initialState,
  reducers: {
    changeLoading,
    setShowWelcomeDialog,
    setProgress,
    changeServiceSelection,
    changeProgramme,
    changeLovedOneDetailStatus,
    changeAddressStatus,
    changeContactStatus,
    changeLovedOneDetail,
    changeReviewStatus,
    changeTermsAndConditionsStatus,
    changeEmergencyContact,
    changeHealthDeclarationStatus,
    setHealthDeclaration,
    setHealthDeclarationLoading,
    reset: () => initialState
  }
});

export default serviceRegistrationSlice.reducer;
