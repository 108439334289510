import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { Cart, CartState } from 'model';

// REDUCER ACTION
import { changeCartLoading, setCart, setCartCheckoutInfo, setGSTConfig } from './reducers';

export const initialSingleCart: Cart = {
  categoryId: '',
  customerId: '',
  referralSource: {
    source: '',
    sourceOthers: ''
  },
  items: [],
  marketingConsent: false
};

export const initialState: CartState = {
  single: initialSingleCart,
  checkoutInfo: undefined,
  loading: false,
  gstConfig: undefined
};

export const cartSlice = createSlice({
  name: Domain.Cart,
  initialState,
  reducers: {
    changeCartLoading,
    setCart,
    setCartCheckoutInfo,
    setGSTConfig
  }
});

export default cartSlice.reducer;
