import { FC, useState, useCallback, useEffect } from 'react';
import { isEqual, debounce } from 'lodash';

import { BaseFormikProps } from './interface';

export const FormikSubmitListener: FC<BaseFormikProps> = ({ formik, onChangeValidateStatus }) => {
  const [lastValues, updateState] = useState(formik.values);

  const submitForm = useCallback(
    debounce(
      (isValidForm: boolean, formValues: any): void => {
        if (onChangeValidateStatus) onChangeValidateStatus(isValidForm, formValues);

        if (isValidForm) {
          formik.submitForm();
        }
      },
      500,
      { maxWait: 1500 }
    ),
    []
  );

  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values);
    const valuesEqualInitialValues = isEqual(formik.values, formik.initialValues);

    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }

    const isValidForm = valuesEqualLastValues && !valuesEqualInitialValues && formik.isValid;

    submitForm(isValidForm, formik.values);
  }, [formik.values, formik.isValid, lastValues, formik.initialValues, submitForm]);

  return null;
};
