import axios, { AxiosRequestConfig } from 'axios';

// KEY
import { getValue } from 'helpers/localstorage';

// AUTH
import { authService } from 'config/auth';

export interface Response<K> {
  httpCode: number;
  error?: string;
  data?: K;
  statusCode?: number;
  responseCode?: number;
  message?: string;
  errorCode?: string;
  errorMessage?: string;
}

// L: Body Type
// K: Params Type
// M: Response Type
export interface CustomAxiosRequest<L, K> extends AxiosRequestConfig {
  data?: L;
  params?: K;
  needAuth: boolean;
  isFormData?: boolean;
  hideToast?: boolean;
}

class BaseHttpService {
  // L: Body Type
  // K: Params Type
  // M: Response Type
  private async callApi<L, K, M>(requestConfig: CustomAxiosRequest<L, K>): Promise<Response<M>> {
    const clientId = getValue<string>('@clientId');

    let newRequestConfig: AxiosRequestConfig & { 'view-as'?: string } = {
      baseURL: process.env.REACT_APP_API_URL,
      ...requestConfig
    };

    const env = process.env.REACT_APP_ENV;

    if (requestConfig.needAuth && env !== 'test') {
      const fetchToken = await authService.generateNewToken(false);

      if (!fetchToken.token) {
        return {
          httpCode: 401,
          error: 'Your token is expired',
          statusCode: 401
        };
      }

      let headers =
        clientId && clientId.length > 0
          ? {
              authorization: `Bearer ${fetchToken.token}`,
              'Content-Type': requestConfig.isFormData ? 'multipart/form-data' : 'application/json',
              'view-as': clientId
            }
          : {
              authorization: `Bearer ${fetchToken.token}`,
              'Content-Type': requestConfig.isFormData ? 'multipart/form-data' : 'application/json'
            };

      if (requestConfig.headers) {
        headers = {
          ...headers,
          ...requestConfig.headers
        };
      }

      newRequestConfig = {
        ...newRequestConfig,
        headers
      };
    }

    try {
      let request;

      switch (newRequestConfig.method) {
        case 'GET':
          request = await axios.get(newRequestConfig.url!, { ...newRequestConfig });
          break;

        case 'POST':
          request = await axios.post(newRequestConfig.url!, newRequestConfig.data, { ...newRequestConfig });
          break;

        case 'PUT':
          request = await axios.put(newRequestConfig.url!, newRequestConfig.data, { ...newRequestConfig });
          break;

        case 'PATCH':
          request = await axios.patch(newRequestConfig.url!, newRequestConfig.data, { ...newRequestConfig });
          break;

        case 'DELETE':
          request = await axios.delete(newRequestConfig.url!, { ...newRequestConfig });
          break;

        default:
          request = await axios({ ...newRequestConfig });
          break;
      }

      return {
        httpCode: 200,
        data: request.data,
        statusCode: request.data && request.data.statusCode
      };
    } catch (error: any) {
      const errorRes = error.response;

      // Status Code is 1 if status code from api is either null, undefined or 0
      return {
        httpCode: errorRes ? errorRes.status : 500,
        error:
          errorRes && errorRes.status === 500
            ? ''
            : errorRes && errorRes.data && errorRes.data.message
            ? errorRes.data.message
            : '',
        statusCode: errorRes && errorRes.data && errorRes.data.statusCode ? errorRes.data.statusCode : 1,
        message: errorRes && errorRes.data && errorRes.data.message,
        errorCode: errorRes && errorRes.data && errorRes.data.errorCode,
        errorMessage: errorRes && errorRes.data && errorRes.data.errorMessage
      };
    }
  }

  // L: Body Type
  // K: Params Type
  // M: Response Type
  execute<L, K, M>(requestConfig: CustomAxiosRequest<L, K>): Promise<Response<M>> {
    return this.callApi<L, K, M>({ ...requestConfig });
  }
}

export const httpService = new BaseHttpService();
