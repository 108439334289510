import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { UserState, User } from 'model';

// ACTION TO CHANGE STATE
export const changeUserLoading: CaseReducer<UserState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setUser: CaseReducer<UserState, PayloadAction<User>> = (state, action) => {
  state.single = action.payload;
};

export const setLoggedInUser: CaseReducer<UserState, PayloadAction<User>> = (state, action) => {
  state.loggedInUser = action.payload;
};

export const setManyUsers: CaseReducer<UserState, PayloadAction<User[]>> = (state, action) => {
  state.many = action.payload;
};
