/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// UIKit
import { Div, Typography } from 'ui-kit';

// CONSTANT
import { LINKS } from 'constant';

// COMPONENTS
import { AddressForm } from 'views/Addresses';

// STYLE
import { RegisterServiceContainer } from 'page/RegisterService/styles';

// DATA
import { addresses } from './data';

const YourAddress: FC = () => {
  const { t: locale } = useTranslation(['registerService', 'profile']);

  return (
    <RegisterServiceContainer>
      <Div>
        <Typography className="title" variant="h2">
          3. {locale('registerService:yourAddress')}
        </Typography>
        <Typography className="guideText" variant="body1">
          {locale('profile:formAddressContent')}{' '}
          <a href={`${LINKS.PDPA}`} target="_blank">
            <b className="linkText">{locale('registerService:personalDataProtectionPolicy')}</b>
          </a>
        </Typography>

        <Div className="divider" margin={{ top: 20, bottom: 20 }} />

        <Typography className="guideText" variant="body1">
          {locale('registerService:appropriateAddressText')}
        </Typography>
        <AddressForm
          readOnly
          addresses={addresses}
          title={locale('profile:addBillingAddressDetails')}
          viewMode="create"
          viewType="registerService"
          onChangeAddress={() => {}}
        />
      </Div>
    </RegisterServiceContainer>
  );
};

export default YourAddress;
