import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { OrderState, Order } from 'model';

// REDUCER ACTION
import { changeOrderLoading, setManyOrders, setOrder, setOrderStatus } from './reducers';

export const initialSingleOrder: Order = {
  id: '',
  customerId: '',
  customerName: '',
  payerId: '',
  payerName: '',
  orderNumber: '',
  subTotalAmount: 0,
  discountAmount: 0,
  createdDate: '',
  gstAmount: 0,
  totalAmount: 0,
  payableAmount: 0,
  status: '',
  address: {
    type: '',
    postalCode: '',
    block: '',
    street: '',
    level: '',
    unit: '',
    building: ''
  },
  items: [],
  paymentTransaction: undefined
};

export const initialState: OrderState = {
  single: initialSingleOrder,
  many: [],
  orderStatus: undefined,
  loading: false
};

export const orderSlice = createSlice({
  name: Domain.Order,
  initialState,
  reducers: {
    changeOrderLoading,
    setManyOrders,
    setOrder,
    setOrderStatus
  }
});

export default orderSlice.reducer;
