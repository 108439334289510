export interface EGiroFAQ {
  question: string;
  answer: string;
}

export const FAQData: EGiroFAQ[] = [
  {
    question: 'Which banks are currently participating in eGIRO?',
    answer:
      'There are 8 participating banks: DBS Bank, Bank of China, HSBC, ICBC, Maybank, OCBC and UOB. Customers can select eligible accounts held with these participating banks for the setup of the GIRO arrangement digitally'
  },
  {
    question: "What if I don't have an account with a participating bank?",
    answer:
      'You will need to establish a banking relationship with one of the participating banks in order to set up a direct debit via eGIRO.'
  },
  {
    question: 'When can I see the deduction in my bank account?',
    answer:
      'A deduction will only be made from your bank account on the 20th of each month. The amount deducted will be reflected in your bank statement and monthly bills. If the 20th falls on weekends or public holidays, deduction will take place on the next business day.'
  }
];
