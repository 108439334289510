import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { SessionState } from 'model';

// REDUCER ACTION
import {
  changeSessionLoading,
  setCancelledSessions,
  setCompletedSessions,
  setManySessions,
  setScheduledSessions,
  setPreClassSessions,
  setSession,
  setCancelledWithChargeSessions,
  setCancelledWithoutChargeSessions,
  setTotalAvailableSlots,
  setTotalTimeSlots,
  setVacantSlots
} from './reducers';

export const initialState: SessionState = {
  single: undefined,
  many: [],
  cancelledSessions: [],
  cancelledWithChargeSessions: [],
  cancelledWithoutChargeSessions: [],
  completedSessions: [],
  scheduledSessions: [],
  preClassSessions: [],
  loading: true,
  vacantSlots: [],
  totalAvailableSlots: 0,
  totalTimeSlots: 0
};

export const sessionSlice = createSlice({
  name: Domain.Sessions,
  initialState,
  reducers: {
    changeSessionLoading,
    setCancelledSessions,
    setCompletedSessions,
    setManySessions,
    setScheduledSessions,
    setPreClassSessions,
    setSession,
    setCancelledWithChargeSessions,
    setCancelledWithoutChargeSessions,
    setTotalAvailableSlots,
    setTotalTimeSlots,
    setVacantSlots
  }
});

export default sessionSlice.reducer;
