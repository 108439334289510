import React, { FC } from 'react';
import { Div, Typography } from 'ui-kit';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';

// VIEW COMPONENTS
import { AddressItem } from 'views/Addresses';

// STYLES
import { ReviewItemWrapper } from 'views/RegisterService/ReviewInformation/styles';

// DATA
import { addresses } from './data';

const ReviewAddress: FC = () => {
  const { t: locale } = useTranslation(['registerService', 'common']);

  return (
    <ReviewItemWrapper>
      <Div className="header" testId="reviewAddressTitle">
        <Typography variant="h3">3. {locale('registerService:yourAddress')}</Typography>
      </Div>
      {isArray(addresses) &&
        addresses.map((item, index) => <AddressItem key={item.id} index={index} item={item} viewType="userProfile" />)}
    </ReviewItemWrapper>
  );
};

export default ReviewAddress;
