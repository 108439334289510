/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isArray } from 'lodash';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';

// UIKit
import { Typography, Div, TextField, Card, CardHeader, CardBody, Button, Collapse } from 'ui-kit';

// MODEL
import { Option, Offer } from 'model';

// Helpers
import { mappingOptionsToSelects } from 'helpers/array';

// PROPS
import { ProgrammeFormProps } from 'views/RegisterService/ServiceSelection/form/interface';

// Components
import { CenterDetailsGreenBox } from './CenterDetailsGreenBox';

// MockData
import {
  center as mockCenter,
  offers as mockOffers,
  subCategories as mockSubCategories,
  products as mockProducts
} from './data';

export const ProgrammeForm: FC<Partial<ProgrammeFormProps>> = ({
  serviceName,
  data,
  index,
  onDeleteProgramme,
  serviceLinks,
  isMobile
}) => {
  const { t: locale } = useTranslation(['registerService', 'common']);

  const isLoading = false;
  const offers: Offer[] = mockOffers;
  const centers: Option[] = mockCenter;
  const subCategories: Option[] = mockSubCategories;
  const products: Option[] = mockProducts;

  const selectCategories = mappingOptionsToSelects(subCategories);
  const selectProducts = mappingOptionsToSelects(products);
  const selectOffers = isArray(offers)
    ? offers.map((v) => ({ value: v.id!, label: `${v.name} - $${(v.pricing?.price ?? 0).toFixed(2)}` }))
    : [];
  const selectCenters = mappingOptionsToSelects(centers);

  const categoryName = subCategories?.find((v) => v.id === data!.category)?.name ?? '';
  const productName = products?.find((v) => v.id === data!.product)?.name ?? '';
  const selectedOffer = offers?.find((v) => v.id === data!.offer);
  const centerName = centers?.find((v) => v.id === data!.center)?.name ?? '';
  const programmeLink = serviceLinks?.find((v) => v.key === serviceName)?.value ?? '';
  const productLink = serviceLinks?.find((v) => v.key === `${serviceName} ${categoryName}`)?.value ?? '';
  const locationLink = serviceLinks?.find((v) => v.key === `${serviceName} Locations`)?.value ?? '';

  const sectionTitle = `${categoryName}${!isEmpty(selectedOffer) ? ` - ${selectedOffer?.name}` : ''}${
    !isEmpty(centerName) ? ` at ${centerName}` : ''
  }`;

  const renderSubHeader = () => (
    <Div display="flex" flexDirection="row" justifyContent="flex-end">
      <Button
        variant="secondary"
        onClick={() => {
          if (data && onDeleteProgramme) {
            onDeleteProgramme(data);
          }
        }}
      >
        {locale('common:delete')}
      </Button>

      <Button withoutBorder className="expandButton" variant="secondary">
        {data!.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
    </Div>
  );

  return (
    <Div margin={{ top: 20 }}>
      <Card>
        <CardHeader
          withBorder
          backgroundColor="grey"
          headerStatus="upcoming"
          subHeader={renderSubHeader()}
          title={`#${index! + 1}. ${sectionTitle}`}
          titleTypographyProps={{ variant: 'h3' }}
        />
        <CardBody>
          <Collapse isOpen={data!.isExpanded}>
            <Div margin={{ all: 20 }}>
              <Div testId="category-selection">
                <Typography className="fieldTitle" variant="h4">
                  {`${locale('registerService:whichCategoryOfProgramme')}`}
                  &nbsp;&nbsp;
                  {locale('registerService:learnMoreProgram')}{' '}
                  <a href={programmeLink} target="_blank">
                    <b className="linkText">{locale('registerService:programmes')}</b>
                  </a>{' '}
                  {locale('registerService:weOffer')}
                </Typography>

                <Div className="inputContainer" margin={{ top: 10 }}>
                  {!isLoading && isEmpty(selectCategories) ? (
                    <Typography variant="body1">{locale('registerService:emptyServiceSelectionMsg')}</Typography>
                  ) : (
                    <TextField
                      fullWidth
                      readOnly
                      placeholder={locale('registerService:selectCategory')}
                      selectItems={selectCategories}
                      testId="selectCategory"
                      type="select"
                      value={data!.category}
                    />
                  )}
                </Div>
              </Div>

              {!isEmpty(data!.category) && (
                <>
                  <Div className="lineDivider" />
                  <Div margin={{ top: 20 }} testId="program-selection">
                    <Typography className="fieldTitle" variant="h4">
                      {`${locale('registerService:whichProgrammeRegister', { categoryName })}`}
                      &nbsp;&nbsp;
                      {locale('registerService:learnMoreProgram')}{' '}
                      <a href={productLink} target="_blank">
                        <b className="linkText">{locale('registerService:packages')}</b>
                      </a>{' '}
                      {locale('registerService:weOffer')}
                    </Typography>

                    <Div className="inputContainer" margin={{ top: 10 }}>
                      {!isLoading && isEmpty(selectProducts) ? (
                        <Typography variant="body1">{locale('registerService:emptyServiceSelectionMsg')}</Typography>
                      ) : (
                        <TextField
                          fullWidth
                          readOnly
                          placeholder={locale('registerService:selectCategory')}
                          selectItems={selectProducts}
                          testId="whichProgrammeRegisterDropdown"
                          type="select"
                          value={data!.product}
                        />
                      )}
                    </Div>
                  </Div>
                </>
              )}

              {!isEmpty(data!.product) && (
                <>
                  <Div className="lineDivider" />
                  <Div margin={{ top: 20 }} testId="package-selection">
                    <Typography className="fieldTitle" variant="h4">
                      {locale('registerService:selectPreferredPackage', { productName })}
                    </Typography>
                    <Div className="inputContainer" margin={{ top: 10 }}>
                      {!isLoading && isEmpty(selectOffers) ? (
                        <Typography variant="body1">{locale('registerService:emptyServiceSelectionMsg')}</Typography>
                      ) : (
                        <TextField
                          fullWidth
                          readOnly
                          placeholder={locale('registerService:selectPackage')}
                          selectItems={selectOffers}
                          testId="selectPreferredPackageDropdown"
                          type="select"
                          value={data!.offer}
                        />
                      )}
                    </Div>
                  </Div>
                </>
              )}

              {!isEmpty(data!.offer) && (
                <>
                  <Div className="lineDivider" />
                  <Div margin={{ top: 20 }} testId="location-selection">
                    <Typography className="fieldTitle" testId="selectPreferredLocationTitle" variant="h4">
                      {`${locale('registerService:selectPreferredLocation', { productName })}`}
                      &nbsp;&nbsp;
                      {locale('registerService:learnMoreAboutOur')}{' '}
                      <a href={locationLink} target="_blank">
                        <b className="linkText">{locale('registerService:locations')}</b>
                      </a>
                    </Typography>

                    <Div className="inputContainer" margin={{ top: 10 }}>
                      {!isLoading && isEmpty(selectCenters) ? (
                        <Typography variant="body1">{locale('registerService:emptyServiceSelectionMsg')}</Typography>
                      ) : (
                        <TextField
                          fullWidth
                          readOnly
                          placeholder={locale('registerService:selectLocation')}
                          selectItems={selectCenters}
                          testId="selectPreferredLocationDropdown"
                          type="select"
                          value={data!.center}
                        />
                      )}
                    </Div>
                  </Div>
                </>
              )}

              {!isEmpty(data!.center) && (
                <CenterDetailsGreenBox
                  centerId={data!.center}
                  centerName={centerName}
                  isMobile={isMobile}
                  offerId={data!.offer}
                  productId={data!.product}
                  productName={productName}
                />
              )}
            </Div>
          </Collapse>
        </CardBody>
      </Card>
    </Div>
  );
};
