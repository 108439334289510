import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { OrderState, Order, OrderStatus } from 'model';

// ACTION TO CHANGE STATE
export const changeOrderLoading: CaseReducer<OrderState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setOrder: CaseReducer<OrderState, PayloadAction<Order>> = (state, action) => {
  state.single = action.payload;
};

export const setManyOrders: CaseReducer<OrderState, PayloadAction<Order[]>> = (state, action) => {
  state.many = action.payload;
};

export const setOrderStatus: CaseReducer<OrderState, PayloadAction<OrderStatus>> = (state, action) => {
  state.orderStatus = action.payload;
};
