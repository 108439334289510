import { NHColor } from 'ui-kit';
import styled from 'styled-components';
import { spacing } from 'helpers/styles';

export const ReviewContactItemWrapper = styled.div`
  .withoutBorderContainer {
    padding: 0px;
    border-bottom: 0px;
  }

  .content {
    margin-top: 0px;
  }
`;

export const ReviewItemWrapper = styled.div`
  border: 1px solid ${NHColor.charcoal300};
  box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.15), 0px 1px 5px rgba(152, 162, 179, 0.15);
  border-radius: 4px;

  .header {
    display: flex;
    flex-direction: row;
    padding: ${spacing(2)};
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${NHColor.charcoal300};

    h3 {
      color: ${NHColor.charcoal700};
    }
  }

  .content {
    padding: ${spacing(3)};
    border-bottom: 1px solid ${NHColor.grey200};
  }

  .textTitle {
    font-weight: bold;
    overflow-wrap: break-word;
  }

  .textContent {
    margin-top: 10px;
    font-weight: normal;
  }

  .divider {
    border: 1px solid ${NHColor.charcoal300};
    height: 0px;
    margin-right: ${spacing(2)};
  }
`;

export const ReviewFooterWrapper = styled.div`
  margin-left: -10px;
  .divider {
    margin-top: 20px;
    border: 1px solid ${NHColor.charcoal300};
    height: 0px;
  }

  .linkText {
    color: ${NHColor.green600};
    text-decoration: underline;
  }
`;
