import { string, object } from 'yup';

import { Address } from 'model';

export const addressSchema = object<Address>({
  block: string().required('Block is required'),
  id: string().optional(),
  level: string().optional(),
  postalCode: string()
    .matches(/^\d{6}$/, 'Postal code is invalid')
    .required('Postal code is required'),
  street: string().required('Address line 1 is required'),
  building: string().optional(),
  type: string().nullable().required('Type of residence is required'),
  unit: string().optional()
}).defined();
