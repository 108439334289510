import { FC } from 'react';
import styled from 'styled-components';
import { Div, NHColor, DivProps, Typography } from 'ui-kit';

type PaymentCardWrapperProps = FC<DivProps & { selected: boolean }>;

export const PaymentCardWrapper = styled<PaymentCardWrapperProps>(Div)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border: ${(props: { selected: boolean }) =>
    `${props.selected ? '2px' : '1px'} solid ${props.selected ? NHColor.green600 : NHColor.charcoal500}`};
`;

export const CardNumberWrapper = styled(Typography)`
  margin-top: 5px;
`;
