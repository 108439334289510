const baseUrl = 'profile';

export const ApiProfile = {
  Profile: baseUrl,
  ProfilePicture: `${baseUrl}/picture`,
  Contact: `${baseUrl}/contact`,
  Address: `${baseUrl}/address`,
  ProfileLinking: `${baseUrl}/linking`,
  ResendCode: `${baseUrl}/referral-code/send`,
  GetProfileByNric: (nric: string) => `${baseUrl}/nric/${nric}`
};
