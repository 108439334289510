import React, { FC, useState, useEffect } from 'react';
import { Grid, Div, Button, Typography, Checkbox } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// TYPES
import { Address } from 'model';

import { initialSingleAddress } from 'redux/address/slice';

// STYLE
import { AddressWrapper, NotApplicableText } from '../styles';

// PROPS
import { AddressFormProps } from './interface';

// BASE ADDRESS INPUT FORM
import { AddressFormik } from './AddressFormik';

export const AddressForm: FC<AddressFormProps> = ({
  onCancel,
  onSubmit,
  data,
  addresses,
  title,
  viewType = 'userProfile',
  viewMode,
  onChangeAddress,
  isHideServiceAddress = false,
  readOnly
}) => {
  const { t: locale } = useTranslation(['profile', 'common', 'service']);

  const initialBillingAddress = { ...initialSingleAddress, type: 'BILLING' };
  const initialServiceAddress = { ...initialSingleAddress, type: 'SERVICE', sameAs: 'BILLING' };
  const initialResidentialAddress = { ...initialSingleAddress, type: 'RESIDENTIAL', sameAs: 'BILLING' };

  const [updateAddresses, setUpdateAddresses] = useState<Address[]>([]);

  const getFormTitle = (type: string): string => {
    let formTitle = '';

    if (viewMode === 'create') {
      switch (type) {
        case 'BILLING':
          formTitle = locale('profile:addBillingAddressDetails');
          break;
        case 'SERVICE':
          formTitle = locale('profile:addServiceAddressDetails');
          break;
        case 'RESIDENTIAL':
          formTitle = locale('profile:addResidentialAddressDetails');
          break;

        default:
          formTitle = '';
          break;
      }
    } else {
      switch (type) {
        case 'BILLING':
          formTitle = locale('profile:editBillingAddressDetails');
          break;
        case 'SERVICE':
          formTitle = locale('profile:editServiceAddressDetails');
          break;
        case 'RESIDENTIAL':
          formTitle = locale('profile:editResidentialAddressDetails');
          break;

        default:
          formTitle = '';
          break;
      }
    }

    return formTitle;
  };

  const isAddressSameAsBilling = (addressType: string): boolean => {
    const existingAddress = updateAddresses.find(
      (address) => address?.type === addressType && address?.sameAs === 'BILLING'
    );

    return !!existingAddress;
  };

  const onChangeFormItem = (address: Address) => {
    if (onChangeAddress) {
      onChangeAddress(address, updateAddresses);
    }
  };

  const onChangeAddressSameAsCheckbox = (addressType: string) => {
    let updateAddress = updateAddresses.find((v) => v.type === addressType);

    if (updateAddress) {
      updateAddress = { ...updateAddress, sameAs: updateAddress.sameAs === 'BILLING' ? 'NONE' : 'BILLING' };

      onChangeFormItem(updateAddress);

      setUpdateAddresses((prev) =>
        prev.map((address) => {
          if (address.type === addressType) {
            address = { ...updateAddress };
          }

          return address;
        })
      );
    }
  };

  const compareTwoAddresses = (address1: Address, address2: Address): boolean => {
    let isSame = false;

    if (address1.postalCode === address2.postalCode) {
      isSame = true;
    }

    return isSame;
  };

  useEffect(() => {
    if (addresses.length === 0) {
      if (isHideServiceAddress) {
        setUpdateAddresses([initialBillingAddress, initialResidentialAddress]);
      } else {
        setUpdateAddresses([initialBillingAddress, initialServiceAddress, initialResidentialAddress]);
      }
    } else {
      let billingAddress = addresses.find((v) => v.type === 'BILLING');
      let serviceAddress = addresses.find((v) => v.type === 'SERVICE');
      let residentialAddress = addresses.find((v) => v.type === 'RESIDENTIAL');

      if (!billingAddress) {
        billingAddress = { ...initialBillingAddress };
      } else {
        billingAddress = { ...billingAddress, isValidForm: true };
      }

      if (!serviceAddress) {
        serviceAddress = { ...initialServiceAddress };
      } else {
        serviceAddress = { ...serviceAddress, isValidForm: true };
      }

      if (!residentialAddress) {
        residentialAddress = { ...initialResidentialAddress };
      } else {
        residentialAddress = { ...residentialAddress, isValidForm: true };
      }

      if (compareTwoAddresses(billingAddress, serviceAddress)) {
        serviceAddress = { ...serviceAddress, sameAs: 'BILLING' };
      } else {
        serviceAddress = { ...serviceAddress, sameAs: 'NONE' };
      }

      if (compareTwoAddresses(billingAddress, residentialAddress)) {
        residentialAddress = { ...residentialAddress, sameAs: 'BILLING' };
      } else {
        residentialAddress = { ...residentialAddress, sameAs: 'NONE' };
      }

      if (isHideServiceAddress) {
        setUpdateAddresses([billingAddress, residentialAddress]);
      } else {
        setUpdateAddresses([billingAddress, serviceAddress, residentialAddress]);
      }
    }
  }, [addresses]);

  if (viewType === 'registerService') {
    return (
      <>
        {updateAddresses.map((address, index) => {
          const addressType = address?.type ?? '';
          const serviceAddress = addressType === 'SERVICE' ? address : null;
          const residentialAddress = addressType === 'RESIDENTIAL' ? address : null;

          if (serviceAddress && serviceAddress.sameAs === 'BILLING') return null;

          if (residentialAddress && residentialAddress.sameAs === 'BILLING') return null;

          return (
            <Div key={`${address.type}- ${index}`} margin={{ top: addressType !== 'BILLING' ? 40 : 0 }}>
              <AddressFormik
                addresses={addresses}
                data={address}
                readOnly={readOnly}
                viewType={viewType}
                onChangeAddress={(address) => onChangeFormItem(address!)}
                onSubmit={onChangeFormItem}
              >
                {(formik, formInput) => (
                  <AddressWrapper data-testid="address-view">
                    <Div className="editContainer">
                      <Div className="header">
                        <Typography variant="h3">{getFormTitle(addressType)}</Typography>
                      </Div>

                      {formInput}
                    </Div>
                  </AddressWrapper>
                )}
              </AddressFormik>
              {addressType === 'BILLING' && (
                <Div margin={{ top: 20 }}>
                  {!isHideServiceAddress && (
                    <Div alignItems="center" display="flex" flexDirection="row" testId="service-address-checkbox">
                      <Checkbox
                        checked={isAddressSameAsBilling('SERVICE')}
                        label={locale('profile:msgServiceAddressCheckbox')}
                        readOnly={readOnly}
                        onChange={() => !readOnly && onChangeAddressSameAsCheckbox('SERVICE')}
                      />
                      <NotApplicableText>
                        ({locale('service:notApplicableForActiveAgeingFitnessProgram')})
                      </NotApplicableText>
                    </Div>
                  )}

                  <Div testId="residental-address-checkbox">
                    <Checkbox
                      checked={isAddressSameAsBilling('RESIDENTIAL')}
                      label={locale('profile:msgResidentialAddressCheckbox')}
                      readOnly={readOnly}
                      onChange={() => !readOnly && onChangeAddressSameAsCheckbox('RESIDENTIAL')}
                    />
                  </Div>
                </Div>
              )}
            </Div>
          );
        })}
      </>
    );
  }

  return (
    <AddressFormik addresses={addresses} data={data!} readOnly={readOnly} viewType={viewType} onSubmit={onSubmit}>
      {(formik, formInput) => (
        <AddressWrapper data-testid="address-view">
          <Div className={viewType === 'userProfile' ? 'withoutBorderContainer' : 'editContainer'}>
            <Div className="header">
              <Typography variant="h3">{title}</Typography>
            </Div>

            {formInput}

            <Div margin={{ top: 20 }}>
              <Grid container spacing={3}>
                <Grid item>
                  <Button
                    disabled={formik.isSubmitting}
                    testId="cancelAddressBtn"
                    variant="secondary"
                    onClick={onCancel}
                  >
                    {locale('common:cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    loading={formik.isSubmitting}
                    testId="saveAddressBtn"
                    variant="primary"
                    onClick={() => formik.submitForm()}
                  >
                    {locale('common:save')}
                  </Button>
                </Grid>
              </Grid>
            </Div>
          </Div>
        </AddressWrapper>
      )}
    </AddressFormik>
  );
};
