/* eslint-disable @typescript-eslint/naming-convention */
// BASE
import { MainEntity } from 'interface';
import { BaseDateTime, BaseState } from './base';

export enum SessionStatus {
  SCHEDULED = 'SCHEDULED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  CANCELLED_WITH_CHARGE = 'CANCELLED_WITH_CHARGE'
}

export type PENDING_SCHEDULER_COMPLETION = 'PENDING_SCHEDULER_COMPLETION';
export type SessionsType = 'PRE_SESSION' | 'SESSION' | 'POST_SESSION';
export type YodaCancelType = 'NO' | 'YES' | 'WITH_A_CHARGE' | 'BY_FORFEITING' | PENDING_SCHEDULER_COMPLETION;

// FOR SESSIONS FROM YODA-SESSIONS API
export interface SessionParams extends Partial<Pick<BaseDateTime, 'fromDate' | 'toDate'>> {
  event?: string;
  subscription?: string;
  termId?: string;
  preClassSessionId?: string;
}

export interface Session extends MainEntity, Partial<BaseDateTime> {
  title?: string;
  sessionId?: string;
  status: SessionStatus;
  event?: string;
  type: SessionsType;
  forfeited?: boolean;
  reschedulable: YESNO | PENDING_SCHEDULER_COMPLETION;
  cancellable: YodaCancelType;
  requested?: Pick<BaseDateTime, 'startDate' | 'endDate'>;
  eventId?: string;
  eventName?: string;
  termId?: string;
  capacity?: number;
  occupancy?: number;
}

export interface ScheduleSessions {
  subscriptionId: string;
  subscriptionTermId: string;
  preSessionId: string;
  sessionIds: string[];
  clientId: string;
  productId: string;
  recurring: boolean;
}

export interface VacantSlot {
  id: string;
  date: string;
  fromTime: string;
  toTime: string;
  availability: boolean;
  vacantSlotType: 'ASSESSMENT' | 'GENERAL';
  availableSlotCount: number;
}

export interface SessionState extends BaseState<Session> {
  completedSessions: Session[];
  cancelledSessions: Session[];
  cancelledWithChargeSessions: Session[];
  cancelledWithoutChargeSessions: Session[];
  scheduledSessions: Session[];
  preClassSessions: Session[];
  vacantSlots: VacantSlot[];
  totalAvailableSlots: number;
  totalTimeSlots: number;
}

export interface SessionsScheduled {
  clientId: string;
  preSession: Session;
  sessions: Session[];
}

export interface CancelSessionParams {
  clientId: string;
  sessionId: string;
  productId: string;
  cancelType?: string;
}

export interface ReScheduleSessionParams extends Pick<ScheduleSessions, 'clientId' | 'productId'> {
  oldSessionId: string;
  newSessionId: string;
}

export interface ReScheduleSessionResponse {
  previousSession: Session;
  newSession: Session;
}
