import React, { FC } from 'react';
import { Typography } from 'ui-kit';
import { Box, CardContent } from '@material-ui/core';
import errImg from 'assets/denticare/error-1.png';
import { Button } from '@chakra-ui/react';

import ErrorScreenLayout from 'components/ErrorScreenLayout';

const content = `It seems that a technical hiccup has occurred. Don't worry, try the following:`;

const SomethingWentWrongPage: FC = () => (
  <ErrorScreenLayout>
    <Box id="err-img-mobile" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <span className="err-img-wrapper">
        <img className="err-img" src={errImg} />
      </span>
    </Box>

    <Box style={{ marginTop: '10px' }} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        <Box id="err-img-desktop" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span className="err-img-wrapper">
            <img className="err-img" src={errImg} />
          </span>
        </Box>
        <Box className="text-content">
          <CardContent style={{ padding: '0' }}>
            <Typography className="err-title" style={{ margin: '0', padding: '0 0 12px 0' }}>
              Oops! Something went wrong
            </Typography>
            <Typography className="err-p">
              {content}
              <br />
              <br />

              <ol style={{ marginLeft: '20px' }}>
                <li>Refresh the page or come back in a while.</li>
                <li>Reach out to us using the contact details provided in the SMS we sent you.</li>
              </ol>
            </Typography>
          </CardContent>
        </Box>
      </Box>
    </Box>

    <Box
      className="err-btn-container"
      style={{ width: '100%' }}
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
    >
      <Button className="err-btn" onClick={() => window.location.reload()}>
        <Typography className="err-btn-txt">Refresh this page</Typography>
      </Button>
    </Box>
  </ErrorScreenLayout>
);

export default SomethingWentWrongPage;
