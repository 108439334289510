import React from 'react';

const yourServiceSelectionSteps = [
  {
    selector: '[data-testid="service-selection"]',
    content: `Select 'Active Aging' for fitness programmes`
  },
  {
    selector: '[data-testid="category-selection"]',
    content: `Select 'Fitness'`
  },
  {
    selector: '[data-testid="program-selection"]',
    content: `Select the programme that you would like to join`
  },
  {
    selector: '[data-testid="package-selection"]',
    content: `Select the number of sessions that you would like to sign up for Note that fees vary according to the number of sessions`
  },
  {
    selector: '[data-testid="location-selection"]',
    content: `Select your preferred location to attend the classes`
  },
  {
    selector: '[data-testid="add-programme-button"]',
    content: `Click on this if you would like to sign up for another programme`
  },
  {
    selector: '[data-testid="marketing-consent-selection"]',
    content: `Let us know how you found out about us`
  },
  {
    selector: '[data-testid="detail-form"]',
    content: `Key in your personal details of the account holder`
  }
];

const yourDetailSteps = [
  {
    selector: '[data-testid="marketing-consent-selection"]',
    content: `Let us know how you found out about us`
  },
  {
    selector: '[data-testid="detail-form"]',
    content: `Key in your personal details of the account holder`
  },
  {
    selector: '[data-testid="union-member-selection"]',
    content: `If you have a NTUC Union membership, select “Yes”`
  },
  {
    selector: '[data-testid="address-view"]',
    content: `Key in your billing address`
  }
];

const yourAddressSteps = [
  {
    selector: '[data-testid="union-member-selection"]',
    content: `If you have a NTUC Union membership, select “Yes”`
  },
  {
    selector: '[data-testid="address-view"]',
    content: `Key in your billing address`
  },
  {
    selector: '[data-testid="service-address-checkbox"]',
    content: `Service address is not applicable for active ageingPlease leave the box ticked`
  },
  {
    selector: '[data-testid="residental-address-checkbox"]',
    content: `Tick if your residential address is the same as the billing address you have filled in`
  },
  {
    selector: '[data-testid="residental-address-checkbox"]',
    content: `Tick if your residential address is the same as the billing address you have filled in`
  }
];

const yourEmergencySteps = [
  {
    selector: '[data-testid="residental-address-checkbox"]',
    content: `Tick if your residential address is the same as the billing address you have filled in`
  },
  {
    selector: '[data-testid="editEmergencyForm"]',
    content: `Key in the details of your Emergency Contact`
  },
  {
    selector: '[data-testid="review-service-selection"]',
    content: `Check that all your details are accurate. Otherwise, click 'Edit' to make changes`
  }
];

const reviewInformationSteps = [
  {
    selector: '[data-testid="editEmergencyForm"]',
    content: `Key in the details of your Emergency Contact`
  },
  {
    selector: '[data-testid="review-service-selection"]',
    content: `Check that all your details are accurate. Otherwise, click 'Edit' to make changes`
  },
  {
    selector: '[data-testid="review-user-details-selection"]',
    content: `Check that all your details are accurate. Otherwise, click 'Edit' to make changes`
  },
  {
    selector: '[data-testid="review-address-selection"]',
    content: `Check that all your details are accurate. Otherwise, click 'Edit' to make changes`
  },
  {
    selector: '[data-testid="review-emergency-contact-selection"]',
    content: `Check that all your details are accurate. Otherwise, click 'Edit' to make changes`
  },
  {
    selector: '[data-testid="footerConfirmCustomerName"]',
    content: `Tick to agree and proceed`
  },
  {
    selector: '[data-testid="footerConfirmToGivePersonalData"]',
    content: `Tick to agree and proceed`
  },
  {
    selector: '[data-testid="footerConfirmMarketingConsent"]',
    content: `Tick if you wish to receive information on promotions and discounts from us`
  },
  {
    selector: '[data-testid="review-payment-online"]',
    content: () => (
      <>
        <div>Click this to proceed with payment.</div>
        <div>Please note that clicking the back button will result in past programme selection to be cleared.</div>
      </>
    )
  },
  {
    selector: '[data-testid="payment-information-summary"]',
    content: `Check payment details are accurate`
  }
];

const paymentSelectionSteps = [
  {
    selector: '[data-testid="review-payment-online"]',
    content: () => (
      <>
        <div>Click this to proceed with payment.</div>
        <div>Please note that clicking the back button will result in past programme selection to be cleared.</div>
      </>
    )
  },
  {
    selector: '[data-testid="payment-information-summary"]',
    content: `Check payment details are accurate`
  },
  {
    selector: '[data-testid="payment-selection-paynow"]',
    content: `Scan and Pay with you PayNow application`
  },
  {
    selector: '[data-testid="payment-selection-credit-debit-card"]',
    content: `Select this option to add you card details and pay with Credit Card`
  },
  {
    selector: '[data-testid="payment-completion-register"]',
    content: () => (
      <>
        <div>You have registered successfully.</div>
        <div>Click this to view your registered package on the ‘My Services’ page.</div>
        <div>You will also receive an invoice and service agreement in your registered email.</div>
      </>
    )
  }
];

const registerCompletionSteps = [
  {
    selector: '[data-testid="payment-selection-credit-debit-card"]',
    content: `Select this option to add you card details and pay with Credit Card`
  },
  {
    selector: '[data-testid="payment-completion-register"]',
    content: () => (
      <>
        <div>You have registered successfully</div>
        <div>Click this to view your registered package on the ‘My Services’ page</div>
        <div>You will also receive an invoice and service agreement in your registered email</div>
      </>
    )
  }
];

export {
  yourServiceSelectionSteps,
  yourDetailSteps,
  yourAddressSteps,
  yourEmergencySteps,
  reviewInformationSteps,
  paymentSelectionSteps,
  registerCompletionSteps
};
