import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { EGiroHistory, EGiroHistoryState, EGiroStatus } from 'model';

import { mergeArraysWithKey } from 'helpers/array';

export const changeEgiroLoading: CaseReducer<EGiroHistoryState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setClienEGiroList: CaseReducer<EGiroHistoryState, PayloadAction<EGiroHistory[]>> = (state, action) => {
  state.clientEGiroList = action.payload;
};

export const setLovedOneEGiroList: CaseReducer<EGiroHistoryState, PayloadAction<EGiroHistory[]>> = (state, action) => {
  state.lovedOneEGiroList = action.payload;
};

export const changeRefreshClientList: CaseReducer<EGiroHistoryState, PayloadAction<boolean>> = (state, action) => {
  state.isRefreshClientList = action.payload;
};

export const changeRefreshLovedOneList: CaseReducer<EGiroHistoryState, PayloadAction<boolean>> = (state, action) => {
  state.isRefreshLovedOneList = action.payload;
};

export const updateEGiroStatusItem: CaseReducer<
  EGiroHistoryState,
  PayloadAction<{ boTransactionRefNo: string; ddaReferenceNo: string; status: EGiroStatus }>
> = (state, action) => {
  const existingEGiroClientItem = (state.clientEGiroList ?? []).find(
    (item) =>
      item.boTransactionRefNo === action.payload.boTransactionRefNo &&
      item.ddaReferenceNo === action.payload.ddaReferenceNo
  );

  if (existingEGiroClientItem) {
    state.clientEGiroList = [...state.clientEGiroList, { ...existingEGiroClientItem, status: action.payload.status }];
  } else {
    const existingEGiroLovedOneItem = (state.lovedOneEGiroList ?? []).find(
      (item) =>
        item.boTransactionRefNo === action.payload.boTransactionRefNo &&
        item.ddaReferenceNo === action.payload.ddaReferenceNo
    );

    if (existingEGiroLovedOneItem) {
      state.lovedOneEGiroList = [
        ...state.lovedOneEGiroList,
        { ...existingEGiroLovedOneItem, status: action.payload.status }
      ];
    }
  }
};

export const updateEGiroItems: CaseReducer<
  EGiroHistoryState,
  PayloadAction<{ items: EGiroHistory[]; isLovedOne: boolean }>
> = (state, action) => {
  const updatedItems = action.payload.items;

  if (updatedItems && updatedItems.length > 0) {
    if (action.payload.isLovedOne) {
      state.lovedOneEGiroList = [
        ...mergeArraysWithKey([...state.lovedOneEGiroList], updatedItems, 'boTransactionRefNo')
      ];
    } else {
      state.clientEGiroList = [...mergeArraysWithKey([...state.clientEGiroList], updatedItems, 'boTransactionRefNo')];
    }
  }
};
