import styled from 'styled-components';

export const InfoItemWrapper = styled.div`
  width: 100%;

  .InforItem-title {
    overflow-wrap: break-word;
    font-weight: bold;
  }

  .InforItem-content {
    margin-top: 10px;
    overflow-wrap: break-word;
  }
`;
