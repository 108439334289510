// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// ROUTER
import { ApiIntake } from 'constant';

// APP ACTIONS
import { setAppMessage } from 'redux/app/actions';

// TYPE
import { Callback } from 'interface';

// MODEL
import { InTake, InTakeParams, EnrollInTake, SessionsScheduled } from 'model';

// SELECTOR
import { getClientId } from 'redux/user/selector';
import { selectService, getSelectedTerm } from 'redux/service/selector';

// SLICE
import { inTakeSlice } from './slice';

const { changeInTakeLoading, setManyInTakes } = inTakeSlice.actions;

const getAllIntakes =
  (params: InTakeParams, callback?: Callback<InTake[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeInTakeLoading(true));

    dispatch(
      httpAction<unknown, InTakeParams, InTake[]>(
        {
          method: 'GET',
          url: ApiIntake.GetAll,
          params,
          needAuth: true
        },
        async (res) => {
          dispatch(changeInTakeLoading(false));

          if (res.data) {
            dispatch(setManyInTakes(res.data));
          }

          if (callback) {
            callback(res.data || []);
          }
        }
      )
    );
  };

const enrollIntake =
  (body: Pick<EnrollInTake, 'preSessionId' | 'intakeId'>, callback?: Callback<SessionsScheduled>): AppThunk =>
  async (dispatch, getState) => {
    const selectedService = selectService(getState());
    const selectedTerm = getSelectedTerm(getState());

    if (!selectedService || !selectedTerm) return;

    const clientId = getClientId(getState());
    const subscription = selectedService.id!;
    const subscriptionTermId = selectedTerm.id!;

    dispatch(changeInTakeLoading(true));

    dispatch(
      httpAction<EnrollInTake, unknown, SessionsScheduled>(
        {
          method: 'POST',
          url: ApiIntake.Enroll,
          data: { clientId, subscription, subscriptionTermId, ...body },
          needAuth: true
        },
        async (res) => {
          dispatch(changeInTakeLoading(false));

          if (res.httpCode === 200) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Enrol intake successfully'
              })
            );
          }

          if (callback) {
            if (res) {
              callback(res.data);
            }
          }
        }
      )
    );
  };

export { getAllIntakes, enrollIntake };
