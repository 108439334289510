import React, { FC } from 'react';
import { Typography } from 'ui-kit';
import { Box, CardContent } from '@material-ui/core';
import errImg from 'assets/denticare/error-2.png';
import { Button } from '@chakra-ui/react';

import ErrorScreenLayout from 'components/ErrorScreenLayout';

const UrlNotFoundPage: FC = () => (
  <ErrorScreenLayout>
    <Box id="err-img-mobile" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <span className="err-img-wrapper">
        <img className="err-img" src={errImg} />
      </span>
    </Box>

    <Box style={{ marginTop: '10px' }} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        <Box id="err-img-desktop" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span className="err-img-wrapper">
            <img className="err-img" src={errImg} />
          </span>
        </Box>
        <Box className="text-content">
          <CardContent style={{ padding: '0' }}>
            <Typography className="err-title" style={{ margin: '0', padding: '0 0 12px 0' }}>
              404 error: Page not found
            </Typography>
            <Typography className="err-p">
              {`It appears the page you were trying to access doesn't exist. If you're interested in exploring more of our
              services or have any other inquiries, please feel free to explore our website or get in touch with us.`}
            </Typography>
          </CardContent>
        </Box>
      </Box>
    </Box>

    <Box
      className="err-btn-container"
      style={{ width: '100%' }}
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
    >
      <Button
        isFullWidth
        backgroundColor="#fff"
        colorScheme="buttonColor"
        variant="outline"
        onClick={() => {
          window.location.href = process.env.REACT_APP_CORPORATE_WEB_SITE!;
        }}
      >
        <>Explore NTUC Health services</>
      </Button>
    </Box>
  </ErrorScreenLayout>
);

export default UrlNotFoundPage;
