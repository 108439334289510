/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { Grid, Div, TextField } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// MODELS
import { Address } from 'model';

// REDUX
import { useAppDispatch } from 'hooks/redux';
import { getAddressDetailByPostalCode } from 'redux/address/actions';

import { FormikSubmitListener } from 'components/FormikSubmitListener';

// PROPS
import { AddressInputProps } from './interface';

export const AddressInputForm: FC<AddressInputProps> = ({
  isInvalidPostalCode,
  onCallbackPostalCode,
  formik,
  onCallbackChangeValidateStatusForm,
  viewType = 'userProfile',
  addresses,
  data,
  readOnly
}) => {
  const { t: locale } = useTranslation(['profile', 'common']);
  const dispatch = useAppDispatch();

  const onChangeValidateDetailsFormStatus = (isValidForm: boolean, formValues: Address) => {
    if (!isValidForm && formValues.postalCode?.length !== 6) {
      const updateAddress = { ...formValues, isValidForm: false };

      if (onCallbackChangeValidateStatusForm) {
        onCallbackChangeValidateStatusForm(updateAddress);
      }
    }
  };

  const residenceTypes: Item<AddressType>[] = [
    { label: locale('profile:billing'), value: 'BILLING' },
    { label: locale('profile:residential'), value: 'RESIDENTIAL' },
    { label: locale('profile:service'), value: 'SERVICE' }
  ];

  const filterResidenceType = (): Item<AddressType>[] => {
    if (data && data.type) {
      const currentItem = residenceTypes.find((v) => v.value === data?.type);

      return residenceTypes.filter((v) => !addresses.find((i) => i.type === v.value)).concat(currentItem!);
    }

    return residenceTypes.filter((v) => !addresses.find((i) => i.type === v.value));
  };

  useEffect(() => {
    if (formik.values.postalCode?.length === 6) {
      dispatch(
        getAddressDetailByPostalCode(formik.values.postalCode, (data) => {
          if (!data) {
            onCallbackPostalCode(true);
          }

          if (data) {
            formik.setValues({
              ...formik.values,
              street: data.ROAD_NAME!,
              block: data.BLK_NO!,
              building: data.BUILDING && data.BUILDING !== 'NIL' ? data.BUILDING : ''
            });
          }
        })
      );
    }
  }, [formik.values.postalCode]);

  return (
    <>
      <Div className="content">
        {viewType === 'userProfile' && (
          <Grid container spacing={3}>
            <Grid item sm={4} xs={12}>
              <TextField
                fullWidth
                required
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.errors.type}
                label={locale('profile:typeOfResidence')}
                name="type"
                placeholder={locale('profile:placeholderAddressType')}
                readOnly={readOnly}
                selectItems={filterResidenceType()}
                testId="typeOfResidenceField"
                type="select"
                value={formik.values.type}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onReset={() => formik.setFieldValue('type', '')}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3}>
          <Grid item sm={4} xs={12}>
            <TextField
              fullWidth
              required
              error={isInvalidPostalCode || (formik.touched.postalCode && Boolean(formik.errors.postalCode))}
              helperText={isInvalidPostalCode ? 'Postal code is invalid' : formik.errors.postalCode}
              label={locale('profile:postalCode')}
              name="postalCode"
              placeholder={locale('profile:placeholderPostalCode')}
              readOnly={readOnly}
              testId="postalCodeField"
              type="text"
              value={formik.values.postalCode}
              onBlur={formik.handleBlur}
              onChange={(v) => {
                if (isInvalidPostalCode) {
                  onCallbackPostalCode(false);
                }

                formik.handleChange(v);
              }}
            />
          </Grid>
          <Grid item sm={5} xs={12}>
            <TextField
              disabled
              fullWidth
              required
              error={formik.touched.block && Boolean(formik.errors.block)}
              helperText={formik.errors.block}
              label={locale('profile:block')}
              name="block"
              placeholder={locale('profile:placeholderBlock')}
              readOnly={readOnly}
              testId="blockField"
              type="text"
              value={formik.values.block}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </Div>
      <Grid container spacing={3}>
        <Grid item sm={5} xs={12}>
          <TextField
            disabled
            fullWidth
            required
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={formik.errors.street}
            label={locale('profile:addressLine1')}
            name="street"
            placeholder={locale('profile:placeholderAddressLine1')}
            readOnly={readOnly}
            testId="addressLineField"
            type="text"
            value={formik.values.street}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          <TextField
            fullWidth
            error={formik.touched.building && Boolean(formik.errors.building)}
            helperText={formik.errors.building}
            label={`${locale('profile:addressLine2')}`}
            name="building"
            placeholder={locale('profile:placeholderAddressLine2')}
            readOnly={readOnly}
            testId="addressLineField2"
            type="text"
            value={formik.values.building}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            error={formik.touched.level && Boolean(formik.errors.level)}
            helperText={formik.errors.level}
            label={`${locale('profile:level')}`}
            name="level"
            placeholder={locale('profile:placeholerLevel')}
            readOnly={readOnly}
            testId="levelField"
            type="text"
            value={formik.values.level}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            fullWidth
            error={formik.touched.unit && Boolean(formik.errors.unit)}
            helperText={formik.errors.unit}
            label={`${locale('profile:unit')}`}
            name="unit"
            placeholder={locale('profile:placeholerUnit')}
            readOnly={readOnly}
            testId="unitField"
            type="text"
            value={formik.values.unit}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      {viewType === 'registerService' && (
        <FormikSubmitListener
          formik={formik}
          onChangeValidateStatus={(isValid, formValues) => {
            if (formik.dirty === false) onChangeValidateDetailsFormStatus(isValid, formValues);
          }}
        />
      )}
    </>
  );
};
