import TagManager from 'react-gtm-module';

// TYPES
import { AppThunk } from 'redux/type';

const initializeGTM = (): AppThunk => async () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_ID || ''
  };

  TagManager.initialize(tagManagerArgs);

  console.log('Google GTM initialized successfully');
};

export { initializeGTM };
