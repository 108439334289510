export enum AppointmentStatusCode {
  EXPIRED = 'EXPIRED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING'
}

export interface GetAppointmentResponse {
  clientName?: string;
  serviceName?: string;
  centerDetails?: string;
  appointmentDateTime?: string;
  appointmentId: string;
  date?: string;
  time?: string;
  clientAttendanceResponseStatus: AppointmentStatusCode;
}

export enum AppointmentClientResponse {
  DECLINED = 'DECLINED',
  CONFIRMED = 'CONFIRMED'
}

export interface UpdateAppointment {
  appointmentId?: string;
  clientResponse?: AppointmentClientResponse;
  clientResponseMethod: 'SMS';
  linkId: string;
}

export interface AppointmentState {
  loading: boolean;
  data?: GetAppointmentResponse;
  showSomethingWentWrong?: boolean;
}
