import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

// TYPE
import { SessionsBookingWithCalendarRef } from 'views/SessionsBookingWithCalendar/interface';

// MODEL
import { Address, Service, ServiceTerm } from 'model';

export const generateAddress = (item?: Address): string => {
  const cloneItem = { ...item };

  if (!item) return '';

  if (item.street1) {
    cloneItem.street = item.street1;
  }

  if (item.street2) {
    cloneItem.building = item.street2;
  }

  let result = `Blk ${cloneItem.block ?? ''} ${cloneItem.street ?? ''}`;

  if (cloneItem.level && !cloneItem.unit) {
    result += `, #${cloneItem.level}`;
  }

  if (!cloneItem.level && cloneItem.unit) {
    result += `, ${cloneItem.unit}`;
  }

  if (cloneItem.unit && cloneItem.level) {
    result += `, #${cloneItem.level} - ${cloneItem.unit}`;
  }

  if (cloneItem.building) {
    result += `, ${cloneItem.building}`;
  }

  result += `, Singapore ${cloneItem.postalCode ?? ''}`;

  return result;
};

export const display = (value?: string | null): string => (value && !isEmpty(value) ? value : '-');

export const capitalize = (text: string): string => {
  const lower = text.toLowerCase();

  return text.charAt(0).toUpperCase() + lower.slice(1);
};

export const generateServiceDescription = (service: Service, selectedTerm: ServiceTerm): string => {
  // KICKBOXING
  if (service.programContentType === 'INTAKES') {
    return `This package is valid for 1 run consisting of ${selectedTerm.totalSessions} sessions. The sessions are conducted twice a week.`;
  }

  // FLEXI WORKOUT
  if (service.programContentType === 'SESSIONS' && !service.fixedSessions) {
    const eventText = ''; // service.events.map((v) => v.name).join(', ');

    return `This package has a total of ${selectedTerm.totalSessions} training sessions. You may choose from ${eventText} within the validity period, depending on your selected centre location.`;
  }

  // GYM TONIC
  if (service.programContentType === 'SESSIONS' && service.fixedSessions) {
    return `This package is valid for ${selectedTerm.totalSessions} training sessions, including a post-assessment at the final session, to be consumed within the validity period. The sessions are conducted twice a week. `;
  }

  return '';
};

export const generateServiceDescriptionForNoUpcoming = (service: Service): string => {
  // KICKBOXING
  if (service.programContentType === 'INTAKES') {
    return `Please book your pre-assessment and preferred run`;
  }

  // FLEXI WORKOUT
  if (service.programContentType === 'SESSIONS' && !service.fixedSessions) {
    return `Please book your pre-assessment and sessions for your activity of choice`;
  }

  // GYM TONIC
  if (service.programContentType === 'SESSIONS' && service.fixedSessions) {
    return `Please book your pre-assessment and sessions.`;
  }

  return '';
};

export const ordinalSuffixOf = (i: number): string => {
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return `${i}st`;
  }

  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }

  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }

  return `${i}th`;
};

export const generateRecurrenceBySelectedDate = (selectedDate: Date[]): string => {
  let result = '';

  selectedDate.forEach((v, i) => {
    if (i === 0) {
      result += `${dayjs(v).format('ddd')}`;
    }

    if (i < selectedDate.length - 1 && i > 0) {
      result += `, ${dayjs(v).format('ddd')}`;
    }

    if (i === selectedDate.length - 1 && i > 0) {
      result += ` and ${dayjs(v).format('ddd')}`;
    }
  });

  return result;
};

export const generateRecurrenceBySelectedDateAndTimeSlot = (data: { date: Date; timeSlot: string }[]): string => {
  let result = '';

  data.forEach((v, i) => {
    if (i === 0) {
      result += `${dayjs(v.date).format('dddd')}, ${v.timeSlot}`;
    }

    if (i < data.length - 1 && i > 0) {
      result += `, ${dayjs(v.date).format('dddd')}, ${v.timeSlot}`;
    }

    if (i === data.length - 1 && i > 0) {
      result += ` and ${dayjs(v.date).format('dddd')}, ${v.timeSlot}`;
    }
  });

  return result;
};

export const generateRecurrenceObject = (data?: SessionsBookingWithCalendarRef): { date: Date; timeSlot: string }[] => {
  const datesAndTimeSlot: { date: Date; timeSlot: string }[] = [];

  if (data && data.data && data.data.firstSessions && data.data.firstSessions.data) {
    const timeSlot = data.data.firstSessions.data.timeSlots.find(
      (v) => v.value === data!.data.firstSessions!.data?.selectedTimeSlot[0]
    );

    datesAndTimeSlot.push({
      date: data.data.firstSessions.data.selectedDate!,
      timeSlot: `${timeSlot!.label}`
    });
  }

  if (data && data.data && data.data.restSessions && data.data.restSessions) {
    data.data.restSessions.forEach((v) => {
      const timeSlot = v.data?.timeSlots.find((t) => t.value === v.data?.selectedTimeSlot[0]);

      datesAndTimeSlot.push({
        date: v.data?.selectedDate!,
        timeSlot: `${timeSlot!.label}`
      });
    });
  }

  return datesAndTimeSlot;
};
