import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

// LOCALIZATION
import { I18nextProvider } from 'react-i18next';

// STYLE
import './index.css';

// HISTORY
import { history } from 'config/history';

// REDUX
import { initializeFirebase } from './redux/firebase/actions';
import { initializeGTM } from './redux/gtm/actions';

// APP COMPONENT
import App from './App';
import reportWebVitals from './reportWebVitals';

// REDUX STORE
import { store } from './redux/store';

// i18N
import i18n from './i18n';

// INITIALIZE GOOGLE TAG
store.dispatch(initializeGTM());

// INITIALIZE FIREBASE
store.dispatch(initializeFirebase());

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
