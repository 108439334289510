import React, { ErrorInfo, PureComponent, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: (hasError: boolean) => ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    hasError: false
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.groupCollapsed(`Error occured!`);
    console.log('Error:', error);
    console.log('Info:', info);
    console.groupEnd();

    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return <>{children(hasError)}</>;
  }
}

export default ErrorBoundary;
