import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { MedicalHistory, MedicalHistoryState, MedicalHistoryProgressEnum } from 'model';

export const setMedicalHistoryLoading: CaseReducer<MedicalHistoryState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setMedicalHistory: CaseReducer<MedicalHistoryState, PayloadAction<MedicalHistory>> = (state, action) => {
  state.single = action.payload;
};

export const setPreviousMedicalHistory: CaseReducer<MedicalHistoryState, PayloadAction<MedicalHistory>> = (
  state,
  action
) => {
  state.previousMedicalHistory = action.payload;
};

export const setCurrentProgress: CaseReducer<MedicalHistoryState, PayloadAction<MedicalHistoryProgressEnum>> = (
  state,
  action
) => {
  state.currentProgress = action.payload;
};

export const setIsAcceptTerm: CaseReducer<MedicalHistoryState, PayloadAction<boolean>> = (state, action) => {
  state.isAcceptTerm = action.payload;
};

export const setIsSigned: CaseReducer<MedicalHistoryState, PayloadAction<boolean>> = (state, action) => {
  state.isSigned = action.payload;
};
