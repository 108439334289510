import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// ROUTER
import { ApiDenticareClient } from 'constant';

// MODEL
import { DenticareClient, DenticareClientStatus } from 'model';

// TYPE
import { Callback } from 'interface';

// HELPERS
import { setValue } from 'helpers/localstorage';
import { StorageKey } from 'constant/storage';
import { v4 } from 'uuid';

// APP ACTIONS
import { setAppMessage } from 'redux/app/actions';

// SLICE
import { denticareClientSlice } from './slice';

// ACTIONS
const {
  changeDenticareClientLoading,
  setDenticareSessionId,
  setDenticareAssociationId,
  setDenticareClientId,
  setDenticareClientStatus
} = denticareClientSlice.actions;

// STATUS

const getDenticareClientStatus =
  (callback?: Callback<DenticareClientStatus>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareClientLoading(true));

    dispatch(
      httpAction<DenticareClient, unknown, DenticareClientStatus>(
        {
          method: 'GET',
          url: ApiDenticareClient.Status,
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(changeDenticareClientLoading(false));

          if (res.data) {
            dispatch(setDenticareClientStatus(res.data));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

// CREATE DENTICARE CLIENT

const createDenticareClient =
  (denticareClient: DenticareClient, callback?: Callback<DenticareClientStatus>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareClientLoading(true));

    dispatch(
      httpAction<DenticareClient, unknown, DenticareClientStatus>(
        {
          method: 'POST',
          url: ApiDenticareClient.Client,
          data: denticareClient,
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(changeDenticareClientLoading(false));

          if (res.data) {
            dispatch(setDenticareClientStatus(res.data));
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Created denticare client successfully'
              })
            );
            setValue<string>('@latestDateShowConfirmMHQDialog', dayjs().format('YYYY-MM-DD hh:mm:ss'));
          }

          if (!res.data) {
            dispatch(
              setAppMessage({
                type: 'support',
                content: 'Your NRIC has already been registered for Denticare Service,'
              })
            );

            setTimeout(() => {
              window.location.href = '/';
            }, 2000);
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const createDenticareSessionId =
  (forceCreateNewSessionId?: boolean): AppThunk =>
  (dispatch) => {
    const sessionId = sessionStorage.getItem(StorageKey.DenticareSessionId);

    if (forceCreateNewSessionId) {
      const key = v4();

      if (sessionId) {
        sessionStorage.setItem(StorageKey.DenticareSessionIdBackUp, sessionId);
      }

      sessionStorage.removeItem(StorageKey.DenticareSessionId);
      sessionStorage.setItem(StorageKey.DenticareSessionId, key);
      dispatch(setDenticareSessionId(key));

      return;
    }

    if (isEmpty(sessionId)) {
      const key = v4();

      sessionStorage.setItem(StorageKey.DenticareSessionId, key);
      dispatch(setDenticareSessionId(key));
    } else {
      dispatch(setDenticareSessionId(sessionId!));
    }
  };

export {
  createDenticareClient,
  createDenticareSessionId,
  setDenticareAssociationId,
  setDenticareClientId,
  getDenticareClientStatus
};
