import styled from 'styled-components';
import { Tabs, Div, NHColor } from 'ui-kit';

export const TabsWrapper = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 0px;
    height: 0px;
  }
`;

export const EGiroLandingWrapper = styled(Div)`
  .header-background {
    background: linear-gradient(135deg, #3a9f8d 0%, #317e97 100%);
  }

  .nh-logo {
    width: 104px;
  }

  .login-btn {
    color: ${NHColor.teal700};
    background: ${NHColor.white};
    border-radius: 3px;
    border: 1px solid ${NHColor.teal700};
    text-transform: none;
    &:hover {
      background: ${NHColor.grey200};
    }
  }

  .header-title {
    font-size: 36px;
    color: white;
  }

  .header-sub-title {
    font-size: 16px;
    font-weight: 400;
    color: white;
  }

  .apply-btn {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    .apply-text {
      color: #002f3b;
      font-size: 16px;
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .section-title {
    color: #333;
    font-size: 20px;
    font-weight: 700;
  }

  .section-content {
    color: #333;
    font-size: 16px;
    font-weight: 400;
  }

  .item-section-container {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
    padding: 15px;
    height: 100%;
    width: 100%;
  }

  .item-section-title {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .green-color {
    color: #007b00;
  }

  .item-section-content {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .section-step-container {
    background: linear-gradient(180deg, rgba(69, 154, 164, 0.1) 0%, rgba(69, 154, 164, 0) 100%);
  }

  .section-step-title-container {
    display: inline-block;
    border-radius: 20px;
    background: #edf7ee;
    padding: 10px 15px;
  }

  .interested-container {
    border-radius: 10px;
    background: linear-gradient(135deg, #3a9f8d 0%, #317e97 100%);
    padding: 20px;
  }

  .interested-title {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .interested-content {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .faq-active {
    background: #e7eef3;
  }

  .faq-inactive {
    background: #f5f8fa;
  }

  .footer {
    background: #14636c;
  }
`;

export const TranslationBoldWrapper = styled.span`
  font-weight: 600;
`;
