import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getRemoteConfig, getAll, Value, fetchAndActivate, RemoteConfig } from 'firebase/remote-config';
import { getAnalytics, initializeAnalytics, setUserId } from 'firebase/analytics';
import { AppEnv } from 'constant';

// TYPES
import { AppThunk } from 'redux/type';

// SELECTOR
import { selectAppConfiguration } from 'redux/app/selector';

// SLICE
import { appSlice } from 'redux/app/slice';

// ACTIONS
const { setAppMessage, setAppConfiguration: setAppConfigurationAction, setOpenModalToRefreshApp } = appSlice.actions;

const initializeFirebase = (): AppThunk => async () => {
  // const firebaseConfig = {
  //   apiKey: 'AIzaSyC3PSXIOXXEGjqWwuW4STJs3qdGBStZURE',
  //   authDomain: 'nh-firebase-scheduling.firebaseapp.com',
  //   databaseURL: 'https://nh-firebase-scheduling.firebaseio.com',
  //   projectId: 'nh-firebase-scheduling',
  //   storageBucket: 'nh-firebase-scheduling.appspot.com',
  //   messagingSenderId: '536136976143',
  //   appId: '1:536136976143:web:df248d33b2d586c97de07a',
  //   measurementId: 'G-8P3ZBMNGR3'
  // };

  const env = process.env.REACT_APP_ENV;

  let firebaseConfig = {};

  if (env === AppEnv.Prod) {
    firebaseConfig = {
      apiKey: 'AIzaSyAAdJwCOUdwsgb9vgu_zosFCH-mX7-LHq8',
      authDomain: 'mynh-29832.firebaseapp.com',
      databaseURL: 'https://mynh-29832-default-rtdb.firebaseio.com/',
      projectId: 'mynh-29832',
      storageBucket: 'mynh-29832.appspot.com',
      messagingSenderId: '34130251606',
      appId: '1:34130251606:web:0d39a2610686b241fad0f7',
      measurementId: 'G-KM4T4FZY9F'
    };
  }

  if (env === AppEnv.Alpha) {
    firebaseConfig = {
      apiKey: 'AIzaSyAUtZBEG_siTE5cbkCfoWdsbpu9oZlzcUM',
      authDomain: 'mynh-alpha.firebaseapp.com',
      projectId: 'mynh-alpha',
      storageBucket: 'mynh-alpha.appspot.com',
      messagingSenderId: '522481905605',
      appId: '1:522481905605:web:09ae49b6b24d887c00e5ac',
      measurementId: 'G-3ELSM26VPR'
    };
  }

  if (env === AppEnv.Uat) {
    firebaseConfig = {
      apiKey: 'AIzaSyDJh_h0d5n8eK-kFDvCxhV35YClrxx6iP4',
      authDomain: 'mynh-uat.firebaseapp.com',
      projectId: 'mynh-uat',
      storageBucket: 'mynh-uat.appspot.com',
      messagingSenderId: '295577326138',
      appId: '1:295577326138:web:11f49c2768636524f91b03',
      measurementId: 'G-T3GTWGV9H3'
    };
  }

  if (env === AppEnv.Qa) {
    firebaseConfig = {
      apiKey: 'AIzaSyAaVQ0c6W55KOtkITY8dlf0op60qRMylYs',
      authDomain: 'mynh-qa.firebaseapp.com',
      projectId: 'mynh-qa',
      storageBucket: 'mynh-qa.appspot.com',
      messagingSenderId: '427992072298',
      appId: '1:427992072298:web:250f97c840542d03c4ca13',
      measurementId: 'G-ZQQ34PDF20'
    };
  }

  const fbApp = initializeApp(firebaseConfig);

  initializeAnalytics(fbApp);

  const db = getFirestore();

  window.fireStoreDB = db;

  console.log('Firebase initialized successfully');
};

const firebaseSetUserId =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      const analytics = getAnalytics();

      setUserId(analytics, userId);
    } catch (error) {
      dispatch(
        setAppMessage({
          type: 'warning',
          content: 'Firebase has not been initialized successfully. Please refresh application!'
        })
      );
    }
  };

const getAppConfig = (): AppThunk => async (dispatch, getState) => {
  const currentAppConfiguration = selectAppConfiguration(getState());

  const setAppConfiguration = (configuration: Record<string, Value>) => {
    dispatch(
      setAppConfigurationAction({
        disabledLovedOne: configuration.disabledLovedOne.asBoolean() || currentAppConfiguration.disabledLovedOne,
        versionNumber: configuration.versionNumber.asString() || currentAppConfiguration.versionNumber
      })
    );
  };

  const getConfigFromRemote = (config: RemoteConfig, callBack: (newUpdate: boolean) => void) => {
    fetchAndActivate(config).then((isNewUpdated) => {
      callBack(isNewUpdated);
    });
  };

  try {
    const appConfigInstance = getRemoteConfig();

    const pool = 600000;

    // Initial pool
    appConfigInstance.settings.minimumFetchIntervalMillis = 0;
    appConfigInstance.defaultConfig = { ...currentAppConfiguration };

    getConfigFromRemote(appConfigInstance, () => {
      const initialAppConfiguration = getAll(appConfigInstance);

      setAppConfiguration(initialAppConfiguration);
    });

    // RE-create pool for next update
    appConfigInstance.settings.minimumFetchIntervalMillis = pool;

    // To Get App Configuration every 10 mins
    setInterval(() => {
      getConfigFromRemote(appConfigInstance, (isNewUpdated) => {
        if (isNewUpdated) {
          const updatedAppConfiguration = getAll(appConfigInstance);

          setAppConfiguration(updatedAppConfiguration);
          dispatch(setOpenModalToRefreshApp(true));
        }
      });
    }, pool);
  } catch (error) {
    dispatch(
      setAppMessage({
        type: 'warning',
        content: 'Firebase has not been initialized successfully. Please refresh application!'
      })
    );
  }
};

export { setAppMessage, initializeFirebase, firebaseSetUserId, getAppConfig };
