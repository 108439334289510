import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { ProductCatalogState, ServiceProductCatalog, ProgramProductCatalog, OffersListProductCatalog } from 'model';

// ACTION TO CHANGE STATE
export const changeProductCatalogLoading: CaseReducer<ProductCatalogState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.loading = action.payload;
};

export const setServicesProductCatalog: CaseReducer<ProductCatalogState, PayloadAction<ServiceProductCatalog[]>> = (
  state,
  action
) => {
  state.services = action.payload;
};

export const setProgramsProductCatalog: CaseReducer<ProductCatalogState, PayloadAction<ProgramProductCatalog[]>> = (
  state,
  action
) => {
  state.programs = action.payload;
};

export const setOffersProductCatalog: CaseReducer<ProductCatalogState, PayloadAction<OffersListProductCatalog[]>> = (
  state,
  action
) => {
  state.offers = action.payload;
};

export const setSelectedServiceProductCatalog: CaseReducer<ProductCatalogState, PayloadAction<string | undefined>> = (
  state,
  action
) => {
  state.selectedService = action.payload;
};
