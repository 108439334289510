import { combineReducers, configureStore } from '@reduxjs/toolkit';
// HISTORY
import { history } from 'config/history';
// REDUCER
import { connectRouter, routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import addressReducer, { initialState as addressInitialState } from './address/slice';
import appReducer, { initialState as appInitialState } from './app/slice';
import appointmentReducer, { initialState as appointmentState } from './appointmentConfirmation/slice';
import appTourReducer, { initialState as appTourInitialState } from './appTour/slice';
import cardReducer, { initialState as cardInitialState } from './card/slice';
import cartReducer, { initialState as cartInitialState } from './cart/slice';
import centerReducer, { initialState as centerInitialState } from './center/slice';
import contactReducer, { initialState as contactInitialState } from './contact/slice';
import denticareAppointmentReducer, {
  initialState as denticareAppointmentInitialState
} from './denticareBookAppointment/slice';
import denticareClientReducer, { initialState as denticareClientInitialState } from './denticareClient/slice';
import egiroRecuder, { initialState as egiroInitialState } from './egiro/slice';
import eInvoiceReducer, { initialState as eInvoiceInitialState } from './einvoice/slice';
import eventReducer, { initialState as eventInitialState } from './event/slice';
import faqRecuder, { initialState as faqInitialState } from './faq/slice';
import featureFlagReducer, { initialState as featureFlagInitialState } from './featureFlag/slice';
import feedbackReducer, { initialState as feedBackInitialState } from './feedback/slice';
import intakeReducer, { initialState as intakeInitialState } from './intake/slice';
import medicalHistoryReducer, { initialState as medicalHistoryInitialState } from './medicalHistory/slice';
import offerReducer, { initialState as offerInitialState } from './offer/slice';
import orderReducer, { initialState as orderInitialState } from './order/slice';
import productCatalogReducer, { initialState as productCatalogInitialState } from './productCatalog/slice';
import registerServiceReducer, {
  initialState as registerServeraddressInitialState
} from './service-registration/slice';
import serviceReducer, { initialState as serviceInitialState } from './service/slice';
import sessionsReducer, { initialState as sessionInitialState } from './sessions/slice';
import { RootState } from './type';
import userReducer, { initialState as userInitialState } from './user/slice';

export const reducer = combineReducers({
  appReducer,
  appTourReducer,
  userReducer,
  feedbackReducer,
  contactReducer,
  addressReducer,
  registerServiceReducer,
  cartReducer,
  orderReducer,
  serviceReducer,
  eventReducer,
  sessionsReducer,
  centerReducer,
  offerReducer,
  intakeReducer,
  cardReducer,
  denticareAppointmentReducer,
  productCatalogReducer,
  denticareClientReducer,
  medicalHistoryReducer,
  faqRecuder,
  egiroRecuder,
  eInvoiceReducer,
  appointmentReducer,
  featureFlagReducer,
  router: connectRouter(history)
});

export const initialAppState: Omit<RootState, 'router'> = {
  appReducer: appInitialState,
  appTourReducer: appTourInitialState,
  userReducer: userInitialState,
  contactReducer: contactInitialState,
  addressReducer: addressInitialState,
  registerServiceReducer: registerServeraddressInitialState,
  cartReducer: cartInitialState,
  cardReducer: cardInitialState,
  orderReducer: orderInitialState,
  serviceReducer: serviceInitialState,
  eventReducer: eventInitialState,
  sessionsReducer: sessionInitialState,
  centerReducer: centerInitialState,
  feedbackReducer: feedBackInitialState,
  offerReducer: offerInitialState,
  intakeReducer: intakeInitialState,
  productCatalogReducer: productCatalogInitialState,
  denticareClientReducer: denticareClientInitialState,
  denticareAppointmentReducer: denticareAppointmentInitialState,
  medicalHistoryReducer: medicalHistoryInitialState,
  faqRecuder: faqInitialState,
  eInvoiceReducer: eInvoiceInitialState,
  egiroRecuder: egiroInitialState,
  appointmentReducer: appointmentState,
  featureFlagReducer: featureFlagInitialState
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_ENV === 'prod'
      ? getDefaultMiddleware().concat(routerMiddleware(history))
      : getDefaultMiddleware().concat(logger, routerMiddleware(history)),
  devTools: process.env.REACT_APP_ENV !== 'prod'
});
