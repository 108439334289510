import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { EGiroHistoryState } from 'model';

// REDUCER ACTION
import {
  changeEgiroLoading,
  setClienEGiroList,
  setLovedOneEGiroList,
  changeRefreshClientList,
  changeRefreshLovedOneList,
  updateEGiroStatusItem,
  updateEGiroItems
} from './reducers';

export const initialState: EGiroHistoryState = {
  clientEGiroList: [],
  lovedOneEGiroList: [],
  loading: false,
  isRefreshClientList: false,
  isRefreshLovedOneList: false
};

export const egiroSlice = createSlice({
  name: Domain.EGiro,
  initialState,
  reducers: {
    changeEgiroLoading,
    setClienEGiroList,
    setLovedOneEGiroList,
    changeRefreshClientList,
    changeRefreshLovedOneList,
    updateEGiroStatusItem,
    updateEGiroItems
  }
});

export default egiroSlice.reducer;
