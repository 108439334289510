import React, { FC } from 'react';
import { Div, Typography } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// VIEW COMPONENTS
import { PersonalDetailItem } from 'views/PersonalDetail';

// STYLES
import { ReviewItemWrapper } from 'views/RegisterService/ReviewInformation/styles';

// DATA
import { user as profile } from './data';

const ReviewDetails: FC = () => {
  const { t: locale } = useTranslation(['registerService', 'common', 'profile']);

  return (
    <ReviewItemWrapper>
      <Div className="header" testId="reviewDetailsTitle">
        <Typography variant="h3">2. {locale('profile:clientDetails')}</Typography>
      </Div>
      {profile && <PersonalDetailItem hideCHAS item={profile} />}
    </ReviewItemWrapper>
  );
};

export default ReviewDetails;
