// MOCK DATA
import { ProfileLinkingStatus } from 'model';

const addresses = [
  {
    id: 'fc33549f-2763-49d8-8d02-1ba28d5b775d',
    block: '55',
    level: '',
    unit: '',
    street: 'COMPASSVALE BOW',
    building: 'THE QUARTZ',
    postalCode: '544986',
    type: 'BILLING'
  },
  {
    id: '073a5490-04e6-43c7-91e1-c86f2984a544',
    block: '55',
    level: '',
    unit: '',
    street: 'COMPASSVALE BOW',
    building: 'THE QUARTZ',
    postalCode: '544986',
    type: 'RESIDENTIAL'
  },
  {
    id: '31132616-3247-4b8a-acb3-c08f61e214a7',
    block: '55',
    level: '',
    unit: '',
    street: 'COMPASSVALE BOW',
    building: 'THE QUARTZ',
    postalCode: '544986',
    type: 'SERVICE'
  }
];

const emergencyContact = {
  id: 'e5e5ccd6-d490-43a1-b976-7aa36d5c96ad',
  name: 'EmergencyContact',
  relationship: 'Spouse',
  otherRelationship: '',
  mobileNumber: '84545235',
  homeNumber: '',
  email: '',
  payer: false,
  emergency: true
};

const user = {
  id: '7c9dcc94-19b7-4a93-bf9c-4db5b5cf825a',
  idType: 'PASSPORT',
  nric: 'B773434234',
  name: 'Nhat',
  countryCode: '65',
  mobileNumber: '84559343',
  homeNumber: '',
  email: 'testing_denticare_2@gmail.com',
  dateOfBirth: 1654469880000,
  maritalStatus: '',
  gender: 'Male',
  language: ['Cantonese'],
  otherLanguage: '',
  religion: '',
  otherReligion: '',
  companyName: '',
  nationality: '',
  residentStatus: 'Long term special pass/ ICA letter',
  otherResidentStatus: '',
  chasSubsidyTier: '',
  chasExpiryDate: '',
  marketingConsent: true,
  unionMember: false,
  profileLinkingStatus: 'NOT_APPLICABLE' as ProfileLinkingStatus
};

const offers = [
  {
    id: '698ec57a-fd68-4d2e-9ce3-9bbefc7c8778',
    name: 'Circuit Training- launch promo',
    pricing: {
      id: '092cbebe-1762-4d55-bcea-d2ce8023c7e0',
      code: null,
      name: '',
      description: null,
      price: 320,
      currency: 'SGD',
      sessions: 24,
      billingCycle: 'P12W'
    },
    promotions: [],
    promotion: null,
    productId: '228e4262-6e53-4ad7-832e-e6789c1a831d',
    enabled: true,
    noOfBufferWeek: 0,
    offeringType: 'LITE'
  },
  {
    id: '86bbc945-5240-4ec3-9b9f-7eb66cd3bb9c',
    name: 'Circuit Training - Sampler',
    pricing: {
      id: 'c46a21d5-d230-49ff-b0d3-009428646ad1',
      code: null,
      name: '',
      description: null,
      price: 74.77,
      currency: 'SGD',
      sessions: 4,
      billingCycle: 'P4W'
    },
    promotions: [
      {
        createdDate: 1650267915269,
        lastModifiedDate: 1650267915269,
        createdBy: 'Dinesh',
        lastModifiedBy: 'Dinesh',
        id: '7d95ae72-9b34-4a22-acf0-66ea6e03f8c7',
        offeringId: '86bbc945-5240-4ec3-9b9f-7eb66cd3bb9c',
        active: true,
        savingsType: 'PRICE',
        applicability: ['NEW_TO_PROGRAM'],
        discountId: '806c7e4a-a5a9-4114-83db-d17a33804aa6',
        centers: ['be21fe61-0081-e511-8117-c4346badc5f4']
      }
    ],
    promotion: null,
    productId: '228e4262-6e53-4ad7-832e-e6789c1a831d',
    enabled: true,
    noOfBufferWeek: 0,
    offeringType: 'LITE'
  },
  {
    id: 'ea870122-f21b-4b1b-b6ec-f0110e0a4ae6',
    name: 'Circuit Training full package',
    pricing: {
      id: '08f6fe49-8593-4b63-8be6-3dd88e9da2aa',
      code: null,
      name: '',
      description: null,
      price: 336.45,
      currency: 'SGD',
      sessions: 24,
      billingCycle: 'P12W'
    },
    promotions: [
      {
        createdDate: 1636692044570,
        lastModifiedDate: 1637336867769,
        createdBy: 'Kalpa',
        lastModifiedBy: 'Dinesh',
        id: '2b1e9392-6a62-4b7b-844e-7506812a8a49',
        offeringId: 'ea870122-f21b-4b1b-b6ec-f0110e0a4ae6',
        active: true,
        savingsType: 'PRICE',
        applicability: ['NEW_TO_PROGRAM', 'UNION_MEMBER'],
        discountId: '8a357561-188d-4b04-b68e-74f94a93d393',
        centers: [
          '0de7e111-60ce-e611-8100-3863bb2e6b78',
          'ecad2de3-35e4-4a23-94f7-0fa6da2a8f51',
          'c521fe61-0081-e511-8117-c4346badc5f4',
          '866f3744-e8b1-40d3-b179-9e3f1f8bb222'
        ]
      },
      {
        createdDate: 1638030654847,
        lastModifiedDate: 1645505094610,
        createdBy: 'Dinesh',
        lastModifiedBy: 'Shruti Madan',
        id: 'daff00b2-1b5c-42ac-bdbb-dee6c219fcb9',
        offeringId: 'ea870122-f21b-4b1b-b6ec-f0110e0a4ae6',
        active: true,
        savingsType: 'PRICE',
        applicability: ['UNION_MEMBER'],
        discountId: 'e9cc3d74-1fa7-4d31-8bbe-991a8fdaeebf',
        centers: [
          'be21fe61-0081-e511-8117-c4346badc5f4',
          'c521fe61-0081-e511-8117-c4346badc5f4',
          '866f3744-e8b1-40d3-b179-9e3f1f8bb222',
          '0de7e111-60ce-e611-8100-3863bb2e6b78',
          'ecad2de3-35e4-4a23-94f7-0fa6da2a8f51'
        ]
      },
      {
        createdDate: 1645415979108,
        lastModifiedDate: 1645512341945,
        createdBy: 'Shruti Madan',
        lastModifiedBy: 'Shruti Madan',
        id: 'e3480387-33ab-4106-8510-793e9c09f547',
        offeringId: 'ea870122-f21b-4b1b-b6ec-f0110e0a4ae6',
        active: false,
        savingsType: 'PRICE',
        applicability: ['PROMO_CODE'],
        discountId: '1ec37398-8f32-4e94-9bb9-73c7edb217ba',
        centers: ['be21fe61-0081-e511-8117-c4346badc5f4']
      },
      {
        createdDate: 1638160902634,
        lastModifiedDate: 1638160902634,
        createdBy: 'Dinesh',
        lastModifiedBy: 'Dinesh',
        id: '4e08eb06-6e70-4788-a727-c609b3b65d4f',
        offeringId: 'ea870122-f21b-4b1b-b6ec-f0110e0a4ae6',
        active: true,
        savingsType: 'PRICE',
        applicability: ['PROMO_CODE'],
        discountId: 'f4a12193-9d3e-4d52-b48c-259049350409',
        centers: ['be21fe61-0081-e511-8117-c4346badc5f4']
      }
    ],
    promotion: null,
    productId: '228e4262-6e53-4ad7-832e-e6789c1a831d',
    enabled: true,
    noOfBufferWeek: 0,
    offeringType: 'FULL'
  }
];

const centerDetails = {
  id: 'be21fe61-0081-e511-8117-c4346badc5f4',
  name: 'Henderson',
  managerId: 'a244320d-76a0-e511-8117-c4346badf690',
  managerName: 'Jenny Fong Yoke Ying',
  members: [
    {
      createdDate: undefined,
      lastModifiedDate: 1648174818457,
      createdBy: undefined,
      lastModifiedBy: 'Dinesh',
      id: '00951ff6-6e09-4809-a583-548f3deaee81',
      email: 'amila.d@ntuchealth.sg',
      username: 'Amila Domingo',
      designation: 'Principal Physiotherapist',
      employeeCode: '9756E',
      userType: 'RESOURCE',
      enabled: true,
      legacyLogin: true,
      mobilePhone: '3456456',
      resource: {
        createdDate: 1605696260445,
        lastModifiedDate: 1648174818559,
        createdBy: 'Amila Domingo',
        lastModifiedBy: 'Dinesh',
        userId: '00951ff6-6e09-4809-a583-548f3deaee81',
        local: false,
        fullTime: true,
        languages: undefined,
        otherLanguage: null,
        dialects: null,
        otherDialect: '',
        gender: 'Male',
        postalCode: '090012',
        block: '12',
        unit: '',
        level: '',
        street1: 'TELOK BLANGAH CRESCENT',
        street2: 'NIL',
        preferredAreas: null,
        skillSet: [
          '0196b633-a97f-e511-8116-c4346bad5740',
          'a8e3b357-a97f-e511-8116-c4346bad5740',
          '70d59843-a77b-e511-8110-c4346badb280',
          '86d493b2-a77b-e511-8110-c4346badb280',
          '64378340-a97f-e511-8116-c4346bad5740',
          'd4d3c89e-a57b-e511-8110-c4346badb280'
        ],
        travelMode: 'NO',
        longitude: 26463.9031907534,
        latitude: 28850.2036344986,
        businessUnits: []
      }
    },
    {
      createdDate: 1631780862503,
      lastModifiedDate: 1632634814163,
      createdBy: 'Kalpa',
      lastModifiedBy: 'sam',
      id: '39d754d5-718b-4422-88b6-be7ae7318734',
      email: 'kalpahip@gmail.com',
      username: 'Kalpa',
      designation: 'Principal Physiotherapist',
      employeeCode: 'R001KALPA',
      userType: 'RESOURCE',
      enabled: true,
      legacyLogin: true,
      mobilePhone: '86083788',
      resource: {
        createdDate: 1631780862741,
        lastModifiedDate: 1632634814273,
        createdBy: 'Kalpa',
        lastModifiedBy: 'sam',
        userId: '39d754d5-718b-4422-88b6-be7ae7318734',
        local: false,
        fullTime: true,
        languages: null,
        otherLanguage: null,
        dialects: null,
        otherDialect: '',
        gender: 'Male',
        postalCode: '640633',
        block: '633',
        unit: '',
        level: '',
        street1: 'JURONG WEST STREET 65',
        street2: 'NIL',
        preferredAreas: null,
        skillSet: [
          '0d944bf3-43ee-4e80-ac0d-e3934f72d407',
          '0196b633-a97f-e511-8116-c4346bad5740',
          '86d493b2-a77b-e511-8110-c4346badb280',
          'd4d3c89e-a57b-e511-8110-c4346badb280',
          '5612737d-bb0a-4cde-82a0-3e3fbf3b56ef',
          'db3548c6-a57b-e511-8110-c4346badb280',
          '08af0110-91c9-417f-994a-544b50ca7911',
          'b63e1ce9-a57b-e511-8110-c4346badb280',
          '70d59843-a77b-e511-8110-c4346badb280',
          'e67a695b-78fe-4b18-9d71-71905c811632',
          'a8e3b357-a97f-e511-8116-c4346bad5740',
          '64378340-a97f-e511-8116-c4346bad5740',
          '037a3cae-bf53-49de-ab99-4d0d0c19e3ea'
        ],
        travelMode: 'NO',
        longitude: 103.69933430697,
        latitude: 1.34326081308615,
        businessUnits: []
      }
    },
    {
      createdDate: 1631788640776,
      lastModifiedDate: 1654350444288,
      createdBy: 'Kalpa',
      lastModifiedBy: 'Bon Pham',
      id: '0b509507-6098-4b21-b282-e159c75904d4',
      email: 'peter@xyz.com',
      username: 'Peter',
      designation: 'Physiotherapist',
      employeeCode: 'R001PETER',
      userType: 'RESOURCE',
      enabled: true,
      legacyLogin: false,
      mobilePhone: '86083788',
      resource: {
        createdDate: 1631788640873,
        lastModifiedDate: 1654350444394,
        createdBy: 'Kalpa',
        lastModifiedBy: 'Bon Pham',
        userId: '0b509507-6098-4b21-b282-e159c75904d4',
        local: false,
        fullTime: true,
        languages: null,
        otherLanguage: null,
        dialects: null,
        otherDialect: '',
        gender: 'Male',
        postalCode: '640626',
        block: '626',
        unit: '',
        level: '',
        street1: 'JURONG WEST STREET 65',
        street2: 'NIL',
        preferredAreas: null,
        skillSet: [
          'db3548c6-a57b-e511-8110-c4346badb280',
          '5612737d-bb0a-4cde-82a0-3e3fbf3b56ef',
          '0d944bf3-43ee-4e80-ac0d-e3934f72d407',
          'b63e1ce9-a57b-e511-8110-c4346badb280',
          'e5ae010c-ce1f-47e8-b632-7b97d5324d46',
          'a8e3b357-a97f-e511-8116-c4346bad5740',
          '97cc26e7-a77b-e511-8110-c4346badb280',
          '037a3cae-bf53-49de-ab99-4d0d0c19e3ea'
        ],
        travelMode: 'NO',
        longitude: null,
        latitude: null,
        businessUnits: [{ userId: '0b509507-6098-4b21-b282-e159c75904d4', businessUnit: 'DENTICARE' }]
      }
    },
    {
      createdDate: 1568882456781,
      lastModifiedDate: 1654068281706,
      createdBy: null,
      lastModifiedBy: 'Nhat Nguyen',
      id: '474cc8a2-573d-4fe9-9a58-d2e68263f34e',
      email: 'anshul.vashishtha_nh@ntucguest.com',
      username: 'Anshul',
      designation: 'Administrative Officer',
      employeeCode: '222222',
      userType: 'RESOURCE',
      enabled: true,
      legacyLogin: false,
      mobilePhone: '86083788',
      resource: {
        createdDate: 1647836872146,
        lastModifiedDate: 1654068281867,
        createdBy: 'Divya Rajulapati',
        lastModifiedBy: 'Nhat Nguyen',
        userId: '474cc8a2-573d-4fe9-9a58-d2e68263f34e',
        local: false,
        fullTime: false,
        languages: null,
        otherLanguage: null,
        dialects: null,
        otherDialect: '',
        gender: '',
        postalCode: '544986',
        block: '55',
        unit: '',
        level: '',
        street1: 'COMPASSVALE BOW',
        street2: 'THE QUARTZ',
        preferredAreas: null,
        skillSet: [],
        travelMode: 'NO',
        longitude: null,
        latitude: null,
        businessUnits: [{ userId: '474cc8a2-573d-4fe9-9a58-d2e68263f34e', businessUnit: 'DENTICARE' }]
      }
    },
    {
      createdDate: 1585838798852,
      lastModifiedDate: 1653754827720,
      createdBy: 'Rong Sen NG',
      lastModifiedBy: 'Long Nguyen',
      id: 'cfeade6d-a37c-4da2-8604-315dfb4dc00b',
      email: 'rongseNatwork@gmail.com',
      username: '001-care-operator',
      designation: 'Physiotherapist',
      employeeCode: '12',
      userType: 'GENERAL',
      enabled: false,
      legacyLogin: false,
      mobilePhone: '767869,768769798,3453',
      resource: {
        createdDate: 1614058861247,
        lastModifiedDate: 1653556732175,
        createdBy: 'Shruti Madan',
        lastModifiedBy: 'Long Nguyen',
        userId: 'cfeade6d-a37c-4da2-8604-315dfb4dc00b',
        local: false,
        fullTime: false,
        languages: null,
        otherLanguage: null,
        dialects: null,
        otherDialect: '',
        gender: 'Female',
        postalCode: '822613',
        block: '613B',
        unit: '',
        level: '',
        street1: 'PUNGGOL DRIVE',
        street2: 'DAMAI GROVE',
        preferredAreas: null,
        skillSet: ['64378340-a97f-e511-8116-c4346bad5740'],
        travelMode: 'NO',
        longitude: 36341.9338637181,
        latitude: 42903.4768015686,
        businessUnits: []
      }
    },
    {
      createdDate: null,
      lastModifiedDate: 1653621741766,
      createdBy: null,
      lastModifiedBy: 'Shruti Madan',
      id: '5f10b73d-18a2-41b8-ae7c-9a3ec39955b9',
      email: 'arvind.kumar_nh+test01@ntucguest.com',
      username: 'Fabianto Wangsamulya',
      designation: 'Care Coordinator',
      employeeCode: '12344',
      userType: 'RESOURCE',
      enabled: true,
      legacyLogin: false,
      mobilePhone: '+6581615848',
      resource: {
        createdDate: 1600058170714,
        lastModifiedDate: 1653621741868,
        createdBy: 'Shruti Madan',
        lastModifiedBy: 'Shruti Madan',
        userId: '5f10b73d-18a2-41b8-ae7c-9a3ec39955b9',
        local: true,
        fullTime: true,
        languages: 'Mandarin,Malay',
        otherLanguage: '',
        dialects: 'Cantonese',
        otherDialect: '',
        gender: 'Male',
        postalCode: '822613',
        block: '613B',
        unit: '23',
        level: '10',
        street1: 'PUNGGOL DRIVE',
        street2: 'DAMAI GROVE',
        preferredAreas: 'EAST,NORTH,WEST',
        skillSet: [
          '4bf5bed6-a77b-e511-8110-c4346badb280',
          'e5968f31-a87b-e511-8110-c4346badb280',
          '0196b633-a97f-e511-8116-c4346bad5740',
          '86d493b2-a77b-e511-8110-c4346badb280',
          'a0376f11-a87b-e511-8110-c4346badb280',
          '0ebf514d-9c95-46b7-bcd0-f9f7b52c4d3e',
          '73101e7b-a67b-e511-8110-c4346badb280',
          '97cc26e7-a77b-e511-8110-c4346badb280',
          'd4d3c89e-a57b-e511-8110-c4346badb280',
          'ea193a35-a67b-e511-8110-c4346badb280',
          '7932dcd8-e68b-425c-922c-eb6f24f55a19',
          '47ac56fa-a77b-e511-8110-c4346badb280',
          'ac48163c-a87b-e511-8110-c4346badb280',
          '92955af5-7bd8-4dee-b446-4504c6a7b264',
          '75ce7eba-81b1-4f11-ae8d-14d333b2efe1',
          '89da2ecd-4413-4b2d-8c9f-cace654841ad',
          '8321f19d-a77b-e511-8110-c4346badb280',
          '4f3be4c7-a77b-e511-8110-c4346badb280'
        ],
        travelMode: 'PUBLIC',
        longitude: 103.90827778637,
        latitude: 1.40427710253969,
        businessUnits: []
      }
    },
    {
      createdDate: 1651822769255,
      lastModifiedDate: 1654668777427,
      createdBy: 'Dinesh',
      lastModifiedBy: 'Raj Kishore Singh',
      id: 'cc252f1b-116b-45cd-adaf-3ff0b4a5e6a0',
      email: 'raj.kishoresingh_nh@ntucguest.com',
      username: 'RajResourceTest1',
      designation: 'Senior Software Engineer',
      employeeCode: 'xwd',
      userType: 'RESOURCE',
      enabled: true,
      legacyLogin: true,
      mobilePhone: '+919939691222',
      resource: {
        createdDate: 1654601064475,
        lastModifiedDate: 1654668777452,
        createdBy: 'Shruti Madan',
        lastModifiedBy: 'Raj Kishore Singh',
        userId: 'cc252f1b-116b-45cd-adaf-3ff0b4a5e6a0',
        local: false,
        fullTime: true,
        languages: 'English',
        otherLanguage: '',
        dialects: null,
        otherDialect: '',
        gender: 'Male',
        postalCode: '822613',
        block: '613B',
        unit: '',
        level: '',
        street1: 'PUNGGOL DRIVE',
        street2: 'DAMAI GROVE',
        preferredAreas: null,
        skillSet: [
          '0d944bf3-43ee-4e80-ac0d-e3934f72d407',
          '0196b633-a97f-e511-8116-c4346bad5740',
          '25af8644-a57b-e511-8110-c4346badb280',
          '5b926b4c-a67b-e511-8110-c4346badb280',
          '037a3cae-bf53-49de-ab99-4d0d0c19e3ea',
          'ea193a35-a67b-e511-8110-c4346badb280'
        ],
        travelMode: 'NO',
        longitude: null,
        latitude: null,
        businessUnits: [{ userId: 'cc252f1b-116b-45cd-adaf-3ff0b4a5e6a0', businessUnit: 'DAY_CARE' }]
      }
    },
    {
      createdDate: 1653488757521,
      lastModifiedDate: 1654859640249,
      createdBy: 'Raj Kishore Singh',
      lastModifiedBy: 'RajResourceTest1',
      id: 'cb73bc59-4b17-4225-8df9-980ea94d48aa',
      email: 'raj.singh0802@gmail.com',
      username: 'RajResourceTest2',
      designation: 'Senior Software Engineer',
      employeeCode: 'R555',
      userType: 'RESOURCE',
      enabled: true,
      legacyLogin: true,
      mobilePhone: '+919939691222',
      resource: {
        createdDate: 1653488757600,
        lastModifiedDate: 1654859640275,
        createdBy: 'Raj Kishore Singh',
        lastModifiedBy: 'RajResourceTest1',
        userId: 'cb73bc59-4b17-4225-8df9-980ea94d48aa',
        local: false,
        fullTime: true,
        languages: null,
        otherLanguage: null,
        dialects: null,
        otherDialect: '',
        gender: 'Male',
        postalCode: '600130',
        block: '130',
        unit: '',
        level: '',
        street1: 'JURONG GATEWAY ROAD',
        street2: 'J CONNECT',
        preferredAreas: null,
        skillSet: [
          '0d944bf3-43ee-4e80-ac0d-e3934f72d407',
          'a0b35132-a57b-e511-8110-c4346badb280',
          '0196b633-a97f-e511-8116-c4346bad5740',
          '25af8644-a57b-e511-8110-c4346badb280',
          'd4d3c89e-a57b-e511-8110-c4346badb280',
          'ea193a35-a67b-e511-8110-c4346badb280',
          '4a477414-a67b-e511-8110-c4346badb280',
          'db3548c6-a57b-e511-8110-c4346badb280',
          '5612737d-bb0a-4cde-82a0-3e3fbf3b56ef',
          '08af0110-91c9-417f-994a-544b50ca7911',
          'b63e1ce9-a57b-e511-8110-c4346badb280',
          'e67a695b-78fe-4b18-9d71-71905c811632',
          '64378340-a97f-e511-8116-c4346bad5740',
          '92955af5-7bd8-4dee-b446-4504c6a7b264',
          'ac48163c-a87b-e511-8110-c4346badb280',
          'e8d16f8b-a67b-e511-8110-c4346badb280',
          '48f5018a-a57b-e511-8110-c4346badb280',
          '037a3cae-bf53-49de-ab99-4d0d0c19e3ea'
        ],
        travelMode: 'NO',
        longitude: null,
        latitude: null,
        businessUnits: [{ userId: 'cb73bc59-4b17-4225-8df9-980ea94d48aa', businessUnit: 'DAY_CARE' }]
      }
    },
    {
      createdDate: 1620186592920,
      lastModifiedDate: 1649239741545,
      createdBy: 'sam',
      lastModifiedBy: 'Gayan Liyanage',
      id: '2f676b93-1d99-4beb-9462-dad752aa6387',
      email: 'rajapaksha.k@ntuchealth.sg',
      username: 'Kalpa',
      designation: 'Administrative Officer',
      employeeCode: null,
      userType: 'GENERAL',
      enabled: false,
      legacyLogin: true,
      mobilePhone: '89398004',
      resource: null
    },
    {
      createdDate: 1653901717453,
      lastModifiedDate: 1653903294026,
      createdBy: 'Dinesh',
      lastModifiedBy: 'System',
      id: '8b5656f3-8cf3-49f3-9270-86b23edc0376',
      email: 'nhat.nguyen@ntucenterprise.sg',
      username: 'Nhat Nguyen',
      designation: 'Senior Care Associate',
      employeeCode: null,
      userType: 'GENERAL',
      enabled: true,
      legacyLogin: true,
      mobilePhone: '98989898',
      resource: null
    },
    {
      createdDate: 1649133261453,
      lastModifiedDate: 1650254950182,
      createdBy: 'Dinesh',
      lastModifiedBy: 'Long Nguyen',
      id: 'e6f528f4-f119-42cc-ac6d-73f5c90503f5',
      email: 'longnguyen.crystaltechs_nh@ntucguest.com',
      username: 'Long Nguyen',
      designation: 'Administrative Officer',
      employeeCode: 'long',
      userType: 'GENERAL',
      enabled: true,
      legacyLogin: true,
      mobilePhone: '',
      resource: null
    },
    {
      createdDate: 1569914530576,
      lastModifiedDate: 1569914661850,
      createdBy: null,
      lastModifiedBy: null,
      id: '37506d1c-c9d4-4dba-88f5-b2b881d295e6',
      email: 'rongsen.n@ntuchealth.sg',
      username: 'Rong Sen NG',
      designation: 'Developer',
      employeeCode: null,
      userType: 'GENERAL',
      enabled: true,
      legacyLogin: true,
      mobilePhone: null,
      resource: null
    },
    {
      createdDate: 1601451123642,
      lastModifiedDate: 1603870194317,
      createdBy: 'sam',
      lastModifiedBy: 'QA1',
      id: '2234b0ae-efe3-4932-9da2-31be46108937',
      email: 'arvind.kumar_nh@ntucguest.com',
      username: 'QA1',
      designation: 'Administrative Officer',
      employeeCode: '1122333',
      userType: 'GENERAL',
      enabled: true,
      legacyLogin: true,
      mobilePhone: '',
      resource: null
    },
    {
      createdDate: 1641893640728,
      lastModifiedDate: 1650254986620,
      createdBy: 'sam',
      lastModifiedBy: 'Long Nguyen',
      id: '7d1a8e0b-868d-4d6f-a992-d24754ab5f81',
      email: 'tan.hoang_ne@ntucguest.com',
      username: 'Tan hoang',
      designation: 'Administrative Officer',
      employeeCode: '12345666',
      userType: 'GENERAL',
      enabled: true,
      legacyLogin: true,
      mobilePhone: '',
      resource: null
    },
    {
      createdDate: 1456969857000,
      lastModifiedDate: 1530596514000,
      createdBy: null,
      lastModifiedBy: 'SYSTEM',
      id: 'a2b9af5e-e2e0-e511-811f-c4346badf500',
      email: 'mark.chua@ntuchealth.sg',
      username: 'Mark Chua',
      designation: 'Centre Supervisor',
      employeeCode: null,
      userType: 'GENERAL',
      enabled: true,
      legacyLogin: true,
      mobilePhone: null,
      resource: null
    }
  ],
  centerBased: false,
  createdDate: 1446427435000,
  lastModifiedDate: 1539251673000,
  createdBy: 'karunkumar Krishnamoorthy',
  lastModifiedBy: 'karunkumar Krishnamoorthy',
  phone: null,
  abbreviation: null,
  active: true,
  vmsCenter: ['0'],
  title: 'NTUC HEALTH SENIOR DAY CARE (HENDERSON)',
  block: '95B',
  level: '01',
  unit: '22',
  street: '95C Henderson Road',
  building: undefined,
  postalCode: '152095',
  mapLink: undefined,
  businessUnit: undefined,
  area: undefined,
  centerCode: undefined
};

const products = [
  { id: '228e4262-6e53-4ad7-832e-e6789c1a831d', name: ' Circuit Training - QA' },
  { id: 'd21745ba-b79f-e511-811b-c4346bad3768', name: 'Caregiver Training - Classroom' },
  { id: '08e276ae-47ec-4b29-ae8a-391f0200ed42', name: 'DayCare+' },
  { id: 'cb232031-1ad9-48ca-9339-5dbe1196ec32', name: 'Flexi Workout - QA' },
  { id: 'df0571ca-4215-e811-817e-e0071b69c9d1', name: 'Gym tonic' },
  { id: '95337f75-d0fe-4c4c-9af8-f46ee05deba0', name: 'Gym tonic - QA' },
  { id: 'ee889368-1b8f-4b1e-86a9-460c357a5e70', name: 'Kickboxing' },
  { id: '89f792d4-0f1e-4af3-a4d6-73d8c7359b0a', name: 'Kickboxing - QA' },
  { id: '60757c39-2a71-462f-80d1-68b57838d3f2', name: 'Long Test AA-pre session' },
  { id: 'a260ff82-88f6-467a-b71a-f4478cec0b64', name: 'Long Test Product AA' },
  { id: '650d17d3-8c3c-481d-9762-bfd47413c3f0', name: 'parent-child test product' }
];

const subCategories = [{ id: 'a3081abd-0174-4306-8156-ddd36dc39f01', name: 'Fitness' }];

const serviceSelection = {
  lovedOne: 'my_self',
  category: 'bbc0ea86-bf96-44b5-ac96-bc19526c349b',
  catergoryName: 'Active Ageing',
  marketingChanel: '',
  sourceOthers: '',
  programmes: [
    {
      id: 1,
      category: 'a3081abd-0174-4306-8156-ddd36dc39f01',
      center: 'be21fe61-0081-e511-8117-c4346badc5f4',
      product: '228e4262-6e53-4ad7-832e-e6789c1a831d',
      offer: 'ea870122-f21b-4b1b-b6ec-f0110e0a4ae6',
      isExpanded: true
    }
  ]
};

const programme = {
  id: 1,
  category: 'a3081abd-0174-4306-8156-ddd36dc39f01',
  center: 'be21fe61-0081-e511-8117-c4346badc5f4',
  product: '228e4262-6e53-4ad7-832e-e6789c1a831d',
  offer: 'ea870122-f21b-4b1b-b6ec-f0110e0a4ae6',
  isExpanded: true
};

const productInfo = {
  title: 'Available Activities',
  contents: ['1. Circuit Training'],
  productDetails: {
    createdDate: 1630841376961,
    lastModifiedDate: 1637834655886,
    createdBy: 'Kalpa',
    lastModifiedBy: 'Dinesh',
    id: '228e4262-6e53-4ad7-832e-e6789c1a831d',
    name: ' Circuit Training - QA',
    frequency: 'PER_SESSION',
    frequencies: 'PER_SESSION',
    status: true,
    trialApplicable: false,
    requireDeposit: false,
    addon: false,
    addons: [],
    services: [],
    gstApplicable: true,
    gstAbsorbable: false,
    glCode: '8765422',
    gstGlCode: '',
    iltcApplicable: false,
    iltcCode: '',
    barthelApplicable: false,
    transportApplicable: false,
    subsidyGLCode: null,
    gstAbsorbedGLCode: null,
    receivableGLCode: null,
    typeChangeable: false,
    attendancePricing: false,
    autoAttendance: false,
    trialFrequency: null,
    recurringEpisodeApplicable: false,
    teleService: false,
    selfRegistrable: false,
    resourceBased: false,
    centerBased: false,
    sendReminder: true,
    abbreviation: '',
    defaultEvent: null,
    autoRecurring: false,
    appointmentAutoHandle: 'NONE',
    markAttendance: false,
    cancellationReasonRequired: false,
    eventIds: ['673d3588-bfb0-4be2-a79c-5fb920a1239e'],
    travel: false,
    sendTermExpiryNotification: false,
    sendInvoiceToClient: false,
    utilizationGlCode: null,
    appointmentTypeRequired: false,
    guestFlowEnabled: true,
    categoryId: 'bbc0ea86-bf96-44b5-ac96-bc19526c349b',
    categoryName: 'Active Ageing',
    subcategory: 'a3081abd-0174-4306-8156-ddd36dc39f01',
    medicalHistory: 'NO',
    healthStatus: 'NO',
    nok: 'NO',
    bookInitialAssignment: false,
    preClassAppointmentType: 'ba36b70e-ab17-4965-8266-6c85b46f0fac',
    preClassSecondaryAppointmentType: null,
    programContentType: 'SESSIONS',
    temporalStop: false,
    postClassAppointmentType: null,
    renewPackages: false,
    clientFeedback: false,
    minSessions: 1,
    maxSessions: 4,
    fixedSessions: false,
    oneRehab: false,
    daysToSendAppointmentReminder: 0
  }
};

const category = 'bbc0ea86-bf96-44b5-ac96-bc19526c349b';

export {
  user,
  emergencyContact,
  addresses,
  category,
  serviceSelection,
  subCategories,
  products,
  centerDetails,
  offers,
  programme,
  productInfo
};
