import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { CartState, Cart, CheckoutInfo, GSTConfig } from 'model';

// ACTION TO CHANGE STATE
export const changeCartLoading: CaseReducer<CartState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setCart: CaseReducer<CartState, PayloadAction<Cart>> = (state, action) => {
  state.single = action.payload;
};

export const setCartCheckoutInfo: CaseReducer<CartState, PayloadAction<CheckoutInfo | undefined>> = (state, action) => {
  state.checkoutInfo = action.payload;
};

export const setGSTConfig: CaseReducer<CartState, PayloadAction<GSTConfig | undefined>> = (state, action) => {
  state.gstConfig = action.payload;
};
