import React, { FC } from 'react';
import { BrowserRouter, Switch, Route, RouteProps as BaseRouteProps, Redirect } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LoadableComponent } from '@loadable/component';

// ROUTE
import { Router } from 'constant';

// COMPONENT
import { baseLoader } from 'components/Loading';
import UrlNotFoundPage from 'components/UrlNotFoundPage';
import { selectEnabledEGiroArrengement, selectEnabledProductCatalog } from 'redux/featureFlag/selectors';
import { useAppSelector } from 'hooks';
import { useSelector } from 'react-redux';

// PAGE
const MyServicePage = baseLoader(() => import('page/MyService'));
const RegisterService = baseLoader(() => import('page/RegisterService'));
const Profile = baseLoader(() => import('page/Profile'));
// const AuthenticatedProfile = withAuthenticationRequired(Profile);
const LinkAccountPage = baseLoader(() => import('page/Profile/LinkProfile'));
const PaymentPage = baseLoader(() => import('page/Payments'));
const GeneralTermCondition = baseLoader(() => import('page/TermCondition/General'));
const ProgramTermCondition = baseLoader(() => import('page/TermCondition/Program'));
const TermAndCondition = baseLoader(() => import('page/TermCondition/Term'));
const NewTermAndCondition = baseLoader(() => import('page/TermCondition/TermAndCondition'));
const InvoiceDetailPage = baseLoader(() => import('page/Invoices/Detail'));
const InvoiceList = baseLoader(() => import('page/Invoices/List'));
const PaymentCompletion = baseLoader(() => import('page/Invoices/PaymentCompletion'));
const MyCardsPage = baseLoader(() => import('page/MyCard'));
const PaymentVerification = baseLoader(() => import('page/PaymentVerification'));
const ProductCatalog = baseLoader(() => import('page/ProductCatalog'));
const ProductCatalogDetail = baseLoader(() => import('page/ProductCatalog/ProductCatalogDetail'));
const ProductCatalogCartReview = baseLoader(() => import('page/ProductCatalog/CartReview'));
const MedicalHistoryPage = baseLoader(() => import('page/MedicalHistory'));
const HealthDeclarationProfile = baseLoader(
  () => import('page/MyService/PurchasedServiceList/ServiceUserProgramSummary/ActiveAgeing/HealthDeclarationProfile')
);
const FAQ = baseLoader(() => import('page/Faq'));
const EGiroArrangements = baseLoader(() => import('page/eGiro'));
const ApplyEGiro = baseLoader(() => import('page/eGiro/apply/ApplyEGiro'));

interface RouteProps extends Omit<BaseRouteProps, 'component'> {
  component: LoadableComponent<unknown>;
}

const AuthenticatedRoute: FC<RouteProps> = ({ component, ...props }) => (
  <Route {...props} component={withAuthenticationRequired(component)} />
);

const AppRoute: FC<Record<string, unknown>> = () => {
  const env = process.env.REACT_APP_ENV;
  const isEnabledProductCatalog = useSelector(selectEnabledProductCatalog);
  const isEnabledEGyroArrangement = useAppSelector(selectEnabledEGiroArrengement);

  const mainRoute = (
    <Switch>
      <Route exact component={() => <></>} path={Router.eGiroLanding} />
      <Route exact component={UrlNotFoundPage} path={Router.NotFoundPage} />
      <AuthenticatedRoute exact component={Profile} path={Router.Profile} />
      <AuthenticatedRoute exact component={RegisterService} path={Router.RegisterService} />
      <AuthenticatedRoute exact component={PaymentPage} path={Router.Payment} />
      <AuthenticatedRoute exact component={InvoiceList} path={Router.InvoiceList} />
      <AuthenticatedRoute exact component={InvoiceDetailPage} path={Router.InvoiceDetail} />
      <AuthenticatedRoute exact component={PaymentCompletion} path={Router.PaymentCompletion} />
      <AuthenticatedRoute exact component={LinkAccountPage} path={Router.LinkAccount} />
      <AuthenticatedRoute exact component={TermAndCondition} path={Router.TermAndCondition} />
      <AuthenticatedRoute exact component={NewTermAndCondition} path={Router.NewTermAndCondition} />
      <AuthenticatedRoute exact component={ProgramTermCondition} path={Router.ProgramTermAndCondition} />
      <AuthenticatedRoute exact component={GeneralTermCondition} path={Router.GeneralTermAndCondition} />
      <AuthenticatedRoute exact component={PaymentVerification} path={Router.PaymentVerification} />
      <AuthenticatedRoute component={HealthDeclarationProfile} path={Router.HealthDeclaration} />
      <AuthenticatedRoute component={MedicalHistoryPage} path={Router.MedicalHistory} />
      <AuthenticatedRoute component={MyCardsPage} path={Router.CardManagement} />
      <AuthenticatedRoute component={FAQ} path={Router.FAQ} />
      {isEnabledEGyroArrangement && <AuthenticatedRoute exact component={EGiroArrangements} path={Router.eGiro} />}
      <AuthenticatedRoute exact component={ApplyEGiro} path={Router.eGiroApply} />
      <AuthenticatedRoute component={MyServicePage} path={Router.Home} />
      <Redirect to={Router.NotFoundPage} />
    </Switch>
  );

  const mainRouteWithProductCatalog = (
    <Switch>
      <Route exact component={ProductCatalog} path={Router.Home} />
      <Route component={ProductCatalogDetail} path={Router.ProductDetail} />
      <Route component={ProductCatalog} path={Router.ServiceTypeDetail} />
      <Route exact component={() => <></>} path={Router.eGiroLanding} />
      <Route exact component={UrlNotFoundPage} path={Router.NotFoundPage} />
      <AuthenticatedRoute exact component={ProductCatalogCartReview} path={Router.ProductCatalogCartReview} />
      <AuthenticatedRoute exact component={Profile} path={Router.Profile} />
      <AuthenticatedRoute exact component={RegisterService} path={Router.RegisterService} />
      <AuthenticatedRoute exact component={PaymentPage} path={Router.Payment} />
      <AuthenticatedRoute exact component={InvoiceList} path={Router.InvoiceList} />
      <AuthenticatedRoute exact component={InvoiceDetailPage} path={Router.InvoiceDetail} />
      <AuthenticatedRoute exact component={PaymentCompletion} path={Router.PaymentCompletion} />
      <AuthenticatedRoute exact component={LinkAccountPage} path={Router.LinkAccount} />
      <AuthenticatedRoute exact component={TermAndCondition} path={Router.TermAndCondition} />
      <AuthenticatedRoute exact component={NewTermAndCondition} path={Router.NewTermAndCondition} />
      <AuthenticatedRoute exact component={ProgramTermCondition} path={Router.ProgramTermAndCondition} />
      <AuthenticatedRoute exact component={GeneralTermCondition} path={Router.GeneralTermAndCondition} />
      <AuthenticatedRoute exact component={PaymentVerification} path={Router.PaymentVerification} />
      <AuthenticatedRoute component={HealthDeclarationProfile} path={Router.HealthDeclaration} />
      <AuthenticatedRoute component={MedicalHistoryPage} path={Router.MedicalHistory} />
      <AuthenticatedRoute component={MyCardsPage} path={Router.CardManagement} />
      <AuthenticatedRoute component={FAQ} path={Router.FAQ} />
      {isEnabledEGyroArrangement && <AuthenticatedRoute exact component={EGiroArrangements} path={Router.eGiro} />}
      <AuthenticatedRoute exact component={ApplyEGiro} path={Router.eGiroApply} />
      <AuthenticatedRoute component={MyServicePage} path={Router.MyService} />
      <Redirect to={Router.NotFoundPage} />
    </Switch>
  );

  if (env === 'test') {
    return <BrowserRouter>{mainRoute}</BrowserRouter>;
  }

  if (isEnabledProductCatalog) return mainRouteWithProductCatalog;

  return mainRoute;
};

export default AppRoute;
