import { isArray, isEmpty } from 'lodash';

// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// MODEL
import { Option, ServiceLink, ServiceLinkResult, LovedOneType, HealthDeclarationForm } from 'model';

// ROUTER
import { ApiRegisterService } from 'constant';

// TYPE
import { Callback } from 'interface';

// ACTION
import { setAppMessage } from 'redux/app/actions';

// SELECTOR
import { getClientId } from 'redux/user/selector';

// SLICE
import { serviceRegistrationSlice } from './slice';

const {
  changeLoading,
  changeServiceSelection,
  setShowWelcomeDialog,
  setProgress,
  changeProgramme,
  changeLovedOneDetailStatus,
  changeContactStatus,
  changeAddressStatus,
  changeLovedOneDetail,
  changeTermsAndConditionsStatus,
  changeReviewStatus,
  reset,
  changeEmergencyContact,
  changeHealthDeclarationStatus,
  setHealthDeclaration,
  setHealthDeclarationLoading
} = serviceRegistrationSlice.actions;

// ----------------------------- SERVICE CATEGORIES -----------------------------------------

export const getServiceCategories =
  (callback?: Callback<Option[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeLoading(true));

    dispatch(
      httpAction<unknown, unknown, Option[]>(
        {
          method: 'GET',
          url: ApiRegisterService.ProgrammeCategory,
          needAuth: true
        },
        async (res) => {
          dispatch(changeLoading(false));

          if (callback) {
            callback(res.data || []);
          }
        }
      )
    );
  };

// ----------------------------- MARKETING CHANNELS -----------------------------------------

export const getMarketingChannels =
  (callback?: Callback<Option[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeLoading(true));

    dispatch(
      httpAction<unknown, unknown, any>(
        {
          method: 'GET',
          url: ApiRegisterService.MarketingChannel,
          needAuth: true
        },
        async (res) => {
          dispatch(changeLoading(false));

          const { content } = res.data ?? {};
          let channels: Option[] = [];

          if (isArray(content)) {
            channels = content.map((v) => ({ id: v.id, name: v.value }));
          }

          if (callback) {
            callback(channels);
          }
        }
      )
    );
  };

// ----------------------------- SERVICE SUB CATEGORIES -----------------------------------------

export const getSubCategories =
  (id: string, callback?: Callback<Option[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeLoading(true));

    dispatch(
      httpAction<unknown, unknown, Option>(
        {
          method: 'GET',
          url: `${ApiRegisterService.ProgrammeCategory}/${id}`,
          needAuth: true
        },
        async (res) => {
          dispatch(changeLoading(false));

          if (callback) {
            callback(res.data?.subcategories || []);
          }
        }
      )
    );
  };

// ----------------------------- PROGRAMME PRODUCTS BY ID -----------------------------------------

export const getProgrammeProductsById =
  (categoryId: string, subCategoryId: string, lovedOne: string, callback?: Callback<Option[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeLoading(true));

    let params = '';

    if (lovedOne === LovedOneType.MySelf) {
      params = `?checkEligibility=true`;
    } else if (lovedOne !== LovedOneType.New) {
      params = `?checkEligibility=true&lovedOne=${lovedOne}`;
    }

    const apiUrl = `${ApiRegisterService.ProgrammeCategory}/${categoryId}/${subCategoryId}${
      !isEmpty(params) ? params : ''
    }`;

    dispatch(
      httpAction<unknown, unknown, Option[]>(
        {
          method: 'GET',
          url: apiUrl,
          needAuth: true
        },
        async (res) => {
          dispatch(changeLoading(false));

          if (callback) {
            callback(res.data || []);
          }
        }
      )
    );
  };

// ----------------------------- SERVICE LINKS -----------------------------------------

export const getServiceLinks =
  (callback?: Callback<ServiceLink[]>): AppThunk =>
  async (dispatch) => {
    dispatch(
      httpAction<unknown, unknown, ServiceLinkResult>(
        {
          method: 'GET',
          url: 'config/mastercode?code=NTUCH_LINKS&size=1000',
          needAuth: true
        },
        async (res) => {
          const { content = [] } = res.data || {};

          if (callback) {
            callback(content);
          }
        }
      )
    );
  };

// ----------------------------- TRAINING DAYS FIXED SESSION -----------------------------------------

export const getTrainingDaysForFixedSession =
  (productName: string, centerName: string, callback?: Callback<ServiceLink>): AppThunk =>
  async (dispatch) => {
    dispatch(
      httpAction<unknown, unknown, ServiceLink>(
        {
          method: 'GET',
          url: `config/mastercode?code=sd-${productName}&key=sd-${centerName}`,
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          if (callback) {
            callback(res.data);
          }

          if (res.httpCode === 404) {
            dispatch(
              setAppMessage({
                type: 'error',
                content: 'Not available at this moment, please select other programme.'
              })
            );
          }
        }
      )
    );
  };

// ----------------------------- AA HEALTH DECLARATION -----------------------------------------
export const updateAAHealthDeclaration =
  (payload: HealthDeclarationForm, callback?: Callback<unknown>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setHealthDeclarationLoading(true));
    const clientId = getClientId(getState());

    dispatch(
      httpAction<unknown, unknown, HealthDeclarationForm>(
        {
          method: 'POST',
          url: `/profile/health-declaration`,
          needAuth: true,
          data: { clientId, ...payload }
        },
        async (res) => {
          dispatch(setHealthDeclarationLoading(false));

          if (res.data) {
            dispatch(setHealthDeclaration(res.data));
          }

          if (callback) {
            callback(res.data);
          }

          if (res.httpCode === 200 || res.httpCode === 201) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Save Health Declaration successfully'
              })
            );
          } else {
            dispatch(
              setAppMessage({
                type: 'error',
                content: 'Save Health Declaration failed.'
              })
            );
          }
        }
      )
    );
  };

export const getAAHealthDeclaration =
  (callback?: Callback<HealthDeclarationForm>): AppThunk =>
  async (dispatch) => {
    dispatch(setHealthDeclarationLoading(true));

    dispatch(
      httpAction<unknown, unknown, HealthDeclarationForm>(
        {
          method: 'GET',
          url: `/profile/health-declaration`,
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(setHealthDeclarationLoading(false));

          if (res.data) {
            dispatch(setHealthDeclaration(res.data));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

export {
  changeServiceSelection,
  setShowWelcomeDialog,
  setProgress,
  changeProgramme,
  changeLovedOneDetailStatus,
  changeContactStatus,
  changeAddressStatus,
  changeLovedOneDetail,
  changeTermsAndConditionsStatus,
  changeReviewStatus,
  reset,
  changeEmergencyContact,
  changeHealthDeclarationStatus,
  changeLoading
};
