export enum AppTourComponent {
  ServiceSelection = 'ServiceSelection',
  YourDetail = 'YourDetail',
  YourAddress = 'YourAddress',
  EmergencyContact = 'EmergencyContact',
  ReviewInformation = 'ReviewInformation',
  PaymentSelection = 'PaymentSelection',
  PaymentCompletion = 'PaymentCompletion'
}

export interface AppTourState {
  enabled: boolean;
  component?: AppTourComponent;
}
