import React, { FC } from 'react';

import { Grid, Div, Button, Typography } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import InfoItem from 'components/InfoItem';

// HELPERS
import { display } from 'helpers/string/generation';

// STYLE
import { NextOfKinDetailsWrapper } from '../styles';

// INTERFACE
import { NextOfKinsItemProps } from './interface';

export const NextOfKinItem: FC<NextOfKinsItemProps> = ({
  onSelect,
  onOpenConfirmation,
  index,
  item,
  viewType = 'userProfile'
}) => {
  const { t: locale } = useTranslation(['profile', 'common']);

  return (
    <NextOfKinDetailsWrapper data-testid={`nok-contaniner-${index}`}>
      <Div
        className={
          viewType === 'userProfile' || viewType === 'userProfileReview' ? 'withoutBorderContainer' : 'cardContainer'
        }
      >
        <Div key={`${item.id}-${index}`} testId={`nok-item-${index}`}>
          <Div
            className={viewType === 'userProfile' || viewType === 'userProfileReview' ? 'header' : 'header headerCard'}
          >
            <Typography testId={`nokName-${item.name}`} variant="h3">
              {item.name}
            </Typography>
            <Div>
              {onSelect && (
                <Button
                  className="editButton"
                  size="small"
                  testId={`nok-editBtn-${index}`}
                  variant="secondary"
                  onClick={() => onSelect(item)}
                >
                  {locale('common:edit')}
                </Button>
              )}
              {onOpenConfirmation && (
                <Button
                  className="deleteButton"
                  size="small"
                  testId={`nok-deleteBtn-${index}`}
                  variant="secondary"
                  onClick={() => {
                    onOpenConfirmation(item);
                  }}
                >
                  {locale('common:delete')}
                </Button>
              )}
            </Div>
          </Div>
          <Div
            className={
              viewType === 'userProfile' || viewType === 'userProfileReview' ? 'content' : 'content contentCard'
            }
          >
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <InfoItem content={item.relationship} title={locale('profile:relationshipToYou')} />
              </Grid>
              <Grid item sm={6} xs={12}>
                {item.otherRelationship !== '' && (
                  <InfoItem content={item.otherRelationship} title={locale('profile:otherRelationship')} />
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                <InfoItem content={item.mobileNumber} title={locale('profile:mobileNumber')} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InfoItem content={display(item.homeNumber)} title={`${locale('profile:homeNumber')} `} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <InfoItem content={display(item.email)} title={`${locale('common:email')} `} />
              </Grid>
              {viewType === 'userProfile' && (
                <Grid item sm={6} testId={`emergency-${index}`} xs={12}>
                  <InfoItem
                    content={item.emergency ? locale('common:yes') : locale('common:no')}
                    title={locale('profile:emergencyContact')}
                  />
                </Grid>
              )}
            </Grid>
          </Div>
        </Div>
      </Div>
    </NextOfKinDetailsWrapper>
  );
};
