import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { AppTourState } from 'model';

// REDUCER ACTION
import { setAppTourComponent, setEnabledAppTour } from './reducers';

export const initialState: AppTourState = {
  enabled: false,
  component: undefined
};

export const appTourSlice = createSlice({
  name: Domain.AppTour,
  initialState,
  reducers: {
    setAppTourComponent,
    setEnabledAppTour
  }
});

export default appTourSlice.reducer;
