import { KeyResource } from 'interface';
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { Center, CenterState } from 'model';

// ACTION TO CHANGE STATE
export const changeCenterLoading: CaseReducer<CenterState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setCenter: CaseReducer<CenterState, PayloadAction<Center | undefined>> = (state, action) => {
  state.single = action.payload;
};

export const setManyCenter: CaseReducer<CenterState, PayloadAction<Center[]>> = (state, action) => {
  state.many = action.payload;
};

export const setCentersMaps: CaseReducer<CenterState, PayloadAction<KeyResource<Center>>> = (state, action) => {
  state.centersMap = action.payload;
};
