import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { ServiceState } from 'model';

// REDUCER ACTION
import {
  changeServiceLoading,
  setManyServices,
  setService,
  setServiceGroupByCategory,
  setSelectedTerm,
  setHealthConditionChanged,
  setSelectedServiceType,
  setManyServicesByProductId,
  setCompletedServices,
  setExpiredServices,
  setOnGoingServices,
  setUpComingServices,
  setManyServicesWithStatus,
  setSelectedServiceTab
} from './reducers';

export const initialState: ServiceState = {
  single: undefined,
  groupByCategory: [],
  upComingServices: [],
  expiredServices: [],
  onGoingServices: [],
  completedServices: [],
  selectedTerm: undefined,
  healthConditionChanged: false,
  many: {},
  manyByStatus: {},
  manyByProductId: {},
  loading: true,
  selectedServiceType: undefined,
  selectedServiceTab: undefined
};

export const serviceSlice = createSlice({
  name: Domain.Service,
  initialState,
  reducers: {
    changeServiceLoading,
    setManyServices,
    setService,
    setServiceGroupByCategory,
    setSelectedTerm,
    setHealthConditionChanged,
    setSelectedServiceType,
    setManyServicesByProductId,
    setCompletedServices,
    setExpiredServices,
    setOnGoingServices,
    setUpComingServices,
    setManyServicesWithStatus,
    setSelectedServiceTab
  }
});

export default serviceSlice.reducer;
