import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  Clinic,
  DenticareAppointmentState,
  Dentist,
  Treatment,
  DenticareAppointmentHistoryResponse,
  DenticareAppointment,
  RevampDenticareCenter,
  RevampDentist,
  RevampAvailableMonths,
  RevampAvailableDates,
  RevampAvailableSlots,
  RevampDenticareClient,
  CancellationData
} from 'model';
import { initialState } from './slice';

// ----------------------------- LOADING -----------------------------------
export const changeDenticareLoading: CaseReducer<DenticareAppointmentState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.loading = action.payload;
};

export const setTreatmentsDenticare: CaseReducer<DenticareAppointmentState, PayloadAction<Treatment[]>> = (
  state,
  action
) => {
  state.treatments = action.payload;
};

export const setDentistsDenticare: CaseReducer<DenticareAppointmentState, PayloadAction<Dentist[]>> = (
  state,
  action
) => {
  state.dentists = action.payload;
};

export const setClinicsDenticare: CaseReducer<DenticareAppointmentState, PayloadAction<Clinic[]>> = (state, action) => {
  state.clinics = action.payload;
};

export const setSelectedDenticareAppointment: CaseReducer<
  DenticareAppointmentState,
  PayloadAction<DenticareAppointment | undefined>
> = (state, action) => {
  state.selectedAppointment = action.payload;
};

export const setDenticareAppointmentHistory: CaseReducer<
  DenticareAppointmentState,
  PayloadAction<DenticareAppointmentHistoryResponse>
> = (state, action) => {
  state.upcomingAppointments = action.payload.upcomingAppointments;
  state.completedAppointments = action.payload.completedAppointments;
  state.cancelledAppointments = action.payload.cancelledAppointments;
};

export const setUpcomingAppointment: CaseReducer<
  DenticareAppointmentState,
  PayloadAction<DenticareAppointment | undefined>
> = (state, action) => {
  state.upcomingAppointment = action.payload;
};

export const setSelectedCenter: CaseReducer<DenticareAppointmentState, PayloadAction<string>> = (state, action) => {
  state.selectedCenter = action.payload;
};

export const setSelectedDentist: CaseReducer<DenticareAppointmentState, PayloadAction<string>> = (state, action) => {
  state.selectedDentist = action.payload;
};

export const setSelectedMonth: CaseReducer<DenticareAppointmentState, PayloadAction<string>> = (state, action) => {
  state.selectedMonth = action.payload;
};

export const setLoadingFetchingCenter: CaseReducer<DenticareAppointmentState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.loadingFetchingCenter = action.payload;
};

export const setLoadingFetchingDentist: CaseReducer<DenticareAppointmentState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.loadingFetchingDentist = action.payload;
};

export const setLoadingFetchingMonth: CaseReducer<DenticareAppointmentState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.loadingFetchingMonth = action.payload;
};

export const setLoadingFetchingDate: CaseReducer<DenticareAppointmentState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.loadingFetchingDate = action.payload;
};

export const setLoadingFetchingSlot: CaseReducer<DenticareAppointmentState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.loadingFetchingSlot = action.payload;
};

export const setSelectedDate: CaseReducer<DenticareAppointmentState, PayloadAction<string>> = (state, action) => {
  state.selectedDate = action.payload;
};

export const setScalingServiceId: CaseReducer<DenticareAppointmentState, PayloadAction<string>> = (state, action) => {
  state.scalingServiceId = action.payload;
};

export const setExistingClient: CaseReducer<DenticareAppointmentState, PayloadAction<boolean>> = (state, action) => {
  state.isExistingClient = action.payload;
};

export const setHasOneAppointment: CaseReducer<DenticareAppointmentState, PayloadAction<boolean>> = (state, action) => {
  state.hasOneAppointment = action.payload;
};

export const setSelectedMonthLabel: CaseReducer<DenticareAppointmentState, PayloadAction<string>> = (state, action) => {
  state.selectedMonthLabel = action.payload;
};

export const setSelectedDateLabel: CaseReducer<DenticareAppointmentState, PayloadAction<string>> = (state, action) => {
  state.selectedDateLabel = action.payload;
};

export const setSelectedSlot: CaseReducer<DenticareAppointmentState, PayloadAction<string>> = (state, action) => {
  state.selectedSlot = action.payload;
};

export const setSelectedDenticareClient: CaseReducer<
  DenticareAppointmentState,
  PayloadAction<RevampDenticareClient>
> = (state, action) => {
  state.newDenticareClient = action.payload;
};

export const setRevampCenters: CaseReducer<DenticareAppointmentState, PayloadAction<RevampDenticareCenter[]>> = (
  state,
  action
) => {
  state.newCenters = action.payload;
};

export const setRevampDentist: CaseReducer<DenticareAppointmentState, PayloadAction<RevampDentist[]>> = (
  state,
  action
) => {
  state.newDentist = action.payload;
};

export const setRevampMonths: CaseReducer<DenticareAppointmentState, PayloadAction<RevampAvailableMonths[]>> = (
  state,
  action
) => {
  state.newMonths = action.payload;
};

export const setRevampDates: CaseReducer<DenticareAppointmentState, PayloadAction<RevampAvailableDates[]>> = (
  state,
  action
) => {
  state.newDates = action.payload;
};

export const setRevampSlots: CaseReducer<DenticareAppointmentState, PayloadAction<RevampAvailableSlots[]>> = (
  state,
  action
) => {
  state.newAvailableSlots = action.payload;
};

export const setCancellationData: CaseReducer<DenticareAppointmentState, PayloadAction<CancellationData>> = (
  state,
  action
) => {
  state.cancellationData = action.payload;
};

export const setFinishLoadingData: CaseReducer<DenticareAppointmentState, PayloadAction<boolean>> = (state, action) => {
  state.finishLoadingData = action.payload;
};

export const resetCancellationData: CaseReducer<DenticareAppointmentState> = (state) => {
  state.cancellationData = initialState.cancellationData;
};
