import { FeedBackRating } from 'model';

export interface Feedback {
  rating: number;
  feedback: string;
}

export enum FeedbackDialogStatus {
  Submitted = 'SUBMITTED',
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export interface FeedbackState {
  selectedFeedback?: FeedBackRating;
  hasSubmittedFeedback: boolean;
  feedbackSuccess: boolean;
}
