import { RootState } from 'redux/type';

import {
  DenticareAppointment,
  RevampDenticareCenter,
  RevampDentist,
  RevampAvailableDates,
  RevampAvailableMonths,
  RevampAvailableSlots,
  RevampDenticareClient
} from 'model';

export const selectDenticareBookingAppointmentLoading = (state: RootState): boolean =>
  state.denticareAppointmentReducer.loading;

export const selectTreatmentList = (state: RootState) => state.denticareAppointmentReducer.treatments;

export const selectDentistList = (state: RootState) => state.denticareAppointmentReducer.dentists;

export const selectClinicList = (state: RootState) => state.denticareAppointmentReducer.clinics;

export const selectAllCentralClinic = (state: RootState) =>
  state.denticareAppointmentReducer.clinics.filter((c) => c.area === 'CENTRAL');

export const selectAllNorthClinic = (state: RootState) =>
  state.denticareAppointmentReducer.clinics.filter((c) => c.area === 'NORTH');

export const selectAllWestClinic = (state: RootState) =>
  state.denticareAppointmentReducer.clinics.filter((c) => c.area === 'WEST');

export const selectCancellationData = (state: RootState) => state.denticareAppointmentReducer.cancellationData;

export const selectAllSouthClinic = (state: RootState) =>
  state.denticareAppointmentReducer.clinics.filter((c) => c.area === 'SOUTH');

export const selectAllEastClinic = (state: RootState) =>
  state.denticareAppointmentReducer.clinics.filter((c) => c.area === 'EAST');

export const selectSelectedAppointment = (state: RootState): DenticareAppointment | undefined =>
  state.denticareAppointmentReducer.selectedAppointment;

export const selectUpcomingAppointments = (state: RootState): DenticareAppointment[] =>
  state.denticareAppointmentReducer.upcomingAppointments ?? [];

export const selectCompletedAppointments = (state: RootState): DenticareAppointment[] =>
  state.denticareAppointmentReducer.completedAppointments ?? [];

export const selectCancelledAppointments = (state: RootState): DenticareAppointment[] =>
  state.denticareAppointmentReducer.cancelledAppointments ?? [];

export const selectUpcomingAppointment = (state: RootState): DenticareAppointment | undefined =>
  state.denticareAppointmentReducer.upcomingAppointment;

// REVAMP
export const selectLoadingFetchingCenter = (state: RootState): boolean =>
  state.denticareAppointmentReducer.loadingFetchingCenter;

export const selectLoadingFetchingDentist = (state: RootState): boolean =>
  state.denticareAppointmentReducer.loadingFetchingDentist;

export const selectLoadingFetchingMonth = (state: RootState): boolean =>
  state.denticareAppointmentReducer.loadingFetchingMonth;

export const selectLoadingFetchingDate = (state: RootState): boolean =>
  state.denticareAppointmentReducer.loadingFetchingDate;

export const selectLoadingFetchingSlot = (state: RootState): boolean =>
  state.denticareAppointmentReducer.loadingFetchingSlot;

export const selectScalingServiceId = (state: RootState): string => state.denticareAppointmentReducer.scalingServiceId;

export const selectSelectedCenter = (state: RootState): string => state.denticareAppointmentReducer.selectedCenter;

export const selectSelectedDentist = (state: RootState): string => state.denticareAppointmentReducer.selectedDentist;

export const selectSelectedMonth = (state: RootState): string => state.denticareAppointmentReducer.selectedMonth;

export const selectSelectedDate = (state: RootState): string => state.denticareAppointmentReducer.selectedDate;

export const selectIsExistingClient = (state: RootState): boolean => state.denticareAppointmentReducer.isExistingClient;

export const selectHasOneAppointment = (state: RootState): boolean | null =>
  state.denticareAppointmentReducer.hasOneAppointment;

export const selectSelectedDenticareClient = (state: RootState): RevampDenticareClient =>
  state.denticareAppointmentReducer.newDenticareClient;

export const selectSelectedMonthLabel = (state: RootState): string =>
  state.denticareAppointmentReducer.selectedMonthLabel;

export const selectSelectedDateLabel = (state: RootState): string =>
  state.denticareAppointmentReducer.selectedDateLabel;

export const selectSelectedSlot = (state: RootState): string => state.denticareAppointmentReducer.selectedSlot;

export const selectNewCenters = (state: RootState): RevampDenticareCenter[] =>
  state.denticareAppointmentReducer.newCenters;

export const selectNewDentist = (state: RootState): RevampDentist[] => state.denticareAppointmentReducer.newDentist;

export const selectNewMonths = (state: RootState): RevampAvailableMonths[] =>
  state.denticareAppointmentReducer.newMonths;

export const selectNewDates = (state: RootState): RevampAvailableDates[] => state.denticareAppointmentReducer.newDates;

export const selectNewAvailableSlot = (state: RootState): RevampAvailableSlots[] =>
  state.denticareAppointmentReducer.newAvailableSlots;

export const selectFinishLoadingData = (state: RootState) => state.denticareAppointmentReducer.finishLoadingData;
