/* eslint-disable camelcase */

// REVAMP
export interface RevampDenticareClient {
  nric: string;
  clientIdType: string;
  isBookingForYourSelf: boolean;
  bookingForYourself?: boolean;
  name: string;
  email: string;
  mobileNumber: string;
  countryCode: string;
}

export interface ReviewConfirmResponse {
  clientSessionId: string;
  clinicName: string;
  dentist: string;
  monthAndYear: string;
  dayOfMonth: number;
  appointmentDate: string;
  timeSlot: string;
  clientEmail: string;
  serviceName: string;
  clientNRIC: string;
  responseType: BookingResponseType;
}

export type BookingResponseType =
  | 'CONFIRMED'
  | 'BLACKLISTED'
  | 'TIME_SLOT_NOT_AVAILABLE'
  | 'HAS_NON_SP_APPOINTMENT'
  | 'APPOINTMENT_NOT_AVAILABLE'
  | 'CANCELLATION_REJECTED'
  | 'CANCELLATION_CONFIRM'
  | 'CANCELLATION_FAILED'
  | 'CANCELLATION_SUCCESS'
  | 'CANCELLATION_RETAINED';

export type UserJourneyOutcome =
  | 'APPOINTMENT_CONFIRMED'
  | 'APPOINTMENT_RETAINED'
  | 'APPOINTMENT_CANCELLED'
  | 'EXISTING_APPOINTMENT_FOUND'
  | 'E_INVOICE_ACCESS_SUCCESS';

export type FeedBackRating = 'NOT_HELPFUL' | 'NEUTRAL' | 'HELPFUL';

export interface RevampDenticareCenter {
  centerId: string;
  centerName: string;
}

export interface RevampDentist {
  resourceId: string | null;
  resourceName: string;
}

export interface BaseRevampOptions {
  value: string;
  label: string;
}

export interface RevampAvailableMonths extends BaseRevampOptions {}

export interface RevampAvailableDates extends BaseRevampOptions {}

export interface RevampAvailableSlots extends BaseRevampOptions {}
// REVAMP

export enum DenticareBookAppointmentProgressEnum {
  AppointmentSelection = 0,
  DateTimeSelection
}

export interface DenticareAppointment {
  appointmentId: string;
  appointmentStatus: ApointmentStatus;
  startDate: string;
  endDate: string;
  treatmentName: string;
  createdBy: 'SCMS' | 'MYNH';
  cancelledBy?: string;
  doctorName: string;
  clinicName?: string;
  centerName?: string;
  clinicAddress?: string;
  reschedulable?: boolean;
  cancellable?: boolean;
  postalCode?: string;
}

export type ApointmentStatus = 'Confirmed' | 'Turned-up' | 'Cancelled';

export interface CancelledDenticareAppointment {
  cancellationReason: string;
}

export interface DenticareAppointmentHistoryResponse {
  upcomingAppointments: DenticareAppointment[];
  completedAppointments: DenticareAppointment[];
  cancelledAppointments: DenticareAppointment[];
}

export interface CancellationData {
  appointmentDate: string;
  nric: string;
  serviceName: string;
  clientSessionId: string;
  appointmentId: string;
}

export interface DenticareAppointmentState {
  treatments: Treatment[];
  dentists: Dentist[];
  clinics: Clinic[];
  loading: boolean;
  selectedAppointment?: DenticareAppointment;
  upcomingAppointment?: DenticareAppointment;
  upcomingAppointments?: DenticareAppointment[];
  completedAppointments?: DenticareAppointment[];
  cancelledAppointments?: DenticareAppointment[];
  // REVAMP
  selectedCenter: string;
  selectedDentist: string;
  selectedMonth: string;
  selectedDate: string;
  selectedSlot: string;
  newCenters: RevampDenticareCenter[];
  newDentist: RevampDentist[];
  newMonths: RevampAvailableMonths[];
  newDates: RevampAvailableDates[];
  newAvailableSlots: RevampAvailableSlots[];
  selectedDateLabel: string;
  selectedMonthLabel: string;
  newDenticareClient: RevampDenticareClient;
  isExistingClient: boolean;
  hasOneAppointment: boolean | null;
  scalingServiceId: string;
  loadingFetchingCenter: boolean;
  loadingFetchingDentist: boolean;
  loadingFetchingMonth: boolean;
  loadingFetchingDate: boolean;
  loadingFetchingSlot: boolean;
  cancellationData: CancellationData;
  finishLoadingData: boolean;
}

export interface BookAppointment {
  serviceId: string;
  resourceId: string;
  centerId: string;
  startTime: string;
  endTime: string;
  durationMinutes: number;
  durationHour: number;
}

export interface RetrieveEmptySlot {
  startDate: string;
  endDate: string;
  resourceId: string;
  centerId: string;
  serviceId: string;
}

export interface RetrieveEmptySlotResponse {
  timeslots: DenticareSlot[];
}

export interface DenticareSlot {
  startTime: string;
  endTime: string;
  durationHour: number;
  durationMinutes: number;
}

export interface AppointmentBooked {
  appointment_uuid: string;
}

export interface Treatment {
  id: string;
  name: string;
  businessUnit: string;
}

export interface Clinic {
  id: string;
  centerId: string;
  serviceId: string;
  centerName: string;
  area: 'CENTRAL' | 'SOUTH' | 'WEST' | 'EAST' | 'NORTH';
  clinicAddress: string;
  postalCode: string;
}

export interface Dentist {
  id: string;
  centerId: string;
  serviceId: string;
  resourceId: string;
  resourceName: string;
}
