import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';

import Dialog from 'components/Dialog';

// ROUTER
import { Router } from 'constant';

// PROPS
import { DialogExitProps } from './interface';

const DialogExit: FC<DialogExitProps> = ({
  body,
  submitText,
  closeText,
  title,
  onCancel: onCancelProps,
  onSubmit: onSubmitProps,
  isEnabledPromp,
  open: openProps
}) => {
  const { t: locale } = useTranslation(['registerService']);
  const [open, setOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState('');

  const { push } = useHistory();

  const onCancel = () => {
    setOpen(false);

    if (onCancelProps) {
      onCancelProps();
    }
  };

  useEffect(() => {
    if (openProps !== undefined && openProps !== open) {
      setOpen(!!openProps);
    }
  }, [openProps, open]);

  return (
    <>
      <Prompt
        message={(nextLocation, action) => {
          if (action === 'POP') {
            return true;
          }

          if (nextLocation.pathname === Router.Payment) {
            return true;
          }

          if (nextLocation.pathname === Router.LinkAccount) {
            return true;
          }

          if (nextLocation.pathname.includes(Router.PaymentCompletionWithoutId)) {
            return true;
          }

          if (!open) {
            setNextLocation(nextLocation.pathname);
            setOpen(true);

            return false;
          }

          return true;
        }}
        when={isEnabledPromp}
      />

      <Dialog
        body={body || locale('registerService:exitFormMessage')}
        closeText={closeText || locale('registerService:exitNoButton')}
        data={null}
        open={open}
        submitText={submitText || locale('registerService:exitYesButton')}
        title={title || locale('registerService:exitFormTitle')}
        onCancel={onCancel}
        onSubmit={(_, formikHeler) => {
          formikHeler.setSubmitting(false);

          if (onSubmitProps) {
            onSubmitProps();
          }

          if (nextLocation) {
            push(nextLocation);
          }
        }}
      />
    </>
  );
};

export default DialogExit;
