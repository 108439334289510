// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// CONSTANT
import { ApiCenters } from 'constant';

// MODEL
import { Center } from 'model';

// HELPERS
// import { generateMapObjFromArr } from 'helpers/array';

// TYPE
import { Callback } from 'interface';

// SELECTOR
import { selectMapCenters, selectManyCenters } from './selector';

// REDUCER ACTION
import { centerSlice } from './slice';

const { changeCenterLoading, setCenter, setCentersMaps, setManyCenter } = centerSlice.actions;

const getCenterDetails =
  (centerID: string, fetchSingle: boolean, callback?: Callback<Center>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeCenterLoading(true));

    const currentMapCenters = selectMapCenters(getState());

    if (currentMapCenters[centerID]) {
      dispatch(changeCenterLoading(false));

      if (fetchSingle && callback) {
        callback(currentMapCenters[centerID]);
      }

      if (fetchSingle) {
        dispatch(setCenter(currentMapCenters[centerID]));
      }

      return;
    }

    dispatch(
      httpAction<unknown, unknown, Center>(
        {
          method: 'GET',
          url: ApiCenters.GetCenterDetail(centerID),
          needAuth: true
        },
        async (res) => {
          if (res.data) {
            const updatedMapCenters = selectMapCenters(getState());
            const updatedManyCenters = selectManyCenters(getState());

            let cloneMapCenters = { ...updatedMapCenters };
            const cloneManyCenters = updatedManyCenters.map((v) => ({ ...v }));

            cloneMapCenters = { ...cloneMapCenters, [centerID]: res.data };

            if (fetchSingle) {
              dispatch(setCenter(res.data));
            }

            dispatch(setManyCenter(cloneManyCenters.concat(res.data)));
            dispatch(setCentersMaps(cloneMapCenters));
          }

          if (callback) {
            callback(res.data || {});
          }

          dispatch(changeCenterLoading(false));
        }
      )
    );
  };

export const getCentersByProductId =
  (productId: string, callback?: Callback<Center[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeCenterLoading(true));

    dispatch(
      httpAction<unknown, unknown, Center[]>(
        {
          method: 'GET',
          url: `products/${productId}/centers/list`,
          needAuth: true
        },
        async (res) => {
          dispatch(changeCenterLoading(false));

          if (callback) {
            callback(res.data || []);
          }
        }
      )
    );
  };

export { getCenterDetails, setCenter };
