export const egiro = {
  finance: 'Finance',
  eGiroArrangements: 'eGIRO arrangements',
  myEGiroArrangements: 'My eGIRO arrangements',
  myLovedOnesEGiroArrangements: 'My dependent’s eGIRO arrangements',
  createDDA: 'Create DDA',
  refresh: 'Refresh',
  clientName: 'Client Name',
  ddaReferenceNo: 'DDA Reference No',
  bankName: 'Bank name',
  effectiveDate: 'Effective Date',
  expiryDate: 'Expiry Date',
  status: 'Status',
  relationShip: 'Relationship',
  selectDDRDetails: 'Select DDR Details',
  yourLovedOneDetails: 'Your Loved One’s Details',
  yourDetails: 'Your Details',
  fullName: 'Full Name',
  gender: 'Gender',
  dateOfBirth: 'Date of Birth',
  nationality: 'Nationality',
  nricNo: 'NRIC No.',
  mobileNumber: 'Mobile Number',
  email: 'Email',
  next: 'Next',
  back: 'Back',
  homeNumber: 'Home Number',
  optional: 'optional',
  ddaReferenceNumber: 'DDA reference number',
  relationShipWithClient: 'RelationShip with client',
  select: 'Select',
  selectBank: 'Select Bank',
  proceedWithGiroSetup: 'Proceed with giro setup',
  name: 'Name',
  noData: 'No Data',
  confirmCancelEGiroMessage: 'Do you really want to Cancel this e-GIRO arrangement?',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  acceptOrReject: 'Accept or Reject Transaction',
  accept: 'Accept',
  reject: 'Reject',
  enterNricOfNok: 'Please enter your loved ones registered NRIC/Passport/FIN number',
  clientInformation: 'Client Information',
  applicantsInformation: 'Applicants Information',
  remarks: 'Remarks',
  acceptedDate: 'Accepted Date',
  notFoundProfile: 'Not Found Profile',
  missingInformation: 'Enjoy the complete range of services we have to offer by completing your profile.',
  residentStatus: 'Residential Status',
  landingPageTitle: 'Apply for eGIRO with NTUC Health',
  landingPageSubTitle: 'Enable convenient and cashless payment for your NTUC Health service.',
  applyForEGiro: 'Apply for eGIRO',
  whatIsGiro: 'eGIRO',
  eGiroExplainContent:
    'eGIRO is an arrangement with your bank to enable convenient and cashless payments directly to NTUC Health for any service with us.',
  eGiroTypes: 'There are two common types of GIRO:',
  itemSectionTitle1: 'TYPE 01',
  itemSectionContent1:
    'GIRO direct debit allows NTUC Health to debit your designated bank account to pay your bills on a regular basis.',
  itemSectionTitle2: 'TYPE 02',
  itemSectionContent2:
    'Some banks provide GIRO-on-demand services where you have to authorise each GIRO payment through the phone/internet.',
  howToApplyEGiro: 'How to apply for an eGIRO with NTUC Health',
  eGiroStep1Title: 'Step 1: Log on to Linkpass',
  eGiroStep1Content: 'Login or create a new profile with <bold>Linkpass</bold>.',
  eGiroStep2Title: 'Step 2: Provide identification details',
  eGiroStep2Content:
    'Key in either your own or your dependent’s NRIC/Passport/FIN number for identification purposes. Then select your preferred bank for your eGIRO payments.',
  eGiroStep3Title: 'Step 3: Authorise eGIRO transaction',
  eGiroStep3Content:
    'You will be redirected to the official website of your selected bank, and asked to authorise your eGIRO arrangement.',
  interested: 'Interested?',
  interestedContent: 'Go cashless with eGIRO. It’s convenient and fast.',
  faq: 'FAQs',
  cancelThisEGiro: 'Cancel this eGIRO',
  applyForNewEGiro: 'Apply for a new eGIRO',
  applyForAnEGiroArrangement: 'Apply for an eGIRO arrangement',
  stepIdentification: '<bold>Step 2/3:</bold> Identification',
  stepReviewAndSelectBank: '<bold>Step 2/3:</bold> Review and select Bank for eGIRO',
  stepIdentificationIndependent: '<bold>Step 2/3:</bold> Identification & eGIRO details',
  stepReviewAndSelectBankIndependent:
    'Applying for an eGIRO arrangement for you or your dependent is <bold>fast & easy .< /bold>',
  identificationDetails: 'Identification details',
  applyingEGiroFor: 'I’m applying eGIRO for:',
  mySelf: 'Myself',
  myDependent: 'My dependent',
  nricPassportFin: 'NRIC/Passport/FIN <bold>*</bold>',
  enterNric: 'Enter your NRIC/Passport/FIN',
  typeHere: 'Type here',
  proceedToEGiroDetails: 'Proceed to eGIRO details',
  eGiroDetails: 'eGIRO details',
  reviewName: 'Name',
  reviewNric: 'NRIC',
  eGiroReferenceNumber: 'eGIRO reference number ',
  selectBankMessage: 'Select the bank you want to associate this eGIRO with.',
  applyEGiroMessage:
    '<bold>*Heads up!</bold> When you click on “Apply for eGIRO” below, you will be redirected to your bank portal and be asked to authorise this eGIRO arrangement.',
  errorTitle: 'Uh oh!',
  errorMessage: 'We don’t have an existing client attached to this {{idType}} you’ve provided.',
  confusedOrNeedHelp: 'Confused or need help?',
  contactCustomerSupport: 'Contact customer support',
  serviceOfferTitle: 'Would you like to know about the services we offer?',
  exploreServices: 'Explore services',
  explainReferenceNumber: 'Please quote this number for easier reference when contacting us.',
  theirRelationshipTitle: 'Their relationship with you <bold>*</bold>',
  missingProfileMessage: 'Enjoy the complete range of services we have to offer by completing your profile.',
  completeMyProfile: 'Complete my profile',
  awesome: 'Awesome!',
  successMessage1: 'You have applied for eGIRO payment to NTUC Health.',
  successMessage2:
    'It will take a few minutes for your bank to approve this request. Until then, the status of this eGIRO application will be shown as "Pending".',
  successMessage3: 'Once your bank approves this request, we will notify you via email.',
  featureAndBenefits: 'Features and benefits',
  featureTitle1: 'Enhanced customer journey',
  featureContent1: 'Able to setup eGIRO arrangements digitally',
  featureTitle2: 'Eliminate manual forms',
  featureContent2: 'Do away with manual forms for eGIRO arrangement which are inefficient',
  featureTitle3: 'Faster turnaround time',
  featureContent3: 'eGIRO setup takes a few minutes',
  howToApply: 'How to apply',
  applyEgiroToday: 'Apply for eGIRO today',
  explainNoticeEmail: 'Upon approval of your eGIRO request, you will be notified via email.',
  howToReachUs: 'How to reach us',
  howToReachUsContent:
    'Feel free to reach out to us anytime if you need assistance or have any questions – we’re here to help!',
  clickHereToMakeInquiry: 'Click here to make an inquiry',
  clickHereToCall: 'Click here to call us',
  clientNric: 'Client {{idType}}',
  clientGeneral: 'NRIC/Passport/FIN',
  clickHereToSendEmail: 'Click here to send us an email',
  cancellationEGiroSuccessMessage1:
    'It will take a few minutes for your bank to approve this request. Until then, the status of this eGIRO application will be shown as "Active".',
  cancellationEGiroSuccessMessage2: 'Once your bank approves this request, we will notify you via email.',
  cancellationEGiroSuccessTitle: 'Cancellation request received!',
  eGiroRefNo: 'eGIRO ref. no.',
  otherRelationshipTitle: 'Other Relationship <bold>*</bold>',
  enterDependentNric: 'Enter your dependent’s NRIC/Passport/FIN',
  limit: 'Limit',
  cancellationDate: 'Cancellation Date',
  nric: 'NRIC',
  passport: 'Passport',
  fin: 'FIN',
  bankNameRequired: 'Bank name <bold>*</bold>',
  operatingHours: 'Operating hours: 9:00 am - 5:00 pm, Mon - Fri (excluding PH)'
};
