import { extendTheme } from '@chakra-ui/react';

const themeConfiguration = {
  components: {
    Checkbox: {
      defaultProps: {
        colorScheme: 'brand'
      }
    },
    IconButton: {
      variants: {
        solid: {
          background: '#007d81'
        }
      }
    },
    Button: {
      variants: {
        outline: {
          border: '1px solid',
          borderColor: '#98A2B3',
          color: '#101828',
          _hover: {
            color: '#101828'
          }
        },
        solid: {
          color: '#101828',
          _hover: {
            color: '#fff'
          },
          _disabled: {
            background: '#BCF0F5',
            color: '#29ADAF',
            opacity: 'unset',
            _hover: {
              background: '#BCF0F5 !important',
              color: '#29ADAF',
              opacity: 'unset'
            }
          }
        }
      }
    }
  },
  colors: {
    brand: {
      100: '#DCF6F7',
      200: '#BCF0F5',
      300: '#8BE7E8',
      400: '#62D2D5',
      500: '#29ADAF',
      600: '#007D81',
      700: '#00565A',
      800: '#00565A',
      900: '#003136'
    },
    buttonColor: {
      50: '#D0D5DD',
      100: '#FFFFFF',
      500: '#62D2D5',
      600: '#007D81',
      700: '#00565A'
    }
  },
  fonts: {
    body: `'Inter', sans-serif`
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem'
  }
};

const theme = extendTheme({ ...themeConfiguration });

export default theme;
