import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { AppState } from 'model';

// REDUCER ACTION
import {
  changeAppLoading,
  setAppMessage,
  setAssociationCreated,
  setAppConfiguration,
  setOpenModalToRefreshApp
} from './reducers';

export const initialState: AppState = {
  associationCreated: false,
  loading: false,
  message: undefined,
  appConfiguration: {
    versionNumber: '0.0.0',
    disabledLovedOne: true
  },
  openModalToRefreshApp: false
};

export const appSlice = createSlice({
  name: Domain.App,
  initialState,
  reducers: {
    changeAppLoading,
    setAppMessage,
    setAssociationCreated,
    setAppConfiguration,
    setOpenModalToRefreshApp
  }
});

export default appSlice.reducer;
