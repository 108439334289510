import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { UserState, User } from 'model';

// REDUCER ACTION
import { changeUserLoading, setUser, setLoggedInUser, setManyUsers } from './reducers';

export const initialSingleUser: User = {
  id: '',
  nric: '',
  idType: 'NRIC',
  name: '',
  countryCode: '',
  mobileNumber: '',
  homeNumber: '',
  dateOfBirth: '',
  gender: '',
  maritalStatus: '',
  language: [],
  otherLanguage: '',
  religion: '',
  otherReligion: '',
  email: '',
  chasSubsidyTier: '',
  chasExpiryDate: '',
  nationality: '',
  residentStatus: '',
  otherResidentStatus: '',
  companyName: '',
  marketingConsent: false
};

export const initialState: UserState = {
  loggedInUser: initialSingleUser, // for logged in user
  single: initialSingleUser, // for selected user, eg: loved one,...
  many: [], // for an array of users, eg: for array of loved one
  profileUrl: '',
  loading: false
};

export const userSlice = createSlice({
  name: Domain.User,
  initialState,
  reducers: {
    changeUserLoading,
    setUser,
    setLoggedInUser,
    setManyUsers
  }
});

export default userSlice.reducer;
