import styled from 'styled-components';

// UI KIT
import { Div } from 'ui-kit';

export const PaymentSelectionWrapper = styled.div`
  width: 100%;
`;

export const QrCodeWrapper = styled(Div)`
  word-wrap: break-word;
`;
