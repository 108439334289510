import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

// MODEL
import {
  ServiceRegistrationState,
  RegistrationServiceProgressEnum,
  ServiceSelection,
  Programme,
  User,
  Contact,
  HealthDeclarationForm
} from 'model';

// ACTION TO CHANGE STATE

// ----------------------------- LOADING -----------------------------------

export const changeLoading: CaseReducer<ServiceRegistrationState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

// ----------------------------- SERVICE SELECTION STATUS -----------------------------------------

export const changeServiceSelectionStatus = (state: ServiceRegistrationState) => {
  if (state.single) {
    const { serviceSelection } = state.single;

    const validProgrammes = serviceSelection.programmes.filter(
      (item) => !isEmpty(item.category) && !isEmpty(item.product) && !isEmpty(item.offer) && !isEmpty(item.center)
    );

    const isValidProgrames =
      validProgrammes.length > 0 && validProgrammes.length === serviceSelection.programmes.length;

    let isValidSelection = true;

    if (serviceSelection.marketingChanel.includes('Others')) {
      isValidSelection = !isEmpty(serviceSelection.sourceOthers);
    }

    if (serviceSelection.catergoryName === 'Denticare') {
      state.isValidServiceSelection = isValidSelection;
    } else {
      state.isValidServiceSelection = isValidProgrames && isValidSelection;
    }
  }
};

// ----------------------------- SERVICE SELECTION -----------------------------------

export const changeServiceSelection: CaseReducer<ServiceRegistrationState, PayloadAction<ServiceSelection>> = (
  state,
  action
) => {
  if (state.single) {
    state.single.serviceSelection = action.payload;

    changeServiceSelectionStatus(state);
  }
};

// ----------------------------- PROGRAMME -----------------------------------------

export const changeProgramme: CaseReducer<ServiceRegistrationState, PayloadAction<Programme>> = (state, action) => {
  if (state.single) {
    state.single.serviceSelection.programmes = state.single.serviceSelection.programmes.map((item) =>
      item.id === action.payload.id ? action.payload : item
    );

    changeServiceSelectionStatus(state);
  }
};

// ----------------------------- SHOW WELCOME DIALOG -----------------------------------

export const setShowWelcomeDialog: CaseReducer<ServiceRegistrationState, PayloadAction<boolean>> = (state, action) => {
  state.isShowWelcomeDialog = action.payload;
};

// ----------------------------- CURRENT PROGRESS -----------------------------------

export const setProgress: CaseReducer<ServiceRegistrationState, PayloadAction<RegistrationServiceProgressEnum>> = (
  state,
  action
) => {
  state.currentProgress = action.payload;
};

// ----------------------------- LOVED ONE DETAIL STATUS -----------------------------------

export const changeLovedOneDetailStatus: CaseReducer<ServiceRegistrationState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.isValidLovedOneDetail = action.payload;
};

// ----------------------------- HEALTH DECLARATION STATUS -----------------------------------

export const changeHealthDeclarationStatus: CaseReducer<ServiceRegistrationState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.isValidHealthDeclaration = action.payload;
};

export const setHealthDeclaration: CaseReducer<ServiceRegistrationState, PayloadAction<HealthDeclarationForm>> = (
  state,
  action
) => {
  state.healthDeclaration = action.payload;
};

export const setHealthDeclarationLoading: CaseReducer<ServiceRegistrationState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.isHealthDeclarationLoading = action.payload;
};
// ----------------------------- ADDRESS STATUS -----------------------------------

export const changeAddressStatus: CaseReducer<ServiceRegistrationState, PayloadAction<boolean>> = (state, action) => {
  state.isValidAddress = action.payload;
};

// ----------------------------- CONTACT STATUS -----------------------------------

export const changeContactStatus: CaseReducer<ServiceRegistrationState, PayloadAction<boolean>> = (state, action) => {
  state.isValidContact = action.payload;
};

// ----------------------------- LOVED ONE DETAIL -----------------------------------

export const changeLovedOneDetail: CaseReducer<ServiceRegistrationState, PayloadAction<User>> = (state, action) => {
  if (state.single) {
    state.single.lovedOneDetail = action.payload;
  }
};

// ----------------------------- REVIEW STATUS -----------------------------------

export const changeReviewStatus: CaseReducer<ServiceRegistrationState, PayloadAction<boolean>> = (state, action) => {
  state.isValidReview = action.payload;
};

// ----------------------------- REVIEW STATUS -----------------------------------

export const changeTermsAndConditionsStatus: CaseReducer<ServiceRegistrationState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.isValidTermsAndConditions = action.payload;
};

// ----------------------------- EMERGENCY CONTACT -----------------------------------

export const changeEmergencyContact: CaseReducer<ServiceRegistrationState, PayloadAction<Contact>> = (
  state,
  action
) => {
  if (state.single) {
    state.single.emergencyContact = action.payload;
  }
};
