import styled from 'styled-components';
import { NHColor, Tabs } from 'ui-kit';

import { spacing } from 'helpers/styles';

export const RegisterServiceContainer = styled.div`
  .divider {
    border: 1px solid #e2e2e2;
    height: 0px;
  }

  .title {
    color: ${NHColor.green600};
  }

  .fieldTitle {
    color: ${NHColor.charcoal700};
  }

  .guideText {
    color: ${NHColor.charcoal700};
    margin-top: ${spacing(1.5)};
  }

  .linkText {
    color: ${NHColor.green600};
    text-decoration: underline;
  }

  .centerMsg {
    background: #e8f0f0;
    font-size: 14px;
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    line-height: 1.1;
    margin-top: 10px;
    padding: 10px;
  }
`;

export const TabsWrapper = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 0px;
    height: 0px;
  }
`;

export const DenticareInfoWrapper = styled.div`
  border: 1px solid ${NHColor.charcoal300};
  border-radius: 4px;

  .header {
    display: flex;
    flex-direction: row;
    padding: ${spacing(2)};
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${NHColor.charcoal300};

    h3 {
      color: ${NHColor.charcoal700};
    }
  }

  .content {
    padding: ${spacing(3)};
    border-bottom: 1px solid ${NHColor.grey200};
  }

  .textTitle {
    font-weight: bold;
    overflow-wrap: break-word;
  }

  .textContent {
    margin-top: 10px;
    font-weight: normal;
  }

  .divider {
    border: 1px solid ${NHColor.charcoal300};
    height: 0px;
    margin-right: ${spacing(2)};
  }
`;
