import { createSlice } from '@reduxjs/toolkit';
import { Domain } from 'constant';
import { DenticareAppointmentState } from 'model';
import {
  changeDenticareLoading,
  setClinicsDenticare,
  setDentistsDenticare,
  setTreatmentsDenticare,
  setDenticareAppointmentHistory,
  setSelectedDenticareAppointment,
  setUpcomingAppointment,
  setRevampCenters,
  setRevampDates,
  setRevampDentist,
  setRevampMonths,
  setRevampSlots,
  setSelectedCenter,
  setSelectedDate,
  setSelectedDentist,
  setSelectedMonth,
  setSelectedSlot,
  setSelectedDateLabel,
  setSelectedMonthLabel,
  setSelectedDenticareClient,
  setExistingClient,
  setHasOneAppointment,
  setScalingServiceId,
  setLoadingFetchingCenter,
  setLoadingFetchingDate,
  setLoadingFetchingDentist,
  setLoadingFetchingMonth,
  setLoadingFetchingSlot,
  setCancellationData,
  setFinishLoadingData,
  resetCancellationData
} from './reducer';

export const initialState: DenticareAppointmentState = {
  loading: true,
  clinics: [],
  dentists: [],
  treatments: [],
  selectedAppointment: undefined,
  upcomingAppointment: undefined,
  upcomingAppointments: [],
  completedAppointments: [],
  cancelledAppointments: [],
  newCenters: [],
  newDentist: [],
  newAvailableSlots: [],
  newMonths: [],
  newDates: [],
  selectedCenter: '',
  selectedDentist: '',
  selectedMonth: '',
  selectedDate: '',
  selectedSlot: '',
  selectedDateLabel: '',
  selectedMonthLabel: '',
  scalingServiceId: '',
  newDenticareClient: {
    nric: '',
    isBookingForYourSelf: false,
    name: '',
    email: '',
    mobileNumber: '',
    clientIdType: '',
    countryCode: ''
  },
  isExistingClient: false,
  hasOneAppointment: null,
  loadingFetchingCenter: false,
  loadingFetchingDentist: false,
  loadingFetchingMonth: false,
  loadingFetchingDate: false,
  loadingFetchingSlot: false,
  cancellationData: {
    appointmentDate: '',
    nric: '',
    serviceName: '',
    appointmentId: '',
    clientSessionId: ''
  },
  finishLoadingData: false
};

export const denticareBookAppointmentSlice = createSlice({
  name: Domain.DenticareBookAppointment,
  initialState,
  reducers: {
    changeDenticareLoading,
    setTreatmentsDenticare,
    setDentistsDenticare,
    setClinicsDenticare,
    setDenticareAppointmentHistory,
    setSelectedDenticareAppointment,
    setUpcomingAppointment,
    setRevampCenters,
    setRevampDates,
    setRevampDentist,
    setRevampMonths,
    setRevampSlots,
    setSelectedCenter,
    setSelectedDate,
    setSelectedDentist,
    setSelectedMonth,
    setSelectedSlot,
    setSelectedDateLabel,
    setSelectedMonthLabel,
    setSelectedDenticareClient,
    setExistingClient,
    setHasOneAppointment,
    setScalingServiceId,
    setLoadingFetchingCenter,
    setLoadingFetchingDate,
    setLoadingFetchingDentist,
    setLoadingFetchingMonth,
    setLoadingFetchingSlot,
    setCancellationData,
    setFinishLoadingData,
    resetCancellationData
  }
});

export default denticareBookAppointmentSlice.reducer;
