/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Add as AddIcon } from '@material-ui/icons';

// UIKit
import { Typography, Div, TextField, Button, isXSScreen, isSmScreen } from 'ui-kit';

// Helpers
import { mappingOptionsToSelects } from 'helpers/array';

// MODEL
import { Option, LovedOneType, ServiceLink } from 'model';

// CONSTANT
import { LINKS } from 'constant';

// STYLE
import { ServiceSelectionContainer } from 'views/RegisterService/ServiceSelection/style';
import { RegisterServiceContainer } from 'page/RegisterService/styles';

// Components
import { ProgrammeForm } from './ProgrammeForm';

// Data
import {
  serviceSelection as mockServiceSelection,
  categories as mockCategories,
  marketingChannels as mockMarketingChannels,
  serviceLinks as mockServiceLinks
} from './data';

const ServiceSelection: FC = () => {
  const { t: locale } = useTranslation(['registerService', 'common']);
  const isMobile = isXSScreen();
  const isTablet = isSmScreen();

  // SELECTOR
  const serviceSelection = mockServiceSelection;
  const isDisabledLovedOne = true;
  const isLoading = false;
  const lovedOnes = [{ id: LovedOneType.MySelf, name: 'Myself' }];
  const categories: Option[] = mockCategories;
  const marketingChannels: Option[] = mockMarketingChannels;
  const serviceLinks: ServiceLink[] = mockServiceLinks;
  const couldAddNewProgramme = false;

  const selectLovedOnes = mappingOptionsToSelects(lovedOnes);
  const selectServiceCategories = mappingOptionsToSelects(categories);
  const selectMarketingChannels = marketingChannels?.map((v) => ({ label: v.name, value: v.name }));
  const serviceName = categories?.find((v) => v.id === serviceSelection.category)?.name ?? '';

  const serviceSelectionSection = useMemo(
    () => (
      <>
        {!isEmpty(serviceSelection.category) && (
          <>
            <Div className="lineDivider" />
            <Div margin={{ top: 20 }}>
              <Typography className="fieldTitle" testId="selectServiceTitle" variant="h4">
                {locale('registerService:selectService')}
              </Typography>
              {serviceSelection.programmes.map((programme, index) => (
                <ProgrammeForm
                  key={`${programme.category}-${index}`}
                  category={serviceSelection.category}
                  data={programme}
                  index={index}
                  isMobile={isMobile || isTablet}
                  programmes={serviceSelection.programmes}
                  serviceLinks={serviceLinks ?? []}
                  serviceName={serviceName}
                />
              ))}
            </Div>
            <Button
              disabled
              className="btn-add-programme"
              startIcon={<AddIcon />}
              testId="add-programme-button"
              variant="secondary"
            >
              {locale('registerService:addAnotherProgamme')}
            </Button>
          </>
        )}

        <Div>
          <Div className="lineDivider" />
          <Div margin={{ top: 20 }} testId="marketing-consent-selection">
            <Typography className="fieldTitle" testId="howDidYouKnowAboutServiceTitle" variant="h4">
              {`${locale('registerService:howDidYouKnowAboutService')}`}
            </Typography>
            <Div margin={{ top: 10 }}>
              <TextField
                clearAll
                fullWidth
                readOnly
                placeholder={locale('registerService:selectOption')}
                selectItems={selectMarketingChannels}
                testId="howDidYouKnowAboutServiceDropdown"
                type="select"
                value={serviceSelection.marketingChanel}
              />
            </Div>
          </Div>
        </Div>

        <Div padding={{ bottom: 50 }} />
      </>
    ),
    [
      categories,
      couldAddNewProgramme,
      isMobile,
      locale,
      selectMarketingChannels,
      serviceLinks,
      serviceName,
      serviceSelection
    ]
  );

  return (
    <RegisterServiceContainer>
      <ServiceSelectionContainer>
        <Typography className="title" testId="serviceSelection" variant="h2">
          1. {locale('registerService:serviceSelection')}
        </Typography>
        <Typography className="guideText" testId="serviceSelectionGuide" variant="body1">
          {locale('registerService:serviceSelectionGuide')}{' '}
          <a href={`${LINKS.PDPA}`} target="_blank">
            <b className="linkText">{locale('registerService:personalDataProtectionPolicy')}</b>
          </a>
        </Typography>
        <Div className="inputContainer" margin={{ top: 20 }}>
          {!isDisabledLovedOne && (
            <Div>
              <Typography className="fieldTitle" variant="h4">
                {locale('registerService:whoAreYouRegistering')}
              </Typography>
              <Div margin={{ top: 10 }}>
                <TextField
                  fullWidth
                  readOnly
                  placeholder={locale('registerService:selectPerson')}
                  selectItems={selectLovedOnes}
                  type="select"
                  value={serviceSelection.lovedOne}
                />
              </Div>
            </Div>
          )}

          {!isEmpty(serviceSelection.lovedOne) && (
            <Div margin={{ top: 20 }} testId="service-selection">
              <Typography className="fieldTitle" testId="registerCategoryService" variant="h4">
                {locale('registerService:registerCategoryService')}
              </Typography>
              <Div margin={{ top: 10 }}>
                {!isLoading && isEmpty(selectServiceCategories) ? (
                  <Typography variant="body1">{locale('registerService:emptyServiceSelectionMsg')}</Typography>
                ) : (
                  <TextField
                    fullWidth
                    readOnly
                    placeholder={locale('registerService:selectCategoryService')}
                    selectItems={selectServiceCategories}
                    testId="selectCategoryService"
                    type="select"
                    value={serviceSelection.category}
                  />
                )}
              </Div>
            </Div>
          )}
        </Div>

        {serviceSelectionSection}
      </ServiceSelectionContainer>
    </RegisterServiceContainer>
  );
};

export default ServiceSelection;
