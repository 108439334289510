import { string, object, StringSchema } from 'yup';
import { isEmpty } from 'lodash';

import { isPhoneNumber, stringEmptyTransform, isNRIC } from 'helpers/validations';

import { User } from 'model';

export const profileSchema = object<User>({
  name: string().trim().required('Name is required'),
  nationality: string().optional(),
  residentStatus: string().optional(),
  email: string().email('Invalid email').required('Email is required'),
  mobileNumber: string()
    .required('Mobile number is required')
    .transform(stringEmptyTransform)
    .test('is-phone', 'Phone number is invalid', (value) => (value && !isEmpty(value) ? isPhoneNumber(value) : false)),
  dateOfBirth: string()
    .required('Date of birth is required')
    .test('is-valid', 'Date of birth is invalid', (value) => !(typeof value === 'string' && value === 'Invalid Date')),
  gender: string().required('Gender is required'),
  idType: string().required('ID Type is required'),
  nric: string().when('idType', (idType: string, schema: StringSchema) => {
    if (idType === 'PASSPORT') {
      return schema
        .required('Passport is required')
        .test('valid passport', 'Passport is invalid', (value) => !value || /^[A-Za-z0-9]*$/.test(value))
        .min(4, 'Passport number should be atleast 4 characters long');
    }

    if (idType === 'NRIC') {
      return schema
        .required('NRIC is required')
        .test('full nric', 'NRIC is invalid', (value) => !value || isNRIC(value, 'nric'));
    }

    return schema
      .required('FIN is required')
      .test('full nric', 'Fin is invalid', (value) => !value || isNRIC(value, 'fin'));
  }),
  language: string().required('Language is required'),
  otherLanguage: string().when('language', {
    is: (language) => !isEmpty(language) && language.includes('Others'),
    then: string().trim().required('Other language is required')
  }),
  religion: string().optional(),
  homeNumber: string()
    .optional()
    .transform(stringEmptyTransform)
    .test('is-phone', 'Home number is invalid', (value) => (value ? isPhoneNumber(value) : true)),
  otherReligion: string().optional(),
  otherResidentStatus: string().when('residentStatus', {
    is: (residentStatus) => residentStatus === 'Others',
    then: string().trim().required('Other Residential Status is required')
  })
}).defined();
