export const healthDeclaration = {
  healthDeclaration: 'Health Declaration',
  updateOnYourHealthStatus: 'Update on your health status',
  updateOnYourHealthStatusCn: '更新您的健康状况',
  updateOnYourHealthStatusSubtitle:
    'Dear customer, kindly update your health status so that we can help you to achieve your fitness goals and keep you safe during your exercise class.',
  updateOnYourHealthStatusSubtitleCn: '请更新您的健康状况以便让我们能帮助您达到健康目标以及确保您上运动课的安全。',
  name: 'Name',
  nameCn: '姓名',
  fullNric: 'Full NRIC (please use capital letters)',
  fullNricCN: '登记号码',
  handphoneNumber: 'Handphone number',
  handphoneNumberCn: '手机号码',
  center: 'Centre:',
  centerCn: '中心：',
  whyDidYouJoinOurFitnessProgramme: 'Why did you join our fitness programme?',
  whyDidYouJoinOurFitnessProgrammeCn: '您参加健身活动的原因是什么？',
  otherReasons: 'Other reasons',
  tickAllThatApply: '[Tick all that apply]',
  tickAllThatApplyCn: '勾选所有合适选项',
  toLoseWeight: 'To lose weight',
  toLoseWeightCn: '减轻体重',
  toBuildMuscleMassOrStrength: 'To build muscle mass or strength',
  toBuildMuscleMassOrStrengthCn: '增强肌肉力量或增加肌肉体积',
  toImproveOrMaintainMyGeneralFitness: 'To improve or maintain my general fitness',
  toImproveOrMaintainMyGeneralFitnessCn: '改善或维持健康状况',
  toImproveAConditionThatIHave: 'To improve a condition that I have',
  toImproveAConditionThatIHaveCn: '改善身体状况',
  toMakeFriends: 'To make friends',
  toMakeFriendsCn: '扩大社交圈',
  other: 'Other',
  otherCn: '其他',
  doYouHaveAnyOfTheseHealthCondition: 'Do you have any of these health conditions?',
  doYouHaveAnyOfTheseHealthConditionCn: '您有以下任何病症/身体状况吗？',
  otherHealthConditions: 'Other health conditions',
  none: 'None',
  noneCn: '无',
  highBloodPressure: 'High blood pressure',
  highBloodPressureCn: '高血压',
  highCholesterol: 'High cholesterol',
  highCholesterolCn: '高胆固醇',
  diabetes: 'Diabetes',
  diabetesCn: '糖尿病',
  heartCondition: 'Heart condition',
  heartConditionCn: '心脏问题',
  haveAPacemaker: 'Have a pacemaker',
  haveAPacemakerCn: '拥有心脏起搏器',
  dementia: 'Dementia',
  dementiaCn: '失智',
  strokeRight: 'Stroke (right side of body)',
  strokeRightCn: '中风 (右边）',
  strokeLeft: 'Stroke (left side of body)',
  strokeLeftCn: '中风 (左边）',
  parkinson: 'Parkinson',
  parkinsonCn: '帕金森病',
  osteoarthritis: 'Osteoarthritis',
  osteoarthritisCn: '骨关节炎',
  osteoporosisSarcopenia: 'Osteoporosis / Sarcopenia',
  osteoporosisSarcopeniaCn: '老年骨骼肌减少症',
  eyesightIssue: 'Eyesight issue',
  eyesightIssueCn: '视力问题',
  hearingIssue: 'Hearing issue',
  hearingIssueCn: '听力问题',
  didYouInjureOrHaveChronicPain: 'Did you injure or have chronic pain in any of these body parts in the last 6 months?',
  didYouInjureOrHaveChronicPainCn: '您曾在过去的六个月期间受到外伤或感觉身体患有慢性疼痛吗？',
  otherInjuries: 'Other injuries or chronic pains',
  shoulderLeft: 'Shoulder, left',
  shoulderLeftCn: '左边肩膀',
  shoulderRight: 'Shoulder, right',
  shoulderRightCn: '右边肩膀',
  elbowLeft: 'Elbow, left',
  elbowLeftCn: '左边手肘',
  elbowRight: 'Elbow, right',
  elbowRightCn: '右边手肘',
  wristLeft: 'Wrist, left',
  wristLeftCn: '左边手腕',
  wristRight: 'Wrist, Right',
  wristRightCn: '右边手腕',
  spine: 'Spine',
  spineCn: '脊柱',
  lowerBack: 'Lower back',
  lowerBackCn: '下背部',
  hipLeft: 'Hip, left',
  hipLeftCn: '左髋',
  hipRight: 'Hip, right',
  hipRightCn: '右髋',
  kneeLeft: 'Knee, left',
  kneeLeftCn: '左膝盖',
  kneeRight: 'Knee, right',
  kneeRightCn: '右膝盖',
  ankleFootLeft: 'Ankle/Foot, left',
  ankleFootLeftCn: '左踝',
  ankleFootRight: 'Ankle/Foot, right',
  ankleFootRightCn: '右踝',
  howManyTimesDoYouExerciseInAWeek: 'How many times do you exercise in a week?',
  howManyTimesDoYouExerciseInAWeekCn: '您一个星期运动几次？',
  chooseOneOption: '[Choose one option]',
  chooseOneOptionCn: '请选择一个选项',
  zero: '0',
  zeroCn: '零',
  oneToThree: '1-3',
  oneToThreeCn: '一至三次',
  fourToFive: '4-5',
  fourToFiveCn: '四至五次',
  moreThanFive: 'More than 5',
  moreThanFiveCn: '多过五次',
  inputPlaceholder: 'Please enter remarks here'
};
