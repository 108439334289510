const categories = [
  {
    id: 'ed275fd7-d71a-4983-9d57-8ae8c687d8f2',
    name: 'Active Ageing',
    subcategories: [{ id: 'a084f717-1c80-4458-89bb-5b1461dbb96d', name: 'Fitness' }]
  },
  {
    id: '482baf56-2809-447d-ba43-7e67a6ea1de8',
    name: 'Day Care',
    subcategories: []
  },
  {
    id: 'f499b441-2fcf-4e72-8d5e-7e94add96d95',
    name: 'Denticare',
    subcategories: []
  },
  {
    id: 'ea2c96d9-7ab6-463d-bd2c-55a505e253d8',
    name: 'Home Care',
    subcategories: []
  }
];

const marketingChannels = [
  {
    id: '6a6b9a36-c4f8-4e34-ad8b-d50faa75a8ff',
    name: 'Search Engine (i.e. Google, Yahoo, Bing)'
  },
  { id: '417f5430-a7a6-42da-8e4f-808ebfb0813e', name: 'Facebook' },
  { id: '29233433-3e25-4a8a-b788-56e6794eb76b', name: 'Instagram' },
  {
    id: 'f471b251-ee18-43bb-b07c-83907f238c08',
    name: 'Media (i.e. Newspaper, TV)'
  },
  { id: '1abc6eed-36a0-45a7-b69f-2ccfd4d9fb1d', name: 'Email' },
  {
    id: '13a051df-93ec-495e-a427-5507f2bb58ea',
    name: 'Print (i.e. Poster, flyer)'
  },
  { id: '9d2c16d9-a770-4e9f-a0e3-ccd0ce432d16', name: 'NTUC Health website' },
  {
    id: '63ea7e94-1e56-4ac0-bad6-e23a960290b4',
    name: 'NTUC Union Membership email/website'
  },
  { id: '2f1f5923-1eec-49c4-8389-5046453d4c3e', name: 'Events/Roadshows' },
  { id: '7e07a63f-44e5-495c-bdef-81da5e15ce41', name: 'Word of mouth' },
  {
    id: '3a77dbf2-b91a-4b80-ab5f-e1ee39dcd415',
    name: 'Government agencies/GP'
  },
  { id: '7d6a12e2-d94c-481e-b694-5fbb60455766', name: 'Staff of NTUC Health' },
  {
    id: '253887c8-0960-4827-87db-c6089cf4d9fd',
    name: 'Others (Please specify)'
  }
];

const serviceLinks = [
  {
    createdDate: null,
    lastModifiedDate: null,
    createdBy: null,
    lastModifiedBy: null,
    id: 'ba19f39a-9e1b-42ab-848f-a05090074879',
    code: '705c0839-f626-4d71-9fa8-4a37d5e1e538',
    codeName: 'NTUCH_LINKS',
    key: 'Active Ageing',
    value: 'https://ntuchealth.sg/active-ageing/services/tango/'
  },
  {
    createdDate: null,
    lastModifiedDate: null,
    createdBy: null,
    lastModifiedBy: null,
    id: 'b4d53547-f71a-42a6-bc5f-b7b6fbe5a27f',
    code: '705c0839-f626-4d71-9fa8-4a37d5e1e538',
    codeName: 'NTUCH_LINKS',
    key: 'Active Ageing Fitness',
    value: 'https://ntuchealth.sg/active-ageing/services/tango/fitness/'
  },
  {
    createdDate: null,
    lastModifiedDate: null,
    createdBy: null,
    lastModifiedBy: null,
    id: '75d87997-4abf-4393-8d81-4f1c42cb3201',
    code: '705c0839-f626-4d71-9fa8-4a37d5e1e538',
    codeName: 'NTUCH_LINKS',
    key: 'Active Ageing Mental Wellness',
    value: 'https://ntuchealth.sg/active-ageing/services/tango/#mental-wellness'
  },
  {
    createdDate: null,
    lastModifiedDate: null,
    createdBy: null,
    lastModifiedBy: null,
    id: '22127b53-1f49-41ce-bd4c-be6e18e274d5',
    code: '705c0839-f626-4d71-9fa8-4a37d5e1e538',
    codeName: 'NTUCH_LINKS',
    key: 'Active Ageing Social',
    value: 'https://ntuchealth.sg/active-ageing/services/tango/#volunteering'
  },
  {
    createdDate: null,
    lastModifiedDate: null,
    createdBy: null,
    lastModifiedBy: null,
    id: '688bb56b-2cc6-41cb-a5ab-ce548676311d',
    code: '705c0839-f626-4d71-9fa8-4a37d5e1e538',
    codeName: 'NTUCH_LINKS',
    key: 'Active Ageing Locations',
    value: 'https://ntuchealth.sg/active-ageing/services/tango/#locations'
  }
];

const subCategories = [{ id: 'a084f717-1c80-4458-89bb-5b1461dbb96d', name: 'Fitness' }];

const products = [
  {
    id: 'd0bf0431-b380-4d02-8be5-2571ea844b6f',
    name: ' Circuit Training - PD Team'
  },
  { id: 'af519015-2d54-4d78-af34-999a6738594c', name: 'CTFIT ' },
  {
    id: '7be630b6-29c6-4bb2-817e-db667daee304',
    name: 'Circuit Training Focus'
  },
  {
    id: 'e693992d-2337-45bc-b8c9-645b04cb3f3c',
    name: 'Circuit Training Focus (post assessment)'
  },
  { id: 'b3e46413-f0a4-4390-a1b5-dcdba8895bc9', name: 'EC - Active Aging' },
  {
    id: '7370c939-dcd6-42f0-a4a6-bc42e95b5587',
    name: 'Functional Strength Training - NEW - PD Team'
  },
  {
    id: 'ef822ed7-467f-4eca-b2e2-0effb76a30ce',
    name: 'Functional Strength Training - PD Team'
  },
  { id: 'df0571ca-4215-e811-817e-e0071b69c9d1', name: 'Gym Tonic - PD Team' },
  { id: '186a749e-69c4-4fa5-99cd-faa482ab59cb', name: 'Gymtonic 2.0' },
  { id: '6b287cc7-45e0-4f96-97ef-a2e007ead957', name: 'Kickboxing - PD Team' },
  {
    id: '576ae9f2-82ad-40df-9bb2-cb9a3831040a',
    name: 'Pentest Product - Intake'
  },
  { id: '38317e13-9edf-42af-995d-7f6c6f6efdba', name: 'Phyo Product - Intake' },
  {
    id: 'ff6dd418-135f-450f-ade8-7fa7ad1918d5',
    name: 'Pythonic Product - Intake'
  },
  {
    id: 'e2ac0d67-5217-43e0-b627-a901c4925488',
    name: 'Pythonic Product - Intake 2'
  },
  {
    id: '21a3757b-eed7-4850-9299-1a6832410cb3',
    name: 'Pythonic Product - Session'
  },
  {
    id: '1c5afa61-fec1-4a39-898b-15b336fb48a1',
    name: 'Tango - Circuit Training'
  },
  {
    id: '89a21529-e92b-46f3-bdc7-da293d821bfc',
    name: 'Tango - Functional Strength Training'
  },
  { id: '71d67fa3-1af0-4c8f-be34-8fdbc32eb469', name: 'Tango - Gymtonic' },
  { id: '49bc4769-2ee2-4ea4-b092-4d811d214481', name: 'Tango - Kickboxing' },
  { id: '0ba85593-6ed3-49ef-be05-44549240e4c3', name: 'Tango - X Fit' },
  { id: '77c89cf6-d0ae-4a1f-b3e5-223cce73cb6e', name: 'Tango Gymtonic +' },
  {
    id: '99e7b5c4-336a-4dec-99af-9f14f09af483',
    name: 'Test- Circuit Training'
  },
  { id: '0cdc7590-6128-4981-801c-02ae946b22ea', name: 'Test-GymTonic' },
  { id: '5020e18a-bb00-43d7-90dd-5ae8a1692612', name: 'X Fit - NEW - PD Team' },
  { id: '3f19affd-78e4-44eb-ba8b-ef532915c009', name: 'X Fit - PD Team' }
];

const offers = [
  {
    id: '7a459dd2-3bb9-4dca-94d0-6b3974222b3f',
    name: 'Circuit Training - 6 Sessions',
    pricing: {
      id: '5b841708-fbbc-4bc8-97a0-5e47b3fee26c',
      code: undefined,
      name: '',
      description: undefined,
      price: 200,
      currency: 'SGD',
      sessions: 6,
      billingCycle: 'P6W'
    },
    promotions: [
      {
        createdDate: 1638173423016,
        lastModifiedDate: 1638174275869,
        createdBy: 'Arvind Kumar',
        lastModifiedBy: 'Arvind Kumar',
        id: 'ebf2922a-baa0-4416-9af0-89345fe1666a',
        offeringId: '7a459dd2-3bb9-4dca-94d0-6b3974222b3f',
        active: true,
        savingsType: 'PRICE',
        applicability: ['PROMO_CODE', 'NEW_TO_SERVICE_CATEGORY'],
        discountId: '51101069-f8b5-4f64-92ba-054a12d6ccbd',
        centers: [
          '426609fd-092a-4c57-9fc8-b21a0eb30262',
          '9f46a6d8-fdc8-4b2b-8e75-937d91d9f231',
          '1f150f7f-45e0-49c0-a708-f86e4dc3ae8b'
        ]
      }
    ],
    promotion: undefined,
    productId: '1c5afa61-fec1-4a39-898b-15b336fb48a1',
    enabled: true,
    noOfBufferWeek: 0,
    offeringType: 'LITE'
  },
  {
    id: '61a122fb-cf3d-471e-8e1b-8b2e0bfee329',
    name: 'Circuit Training - 12 Sessions',
    pricing: {
      id: 'bf2196c3-fdac-4fd2-9611-91608018d8e6',
      code: undefined,
      name: '',
      description: undefined,
      price: 200.5,
      currency: 'SGD',
      sessions: 12,
      billingCycle: 'P12W'
    },
    promotions: [
      {
        createdDate: 1635409317848,
        lastModifiedDate: 1651055922646,
        createdBy: 'Arvind Kumar',
        lastModifiedBy: 'Divya',
        id: '88bf4322-cf00-419e-831b-78ef178ad437',
        offeringId: '61a122fb-cf3d-471e-8e1b-8b2e0bfee329',
        active: true,
        savingsType: 'PRICE',
        applicability: ['PROMO_CODE', 'NEW_TO_PROGRAM'],
        discountId: '49d55cee-ae15-4952-91a1-a66c50a25055',
        centers: [
          'be21fe61-0081-e511-8117-c4346badc5f4',
          '9f46a6d8-fdc8-4b2b-8e75-937d91d9f231',
          '18b54009-a792-42a4-9886-5a610a0a3ce9',
          '14945898-1678-4caa-ae87-17b02b63ec8f',
          '426609fd-092a-4c57-9fc8-b21a0eb30262',
          '45a67e21-096c-4fd1-9288-74bfc070a088',
          '1f150f7f-45e0-49c0-a708-f86e4dc3ae8b',
          '3e8a244c-b759-4c99-b61a-af2d293c906e'
        ]
      },
      {
        createdDate: 1635409351296,
        lastModifiedDate: 1650953842645,
        createdBy: 'Arvind Kumar',
        lastModifiedBy: 'Anshul',
        id: 'e1772e39-5211-43b0-b6af-e6361e788ef2',
        offeringId: '61a122fb-cf3d-471e-8e1b-8b2e0bfee329',
        active: true,
        savingsType: 'PRICE',
        applicability: ['NEW_TO_SERVICE_CATEGORY', 'PROMO_CODE'],
        discountId: '51101069-f8b5-4f64-92ba-054a12d6ccbd',
        centers: ['9f46a6d8-fdc8-4b2b-8e75-937d91d9f231', 'be21fe61-0081-e511-8117-c4346badc5f4']
      }
    ],
    promotion: undefined,
    productId: '1c5afa61-fec1-4a39-898b-15b336fb48a1',
    enabled: true,
    noOfBufferWeek: 6,
    offeringType: 'LITE'
  },
  {
    id: '47b8c8ce-a1f8-4e47-af05-d670483b6d24',
    name: 'Circuit Training (Lite)',
    pricing: {
      id: '95135b51-ffa5-4ee0-a73e-e0dc7a655922',
      code: undefined,
      name: '',
      description: undefined,
      price: 200.93,
      currency: 'SGD',
      sessions: 12,
      billingCycle: 'P18W'
    },
    promotions: [
      {
        createdDate: 1633703701026,
        lastModifiedDate: 1650366111000,
        createdBy: 'Kalpa',
        lastModifiedBy: 'Anshul',
        id: '241bb398-2db1-466d-903b-04edabad0743',
        offeringId: '47b8c8ce-a1f8-4e47-af05-d670483b6d24',
        active: true,
        savingsType: 'PRICE',
        applicability: ['NEW_TO_SERVICE_CATEGORY', 'PROMO_CODE'],
        discountId: '51101069-f8b5-4f64-92ba-054a12d6ccbd',
        centers: [
          '1f150f7f-45e0-49c0-a708-f86e4dc3ae8b',
          '14945898-1678-4caa-ae87-17b02b63ec8f',
          '9f46a6d8-fdc8-4b2b-8e75-937d91d9f231',
          '3e8a244c-b759-4c99-b61a-af2d293c906e',
          '45a67e21-096c-4fd1-9288-74bfc070a088',
          'be21fe61-0081-e511-8117-c4346badc5f4'
        ]
      },
      {
        createdDate: 1633703988043,
        lastModifiedDate: 1651055974170,
        createdBy: 'Kalpa',
        lastModifiedBy: 'Divya',
        id: 'bf696b50-e287-45ce-bf15-6f1fa65b8470',
        offeringId: '47b8c8ce-a1f8-4e47-af05-d670483b6d24',
        active: true,
        savingsType: 'PRICE',
        applicability: ['PROMO_CODE', 'NEW_TO_PROGRAM'],
        discountId: '49d55cee-ae15-4952-91a1-a66c50a25055',
        centers: [
          '45a67e21-096c-4fd1-9288-74bfc070a088',
          '9f46a6d8-fdc8-4b2b-8e75-937d91d9f231',
          '1f150f7f-45e0-49c0-a708-f86e4dc3ae8b',
          '14945898-1678-4caa-ae87-17b02b63ec8f',
          '3e8a244c-b759-4c99-b61a-af2d293c906e'
        ]
      }
    ],
    promotion: undefined,
    productId: '1c5afa61-fec1-4a39-898b-15b336fb48a1',
    enabled: true,
    noOfBufferWeek: 6,
    offeringType: 'LITE'
  },
  {
    id: '96c964d3-c3d0-4637-ae2d-3b997b0733d1',
    name: 'Circuit Training (Full)',
    pricing: {
      id: '94f7dfe2-2247-4673-b4e9-3d3ad44e40d9',
      code: undefined,
      name: '',
      description: undefined,
      price: 336.46,
      currency: 'SGD',
      sessions: 24,
      billingCycle: 'P32W'
    },
    promotions: [
      {
        createdDate: 1633704910891,
        lastModifiedDate: 1650365832439,
        createdBy: 'Kalpa',
        lastModifiedBy: 'rajapaksha k',
        id: '7d8f51bb-0432-47b9-a696-76ec73f2d71c',
        offeringId: '96c964d3-c3d0-4637-ae2d-3b997b0733d1',
        active: true,
        savingsType: 'PRICE',
        applicability: ['PROMO_CODE', 'NEW_TO_PROGRAM'],
        discountId: '49d55cee-ae15-4952-91a1-a66c50a25055',
        centers: [
          '45a67e21-096c-4fd1-9288-74bfc070a088',
          '9f46a6d8-fdc8-4b2b-8e75-937d91d9f231',
          '14945898-1678-4caa-ae87-17b02b63ec8f',
          '1f150f7f-45e0-49c0-a708-f86e4dc3ae8b',
          '3e8a244c-b759-4c99-b61a-af2d293c906e',
          'be21fe61-0081-e511-8117-c4346badc5f4'
        ]
      },
      {
        createdDate: 1633704838822,
        lastModifiedDate: 1650965855185,
        createdBy: 'Kalpa',
        lastModifiedBy: 'Anshul',
        id: 'a7ac4c92-ae10-43f2-9529-746ec8e3002b',
        offeringId: '96c964d3-c3d0-4637-ae2d-3b997b0733d1',
        active: true,
        savingsType: 'PRICE',
        applicability: ['PROMO_CODE', 'NEW_TO_SERVICE_CATEGORY'],
        discountId: '51101069-f8b5-4f64-92ba-054a12d6ccbd',
        centers: [
          '45a67e21-096c-4fd1-9288-74bfc070a088',
          '9f46a6d8-fdc8-4b2b-8e75-937d91d9f231',
          '1f150f7f-45e0-49c0-a708-f86e4dc3ae8b',
          '3e8a244c-b759-4c99-b61a-af2d293c906e',
          '14945898-1678-4caa-ae87-17b02b63ec8f',
          'be21fe61-0081-e511-8117-c4346badc5f4',
          '18b54009-a792-42a4-9886-5a610a0a3ce9',
          '426609fd-092a-4c57-9fc8-b21a0eb30262'
        ]
      },
      {
        createdDate: 1633854496696,
        lastModifiedDate: 1646371270779,
        createdBy: 'Kalpa',
        lastModifiedBy: 'Arvind Kumar',
        id: '9f1ef8ed-3d33-410c-93eb-7e3b379a63c7',
        offeringId: '96c964d3-c3d0-4637-ae2d-3b997b0733d1',
        active: true,
        savingsType: 'PRICE',
        applicability: ['NEW_TO_PROGRAM', 'UNION_MEMBER'],
        discountId: '9851bbd3-9966-4b89-a0cc-e3aa236a75b9',
        centers: [
          '14945898-1678-4caa-ae87-17b02b63ec8f',
          '9f46a6d8-fdc8-4b2b-8e75-937d91d9f231',
          '3e8a244c-b759-4c99-b61a-af2d293c906e',
          '45a67e21-096c-4fd1-9288-74bfc070a088',
          'be21fe61-0081-e511-8117-c4346badc5f4',
          '1f150f7f-45e0-49c0-a708-f86e4dc3ae8b'
        ]
      }
    ],
    promotion: undefined,
    productId: '1c5afa61-fec1-4a39-898b-15b336fb48a1',
    enabled: true,
    noOfBufferWeek: 8,
    offeringType: 'FULL'
  },
  {
    id: 'fdc52173-b891-46ca-b61f-964fd7d5c81d',
    name: 'Circuit Training Focus',
    pricing: {
      id: '31fbe569-292d-4d12-8c28-fa702dfa604f',
      code: undefined,
      name: '',
      description: undefined,
      price: 280.38,
      currency: 'SGD',
      sessions: 12,
      billingCycle: 'P18W'
    },
    promotions: [],
    promotion: undefined,
    productId: '1c5afa61-fec1-4a39-898b-15b336fb48a1',
    enabled: true,
    noOfBufferWeek: 6,
    offeringType: 'LITE'
  },
  {
    id: '91819047-f4a7-4dcf-b923-a2694252eea1',
    name: 'Circuit Training Focus (post assessment)',
    pricing: {
      id: '657c4ee2-f7d7-4644-baf1-025a7de588a6',
      code: undefined,
      name: '',
      description: undefined,
      price: 0,
      currency: 'SGD',
      sessions: 1,
      billingCycle: 'P18W'
    },
    promotions: [],
    promotion: undefined,
    productId: '1c5afa61-fec1-4a39-898b-15b336fb48a1',
    enabled: true,
    noOfBufferWeek: 0,
    offeringType: 'LITE'
  }
];

const center = [
  {
    id: '45a67e21-096c-4fd1-9288-74bfc070a088',
    name: 'Tango - Bedok',
    manager: undefined,
    centerBased: true,
    phone: undefined,
    abbreviation: undefined,
    mapLink: undefined
  },
  {
    id: '9f46a6d8-fdc8-4b2b-8e75-937d91d9f231',
    name: 'Tango - Bukit Batok West',
    manager: undefined,
    centerBased: true,
    phone: undefined,
    abbreviation: undefined,
    mapLink: undefined
  },
  {
    id: '14945898-1678-4caa-ae87-17b02b63ec8f',
    name: 'Tango - Serangoon',
    manager: undefined,
    centerBased: true,
    phone: '123456789',
    abbreviation: 't-s',
    mapLink: undefined
  },
  {
    id: '18b54009-a792-42a4-9886-5a610a0a3ce9',
    name: 'Tango Lakeside',
    manager: undefined,
    centerBased: true,
    phone: '12345678',
    abbreviation: '',
    mapLink: undefined
  },
  {
    id: 'be21fe61-0081-e511-8117-c4346badc5f4',
    name: 'Henderson',
    manager: undefined,
    centerBased: true,
    phone: undefined,
    abbreviation: undefined,
    mapLink: 'https://g.page/SCCHD'
  },
  {
    id: '426609fd-092a-4c57-9fc8-b21a0eb30262',
    name: 'Tango - Jurong Central Plaza',
    manager: undefined,
    centerBased: true,
    phone: undefined,
    abbreviation: '',
    mapLink: undefined
  },
  {
    id: '1f150f7f-45e0-49c0-a708-f86e4dc3ae8b',
    name: 'Tango - Admiralty',
    manager: undefined,
    centerBased: true,
    phone: '90047681',
    abbreviation: 'Tango - Admiralty',
    mapLink: undefined
  },
  {
    id: '3e8a244c-b759-4c99-b61a-af2d293c906e',
    name: 'Tango - Radin Mas',
    manager: undefined,
    centerBased: true,
    phone: '+62580295',
    abbreviation: 'Tango - Radin Mas',
    mapLink: 'https://g.page/SCCRDM'
  }
];

const serviceSelection = {
  lovedOne: 'my_self',
  category: 'ed275fd7-d71a-4983-9d57-8ae8c687d8f2',
  catergoryName: 'Active Ageing',
  marketingChanel: '',
  sourceOthers: '',
  programmes: [
    {
      id: 1,
      category: 'a084f717-1c80-4458-89bb-5b1461dbb96d',
      center: '45a67e21-096c-4fd1-9288-74bfc070a088',
      product: '1c5afa61-fec1-4a39-898b-15b336fb48a1',
      offer: '96c964d3-c3d0-4637-ae2d-3b997b0733d1',
      isExpanded: true
    }
  ]
};

const productInfor = {
  title: 'Available Activities',
  contents: ['1. Tango - Circuit Training'],
  productDetails: {
    createdDate: 1598927537081,
    lastModifiedDate: 1653285486534,
    createdBy: 'John Smith',
    lastModifiedBy: 'Anshul',
    id: '1c5afa61-fec1-4a39-898b-15b336fb48a1',
    name: 'Tango - Circuit Training',
    frequency: 'PER_SESSION',
    frequencies: 'PER_SESSION',
    status: true,
    trialApplicable: false,
    requireDeposit: false,
    addon: false,
    addons: [],
    services: [
      { id: '6719318d-a91a-482d-89bd-0b0ff1f2dd1a', name: 'Tango - Circuit Training', businessUnit: 'DEFAULT' }
    ],
    gstApplicable: true,
    gstAbsorbable: false,
    glCode: '21210101',
    gstGlCode: '22140101',
    iltcApplicable: false,
    iltcCode: '',
    barthelApplicable: false,
    transportApplicable: false,
    subsidyGLCode: null,
    gstAbsorbedGLCode: null,
    receivableGLCode: null,
    typeChangeable: false,
    attendancePricing: false,
    autoAttendance: false,
    trialFrequency: null,
    recurringEpisodeApplicable: false,
    teleService: false,
    selfRegistrable: false,
    resourceBased: true,
    centerBased: true,
    sendReminder: true,
    abbreviation: 'ct',
    defaultEvent: '11efba34-bf1f-424a-aade-2d72d7f3347f',
    autoRecurring: false,
    appointmentAutoHandle: 'NONE',
    markAttendance: true,
    cancellationReasonRequired: true,
    eventIds: ['24d181cf-af53-4344-a848-e0472a495874'],
    travel: false,
    sendTermExpiryNotification: true,
    sendInvoiceToClient: true,
    utilizationGlCode: '42210001',
    appointmentTypeRequired: true,
    guestFlowEnabled: true,
    categoryId: 'ed275fd7-d71a-4983-9d57-8ae8c687d8f2',
    categoryName: 'Active Ageing',
    subcategory: 'a084f717-1c80-4458-89bb-5b1461dbb96d',
    medicalHistory: 'NO',
    healthStatus: 'NO',
    nok: 'NO',
    bookInitialAssignment: true,
    preClassAppointmentType: 'b2c5b82d-f571-405f-9e13-95eaa304afaa',
    preClassSecondaryAppointmentType: null,
    programContentType: 'SESSIONS',
    temporalStop: false,
    postClassAppointmentType: null,
    renewPackages: true,
    clientFeedback: false,
    minSessions: 1,
    maxSessions: 2,
    fixedSessions: false,
    oneRehab: false,
    daysToSendAppointmentReminder: 4
  }
};

const centerDetails = {
  id: '45a67e21-096c-4fd1-9288-74bfc070a088',
  name: 'Tango - Bedok',
  managerId: 'd54d93ee-06b7-e811-a97a-000d3a81e7bd',
  managerName: 'Yeoh Yik Sharn',
  centerBased: true,
  createdDate: 1600294312196,
  lastModifiedDate: 1600294312196,
  createdBy: 'Phyo Phyo Lwin',
  lastModifiedBy: 'Phyo Phyo Lwin',
  phone: null,
  abbreviation: null,
  active: true,
  vmsCenter: ['0'],
  title: 'NTUC Health Active Ageing Hub (Bedok)',
  block: '439',
  level: '02',
  unit: '01',
  street: 'Bedok Ave 8',
  building: undefined,
  postalCode: '650439',
  mapLink: null
};

export {
  categories,
  marketingChannels,
  serviceLinks,
  subCategories,
  products,
  center,
  offers,
  serviceSelection,
  productInfor,
  centerDetails
};
