// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// ROUTER
import { ExternalApi } from 'constant';

// MODEL
import { Address, AddressWithExternal } from 'model';

// TYPE
import { Callback } from 'interface';

// APP ACTIONS
import { setAppMessage } from 'redux/app/actions';

// HELPERS
import { pushData, changeValueOfItemFromArr, removeData } from 'helpers/array';

// SLICE
import { addressSlice } from './slice';

// SELECTOR
import { selectManyAddresses } from './selector';

// ACTIONS
const { changeAddressLoading, setAddress, setManyAddresses, setListAddNewAddresses, setListUpdateAddresses } =
  addressSlice.actions;

const getManyAddresses =
  (apiUrl: string, callback?: Callback<Address[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeAddressLoading(true));

    dispatch(
      httpAction<unknown, unknown, Address[]>(
        {
          method: 'GET',
          url: apiUrl,
          needAuth: true
        },
        async (res) => {
          const data = res.data || [];

          dispatch(setManyAddresses(data));
          dispatch(changeAddressLoading(false));

          if (callback) {
            callback(data);
          }
        }
      )
    );
  };

const createNewAddress =
  (address: Address, apiUrl: string, callback?: Callback<Address>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeAddressLoading(true));

    const currentAddresses = selectManyAddresses(getState());

    dispatch(
      httpAction<Address, unknown, Address>(
        {
          method: 'POST',
          url: apiUrl,
          data: address,
          needAuth: true
        },
        async (res) => {
          dispatch(changeAddressLoading(false));

          if (res.data) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Created address successfully'
              })
            );

            dispatch(setManyAddresses(pushData<Address>(currentAddresses, res.data)));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const createNewAddresses =
  (addresses: Address[], apiUrl: string, callback?: Callback<boolean>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeAddressLoading(true));

    const currentAddresses = selectManyAddresses(getState());

    Promise.all(
      addresses.map((address) =>
        dispatch(
          httpAction<Address, unknown, Address>(
            {
              method: 'POST',
              url: apiUrl,
              data: address,
              needAuth: true
            },
            async (res) => {
              if (res.data) {
                dispatch(setManyAddresses(pushData<Address>(currentAddresses, res.data)));
              }
            }
          )
        )
      )
    )
      .then(() => {
        dispatch(changeAddressLoading(false));

        dispatch(
          setAppMessage({
            type: 'success',
            content: 'Created addresses successfully'
          })
        );

        if (callback) callback(true);
      })
      .catch(() => {
        if (callback) callback(false);
      });
  };

const updateAddress =
  (address: Address, apiUrl: string, callback?: Callback<Address>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeAddressLoading(true));

    const currentAddresses = selectManyAddresses(getState());
    const immutableAddresses = changeValueOfItemFromArr<Address>(currentAddresses, address);

    dispatch(
      httpAction<Address, unknown, Address>(
        {
          method: 'PUT',
          url: `${apiUrl}/${address.id}`,
          data: address,
          needAuth: true
        },
        async (res) => {
          dispatch(changeAddressLoading(false));

          if (res.data) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Updated address successfully'
              })
            );

            dispatch(setManyAddresses(immutableAddresses));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const updateAddresses =
  (addresses: Address[], apiUrl: string, callback?: Callback<boolean>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeAddressLoading(true));

    const currentAddresses = selectManyAddresses(getState());

    Promise.all(
      addresses.map((address) => {
        const immutableAddresses = changeValueOfItemFromArr<Address>(currentAddresses, address);

        return dispatch(
          httpAction<Address, unknown, Address>(
            {
              method: 'PUT',
              url: `${apiUrl}/${address.id}`,
              data: address,
              needAuth: true
            },
            async (res) => {
              dispatch(changeAddressLoading(false));

              if (res.data) {
                dispatch(setManyAddresses(immutableAddresses));
              }
            }
          )
        );
      })
    )
      .then(() => {
        dispatch(changeAddressLoading(false));

        dispatch(
          setAppMessage({
            type: 'success',
            content: 'Updated address successfully'
          })
        );

        if (callback) callback(true);
      })
      .catch(() => {
        if (callback) callback(false);
      });
  };

const deleteAddress =
  (address: Address, apiUrl: string, callback?: Callback<boolean>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(changeAddressLoading(true));

    const currentAddresses = selectManyAddresses(getState());
    const immutableAddresses = removeData<Address>(currentAddresses, address);

    dispatch(
      httpAction<Address, unknown, boolean>(
        {
          method: 'DELETE',
          url: `${apiUrl}/${address.id}`,
          needAuth: true
        },
        async (res) => {
          dispatch(changeAddressLoading(false));

          if (res.httpCode === 200) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Deleted address successfully'
              })
            );

            dispatch(setManyAddresses(immutableAddresses));
          }

          if (callback) {
            callback(res.httpCode === 200);
          }
        }
      )
    );
  };

const getAddressDetailByPostalCode =
  (postalCode: string, callback: Callback<AddressWithExternal>): AppThunk =>
  async (dispatch) => {
    const url = ExternalApi.GetAddressByPostalCode(postalCode);

    dispatch(
      httpAction<unknown, unknown, { results: AddressWithExternal[] }>(
        {
          baseURL: url.baseUrl,
          method: 'GET',
          url: url.path,
          needAuth: false
        },
        async (res) => {
          let item: AddressWithExternal | undefined;

          if (res.data && res.data.results.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            item = res.data.results[0];
          }

          if (callback) {
            callback(item);
          }
        }
      )
    );
  };

export {
  getManyAddresses,
  createNewAddress,
  updateAddress,
  setAddress,
  getAddressDetailByPostalCode,
  deleteAddress,
  setListAddNewAddresses,
  setListUpdateAddresses,
  createNewAddresses,
  updateAddresses
};
