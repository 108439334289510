import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Button, Div, Dialog as BaseDialog, DialogContent, DialogTitle, Typography, Grid } from 'ui-kit';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';

// TYPES
import { DialogProps } from './interface';

// STYLE
import { DialogWrapper, DismissButton } from './style';

function Dialog<T>({
  onCancel,
  onSubmit,
  data,
  open,
  title,
  body,
  closeText,
  submitText,
  isShowCloseButton = true,
  isShowDismissButton = false,
  isDisableSubmitButton = false,
  isShowSubmitButton = true,
  onDismiss
}: DialogProps<T>) {
  const { t: locale } = useTranslation('common');

  const formik = useFormik<T>({
    initialValues: {
      ...data
    },
    onSubmit: (values, formikHeler) => onSubmit(values, formikHeler)
  });

  useEffect(() => {
    if (data) {
      formik.setValues({
        ...data
      });
    }
  }, [data]);

  const onDismissPopup = () => {
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <BaseDialog fullWidth aria-labelledby="campaign-dialog-title" maxWidth="sm" open={open}>
      <DialogTitle id="simple-dialog-title" testId="dialogTitle">
        {title}
        {isShowDismissButton && (
          <DismissButton variant="secondary" onClick={onDismissPopup}>
            <CloseIcon />
          </DismissButton>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogWrapper>
          {body && typeof body === 'string' && (
            <Div margin={{ bottom: 30 }}>
              <Typography fontWeight="regular" testId="dialogBody" variant="body1">
                {body}
              </Typography>
            </Div>
          )}
          {body && typeof body === 'object' && (
            <Div margin={{ bottom: 30 }} testId="dialogBody">
              {body}
            </Div>
          )}
          <Grid container spacing={1}>
            {isShowCloseButton && (
              <Grid item md={6} xs={12}>
                <Button
                  fullWidth
                  className="customButton"
                  disabled={formik.isSubmitting}
                  testId="dialogCloseButton"
                  variant="secondary"
                  onClick={onCancel}
                >
                  {closeText || locale('common:close')}
                </Button>
              </Grid>
            )}
            {isShowSubmitButton && (
              <Grid item md={isShowCloseButton ? 6 : 12} xs={12}>
                <form onSubmit={formik.handleSubmit}>
                  <Button
                    fullWidth
                    className="customButton"
                    disabled={isDisableSubmitButton}
                    loading={formik.isSubmitting}
                    testId="dialogSubmitButton"
                    type="submit"
                    variant="primary"
                  >
                    {submitText || locale('common:delete')}
                  </Button>
                </form>
              </Grid>
            )}
          </Grid>
        </DialogWrapper>
      </DialogContent>
    </BaseDialog>
  );
}

export default Dialog;
