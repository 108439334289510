import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Typography, Button, Grid } from 'ui-kit';

const ErrorView: FC = () => {
  const { t: locale } = useTranslation('common');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ErrorIcon fontSize="large" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{locale('errorMsg')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="secondary" onClick={() => window.location.reload()}>
          {locale('retry')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorView;
