import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { AddressState, Address } from 'model';

// ACTION TO CHANGE STATE
export const changeAddressLoading: CaseReducer<AddressState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setAddress: CaseReducer<AddressState, PayloadAction<Address>> = (state, action) => {
  state.single = action.payload;
};

export const setManyAddresses: CaseReducer<AddressState, PayloadAction<Address[]>> = (state, action) => {
  state.many = action.payload;
};

export const setListAddNewAddresses: CaseReducer<AddressState, PayloadAction<Address[]>> = (state, action) => {
  state.listAddNewAddreses = action.payload;
};

export const setListUpdateAddresses: CaseReducer<AddressState, PayloadAction<Address[]>> = (state, action) => {
  state.listUpdatedAddreses = action.payload;
};
