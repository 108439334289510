import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { AddressState } from 'model';

// REDUCER ACTION
import {
  changeAddressLoading,
  setAddress,
  setManyAddresses,
  setListAddNewAddresses,
  setListUpdateAddresses
} from './reducers';

export const initialSingleAddress = {
  id: '',
  type: '',
  postalCode: '',
  block: '',
  street: '',
  level: '',
  unit: '',
  building: '',
  sameAs: ''
};

export const initialState: AddressState = {
  single: initialSingleAddress,
  many: [],
  loading: false,
  listAddNewAddreses: [],
  listUpdatedAddreses: []
};

export const addressSlice = createSlice({
  name: Domain.Address,
  initialState,
  reducers: {
    changeAddressLoading,
    setAddress,
    setManyAddresses,
    setListAddNewAddresses,
    setListUpdateAddresses
  }
});

export default addressSlice.reducer;
