import styled from 'styled-components';

import { spacing } from 'helpers/styles';

// INTERFACE

import { GreenBoxProps } from './interface';

export const GreenBoxContainer = styled.div`
  max-width: ${(props: GreenBoxProps) => (props.isMobile ? '100%' : '70%')};
  padding: ${spacing(2)};
  background: #e8f0f0;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.15), 0px 1px 5px rgba(152, 162, 179, 0.15);
  border-radius: 4px;

  .resultLeftText {
    flex: 4;
  }

  .leftContainer {
    flex: 4;
  }

  .resultRight {
    flex: 6;
  }

  .viewAvailableSlots {
    color: #0052cc;
    font-size: 14px;
    cursor: pointer;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #e2e2e2;
  }

  .resultRightText {
    font-weight: normal;
  }

  .itemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
