export const medicalHistory = {
  healthStatus: 'Health Status',
  healthCondition: 'Health Condition',
  healthStatusMessageEn1:
    'Your mouth is part of your body. Health problems that you may have or medications you are taking could have an important inter-relationship with the dental treatment that you are receiving. Please answer the questions accurately.',
  healthStatusMessageCn1:
    '口腔是身体的一部分。您的健康状况或您所服食的药物，会与您所要接受的牙齿治疗有密切的关系。请回答以下的问题。',
  healthStatusMessageCn2:
    '填写此表格大约需要 {{minMinutes}} 到 {{maxMinutes}} 分钟。您提供的所有信息将按照 NTUC Health 的规定严格保密 ',
  personalDataProtectionPolicyCn: '个人数据保护政策。',
  reviewInformationMessage1:
    'The Dental Surgeon / Dentist or any member of his / her staff and Corporate Dental Clinic will not be responsible for any error or omission that you may have made in the completion of this form.',
  reviewInformationMessage2: 'Please ensure that all details are correct before proceeding. ',
  resultTitle: 'Your medical history has been successfully submitted!',
  resultMessage1: 'Thank you for submitting your medical history.',
  resultMessage2: 'Please be assured this is confidential and we would not share with any third parties.',
  statusFieldTitle1: '1. Have you been hospitalised before?',
  statusFieldSubTitle1: 'If yes, please state when, why and which hospital.',
  statusFieldTitle1Cn: '您有否对任何药物或食物过敏？*如有，请列明何种药物。',
  statusFieldTitle2: '2. Do you have any allergy, e. g. food, medications?',
  statusFieldSubTitle2: 'If yes, what are you allergic to?',
  statusFieldTitle2Cn: '您有否对任何药物或食物过敏？*如有，请列明何种药物。',
  statusFieldTitle3: '3. Has there been any changes in your health condition recently?',
  statusFieldTitle3Cn: '您的健康状况最近有否任何改变？',
  statusFieldTitle4: '4. Are you under medical treatment now?',
  statusFieldSubTitle4: 'If yes, please specify.',
  statusFieldTitle4Cn: '您目前是否正在接受任何医疗？*如有，请详细说明。',
  statusFieldTitle5: '5. Are you taking any medications now?',
  statusFieldSubTitle5: 'If yes, please specify.',
  statusFieldTitle5Cn: '您目前是否服食任何药物？*如有，请详细说明。',
  statusFieldTitle6: '6. Have you ever had:',
  statusFieldTitle6Cn: '您曾否接受过：',
  statusFieldTitle6a: '(a) any kidney dialysis treatment?',
  statusFieldTitle6aCn: '洗肾治疗？',
  statusFieldTitle6b: '(b) a blood transfusion?',
  statusFieldTitle6bCn: '输血？',
  statusFieldTitle6c: '(c) excessive bleeding after a cut or tooth extraction?',
  statusFieldTitle6cCn: '您在拔牙后或割伤后，有否大量出血的状况？',
  statusFieldTitle7: '7. Are you pregnant now? (For female patients only)',
  statusFieldTitle7Cn: '您是否怀孕？(女性病人)',
  deliveryDate: 'Expected date of delivery',
  deliveryDateCn: '如有，预产日期',
  statusFieldTitle8: '8. Have you been informed that you have the following conditions?',
  statusFieldTitle8Cn: '您有否以下所举的病例',
  healthConditionTitle: 'Have you been informed that you have the following conditions?',
  heartDefect: 'Heart defect',
  heartDefectCn: '心漏',
  highBloodPressure: 'High blood pressure',
  highBloodPressureCn: '高血压',
  rheumaticFever: 'Rheumatic fever',
  rheumaticFeverCn: '风湿热',
  heartMurmur: 'Heart murmur',
  heartMurmurCn: '心杂音',
  mitralValveProlapse: 'Mitral valve prolapse',
  mitralValveProlapseCn: '三尖瓣下垂',
  angina: 'Angina / chest pain',
  anginaCn: '心绞痛 / 胸痛',
  stroke: 'Stroke',
  strokeCn: '中风',
  bloodDisease: 'Blood disease',
  bloodDiseaseCn: '血病',
  haemophilia: 'Haemophilia',
  haemophiliaCn: '通血症',
  epilepsy: 'Epilepsy / fits',
  epilepsyCn: '羊癫症',
  thyroidDisease: 'Thyroid disease',
  thyroidDiseaseCn: '甲状腺',
  rheumatoidArthritis: 'Rheumatoid arthritis',
  rheumatoidArthritisCn: '关节炎',
  kidneyDisease: 'Kidney disease',
  kidneyDiseaseCn: '肾病',
  g6PDDeficiency: 'G6PD deficiency',
  g6PDDeficiencyCn: '蚕豆病',
  hepatitisJaundice: 'Hepatitis / Jaundice',
  hepatitisJaundiceCn: '心漏',
  hepatitisCarrier: 'Hepatitis carrier',
  hepatitisCarrierCn: '肝炎带菌者',
  liverDisease: 'Liver disease',
  liverDiseaseCn: '心漏',
  gastricProblem: 'Gastric problem',
  gastricProblemCn: '胃病',
  tumoursGrowth: 'Tumours / growth',
  tumoursGrowthCn: '心漏',
  diabetes: 'Diabetes',
  diabetesCn: '糖尿病',
  tuberculosis: 'Tuberculosis',
  tuberculosisCn: '心漏',
  respiratoryDisease: 'Respiratory disease',
  respiratoryDiseaseCn: '呼吸系统',
  asthma: 'Asthma',
  asthmaCn: '心漏',
  sinusProblem: 'Sinus problem',
  sinusProblemCn: '鼻敏感',
  systemicLupusErythematosus: 'Systemic lupus erythematosus',
  systemicLupusErythematosusCn: '狼疮',
  aidsHIV: 'Aids / HIV',
  aidsHIVCn: '艾滋病 / 性病',
  others: 'Others',
  othersCn: '其他',
  otherPlaceholder: 'Any other health condition(s) that are not listed above',
  otherPlaceholderCn: '上面未列出的任何其他健康状况',
  reviewHealthStatusField1: 'Food or Medications Allergy',
  reviewHealthStatusField2: 'Recent Changes in Health Condition',
  reviewHealthStatusField3: 'Currently Under Medical Treatment',
  reviewHealthStatusField4: 'Currently Taking Medications',
  reviewHealthStatusField5: 'Currently Pregnant',
  termMessage: 'I agree that the information provided above is true and accurate to the best of my knowledge.',
  termMessageCn: '我同意，据我所知，以上提供的信息是真实准确的。',
  signatureTitle: 'Signature of patient/parent/legal guardian',
  signatureTitleCn: '患者/家长/法定监护人签名',
  name: 'Name:',
  nameCn: '姓名',
  contactNo: 'Contact No.:',
  contactNoCn: '联络号码',
  dateOfSignature: 'Date of Signature:',
  dateOfSignatureCn: '签名日期',
  controlledOrUncontrolled: 'Controlled/Uncontrolled',
  controlledOrUncontrolledCn: '控制中/无法控制',
  titleConfirmSubmitMHQDialog: 'Please update your Medical History',
  messageConfirmSubmitMHQDialog: 'Your Medical History is more than 1 year',
  messageConfirmSubmitForUnfilledMHQDialog: 'You have not submitted any medical condition',
  goBack: 'Go Back',
  submit: 'Submit',
  lastUpdatedDate: 'Last Updated Date: ',
  inputPlaceholder: 'Please enter remarks here',
  clearSignature: 'Clear signature'
};
