const checkoutInfo = {
  gstAmount: 22.4,
  totalAmount: 320,
  subTotalAmount: 320,
  totalDiscountAmount: 0,
  totalPayableAmount: 342.4,
  items: [
    {
      id: 'fe28dc68-2b4b-452e-bd5f-bda074b4830f',
      itemDescription: 'Circuit Training- launch promo',
      unitPrice: 0,
      quantity: 1,
      amount: 320
    }
  ]
};

const orderDetail = {
  createdDate: '2022-06-12T00:51:05.731+0000',
  lastModifiedDate: '2022-06-12T00:51:23.095+0000',
  createdBy: 'System',
  lastModifiedBy: 'System',
  id: '7e5d86b2-ecec-4a9a-8ab5-f075ce5611e6',
  customerId: '7c9dcc94-19b7-4a93-bf9c-4db5b5cf825a',
  customerName: 'Nhat',
  payerId: '7c9dcc94-19b7-4a93-bf9c-4db5b5cf825a',
  payerName: 'Nhat',
  orderNumber: 'xmccb51n1oqhf9w',
  subTotalAmount: 320,
  discountAmount: 0,
  gstAmount: 22.4,
  totalAmount: 320,
  payableAmount: 342.4,
  status: 'COMPLETED',
  address: {
    block: '55',
    level: '',
    unit: '',
    street1: 'COMPASSVALE BOW',
    street2: 'THE QUARTZ',
    postalCode: '544986'
  },
  paymentTransaction: {
    createdDate: '2022-06-12T00:51:23.093+0000',
    lastModifiedDate: '2022-06-12T00:51:23.093+0000',
    createdBy: 'System',
    lastModifiedBy: 'System',
    id: '4d71e6d9-9f17-44b7-bdbf-d24ab4572870',
    status: 'PAID',
    paymentMethod: 'PAYNOW',
    paymentType: 'PAYNOW',
    timestamp: '2022-06-12T00:51:22.918+0000',
    amount: 342.4,
    currency: 'SGD',
    transactionId: 'c93ae41e-c0ab-4276-bea6-ed38f395152c'
  },
  items: [
    {
      createdDate: '2022-06-12T00:51:05.735+0000',
      lastModifiedDate: '2022-06-12T00:51:05.735+0000',
      createdBy: 'System',
      lastModifiedBy: 'System',
      id: 'fe28dc68-2b4b-452e-bd5f-bda074b4830f',
      quantity: 1,
      productId: '228e4262-6e53-4ad7-832e-e6789c1a831d',
      offeringId: '698ec57a-fd68-4d2e-9ce3-9bbefc7c8778',
      centerId: 'be21fe61-0081-e511-8117-c4346badc5f4',
      description: 'Circuit Training- launch promo',
      amount: 320,
      discount: null,
      location: {
        title: 'NTUC HEALTH SENIOR DAY CARE (HENDERSON)',
        block: '95B',
        level: '01',
        unit: '22',
        street: '95C Henderson Road',
        building: null,
        postalCode: '152095'
      }
    }
  ]
};

const lessRounding = 0;

export { checkoutInfo, orderDetail, lessRounding };
