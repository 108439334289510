import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';

// UIKit
import { Typography, Div } from 'ui-kit';

// MODEL
import { Address } from 'model';

// HELPERS
import { generateAddress } from 'helpers/string/generation';

// STYLES
import { GreenBoxContainer } from 'components/GreenBox';

// PROPS
import { CenterDetailsGreenBoxProps } from 'views/RegisterService/ServiceSelection/form/interface';

// MOCK DATA
import { productInfor as mockProductInfo, centerDetails as mockCenterDetails } from './data';

export const CenterDetailsGreenBox: FC<Partial<CenterDetailsGreenBoxProps>> = ({ isMobile }) => {
  const { t: locale } = useTranslation(['registerService']);
  const productInfo = mockProductInfo;
  const centerDetails: Address = mockCenterDetails;

  return (
    <Div margin={{ top: 15 }} testId="centerDetail">
      <GreenBoxContainer isMobile={isMobile}>
        <Div className="itemContainer">
          {productInfo && (
            <>
              <Typography className="resultLeftText" variant="h4">
                {productInfo.title}
              </Typography>
              <Div className="resultRight">
                {isArray(productInfo.contents) &&
                  productInfo.contents.map((v, index) => (
                    <Div key={`${v}-${index}`} margin={{ top: 10 }}>
                      {v.split('\n').map((t, i) => (
                        <Div key={`${t}-${i}`} margin={{ top: 5 }}>
                          <Typography className="resultRightText" variant="h4">
                            {t}
                          </Typography>
                        </Div>
                      ))}
                    </Div>
                  ))}
              </Div>
            </>
          )}
        </Div>
        <Div className="divider" margin={{ top: 20 }} />
        <Div margin={{ top: 20 }}>
          <Div className="itemContainer">
            <Typography className="resultLeftText" variant="h4">
              {locale('registerService:centreLocation')}
            </Typography>
            <Div className="resultRight" padding={{ top: 10 }}>
              <Typography className="resultRight resultRightText" variant="h4">
                {centerDetails?.title}
              </Typography>
              <Typography className="resultRight resultRightText" variant="h4">
                {generateAddress(centerDetails)}
              </Typography>
            </Div>
          </Div>
        </Div>
      </GreenBoxContainer>
    </Div>
  );
};
