import React, { FC } from 'react';
import { Div, Typography } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// VIEW COMPONENTS
import { NextOfKinItem } from 'views/NextOfKins';

// PROPS
import { ReviewItemWrapper, ReviewContactItemWrapper } from 'views/RegisterService/ReviewInformation/styles';

// DATA
import { emergencyContact } from './data';

const ReviewEmergencyContact: FC = () => {
  const { t: locale } = useTranslation(['registerService', 'common']);

  return (
    <ReviewItemWrapper>
      <Div className="header" testId="reviewEmergencyContactTitle">
        <Typography variant="h3">4. {locale('registerService:emergencyContact')}</Typography>
      </Div>

      <ReviewContactItemWrapper>
        <NextOfKinItem index={0} item={emergencyContact} viewType="userProfileReview" />
      </ReviewContactItemWrapper>
    </ReviewItemWrapper>
  );
};

export default ReviewEmergencyContact;
