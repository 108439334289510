import React, { useEffect } from 'react';
import { useFormik } from 'formik';

// INTERFACE
import { FormProps } from './interface';

function Form<T extends object>({ data, initialValues, schema, onSubmit, children }: FormProps<T>) {
  const formik = useFormik<T>({
    initialValues: {
      ...initialValues
    },
    validationSchema: schema,
    onSubmit
  });

  useEffect(() => {
    if (data) {
      formik.setValues(data);
    }
  }, [data]);

  return <form onSubmit={formik.handleSubmit}>{children(formik)}</form>;
}

export default Form;
