import { MapCenterItem } from 'components/MapCenterList/interface';
import { FAQItem } from 'views/FAQ/interface';
import { MarkerRegion } from 'views/MapView/interface';
import { StatisticsItem } from 'views/Statistics/interface';
import { AppEnv } from 'constant';

// ASSETS
import CircuitTrainingHero from 'assets/CircuitTrainingHero.png';
import CircuitTrainingIcon from 'assets/CircuitTrainingIcon.png';
import FunctionalStrengthTrainingHero from 'assets/FunctionalStrengthTrainingHero.png';
import FunctionalStrengthTrainingIcon from 'assets/FunctionalStrengthTrainingIcon.png';
import FunctionalStrengthStatistics from 'assets/FunctionalStrengthStatistics.png';
import GymTonicHero from 'assets/GymTonicHero.png';
import GymTonicIcon from 'assets/GymTonicIcon.png';
import WeightManagement from 'assets/WeightManagement.png';
import WeightManagementMain from 'assets/WeightManagement_main.png';
import ImprovingPhysical from 'assets/ImprovingPhysical.png';
import ImprovingPhysicalMain from 'assets/ImprovingPhysical_main.png';
import ReducingHeartRate from 'assets/ReducingHeartRate.png';
import ReducingHeartRateMain from 'assets/ReducingHeartRate_main.png';
import GymTonicWalkingSpeed from 'assets/GymTonicWalkingSpeed.png';
import GymTonicStandSpeed from 'assets/GymTonicStandSpeed.png';
import GymTonicMuscleStrength from 'assets/GymTonicMuscleStrength.png';

interface DetailData {
  id: number | string;
  name: string;
  iconImage?: string;
  descriptions?: (string | string[])[];
  statisticImages?: string | string[];
  statistics?: StatisticsItem[];
  heroImage?: string;
  faq?: FAQItem[];
  locations?: MapCenterItem[];
  markerRegions?: MarkerRegion[];
}

const appEnv = (process.env.REACT_APP_ENV || AppEnv.Alpha) as AppEnv;

const productDetailDataByAppEnv: { [key in AppEnv]: DetailData[] } = {
  uat: [
    {
      id: 'd0bf0431-b380-4d02-8be5-2571ea844b6f',
      name: 'Circuit Training',
      iconImage: CircuitTrainingIcon,
      descriptions: [
        'Developed in-house by our physiotherapists, Circuit Training is a moderate intensity training programme. It includes not only strength but also cardiovascular and balance exercises using age-friendly pneumatic machines (HUR brand imported from Finland) to boost metabolism and build muscular strength and endurance.',
        'Participants will go through short bursts of intense exercise alternated with breaks by rotating between different stations, working on their arms, legs, core and back. Movement patterns include push, pull, extension, flexion, abduction and adduction.',
        'Each machine is able to work on at least two muscle groups using concentric and eccentric movements. A set of 5-6 machines plus body weight exercises such as squat, lunges and heel raises will provide you a full body workout that helps you to achieve your fitness goals.',
        'Such exercises can help to reduce risk of morbidity and mortality by:',
        [
          'Weight management',
          'Improving physical functions and oxygen consumption',
          'Reducing heart rate, blood pressure and blood sugar'
        ]
      ],
      heroImage: CircuitTrainingHero,
      statisticImages: [WeightManagementMain, ImprovingPhysicalMain, ReducingHeartRateMain],
      statistics: [
        {
          description: 'Weight Management',
          image: WeightManagement
        },
        {
          description: 'Improving Physical Functions And Oxygen Consumption',
          image: ImprovingPhysical
        },
        {
          description: 'Reducing Heart Rate, Blood Pressure And Blood Sugar',
          image: ReducingHeartRate
        }
      ],
      faq: [
        {
          question: 'What can you expect from our gym-based fitness programmes?',
          answers: [
            'Age-friendly pneumatic/hydraulic machines that are gentle on muscles and joints',
            'Supervised group sessions and small group sizes',
            'Warm up and cool down exercises to minimise risk of injury'
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'How is your elderly gym equipment safe for seniors?',
          subAnswers: [
            'Our centres are equipped with gym equipment designed with the elderly in mind. Our HUR and FREI equipment are adjusted using air and water pressure technology, and are gentle and safe on the muscles and joints. These machines are suitable for elderly who have not been exercising regularly, have poor balance or weak limbs.',
            'The machines have built in smart technology that remembers the user’s preferences and will adjust the weights accordingly, with the use of a smart card. The machines can also be adjusted with the tap of a button, and the weights increase in 100g increments, making it very safe for the elderly.'
          ]
        },
        {
          question: 'Why is resistance training or weight-bearing exercise important for seniors?',
          subAnswers: [
            'Sarcopenia, or age-related loss of skeletal muscle mass and strength, can lead to physical disability and loss of independence.',
            'Studies have shown that we lose about 3%-8% muscle mass per decade after the age of 30 and the rate of loss increases after the age of 60. As muscle loss progresses, we may begin to reduce our physical activities, resulting in further loss of muscle strength and endurance and hence increase the risks of fall and fractures.',
            'Therefore, resistance or weight-bearing exercises are important for us to strengthen our muscles and bones to prevent osteoporosis and sarcopenia, so that we can continue to be independent and active as we age.'
          ]
        }
      ],
      locations: [
        {
          name: 'ActiveSG Gym @ Serangoon Central',
          detail: '264 Serangoon Central, #01-207, Singapore 550264',
          postalCode: '550264',
          region: 'ne',
          location: [1.35159, 103.874]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Bukit Batok West)',
          detail: 'Blk 439 Bukit Batok West Ave 8, #02-01, Singapore 650439',
          postalCode: '650439',
          region: 'sw',
          location: [1.35178, 103.73961]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Kampung Admiralty)',
          detail: '676 Woodlands Drive 71 #06-01, Singapore 730676',
          postalCode: '730676',
          region: 'nw',
          location: [1.43973, 103.800171]
        },
        {
          name: 'NTUC Health Day Centre for Seniors (Heartbeat@Bedok)',
          detail: '11 Bedok North Street 1 #02-02, Singapore 469662',
          postalCode: '469662',
          region: 'se',
          location: [1.32615, 103.93074]
        },
        {
          name: 'NTUC Health Day Centre for Seniors (Jurong Spring)',
          detail: '6B Jurong West, Street 52 Level 1, Singapore 649298',
          postalCode: '649298',
          region: 'sw',
          location: [1.28944, 103.84998]
        }
      ],
      markerRegions: ['ne', 'sw', 'nw', 'se']
    },
    {
      id: '7370c939-dcd6-42f0-a4a6-bc42e95b5587',
      name: 'Functional Strength Training',
      iconImage: FunctionalStrengthTrainingIcon,
      descriptions: [
        'Functional Strength Training is a moderate intensity workout that focuses on five body movements such as squatting, pulling, pushing, hingeing, and lifting. Fitness equipment such as stretch bands and dumbbells will be used to complement the exercises.',
        'The different combination of exercises will improve your range of motion and your ability to perform daily activities over time.',
        'Most of us tend to focus on one type of exercise like leisure walks and think it is enough. However, research has shown that it is important to get all four types of exercise (endurance, strength, balance and flexibility) in order to reap the full benefits.',
        'If you are looking to incorporate exercise in your lifestyle, why not try one of our fitness classes where you will get to work on all four types of exercise?',
        'Our Functional Strength Training incorporates both fitness equipment and bodyweight exercises. Participants will be rotated between different stations for a full body workout.',
        'Besides learning a new exercise, you can also get to meet like-minded people in the class!'
      ],
      heroImage: FunctionalStrengthTrainingHero,
      statisticImages: FunctionalStrengthStatistics,
      statistics: [
        {
          description: 'Elderly Feel Increased Belongings With Anvayaa',
          percentage: 97
        },
        {
          description: 'Elderly Lives Saved During Medical Emergencies',
          percentage: 96
        },
        {
          description: 'Reduction In Elderly Hospitalization Due To Proactive Care',
          percentage: 50
        }
      ],
      faq: [
        {
          question: 'What are the benefits of fitness classes?',
          subAnswers: ['Each one of our exercise programmes has different benefits:'],
          answers: [
            'Endurance exercises improve the functions of the heart, lungs, and circulatory system',
            'Strength exercises improve muscular strength which makes everyday activities feel easier',
            'Balance exercises help prevent falls',
            "Flexibility exercises increase one's range of motion"
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'What can you expect from our fitness classes?',
          answers: ['Supervised group sessions', 'Warm up and cool down exercises to minimise risk of injury']
        }
      ],
      locations: [
        {
          name: 'NTUC Health Active Ageing Hub (Kampung Admiralty)',
          detail: '676 Woodlands Drive 71 #06-01, Singapore 730676',
          postalCode: '730676',
          region: 'nw',
          location: [1.43973, 103.800171]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Jurong Central Plaza)',
          detail: 'Block 493 Jurong West Street 41, #02-01 Singapore 640493',
          postalCode: '640493',
          region: 'sw',
          location: [1.34889, 103.723122]
        }
      ],
      markerRegions: ['nw', 'sw']
    },
    {
      id: 'df0571ca-4215-e811-817e-e0071b69c9d1',
      name: 'Gym Tonic',
      iconImage: GymTonicIcon,
      descriptions: [
        'Gym Tonic is an evidence-based, low intensity strength training programme that uses a five piece set of age-friendly, hydraulic machines from FREI. Through structured and supervised training in a group setting, this customised training programme helps participants to build and maintain muscle mass and strength. This can help to improve the performance of their daily activities such as climbing the stairs with ease or lifting up an extra bag of groceries.',
        'Each participant will be prescribed a programme according to their fitness level. These smart machines can be configured with a pre-set weight load and repetition count, which can be later adjusted according to individuals’ speed of progression. A pre and post fitness and functional assessment will be conducted for a 24-session programme.',
        'A local study of 183 Gym Tonic participants showed that:',
        [
          '70% of them had improvement in their walking speed',
          '76% of them had improvement in their Sit to Stand Speed',
          '88% of them had improvement in their lower body muscle strength'
        ]
      ],
      heroImage: GymTonicHero,
      statisticImages: [GymTonicWalkingSpeed, GymTonicStandSpeed, GymTonicMuscleStrength],
      statistics: [
        {
          description: 'Of Them Had Improvement In Their Walking Speed',
          percentage: 70
        },
        {
          description: 'Of Them Had Improvement In Their Sit To Stand Speed',
          percentage: 76
        },
        {
          description: 'Of Them Had Improvement In Their Lower Body Muscle Strength',
          percentage: 88
        }
      ],
      faq: [
        {
          question: 'What can you expect from our gym-based fitness programmes?',
          answers: [
            'Age-friendly pneumatic/hydraulic machines that are gentle on muscles and joints',
            'Supervised group sessions and small group sizes',
            'Warm up and cool down exercises to minimise risk of injury'
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'How is your elderly gym equipment safe for seniors?',
          subAnswers: [
            'Our centres are equipped with gym equipment designed with the elderly in mind. Our HUR and FREI equipment are adjusted using air and water pressure technology, and are gentle and safe on the muscles and joints. These machines are suitable for elderly who have not been exercising regularly, have poor balance or weak limbs.',
            'The machines have built in smart technology that remembers the user’s preferences and will adjust the weights accordingly, with the use of a smart card. The machines can also be adjusted with the tap of a button, and the weights increase in 100g increments, making it very safe for the elderly.'
          ]
        },
        {
          question: 'Why is resistance training or weight-bearing exercise important for seniors?',
          subAnswers: [
            'Sarcopenia, or age-related loss of skeletal muscle mass and strength, can lead to physical disability and loss of independence.',
            'Studies have shown that we lose about 3%-8% muscle mass per decade after the age of 30 and the rate of loss increases after the age of 60. As muscle loss progresses, we may begin to reduce our physical activities, resulting in further loss of muscle strength and endurance and hence increase the risks of fall and fractures.',
            'Therefore, resistance or weight-bearing exercises are important for us to strengthen our muscles and bones to prevent osteoporosis and sarcopenia, so that we can continue to be independent and active as we age.'
          ]
        }
      ],
      locations: [
        {
          name: 'NTUC Health Active Ageing Hub (Jurong Central Plaza)',
          detail: 'Blk 493 Jurong West Street 41 #02-01, Singapore 640493',
          postalCode: '640493',
          region: 'sw',
          location: [1.34889, 103.723122]
        }
      ],
      markerRegions: ['sw']
    }
  ],
  qa: [
    {
      id: 'd0bf0431-b380-4d02-8be5-2571ea844b6f',
      name: 'Circuit Training',
      iconImage: CircuitTrainingIcon,
      descriptions: [
        'Developed in-house by our physiotherapists, Circuit Training is a moderate intensity training programme. It includes not only strength but also cardiovascular and balance exercises using age-friendly pneumatic machines (HUR brand imported from Finland) to boost metabolism and build muscular strength and endurance.',
        'Participants will go through short bursts of intense exercise alternated with breaks by rotating between different stations, working on their arms, legs, core and back. Movement patterns include push, pull, extension, flexion, abduction and adduction.',
        'Each machine is able to work on at least two muscle groups using concentric and eccentric movements. A set of 5-6 machines plus body weight exercises such as squat, lunges and heel raises will provide you a full body workout that helps you to achieve your fitness goals.',
        'Such exercises can help to reduce risk of morbidity and mortality by:',
        [
          'Weight management',
          'Improving physical functions and oxygen consumption',
          'Reducing heart rate, blood pressure and blood sugar'
        ]
      ],
      heroImage: CircuitTrainingHero,
      statisticImages: [WeightManagementMain, ImprovingPhysicalMain, ReducingHeartRateMain],
      statistics: [
        {
          description: 'Weight Management',
          image: WeightManagement
        },
        {
          description: 'Improving Physical Functions And Oxygen Consumption',
          image: ImprovingPhysical
        },
        {
          description: 'Reducing Heart Rate, Blood Pressure And Blood Sugar',
          image: ReducingHeartRate
        }
      ],
      faq: [
        {
          question: 'What can you expect from our gym-based fitness programmes?',
          answers: [
            'Age-friendly pneumatic/hydraulic machines that are gentle on muscles and joints',
            'Supervised group sessions and small group sizes',
            'Warm up and cool down exercises to minimise risk of injury'
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'How is your elderly gym equipment safe for seniors?',
          subAnswers: [
            'Our centres are equipped with gym equipment designed with the elderly in mind. Our HUR and FREI equipment are adjusted using air and water pressure technology, and are gentle and safe on the muscles and joints. These machines are suitable for elderly who have not been exercising regularly, have poor balance or weak limbs.',
            'The machines have built in smart technology that remembers the user’s preferences and will adjust the weights accordingly, with the use of a smart card. The machines can also be adjusted with the tap of a button, and the weights increase in 100g increments, making it very safe for the elderly.'
          ]
        },
        {
          question: 'Why is resistance training or weight-bearing exercise important for seniors?',
          subAnswers: [
            'Sarcopenia, or age-related loss of skeletal muscle mass and strength, can lead to physical disability and loss of independence.',
            'Studies have shown that we lose about 3%-8% muscle mass per decade after the age of 30 and the rate of loss increases after the age of 60. As muscle loss progresses, we may begin to reduce our physical activities, resulting in further loss of muscle strength and endurance and hence increase the risks of fall and fractures.',
            'Therefore, resistance or weight-bearing exercises are important for us to strengthen our muscles and bones to prevent osteoporosis and sarcopenia, so that we can continue to be independent and active as we age.'
          ]
        }
      ],
      locations: [
        {
          name: 'ActiveSG Gym @ Serangoon Central',
          detail: '264 Serangoon Central, #01-207, Singapore 550264',
          postalCode: '550264',
          region: 'ne',
          location: [1.35159, 103.874]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Bukit Batok West)',
          detail: 'Blk 439 Bukit Batok West Ave 8, #02-01, Singapore 650439',
          postalCode: '650439',
          region: 'sw',
          location: [1.35178, 103.73961]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Kampung Admiralty)',
          detail: '676 Woodlands Drive 71 #06-01, Singapore 730676',
          postalCode: '730676',
          region: 'nw',
          location: [1.43973, 103.800171]
        },
        {
          name: 'NTUC Health Day Centre for Seniors (Heartbeat@Bedok)',
          detail: '11 Bedok North Street 1 #02-02, Singapore 469662',
          postalCode: '469662',
          region: 'se',
          location: [1.32615, 103.93074]
        },
        {
          name: 'NTUC Health Day Centre for Seniors (Jurong Spring)',
          detail: '6B Jurong West, Street 52 Level 1, Singapore 649298',
          postalCode: '649298',
          region: 'sw',
          location: [1.28944, 103.84998]
        }
      ],
      markerRegions: ['ne', 'sw', 'nw', 'se']
    },
    {
      id: '7370c939-dcd6-42f0-a4a6-bc42e95b5587',
      name: 'Functional Strength Training',
      iconImage: FunctionalStrengthTrainingIcon,
      descriptions: [
        'Functional Strength Training is a moderate intensity workout that focuses on five body movements such as squatting, pulling, pushing, hingeing, and lifting. Fitness equipment such as stretch bands and dumbbells will be used to complement the exercises.',
        'The different combination of exercises will improve your range of motion and your ability to perform daily activities over time.',
        'Most of us tend to focus on one type of exercise like leisure walks and think it is enough. However, research has shown that it is important to get all four types of exercise (endurance, strength, balance and flexibility) in order to reap the full benefits.',
        'If you are looking to incorporate exercise in your lifestyle, why not try one of our fitness classes where you will get to work on all four types of exercise?',
        'Our Functional Strength Training incorporates both fitness equipment and bodyweight exercises. Participants will be rotated between different stations for a full body workout.',
        'Besides learning a new exercise, you can also get to meet like-minded people in the class!'
      ],
      heroImage: FunctionalStrengthTrainingHero,
      statisticImages: FunctionalStrengthStatistics,
      statistics: [
        {
          description: 'Elderly Feel Increased Belongings With Anvayaa',
          percentage: 97
        },
        {
          description: 'Elderly Lives Saved During Medical Emergencies',
          percentage: 96
        },
        {
          description: 'Reduction In Elderly Hospitalization Due To Proactive Care',
          percentage: 50
        }
      ],
      faq: [
        {
          question: 'What are the benefits of fitness classes?',
          subAnswers: ['Each one of our exercise programmes has different benefits:'],
          answers: [
            'Endurance exercises improve the functions of the heart, lungs, and circulatory system',
            'Strength exercises improve muscular strength which makes everyday activities feel easier',
            'Balance exercises help prevent falls',
            "Flexibility exercises increase one's range of motion"
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'What can you expect from our fitness classes?',
          answers: ['Supervised group sessions', 'Warm up and cool down exercises to minimise risk of injury']
        }
      ],
      locations: [
        {
          name: 'NTUC Health Active Ageing Hub (Kampung Admiralty)',
          detail: '676 Woodlands Drive 71 #06-01, Singapore 730676',
          postalCode: '730676',
          region: 'nw',
          location: [1.43973, 103.800171]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Jurong Central Plaza)',
          detail: 'Block 493 Jurong West Street 41, #02-01 Singapore 640493',
          postalCode: '640493',
          region: 'sw',
          location: [1.34889, 103.723122]
        }
      ],
      markerRegions: ['nw', 'sw']
    },
    {
      id: 'df0571ca-4215-e811-817e-e0071b69c9d1',
      name: 'Gym Tonic',
      iconImage: GymTonicIcon,
      descriptions: [
        'Gym Tonic is an evidence-based, low intensity strength training programme that uses a five piece set of age-friendly, hydraulic machines from FREI. Through structured and supervised training in a group setting, this customised training programme helps participants to build and maintain muscle mass and strength. This can help to improve the performance of their daily activities such as climbing the stairs with ease or lifting up an extra bag of groceries.',
        'Each participant will be prescribed a programme according to their fitness level. These smart machines can be configured with a pre-set weight load and repetition count, which can be later adjusted according to individuals’ speed of progression. A pre and post fitness and functional assessment will be conducted for a 24-session programme.',
        'A local study of 183 Gym Tonic participants showed that:',
        [
          '70% of them had improvement in their walking speed',
          '76% of them had improvement in their Sit to Stand Speed',
          '88% of them had improvement in their lower body muscle strength'
        ]
      ],
      heroImage: GymTonicHero,
      statisticImages: [GymTonicWalkingSpeed, GymTonicStandSpeed, GymTonicMuscleStrength],
      statistics: [
        {
          description: 'Of Them Had Improvement In Their Walking Speed',
          percentage: 70
        },
        {
          description: 'Of Them Had Improvement In Their Sit To Stand Speed',
          percentage: 76
        },
        {
          description: 'Of Them Had Improvement In Their Lower Body Muscle Strength',
          percentage: 88
        }
      ],
      faq: [
        {
          question: 'What can you expect from our gym-based fitness programmes?',
          answers: [
            'Age-friendly pneumatic/hydraulic machines that are gentle on muscles and joints',
            'Supervised group sessions and small group sizes',
            'Warm up and cool down exercises to minimise risk of injury'
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'How is your elderly gym equipment safe for seniors?',
          subAnswers: [
            'Our centres are equipped with gym equipment designed with the elderly in mind. Our HUR and FREI equipment are adjusted using air and water pressure technology, and are gentle and safe on the muscles and joints. These machines are suitable for elderly who have not been exercising regularly, have poor balance or weak limbs.',
            'The machines have built in smart technology that remembers the user’s preferences and will adjust the weights accordingly, with the use of a smart card. The machines can also be adjusted with the tap of a button, and the weights increase in 100g increments, making it very safe for the elderly.'
          ]
        },
        {
          question: 'Why is resistance training or weight-bearing exercise important for seniors?',
          subAnswers: [
            'Sarcopenia, or age-related loss of skeletal muscle mass and strength, can lead to physical disability and loss of independence.',
            'Studies have shown that we lose about 3%-8% muscle mass per decade after the age of 30 and the rate of loss increases after the age of 60. As muscle loss progresses, we may begin to reduce our physical activities, resulting in further loss of muscle strength and endurance and hence increase the risks of fall and fractures.',
            'Therefore, resistance or weight-bearing exercises are important for us to strengthen our muscles and bones to prevent osteoporosis and sarcopenia, so that we can continue to be independent and active as we age.'
          ]
        }
      ],
      locations: [
        {
          name: 'NTUC Health Active Ageing Hub (Jurong Central Plaza)',
          detail: 'Blk 493 Jurong West Street 41 #02-01, Singapore 640493',
          postalCode: '640493',
          region: 'sw',
          location: [1.34889, 103.723122]
        }
      ],
      markerRegions: ['sw']
    }
  ],
  prod: [
    {
      id: 'd0bf0431-b380-4d02-8be5-2571ea844b6f',
      name: 'Circuit Training',
      iconImage: CircuitTrainingIcon,
      descriptions: [
        'Developed in-house by our physiotherapists, Circuit Training is a moderate intensity training programme. It includes not only strength but also cardiovascular and balance exercises using age-friendly pneumatic machines (HUR brand imported from Finland) to boost metabolism and build muscular strength and endurance.',
        'Participants will go through short bursts of intense exercise alternated with breaks by rotating between different stations, working on their arms, legs, core and back. Movement patterns include push, pull, extension, flexion, abduction and adduction.',
        'Each machine is able to work on at least two muscle groups using concentric and eccentric movements. A set of 5-6 machines plus body weight exercises such as squat, lunges and heel raises will provide you a full body workout that helps you to achieve your fitness goals.',
        'Such exercises can help to reduce risk of morbidity and mortality by:',
        [
          'Weight management',
          'Improving physical functions and oxygen consumption',
          'Reducing heart rate, blood pressure and blood sugar'
        ]
      ],
      heroImage: CircuitTrainingHero,
      statisticImages: [WeightManagementMain, ImprovingPhysicalMain, ReducingHeartRateMain],
      statistics: [
        {
          description: 'Weight Management',
          image: WeightManagement
        },
        {
          description: 'Improving Physical Functions And Oxygen Consumption',
          image: ImprovingPhysical
        },
        {
          description: 'Reducing Heart Rate, Blood Pressure And Blood Sugar',
          image: ReducingHeartRate
        }
      ],
      faq: [
        {
          question: 'What can you expect from our gym-based fitness programmes?',
          answers: [
            'Age-friendly pneumatic/hydraulic machines that are gentle on muscles and joints',
            'Supervised group sessions and small group sizes',
            'Warm up and cool down exercises to minimise risk of injury'
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'How is your elderly gym equipment safe for seniors?',
          subAnswers: [
            'Our centres are equipped with gym equipment designed with the elderly in mind. Our HUR and FREI equipment are adjusted using air and water pressure technology, and are gentle and safe on the muscles and joints. These machines are suitable for elderly who have not been exercising regularly, have poor balance or weak limbs.',
            'The machines have built in smart technology that remembers the user’s preferences and will adjust the weights accordingly, with the use of a smart card. The machines can also be adjusted with the tap of a button, and the weights increase in 100g increments, making it very safe for the elderly.'
          ]
        },
        {
          question: 'Why is resistance training or weight-bearing exercise important for seniors?',
          subAnswers: [
            'Sarcopenia, or age-related loss of skeletal muscle mass and strength, can lead to physical disability and loss of independence.',
            'Studies have shown that we lose about 3%-8% muscle mass per decade after the age of 30 and the rate of loss increases after the age of 60. As muscle loss progresses, we may begin to reduce our physical activities, resulting in further loss of muscle strength and endurance and hence increase the risks of fall and fractures.',
            'Therefore, resistance or weight-bearing exercises are important for us to strengthen our muscles and bones to prevent osteoporosis and sarcopenia, so that we can continue to be independent and active as we age.'
          ]
        }
      ],
      locations: [
        {
          name: 'ActiveSG Gym @ Serangoon Central',
          detail: '264 Serangoon Central, #01-207, Singapore 550264',
          postalCode: '550264',
          region: 'ne',
          location: [1.35159, 103.874]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Bukit Batok West)',
          detail: 'Blk 439 Bukit Batok West Ave 8, #02-01, Singapore 650439',
          postalCode: '650439',
          region: 'sw',
          location: [1.35178, 103.73961]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Kampung Admiralty)',
          detail: '676 Woodlands Drive 71 #06-01, Singapore 730676',
          postalCode: '730676',
          region: 'nw',
          location: [1.43973, 103.800171]
        },
        {
          name: 'NTUC Health Day Centre for Seniors (Heartbeat@Bedok)',
          detail: '11 Bedok North Street 1 #02-02, Singapore 469662',
          postalCode: '469662',
          region: 'se',
          location: [1.32615, 103.93074]
        },
        {
          name: 'NTUC Health Day Centre for Seniors (Jurong Spring)',
          detail: '6B Jurong West, Street 52 Level 1, Singapore 649298',
          postalCode: '649298',
          region: 'sw',
          location: [1.28944, 103.84998]
        }
      ],
      markerRegions: ['ne', 'sw', 'nw', 'se']
    },
    {
      id: '7370c939-dcd6-42f0-a4a6-bc42e95b5587',
      name: 'Functional Strength Training',
      iconImage: FunctionalStrengthTrainingIcon,
      descriptions: [
        'Functional Strength Training is a moderate intensity workout that focuses on five body movements such as squatting, pulling, pushing, hingeing, and lifting. Fitness equipment such as stretch bands and dumbbells will be used to complement the exercises.',
        'The different combination of exercises will improve your range of motion and your ability to perform daily activities over time.',
        'Most of us tend to focus on one type of exercise like leisure walks and think it is enough. However, research has shown that it is important to get all four types of exercise (endurance, strength, balance and flexibility) in order to reap the full benefits.',
        'If you are looking to incorporate exercise in your lifestyle, why not try one of our fitness classes where you will get to work on all four types of exercise?',
        'Our Functional Strength Training incorporates both fitness equipment and bodyweight exercises. Participants will be rotated between different stations for a full body workout.',
        'Besides learning a new exercise, you can also get to meet like-minded people in the class!'
      ],
      heroImage: FunctionalStrengthTrainingHero,
      statisticImages: FunctionalStrengthStatistics,
      statistics: [
        {
          description: 'Elderly Feel Increased Belongings With Anvayaa',
          percentage: 97
        },
        {
          description: 'Elderly Lives Saved During Medical Emergencies',
          percentage: 96
        },
        {
          description: 'Reduction In Elderly Hospitalization Due To Proactive Care',
          percentage: 50
        }
      ],
      faq: [
        {
          question: 'What are the benefits of fitness classes?',
          subAnswers: ['Each one of our exercise programmes has different benefits:'],
          answers: [
            'Endurance exercises improve the functions of the heart, lungs, and circulatory system',
            'Strength exercises improve muscular strength which makes everyday activities feel easier',
            'Balance exercises help prevent falls',
            "Flexibility exercises increase one's range of motion"
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'What can you expect from our fitness classes?',
          answers: ['Supervised group sessions', 'Warm up and cool down exercises to minimise risk of injury']
        }
      ],
      locations: [
        {
          name: 'NTUC Health Active Ageing Hub (Kampung Admiralty)',
          detail: '676 Woodlands Drive 71 #06-01, Singapore 730676',
          postalCode: '730676',
          region: 'nw',
          location: [1.43973, 103.800171]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Jurong Central Plaza)',
          detail: 'Block 493 Jurong West Street 41, #02-01 Singapore 640493',
          postalCode: '640493',
          region: 'sw',
          location: [1.34889, 103.723122]
        }
      ],
      markerRegions: ['nw', 'sw']
    },
    {
      id: 'df0571ca-4215-e811-817e-e0071b69c9d1',
      name: 'Gym Tonic',
      iconImage: GymTonicIcon,
      descriptions: [
        'Gym Tonic is an evidence-based, low intensity strength training programme that uses a five piece set of age-friendly, hydraulic machines from FREI. Through structured and supervised training in a group setting, this customised training programme helps participants to build and maintain muscle mass and strength. This can help to improve the performance of their daily activities such as climbing the stairs with ease or lifting up an extra bag of groceries.',
        'Each participant will be prescribed a programme according to their fitness level. These smart machines can be configured with a pre-set weight load and repetition count, which can be later adjusted according to individuals’ speed of progression. A pre and post fitness and functional assessment will be conducted for a 24-session programme.',
        'A local study of 183 Gym Tonic participants showed that:',
        [
          '70% of them had improvement in their walking speed',
          '76% of them had improvement in their Sit to Stand Speed',
          '88% of them had improvement in their lower body muscle strength'
        ]
      ],
      heroImage: GymTonicHero,
      statisticImages: [GymTonicWalkingSpeed, GymTonicStandSpeed, GymTonicMuscleStrength],
      statistics: [
        {
          description: 'Of Them Had Improvement In Their Walking Speed',
          percentage: 70
        },
        {
          description: 'Of Them Had Improvement In Their Sit To Stand Speed',
          percentage: 76
        },
        {
          description: 'Of Them Had Improvement In Their Lower Body Muscle Strength',
          percentage: 88
        }
      ],
      faq: [
        {
          question: 'What can you expect from our gym-based fitness programmes?',
          answers: [
            'Age-friendly pneumatic/hydraulic machines that are gentle on muscles and joints',
            'Supervised group sessions and small group sizes',
            'Warm up and cool down exercises to minimise risk of injury'
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'How is your elderly gym equipment safe for seniors?',
          subAnswers: [
            'Our centres are equipped with gym equipment designed with the elderly in mind. Our HUR and FREI equipment are adjusted using air and water pressure technology, and are gentle and safe on the muscles and joints. These machines are suitable for elderly who have not been exercising regularly, have poor balance or weak limbs.',
            'The machines have built in smart technology that remembers the user’s preferences and will adjust the weights accordingly, with the use of a smart card. The machines can also be adjusted with the tap of a button, and the weights increase in 100g increments, making it very safe for the elderly.'
          ]
        },
        {
          question: 'Why is resistance training or weight-bearing exercise important for seniors?',
          subAnswers: [
            'Sarcopenia, or age-related loss of skeletal muscle mass and strength, can lead to physical disability and loss of independence.',
            'Studies have shown that we lose about 3%-8% muscle mass per decade after the age of 30 and the rate of loss increases after the age of 60. As muscle loss progresses, we may begin to reduce our physical activities, resulting in further loss of muscle strength and endurance and hence increase the risks of fall and fractures.',
            'Therefore, resistance or weight-bearing exercises are important for us to strengthen our muscles and bones to prevent osteoporosis and sarcopenia, so that we can continue to be independent and active as we age.'
          ]
        }
      ],
      locations: [
        {
          name: 'NTUC Health Active Ageing Hub (Jurong Central Plaza)',
          detail: 'Blk 493 Jurong West Street 41 #02-01, Singapore 640493',
          postalCode: '640493',
          region: 'sw',
          location: [1.34889, 103.723122]
        }
      ],
      markerRegions: ['sw']
    }
  ],
  alpha: [
    {
      id: '228e4262-6e53-4ad7-832e-e6789c1a831d',
      name: 'Circuit Training - QA',
      iconImage: CircuitTrainingIcon,
      descriptions: [
        'Developed in-house by our physiotherapists, Circuit Training is a moderate intensity training programme. It includes not only strength but also cardiovascular and balance exercises using age-friendly pneumatic machines (HUR brand imported from Finland) to boost metabolism and build muscular strength and endurance.',
        'Participants will go through short bursts of intense exercise alternated with breaks by rotating between different stations, working on their arms, legs, core and back. Movement patterns include push, pull, extension, flexion, abduction and adduction.',
        'Each machine is able to work on at least two muscle groups using concentric and eccentric movements. A set of 5-6 machines plus body weight exercises such as squat, lunges and heel raises will provide you a full body workout that helps you to achieve your fitness goals.',
        'Such exercises can help to reduce risk of morbidity and mortality by:',
        [
          'Weight management',
          'Improving physical functions and oxygen consumption',
          'Reducing heart rate, blood pressure and blood sugar'
        ]
      ],
      heroImage: CircuitTrainingHero,
      statisticImages: [WeightManagementMain, ImprovingPhysicalMain, ReducingHeartRateMain],
      statistics: [
        {
          description: 'Weight Management',
          image: WeightManagement
        },
        {
          description: 'Improving Physical Functions And Oxygen Consumption',
          image: ImprovingPhysical
        },
        {
          description: 'Reducing Heart Rate, Blood Pressure And Blood Sugar',
          image: ReducingHeartRate
        }
      ],
      faq: [
        {
          question: 'What can you expect from our gym-based fitness programmes?',
          answers: [
            'Age-friendly pneumatic/hydraulic machines that are gentle on muscles and joints',
            'Supervised group sessions and small group sizes',
            'Warm up and cool down exercises to minimise risk of injury'
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'How is your elderly gym equipment safe for seniors?',
          subAnswers: [
            'Our centres are equipped with gym equipment designed with the elderly in mind. Our HUR and FREI equipment are adjusted using air and water pressure technology, and are gentle and safe on the muscles and joints. These machines are suitable for elderly who have not been exercising regularly, have poor balance or weak limbs.',
            'The machines have built in smart technology that remembers the user’s preferences and will adjust the weights accordingly, with the use of a smart card. The machines can also be adjusted with the tap of a button, and the weights increase in 100g increments, making it very safe for the elderly.'
          ]
        },
        {
          question: 'Why is resistance training or weight-bearing exercise important for seniors?',
          subAnswers: [
            'Sarcopenia, or age-related loss of skeletal muscle mass and strength, can lead to physical disability and loss of independence.',
            'Studies have shown that we lose about 3%-8% muscle mass per decade after the age of 30 and the rate of loss increases after the age of 60. As muscle loss progresses, we may begin to reduce our physical activities, resulting in further loss of muscle strength and endurance and hence increase the risks of fall and fractures.',
            'Therefore, resistance or weight-bearing exercises are important for us to strengthen our muscles and bones to prevent osteoporosis and sarcopenia, so that we can continue to be independent and active as we age.'
          ]
        }
      ],
      locations: [
        {
          name: 'ActiveSG Gym @ Serangoon Central',
          detail: '264 Serangoon Central, #01-207, Singapore 550264',
          postalCode: '550264',
          region: 'ne',
          location: [1.35159, 103.874]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Bukit Batok West)',
          detail: 'Blk 439 Bukit Batok West Ave 8, #02-01, Singapore 650439',
          postalCode: '650439',
          region: 'sw',
          location: [1.35178, 103.73961]
        },
        {
          name: 'NTUC Health Active Ageing Hub (Kampung Admiralty)',
          detail: '676 Woodlands Drive 71 #06-01, Singapore 730676',
          postalCode: '730676',
          region: 'nw',
          location: [1.43973, 103.800171]
        },
        {
          name: 'NTUC Health Day Centre for Seniors (Heartbeat@Bedok)',
          detail: '11 Bedok North Street 1 #02-02, Singapore 469662',
          postalCode: '469662',
          region: 'se',
          location: [1.32615, 103.93074]
        },
        {
          name: 'NTUC Health Day Centre for Seniors (Jurong Spring)',
          detail: '6B Jurong West, Street 52 Level 1, Singapore 649298',
          postalCode: '649298',
          region: 'sw',
          location: [1.28944, 103.84998]
        }
      ],
      markerRegions: ['ne', 'sw', 'nw', 'se']
    },
    {
      id: 'df0571ca-4215-e811-817e-e0071b69c9d1',
      name: 'Gym Tonic',
      iconImage: GymTonicIcon,
      descriptions: [
        'Gym Tonic is an evidence-based, low intensity strength training programme that uses a five piece set of age-friendly, hydraulic machines from FREI. Through structured and supervised training in a group setting, this customised training programme helps participants to build and maintain muscle mass and strength. This can help to improve the performance of their daily activities such as climbing the stairs with ease or lifting up an extra bag of groceries.',
        'Each participant will be prescribed a programme according to their fitness level. These smart machines can be configured with a pre-set weight load and repetition count, which can be later adjusted according to individuals’ speed of progression. A pre and post fitness and functional assessment will be conducted for a 24-session programme.',
        'A local study of 183 Gym Tonic participants showed that:',
        [
          '70% of them had improvement in their walking speed',
          '76% of them had improvement in their Sit to Stand Speed',
          '88% of them had improvement in their lower body muscle strength'
        ]
      ],
      heroImage: GymTonicHero,
      statisticImages: [GymTonicWalkingSpeed, GymTonicStandSpeed, GymTonicMuscleStrength],
      statistics: [
        {
          description: 'Of Them Had Improvement In Their Walking Speed',
          percentage: 70
        },
        {
          description: 'Of Them Had Improvement In Their Sit To Stand Speed',
          percentage: 76
        },
        {
          description: 'Of Them Had Improvement In Their Lower Body Muscle Strength',
          percentage: 88
        }
      ],
      faq: [
        {
          question: 'What can you expect from our gym-based fitness programmes?',
          answers: [
            'Age-friendly pneumatic/hydraulic machines that are gentle on muscles and joints',
            'Supervised group sessions and small group sizes',
            'Warm up and cool down exercises to minimise risk of injury'
          ]
        },
        {
          question: 'Who are these programes suitable for?',
          subAnswers: ['Our programmes are suitable for those who:'],
          answers: [
            'require no assistance in mobility and moving from station to station',
            'do not have any physical or medical conditions or symptoms that may be made worse with exercise',
            'have normal/ controlled high blood pressure and heart rate',
            'have the physical or mental capacity to follow through the programme'
          ]
        },
        {
          question: 'How is your elderly gym equipment safe for seniors?',
          subAnswers: [
            'Our centres are equipped with gym equipment designed with the elderly in mind. Our HUR and FREI equipment are adjusted using air and water pressure technology, and are gentle and safe on the muscles and joints. These machines are suitable for elderly who have not been exercising regularly, have poor balance or weak limbs.',
            'The machines have built in smart technology that remembers the user’s preferences and will adjust the weights accordingly, with the use of a smart card. The machines can also be adjusted with the tap of a button, and the weights increase in 100g increments, making it very safe for the elderly.'
          ]
        },
        {
          question: 'Why is resistance training or weight-bearing exercise important for seniors?',
          subAnswers: [
            'Sarcopenia, or age-related loss of skeletal muscle mass and strength, can lead to physical disability and loss of independence.',
            'Studies have shown that we lose about 3%-8% muscle mass per decade after the age of 30 and the rate of loss increases after the age of 60. As muscle loss progresses, we may begin to reduce our physical activities, resulting in further loss of muscle strength and endurance and hence increase the risks of fall and fractures.',
            'Therefore, resistance or weight-bearing exercises are important for us to strengthen our muscles and bones to prevent osteoporosis and sarcopenia, so that we can continue to be independent and active as we age.'
          ]
        }
      ],
      locations: [
        {
          name: 'NTUC Health Active Ageing Hub (Jurong Central Plaza)',
          detail: 'Blk 493 Jurong West Street 41 #02-01, Singapore 640493',
          postalCode: '640493',
          region: 'sw',
          location: [1.34889, 103.723122]
        }
      ],
      markerRegions: ['sw']
    }
  ]
};

const productDetailData: DetailData[] = productDetailDataByAppEnv[appEnv];

export default productDetailData;
