export const denticare = {
  bookYourAppointment: 'Book Your Appointment',
  bookYourAppointmentMessage: 'Please select your service, preferred dentist and location.',
  bookAppointment: 'Book Appointment',
  allAppointments: 'All Appointments',
  allAppointmentsMessage: 'Manage or view upcoming, completed and cancelled appointments.',
  clinicLocation: 'Clinic Location',
  originalClinicLocation: 'Original Clinic Location',
  changedClinicLocation: 'Changed Clinic Location',
  yourDentist: 'Your Dentist',
  originalDentist: 'Original Dentist',
  changedDentist: 'Changed Dentist',
  dateAndTime: 'Date and Time',
  viewAllAppointments: 'View All Appointments',
  medicalHistory: 'Medical History',
  medicalHistoryMessage:
    'Your health condition is closely related to the dental treatment that you are receiving, thus it is essential to keep your medical history up-to-date.',
  submit: 'Submit',
  upcomingAppointment: 'Upcoming Appointment',
  reasons: 'Reasons*',
  reasonPlaceholder: 'E.g. Reason for cancellation',
  upcoming: 'Upcoming',
  completed: 'Completed',
  cancelledOrOther: 'Cancelled/Other',
  rescheduleWarningMessage:
    'You may reschedule / cancel your appointment at least 24 hours before the scheduled appointment time.'
};
