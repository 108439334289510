import { NHColor, Typography } from 'ui-kit';
import styled from 'styled-components';

export const NotApplicableText = styled(Typography)`
  font-style: italic;
  font-weight: 500;
  margin-left: 5px;
`;

export const AddressWrapper = styled.div`
  .withoutBorderContainer {
    padding: 20px;
    border-bottom: 2px solid ${NHColor.grey200};
  }

  .borderContainer {
    margin-top: 20px;
    border-radius: 3.5px;
    border: solid 1px #d3dae6;
  }

  .editContainer {
    margin-top: 20px;
  }

  .rightContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .editButton {
    margin-right: 10px;
  }

  .deleteButton {
  }

  .addButton {
    // margin-top: 10px;
    display: flex;
    align-items: center;

    // svg {
    //   margin-right: 5px;
    // }
  }

  .divider {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      margin-top: 10px;
    }
  }

  .content {
    margin-top: 20px;
  }
`;
