export const service = {
  myService: 'My Services',
  youMayProceedWithOneOfTheFollowing: 'You may proceed with one of the following below to get started:',
  youMayProceedToRegister: 'Your may proceed to register a service with us below.',
  welcomeMessage: 'Welcome to NTUC Health! ',
  registerForNewService: 'Register for a new service',
  uploadMedicalDocuments: 'Update NTUC Health & Upload Medical Documents',
  updateUs: 'Update us',
  validityPeriodWithTime: 'Validity Period: {{ startDate }} - {{ endDate }}',
  bookedSession: 'Booked session(s)',
  attendedSession: 'Attended session(s)',
  cancelledWithChargeSession: 'Cancelled with charge session(s)',
  forfeitedSession: 'Forfeited session(s)',
  viewAllSessions: 'View all sessions',
  viewSessions: 'View sessions',
  bookSessions: 'Book sessions',
  reschedule: 'Reschedule',
  registerdServiceFor: 'Registered Service(s) for',
  sessions: 'sessions',
  bookYourSession: 'Book your session',
  upcomming: 'Upcoming',
  preClassAssessment: 'Pre-assessment',
  preClass: 'Pre-class',
  session: 'Session',
  healthStatus: 'Health Status',
  anyHealthConditionChange: 'Have you had a significant decline in your health since your last pre-assessment?',
  submitFormRecurrenceText: 'Your sessions will recur <green>{{recurrence}}</green>',
  requiredToCompleteBy:
    'Based on the {{product_type}}, you are required to complete all sessions by <green>{{data}}</green>',
  validityPeriod: 'Validity Period',
  sessionsDateAndTimes: 'Session(s) Date and Time',
  bookedFor: 'Booked For',
  preClassAssessmentDateandTime: 'Pre-assessment Date and Time',
  whatToBringForPreClassAssessment: 'What to bring for Pre-assessment',
  pleaseArriveMinutesBeforeYourScheduledAssessmentTime:
    'Please arrive <green>{{minutes}} minutes before</green> your scheduled assessment time.',
  medicalReportIfApplicable: 'Medical reports & discharge memos, if applicable',
  allBookedSession: 'All Booked Session(s)',
  sessionItem: 'Session #{{order}} - <green>{{sessionDate}}</green>',
  selectLocation: 'Select location',
  selectPackage: 'Select package',
  selectActivity: 'Select activiy',
  selectATimeSlotForYourSession: 'Select a time slot for your {{value}} session',
  yourSessionOfTheWeek: 'Your {{value}} session of the week',
  howManySessionsWouldYouLikeToBook: 'How many sessions would you like to book in a week?',
  selectOption: 'Select option',
  whichActivityWouldYouLikeToBookForYour: 'Which activity would you like to book for your {{value}} session?',
  selectADateForYourSession: 'Select a date for your {{value}} session',
  selectADateForYourSessionOfTheWeek: 'Select a date for your {{value}} session of the week',
  selectATimeSlotForYourSessionOfTheWeek: 'Select a time slot for your {{value}} session of the week',
  selectADateFor: 'Select a date for your {{value}}',
  doYouWantToBookRecurringSessionsFor: ' Do you want to book recurring sessions for {{value}}?',
  noDoNotBookRecurringSessionsFor: 'No, do not book recurring sessions for {{event}}',
  yesBookRecurringSessionFor: 'Yes, book {{event}} for every {{recurrence}}',
  numberSession: '{{value}} session',
  yourSessionWillRecurOnTheFollowingDays: 'Your sessions will recur on the following days',
  everyWithValue: 'Every {{value}}',
  renewPackage: 'Renew Package',
  cancellationTermsAndConditions: 'Cancellation Terms & Conditions',
  cancellationSessionResultTitle: 'Your session has been cancelled.',
  whichActivityWouldYouLikeToRenew: 'Which package would you like to renew?',
  trainingDays: 'Training Days',
  selectYourPreferredLocationFor: 'Select your preferred location for {{value}}.',
  selectYourPreferredPackageType: 'Select your preferred package type for {{value}}.',
  cancellationSessionResultSubtitle:
    'Your cancelled session will be forfeited. To avoid future forfeits, please cancel your session(s) at least 2 working days in advance.',
  cancellationSessionResultSubtitleWithoutCharge: `You've successfully cancelled the appointment scheduled for <green>{{bookingTime}}</green>. If you'd like to reschedule, please book a new appointment at your convenience. Remember, to avoid forfeiture of the session, kindly cancel at least 2 days in advance.`,
  confirmCancellation: 'Confirm Cancellation',
  cancelContent1:
    'I agree that my scheduled session will be forfeited because I rescheduled or cancelled my session with less than 2 working days notice.',
  cancelContent2: 'I acknowledge that there will be no replacement or refund for any last minute no-shows. ',
  cancelSubContent1:
    'i. Cancellation or termination of service less than two (2) working days before my agreed scheduled appointment for services, OR',
  cancelSubContent2: 'ii. Last-minute no-show, OR',
  cancelSubContent3: 'iii. All un-utilised services',
  cancelContent3:
    'If the client cancels the appointment after the grace period of 2 working days before the appointment date, the client will be directed to the cancellation policy page for their acknowledgement. Once acknowledged, the client will be allowed to cancel the appointment and the session will be forfeited. The cancellation policy messages will be hardcoded in the system as in the UI design.',
  cancelContent4: 'The system should have configuration of cancellation grace period for different service category.',
  cancelContent5:
    'Cancellation of an intake-based appointment, regardless of whether it is within or past the grace period, the appointment should be cancelled with charge, as the client cannot reschedule an intake-based appointment. If a client is not able to continue with the run booked, he/she can request to join the next run by calling admin staff, subject to approval.',
  areYouSureYouWantToReset: 'Are you sure you want to start from beginning?',
  areYouSureYouWantToDeleteSession: 'Are you sure you want to delete this session?',
  whichActivityWouldYouLikeToChange: 'Which activity would you like to change to?',
  rescheduleSessionReasonTitle: 'If your preferred dates are unavailable, it might be due to the following reasons:',
  rescheduleSessionFirstReason: '(i) You have conflicting session dates for different programmes',
  rescheduleSessionSecondReason: '(ii) The date falls on a Public Holiday',
  rescheduleSessionLastReason: '(iii) The session is fully booked',
  preclassAssessmentMustBeBefore: '(iv) The Pre-assessment must be before the 1st session',
  originalSessionDateTime: 'Original Date and Time',
  changedSessionDateTime: 'Changed Date and Time',
  youAreCancellingSession: 'You are cancelling session for',
  on: 'on',
  cancelYourSession: 'Cancel your session?',
  getAnOverviewUpcomingAppointments: 'Get an overview of your upcoming appointments in <green>{{page}}</green>',
  hasBeenRescheduledSuccessfully: 'Your session(s) has been rescheduled successfully',
  hasBeenBookedSuccessfully: 'Your session(s) has been successfully booked!',
  reviewSessionsBooking: 'Review Session Booking(s)',
  reviewAppointmentBooking: 'Review Appointment Booking',
  noAppointmentBooking: 'No appointment to be displayed',
  rescheduleAppointmentNoticeSessios: 'You may reschedule your session up to 2 working days before it commences.',
  rescheduleAppointmentNoticeIntakes:
    'Please note that you are not allowed to reschedule your bookings for this programme.',
  newToNTUCHealth: 'New to NTUC Health?',
  registerForServicesWithUs: 'Register for service(s) with us.',
  registerServices: 'Register service(s)',
  haveExistingServicesWithUs: 'Have existing service(s) with us?',
  linkYourAccountWithClientId: 'Link your account with Client ID to retrieve existing service(s).',
  linkAccount: 'Link Account',
  pleaseContactUs: 'Please contact us at <green>{{phoneNumber}}</green> if you have any questions.',
  hotlineMessage: 'Hotline operating hours: Between 8.30am to 6pm on Mondays to Fridays, excluding Public Holidays.',
  cancelAppointmentMessage: 'You are cancelling appointment for <green>{{name}}</green> on <green>{{time}}</green>',
  cancellationAppointmentResultTitle: 'Your appointment has been cancelled.',
  cancellationAppointmentResultMessage: 'Get an overview of your upcoming appointments in <green>{{page}}</green>.',
  notApplicableForActiveAgeingFitnessProgram: 'Not applicable for Active Ageing fitness program',
  phoneSupportMessage: 'Require further technical assistance? Email <green>{{support}}</green>',
  applicationSupportTeam: 'support@ntuchealth.sg',
  emailSupportMessage: 'Need more information on NTUC Health Services? Contact us <green>{{call}}</green>',
  faqMessage: 'Refer to our <green>{{link}}</green> page for a list of frequently asked questions.',
  callUsOrLeaveYourEnquiry: 'https://ntuchealth.sg/contact-us',
  customerSupport: 'Customer Support',
  supportServiceTitle: 'NTUC Health Services',
  supportServiceMessage: 'For enquiries related to NTUC Health services, please visit <green>{{support}}</green>',
  supportWebsiteTitle: 'Technical support',
  supportWebsiteMessage: 'For enquiries related to your account or website, please email to <green>{{support}}</green>'
};
