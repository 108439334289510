import React, { FC } from 'react';
import { PackageDetailsTable as BasePackageDetailsTable } from 'ui-kit';

// PROPS
import { PackageDetailsTableProps } from './interface';

const PackageDetailsTable: FC<PackageDetailsTableProps> = ({ title, data, headers }) => {
  if (!data) return null;

  return <BasePackageDetailsTable headers={headers} items={data.items} title={title} />;
};

export default PackageDetailsTable;
