export function isNRIC(text: string, type: 'nric' | 'fin' | 'all') {
  const FIRST_LETTER_NRIC = ['S', 'T'];
  const FIRST_LETTER_FIN = ['F', 'G', 'M'];

  if (text.length !== 9) return false;

  if (type === 'all' && !FIRST_LETTER_NRIC.concat(FIRST_LETTER_FIN).includes(text[0])) return false;

  if (type === 'nric' && !FIRST_LETTER_NRIC.includes(text[0])) return false;

  if (type === 'fin' && !FIRST_LETTER_FIN.includes(text[0])) return false;

  const nric = text.toUpperCase();
  const multiplier = [1, 2, 7, 6, 5, 4, 3, 2];
  const icArray = nric.split('').map((c, index) => {
    if (index !== 0 && index !== nric.length - 1) {
      return Number(c) * multiplier[index];
    }

    return c;
  });

  const weight = icArray.reduce((acc: number, val: string | number) => {
    if (typeof val === 'number') {
      // eslint-disable-next-line no-param-reassign
      acc += val;
    }

    return acc;
  }, 0);

  let theAlpha;
  const firstLetter = icArray[0];
  const lastLetter = icArray[icArray.length - 1];

  let offset = 0;

  if (firstLetter === 'T' || firstLetter === 'G') {
    offset = 4;
  }

  if (firstLetter === 'M') {
    offset = 3;
  }

  const st = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A'];
  const fg = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K'];
  const fgm = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'J', 'L', 'K'];
  const alphaIndex = (offset + weight) % 11;

  if (firstLetter === 'S' || firstLetter === 'T') {
    theAlpha = st[alphaIndex];
  } else if (firstLetter === 'F' || firstLetter === 'G') {
    theAlpha = fg[alphaIndex];
  } else if (firstLetter === 'M') {
    theAlpha = fgm[alphaIndex];
  }

  return lastLetter === theAlpha;
}
