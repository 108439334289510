import { collection, getDocs } from 'firebase/firestore';
import { ProductType } from 'ui-kit';
import { push } from 'connected-react-router';

// TYPES
import { AppThunk } from 'redux/type';

// HELPERS
import { setValue } from 'helpers/localstorage';

// MODEL
import {
  ServiceProductCatalog,
  ProgramProductCatalog,
  OffersListProductCatalog,
  ServiceSelection,
  Programme
} from 'model';

// CONSTANT
import { AppEnv, Domain, Router } from 'constant';

// REDUCER
import { setAppMessage } from 'redux/app/actions';
import { changeServiceSelection } from 'redux/service-registration/actions';
import { initialServiceSelection } from 'redux/service-registration/slice';
import { selectServiceSelection } from 'redux/service-registration/selector';
import {
  selectServicesProductCatalog,
  selectSelectedServiceProductCatalog,
  selectProgramsProductCatalog
} from './selector';
import { productCatalogSlice } from './slice';

const appEnv = process.env.REACT_APP_ENV;

const {
  changeProductCatalogLoading,
  setServicesProductCatalog,
  setProgramsProductCatalog,
  setOffersProductCatalog,
  setSelectedServiceProductCatalog: setSelectedServiceProductCatalogAction
} = productCatalogSlice.actions;

const setSelectedServiceProductCatalog =
  (productType?: ProductType): AppThunk =>
  async (dispatch, getState) => {
    if (!productType) {
      dispatch(setSelectedServiceProductCatalogAction());

      return;
    }

    const services = selectServicesProductCatalog(getState());

    const foundService = services.find((v) => v.type === productType);

    dispatch(setSelectedServiceProductCatalogAction(foundService?.id));
  };

const storeCartIntoLocalStorage = (): AppThunk => async (_, getState) => {
  const serviceSelection = selectServiceSelection(getState());

  setValue<ServiceSelection>('@productCatalogCartKey', serviceSelection);
};

const addToCartProductCatalog =
  (offerId: string, programId: string): AppThunk =>
  async (dispatch, getState) => {
    if (!programId) {
      dispatch(setAppMessage({ type: 'error', content: 'Product is not valid' }));

      dispatch(push(Router.Home));

      return;
    }

    const selectedService = selectSelectedServiceProductCatalog(getState());

    if (!selectedService) {
      dispatch(setAppMessage({ type: 'error', content: 'Service is not valid' }));

      dispatch(push(Router.Home));

      return;
    }

    const programs = selectProgramsProductCatalog()(getState());
    const services = selectServicesProductCatalog(getState());
    const selectedPrograms = programs.find((v) => v.id === programId);
    const foundService = services.find((v) => v.id === selectedService);

    if (!selectedPrograms) {
      dispatch(setAppMessage({ type: 'error', content: 'Product is not valid' }));

      dispatch(push(Router.Home));

      return;
    }

    if (!foundService) {
      dispatch(setAppMessage({ type: 'error', content: 'Service is not valid' }));

      dispatch(push(Router.Home));

      return;
    }

    const selectedCategory = selectedPrograms.categoryId;

    const currentServiceSelection = selectServiceSelection(getState());
    const cloneCurrentServiceSelection = {
      ...currentServiceSelection,
      programmes: currentServiceSelection.programmes.map((v) => ({ ...v }))
    };

    const programmesLength = cloneCurrentServiceSelection.programmes.length;

    if (programmesLength === 0) {
      dispatch(
        changeServiceSelection({
          lovedOne: 'my_self',
          category: selectedService,
          catergoryName: foundService.name,
          marketingChanel: '',
          programmes: [
            {
              id: 1,
              category: selectedCategory,
              product: programId,
              offer: offerId,
              center: '',
              isExpanded: true
            }
          ]
        })
      );

      return;
    }

    const lastItem = currentServiceSelection.programmes[programmesLength - 1];
    const nextId = lastItem.id + 1;
    const newProgrames = currentServiceSelection.programmes.concat({
      id: nextId,
      category: selectedCategory,
      product: programId,
      offer: offerId,
      center: '',
      isExpanded: false
    });

    dispatch(
      changeServiceSelection({
        ...cloneCurrentServiceSelection,
        programmes: newProgrames
      })
    );
  };

const removeItemFromCartProductCatalog =
  (offerId: string): AppThunk =>
  async (dispatch, getState) => {
    const currentServiceSelection = selectServiceSelection(getState());
    const cloneCurrentServiceSelection = {
      ...currentServiceSelection,
      programmes: currentServiceSelection.programmes.filter((v) => v.offer !== offerId)
    };

    dispatch(
      changeServiceSelection({
        ...cloneCurrentServiceSelection
      })
    );
  };

const clearCartCartProductCatalog = (): AppThunk => async (dispatch) => {
  dispatch(
    changeServiceSelection({
      ...initialServiceSelection
    })
  );
};

const updateProductCatalogCart =
  (programmes: Programme[]): AppThunk =>
  async (dispatch, getState) => {
    const serviceSelection = selectServiceSelection(getState());

    if (programmes.length === 1) {
      dispatch(
        changeServiceSelection({
          ...serviceSelection,
          programmes: [{ ...programmes[0], isExpanded: true }]
        })
      );

      return;
    }

    dispatch(
      changeServiceSelection({
        ...serviceSelection,
        programmes
      })
    );
  };

const getNecessaryDataProductCatalog =
  ({ serviceType, productId }: { serviceType?: ProductType; productId?: string }): AppThunk =>
  async (dispatch) => {
    dispatch(changeProductCatalogLoading(true));

    getDocs(
      collection(
        window.fireStoreDB,
        appEnv === AppEnv.Alpha ? Domain.ProductCatalogServiceAlpha : Domain.ProductCatalogService
      )
    ).then((querySnapshot) => {
      const services: ServiceProductCatalog[] = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();

        services.push(data as ServiceProductCatalog);
      });

      dispatch(setServicesProductCatalog(services));

      if (serviceType) {
        dispatch(setSelectedServiceProductCatalog(serviceType));
      }
    });

    getDocs(
      collection(
        window.fireStoreDB,
        appEnv === AppEnv.Alpha ? Domain.ProductCatalogProgramAlpha : Domain.ProductCatalogProgram
      )
    ).then((querySnapshot) => {
      const programs: ProgramProductCatalog[] = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();

        programs.push(data as ProgramProductCatalog);
      });

      dispatch(setProgramsProductCatalog(programs));

      if (productId) {
        const data = programs.find((v) => v.id === productId);

        if (!data) {
          dispatch(setAppMessage({ type: 'error', content: 'Product is not valid' }));

          dispatch(push(Router.Home));
        } else {
          dispatch(setSelectedServiceProductCatalogAction(data.parentCategoryId));
        }
      }
    });

    getDocs(
      collection(
        window.fireStoreDB,
        appEnv === AppEnv.Alpha ? Domain.ProductCatalogOfferAlpha : Domain.ProductCatalogOffer
      )
    ).then((querySnapshot) => {
      const offers: OffersListProductCatalog[] = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();

        offers.push(data as OffersListProductCatalog);
      });

      dispatch(setOffersProductCatalog(offers));
    });
  };

export {
  getNecessaryDataProductCatalog,
  setSelectedServiceProductCatalog,
  addToCartProductCatalog,
  removeItemFromCartProductCatalog,
  clearCartCartProductCatalog,
  storeCartIntoLocalStorage,
  updateProductCatalogCart
};
