import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { ProductCatalogState } from 'model';

// REDUCER ACTION
import {
  changeProductCatalogLoading,
  setServicesProductCatalog,
  setProgramsProductCatalog,
  setOffersProductCatalog,
  setSelectedServiceProductCatalog
} from './reducers';

export const initialState: ProductCatalogState = {
  loading: false,
  services: [],
  programs: [],
  offers: [],
  selectedService: undefined
};

export const productCatalogSlice = createSlice({
  name: Domain.ProductCatalog,
  initialState,
  reducers: {
    changeProductCatalogLoading,
    setServicesProductCatalog,
    setProgramsProductCatalog,
    setOffersProductCatalog,
    setSelectedServiceProductCatalog
  }
});

export default productCatalogSlice.reducer;
