import { RootState } from 'redux/type';
import { createSelector } from '@reduxjs/toolkit';

// MODEL
import {
  ServiceRegistration,
  RegistrationServiceProgressEnum,
  ServiceSelection,
  Cart,
  User,
  ServiceRegistrationState,
  LovedOneType,
  Contact,
  HealthDeclarationForm
} from 'model';

// SELECTOR
import { selectLoggedInUser } from 'redux/user/selector';

// INITIAL
import { selectEnabledHealthDeclaration } from 'redux/featureFlag/selectors';
import { initialServiceRegistration } from './slice';

// ----------------------------- SERVICE REGISTRATION STATE -----------------------------------

export const selectServiceRegistrationState = (state: RootState): ServiceRegistrationState =>
  state.registerServiceReducer;

// ----------------------------- LOADING -----------------------------------

export const selectLoadingStatus = (state: RootState): boolean => state.registerServiceReducer.loading;

// ----------------------------- SERVICE REGISTRATION -----------------------------------

export const selectServiceRegistration = (state: RootState): ServiceRegistration =>
  state.registerServiceReducer.single || initialServiceRegistration;

// ----------------------------- SHOW WELCOME DIALOG -----------------------------------

export const selectShowWelcomeDialog = (state: RootState): boolean =>
  state.registerServiceReducer.isShowWelcomeDialog ?? false;

// ----------------------------- CURRENT PROGRESS -----------------------------------

export const selectCurrentProgress = (state: RootState): RegistrationServiceProgressEnum =>
  state.registerServiceReducer.currentProgress ?? RegistrationServiceProgressEnum.ServiceSelection;

// ------------------------------ ACTIVE TAB ----------------------------------

export const selectActiveTab = (state: RootState): number =>
  state.registerServiceReducer.currentProgress ? state.registerServiceReducer.currentProgress : 0;

// ----------------------------------- SEVICE SELECTION -----------------------------

export const selectServiceSelection = createSelector(
  selectServiceRegistration,
  (serviceRegistration: ServiceRegistration): ServiceSelection => serviceRegistration.serviceSelection
);

export const isSelectedUserLoveOne = (state: RootState): boolean =>
  state.userReducer.loggedInUser.id !== state.registerServiceReducer.single!.lovedOneDetail.id;

// ----------------------------------- VALID STEP -----------------------------

export const selectCurrentProgressStatus = createSelector(
  [selectCurrentProgress, selectServiceRegistrationState, selectEnabledHealthDeclaration],
  (
    currentProgress: RegistrationServiceProgressEnum,
    serviceRegistrationState: ServiceRegistrationState,
    isEnableHealthDeclaration: boolean
  ): boolean => {
    const isActiveAging = serviceRegistrationState.single?.serviceSelection.catergoryName === 'Active Ageing';
    const enableHealthDeclaration = isEnableHealthDeclaration && isActiveAging;

    if (currentProgress === RegistrationServiceProgressEnum.ServiceSelection) {
      return serviceRegistrationState.isValidServiceSelection ?? false;
    }

    // 1 means Health Declaration
    if (currentProgress === 1 && enableHealthDeclaration) {
      return serviceRegistrationState.isValidHealthDeclaration ?? false;
    }

    if (
      currentProgress ===
      (enableHealthDeclaration
        ? RegistrationServiceProgressEnum.YourDetails + 1
        : RegistrationServiceProgressEnum.YourDetails)
    ) {
      return serviceRegistrationState.isValidLovedOneDetail ?? false;
    }

    if (
      currentProgress ===
      (enableHealthDeclaration
        ? RegistrationServiceProgressEnum.YourAddress + 1
        : RegistrationServiceProgressEnum.YourAddress)
    ) {
      return serviceRegistrationState.isValidAddress ?? false;
    }

    if (
      currentProgress ===
      (enableHealthDeclaration
        ? RegistrationServiceProgressEnum.EmergencyContact + 1
        : RegistrationServiceProgressEnum.EmergencyContact)
    ) {
      return serviceRegistrationState.isValidContact ?? false;
    }

    if (
      currentProgress ===
      (enableHealthDeclaration
        ? RegistrationServiceProgressEnum.ReviewInformation + 1
        : RegistrationServiceProgressEnum.ReviewInformation)
    ) {
      return serviceRegistrationState.isValidReview ?? false;
    }

    return false;
  }
);

// ----------------------------------- GET CART FOR SERVICE SELECTION -----------------------------

export const selectServiceSelectionCart = createSelector(
  [selectServiceRegistration, selectLoggedInUser, selectServiceRegistrationState],
  (
    serviceRegistration: ServiceRegistration,
    user: User,
    serviceRegistrationState: ServiceRegistrationState
  ): Cart | null => {
    const { serviceSelection } = serviceRegistration;

    if (serviceRegistrationState.isValidServiceSelection) {
      let customerId = null;

      if (serviceSelection.lovedOne === LovedOneType.MySelf) {
        customerId = user.id!;
      } else if (serviceSelection.lovedOne !== LovedOneType.New) {
        customerId = serviceSelection.lovedOne;
      }

      const cart = {
        customerId,
        categoryId: serviceSelection.category,
        referralSource: {
          source: serviceSelection.marketingChanel,
          sourceOthers: serviceSelection.sourceOthers || ''
        },
        marketingConsent: !!user.marketingConsent,
        items: serviceSelection.programmes.map((item) => ({
          categoryId: item.category,
          productId: item.product,
          offeringId: item.offer,
          centerId: item.center
        }))
      };

      return cart;
    }

    return null;
  }
);

// ----------------------------------- LOVED ONE DETAIL -----------------------------

export const selectLovedOneDetail = createSelector(
  selectServiceRegistration,
  (serviceRegistration: ServiceRegistration): User => serviceRegistration.lovedOneDetail
);

// ----------------------------------- EMERGENCY CONTACT -----------------------------

export const selectEmergencyContact = createSelector(
  selectServiceRegistration,
  (serviceRegistration: ServiceRegistration): Contact => serviceRegistration.emergencyContact
);

// ----------------------------------- HEALTH DECLARATION -----------------------------
export const selectHealthDeclaration = (state: RootState): HealthDeclarationForm | undefined =>
  state.registerServiceReducer.healthDeclaration;

export const selectHealthDeclarationIsValid = (state: RootState): boolean | undefined =>
  state.registerServiceReducer.isValidHealthDeclaration;
export const selectHealthDeclarationLoading = (state: RootState): boolean | undefined =>
  state.registerServiceReducer.isHealthDeclarationLoading;
export const selectIsHealthDeclarationValid = (state: RootState): boolean | undefined =>
  state.registerServiceReducer.isValidHealthDeclaration;

// ----------------------------------- ACTIVE AGING -----------------------------
export const selectIsActiveAging = createSelector(
  selectServiceSelection,
  (serviceSelection: ServiceSelection) => serviceSelection.catergoryName === 'Active Ageing'
);

// ----------------------------------- DENTICARE -----------------------------

export const selectIsDenticare = createSelector(
  selectServiceSelection,
  (serviceSelection: ServiceSelection) => serviceSelection.catergoryName === 'Denticare'
);
