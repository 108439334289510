import { createSlice } from '@reduxjs/toolkit';
import { Domain } from 'constant';
import { FeatureFlagState } from 'model/featureFlag';
import { setFeatureFlag, setFeatureFlags } from './reducers';

export const initialState: FeatureFlagState = {
  ENABLED_PRODUCT_CATALOG: process.env.REACT_APP_ENABLED_PRODUCT_CATALOG === 'true',
  ENABLED_DENTICARE: process.env.REACT_APP_ENABLED_DENTICARE === 'true',
  ENABLED_DENTICARE_SERVICE_REGISTRATION: process.env.REACT_APP_ENABLED_DENTICARE_SERVICE_REGISTRATION === 'true',
  ENABLED_APP_TOUR: process.env.REACT_APP_ENABLED_APP_TOUR === 'true',
  ENABLED_ACTIVITY_SELECTION: process.env.REACT_APP_ENABLED_ACTIVITY_SELECTION === 'true',
  ENABLED_MHQ: process.env.REACT_APP_ENABLED_MHQ === 'true',
  ENABLED_HEALTH_DECLARATION: process.env.REACT_APP_ENABLED_HEALTH_DECLARATION === 'true',
  ENABLED_FAQ: process.env.REACT_APP_ENABLED_FAQ === 'true',
  ENABLED_E_GIRO: process.env.REACT_APP_ENABLED_E_GIRO === 'true',
  ENABLED_E_GYRO_ARRANGEMENT: process.env.REACT_APP_ENABLED_E_GYRO_ARRANGEMENT === 'true',
  ENABLED_NEW_DENTICARE: process.env.REACT_APP_ENABLED_NEW_DENTICARE === 'true',
  ENABLED_CENTER_FILTERED: process.env.REACT_APP_ENABLED_CENTER_FILTERED === 'true',
  ENABLED_NEW_SCAN_QR_MESSAGE: process.env.REACT_APP_ENABLED_NEW_SCAN_QR_MESSAGE === 'true',
  ENABLED_ERROR_MESSAGE_FOR_LINK_PASS: process.env.REACT_APP_ENABLED_ERROR_MESSAGE_FOR_LINK_PASS === 'true'
};

export const featureFlagSlice = createSlice({
  name: Domain.FeatureFlag,
  initialState,
  reducers: {
    setFeatureFlag,
    setFeatureFlags
  }
});

export default featureFlagSlice.reducer;
