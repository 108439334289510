import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { CenterState } from 'model';

// REDUCER ACTION
import { changeCenterLoading, setCenter, setManyCenter, setCentersMaps } from './reducers';

export const initialState: CenterState = {
  single: undefined,
  many: [],
  centersMap: {},
  loading: true
};

export const centerSlice = createSlice({
  name: Domain.Center,
  initialState,
  reducers: {
    changeCenterLoading,
    setCenter,
    setManyCenter,
    setCentersMaps
  }
});

export default centerSlice.reducer;
