/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Div, Typography } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// MODEL
import { Contact } from 'model';

// COMPONENTS
import { NextOfKinsInputForm, contactSchema } from 'views/NextOfKins';

// STYLE
import { EmergencyContactFormWrapper } from 'views/RegisterService/EmergencyContact/form/styles';
import { RegisterServiceContainer } from 'page/RegisterService/styles';

// DATA
import { emergencyContact } from './data';

const EmergencyContact: FC = () => {
  const { t: locale } = useTranslation(['registerService']);

  const formik = useFormik<Contact>({
    initialValues: {
      ...emergencyContact
    },
    validationSchema: contactSchema,
    onSubmit: () => {}
  });

  return (
    <RegisterServiceContainer>
      <form onSubmit={formik.handleSubmit}>
        <EmergencyContactFormWrapper>
          <Typography className="title" testId="emergencyContactTitle" variant="h2">
            4. {locale('registerService:emergencyContact')}
          </Typography>
          <Typography className="guideText" testId="messageNOK" variant="body1">
            {locale('registerService:messageNOK')}
          </Typography>
          <Div className="divider" margin={{ top: 20 }} />
          <Div className="editContainer" testId="editEmergencyForm">
            <NextOfKinsInputForm readOnly couldChangeEmergency={false} formik={formik} />
          </Div>
          <Div className="divider" margin={{ top: 40 }} />
        </EmergencyContactFormWrapper>
      </form>
    </RegisterServiceContainer>
  );
};

export default EmergencyContact;
