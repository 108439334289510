import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { FeatureFlagState, SetConfigPayload, SetManyConfigPayload } from 'model/featureFlag';

export const setFeatureFlag: CaseReducer<FeatureFlagState, PayloadAction<SetConfigPayload>> = (state, action) => {
  state[action.payload.key as keyof FeatureFlagState] = action.payload.value;
};

export const setFeatureFlags: CaseReducer<FeatureFlagState, PayloadAction<SetManyConfigPayload>> = (state, action) => {
  Object.keys(action.payload).forEach((key) => {
    state[key as keyof FeatureFlagState] = action.payload[key as keyof FeatureFlagState];
  });
};
