export const payment = {
  payment: 'Payment',
  returnToPayment: 'Return to payment',
  paymentWasUnsuccessfull: 'Your payment was unsuccessful',
  invalidPayment: 'Invalid Payment',
  failedPaymentDescription: 'Please try again or use another card.',
  invalidCard: 'Invalid Card',
  cardManagement: 'Card Management',
  addNewCard: 'Add new card',
  paymentConfirmation: 'Payment Confirmation',
  checkoutTitle2: 'Please make payment to complete your registration.',
  checkoutTitle3: 'Please make payment to complete your Loved one registration.',
  packageDetails: 'Your Package Details',
  promoCode: 'Promo Code',
  promoCodeApplied: 'Promo Code',
  subTotal: 'Sub Total',
  lessTotalDiscount: 'Less Total Discount',
  total: 'Total',
  gst: 'GST {{gstPercentage}}%',
  totalPaid: 'Total Paid',
  payOnline: 'Pay online',
  totalPayable: 'Total Payable',
  items: 'Items',
  scanQRTitle: 'Scan QR Code with PayNow or PayLah App',
  scanQRContentMsg:
    'Pay within 15 minutes to ensure prompt product activation; contact customer care if you encounter any issues after confirming payment.',
  scanQRContent1: '1. Open your preferred Mobile Banking App and tap on the PayNow function on your App.',
  scanQRContent2: '2. Select the function to scan a QR Code.',
  scanQRContent3: '3. Scan the QR Code below and complete payment.',
  scanQRContent4:
    'If you are viewing this on your mobile device, press and hold on the QR Code below to save the QR Code image to your phone.',
  scanQRContent5:
    'In the PayNow function in your Mobile Banking App, click on the option to access your images album and select the QR Code image that you have just saved.',
  checkoutFooter:
    'If you wish to make payment later, you may do so at the ‘My Services’ page. You will only be allowed to start booking session(s) after the payment is completed.',
  invoiceNumber: 'Invoice Number',
  invoiceDate: 'Invoice Date',
  taxInvoice: 'TAX INVOICE',
  paymentDate: 'Payment Date',
  paymentMode: 'Payment Mode',
  downloadEInvoice: 'Download e-invoice',
  invoicesAndPayments: 'Invoices & Payment',
  displayingOutOfInvoices: 'Displaying {{display}} out of {{total}} invoices',
  invoiceIdAndDate: 'Invoice ID & Date',
  clientNameAndService: 'Client Name & Service',
  totalServiceFees: 'Total Service Fees',
  discounts: 'Discounts',
  totalPayableTitle: 'Total Payable (incl. {{gstPercentage}}% GST)',
  totalAmountTitle: 'Total Amount (incl. {{gstPercentage}}% GST)',
  status: 'Status',
  paymentHistory: 'Payment History',
  thankYourForYourPayment: 'Thank you for your payment!',
  deletePromotionBody: 'Are you sure you want to delete this promo code?',
  referServiceToFriend: 'Refer a friend to {{service}} Services!',
  enjoyDiscontWithCode: 'Both you and your friend can enjoy a 5% discount, when they use your promo code: {{code}}',
  discountCodeDisclaimer: '*Your promo code will be emailed to you upon successful referral',
  copyCode: 'Copy Code',
  selectPaymentMethod: 'Select Payment Mode',
  endingIn: 'Ending in {{value}}',
  showAll: 'Show All Cards',
  showLess: 'Show Less Cards',
  debitCard: 'Debit',
  creditCard: 'Credit',
  cardType: '{{value}} card',
  addCardType: 'Add new {{value}}',
  confirmPayment: 'Confirm Payment',
  creditCardInformation: 'Credit Card Information',
  creditCardDescription: 'Your payment details will be kept safe with us.',
  checkingPayment: '...Checking your payment request',
  checkingCard: '...Checking your card information',
  currentPaymentMethod: 'Current Payment Method',
  expires: 'Expires',
  default: 'Default',
  setAsDefault: 'Set as Default',
  saveThisCard: 'Save This Card',
  cardIsNotValid: 'Your credit / debit card is not valid',
  paymentIsNotValid: 'Payment is not valid',
  backToAddCard: 'Back To Add Card',
  deleteCardConfirmationn: 'Are you sure you want to delete this card?',
  saveAsDefaultPayment: 'Set as default payment method',
  noAvailableCards: 'No added cards are available, please save your card details.',
  cardHasExpired: 'This card has expired',
  saveAndContinueToPay: 'SAVE AND CONTINUE TO PAY',
  highestPromoCodeApplied: 'The highest discount amount has been applied'
};
