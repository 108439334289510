import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// ROUTE
import { Router } from 'constant';

// COMPONENT
import { baseLoader } from 'components/Loading';

// PAGE

const DenticareHome = baseLoader(() => import('page/Denticare/Home'));
const DenticareBookAppointment = baseLoader(() => import('page/Denticare/BookAppointment'));
const SomethingWentWrongPage = baseLoader(() => import('page/Denticare/SomethingWentWrongPage'));
const UrlNotFoundPage = baseLoader(() => import('page/Denticare/UrlNotFoundPage'));
const ConfirmOrder = baseLoader(() => import('page/Denticare/ValidationScreens/ConfirmOrder'));
const RetainedOrder = baseLoader(() => import('page/Denticare/ValidationScreens/RetainedOrder'));
const HoldOnScreen = baseLoader(() => import('page/Denticare/ValidationScreens/HoldOnScreen'));
const ExistingAppointmentFound = baseLoader(() => import('page/Denticare/ValidationScreens/ExistingAppointmentFound'));

const AppDenticareRoute: FC = () => (
  <Switch>
    <Route exact component={DenticareHome} path={Router.denticare} />
    <Route exact component={DenticareBookAppointment} path={Router.denticareBookAppointment} />
    <Route exact component={DenticareBookAppointment} path={Router.denticareBookAppointmentWithHash} />
    <Route exact component={UrlNotFoundPage} path={Router.denticarePageNotFoundError} />
    <Route exact component={SomethingWentWrongPage} path={Router.denticareCommonError} />
    <Route exact component={ConfirmOrder} path={Router.denticareConfirmOrder} />
    <Route exact component={RetainedOrder} path={Router.denticareRetainedOrder} />
    <Route exact component={HoldOnScreen} path={Router.denticareHoldOn} />
    <Route exact component={ExistingAppointmentFound} path={Router.denticareExistingAppointmentFound} />
    <Redirect to={Router.denticarePageNotFoundError} />
  </Switch>
);

export default AppDenticareRoute;
