// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// MODEL
import { Service, ServiceByCategory, ServiceResponse, Session, ServiceStatus } from 'model';

// TYPE
import { Callback } from 'interface';

// HELPERS
import { generateMapObjFromArr, groupByAccessor } from 'helpers/array';
import { intervalAction } from 'helpers/ulti';

// ACTIONS
import { setEventsMaps, setManyEvents, getEventByProduct } from 'redux/event/actions';
import { getCenterDetails } from 'redux/center/action';

// REDUCER ACTION
import { serviceSlice } from './slice';

const {
  changeServiceLoading,
  setManyServices,
  setServiceGroupByCategory,
  setService,
  setSelectedTerm,
  setHealthConditionChanged,
  setSelectedServiceType,
  setManyServicesByProductId,
  setCompletedServices,
  setExpiredServices,
  setOnGoingServices,
  setUpComingServices,
  setManyServicesWithStatus,
  setSelectedServiceTab
} = serviceSlice.actions;

const setServiceThunk =
  (shouldGetEvents: boolean, service?: Service): AppThunk =>
  (dispatch) => {
    if (service) {
      dispatch(setService(service));

      if (shouldGetEvents) dispatch(getEventByProduct(service.productId));
    }

    if (!service) {
      dispatch(setService());
      dispatch(setManyEvents([]));
      dispatch(setEventsMaps({}));
      dispatch(setSelectedTerm());
    }
  };

const getServicesList =
  (apiURL: string, retryLogic?: boolean, callback?: Callback<Service[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeServiceLoading(true));

    const dispatchAction = () =>
      dispatch(
        httpAction<unknown, unknown, ServiceResponse>(
          {
            method: 'GET',
            url: apiURL,
            needAuth: true,
            hideToast: true
          },
          async (res) => {
            if (res.data) {
              const data = generateMapObjFromArr<Service>(res.data.services, 'id');
              const dataByProductId = generateMapObjFromArr<Service>(res.data.services, 'productId');
              const dataWithStatus = generateMapObjFromArr<Service>(res.data.services, 'id', 'serviceStatus');

              const groupByCategoryId = groupByAccessor<Service, ServiceByCategory>(
                res.data.services,
                'categoryId',
                (key, innerData) => {
                  const serviceFound = res.data!.services.find((v) => v.categoryId === key);

                  return {
                    id: key,
                    categoryName: serviceFound!.categoryName,
                    services: innerData.map((v) => v.id!)
                  };
                }
              );

              const upComingServices = res.data.services
                .filter((v) => v.serviceStatus === ServiceStatus.UPCOMING)
                .map((v) => `${v.serviceStatus}_${v.id}`);
              const onGoingServices = res.data.services
                .filter((v) => v.serviceStatus === ServiceStatus.ONGOING)
                .map((v) => `${v.serviceStatus}_${v.id}`);
              const expiredServices = res.data.services
                .filter((v) => v.serviceStatus === ServiceStatus.EXPIRED)
                .map((v) => `${v.serviceStatus}_${v.id}`);
              const completedServices = res.data.services
                .filter((v) => v.serviceStatus === ServiceStatus.COMPLETED)
                .map((v) => `${v.serviceStatus}_${v.id}`);

              res.data.services.forEach((v) => {
                dispatch(getCenterDetails(v.centerId, false));
              });

              dispatch(setCompletedServices(completedServices));
              dispatch(setExpiredServices(expiredServices));
              dispatch(setOnGoingServices(onGoingServices));
              dispatch(setUpComingServices(upComingServices));
              dispatch(setManyServices(data));
              dispatch(setManyServicesWithStatus(dataWithStatus));
              dispatch(setManyServicesByProductId(dataByProductId));
              dispatch(setServiceGroupByCategory(groupByCategoryId));
            }

            if (callback) {
              callback(res.data?.services);
            }

            dispatch(changeServiceLoading(false));
          }
        )
      );

    if (retryLogic) {
      intervalAction(dispatchAction);
    }

    dispatchAction();
  };

const getSessionList =
  (apiURL: string, callback?: Callback<Session[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeServiceLoading(true));

    dispatch(
      httpAction<unknown, unknown, Session[]>(
        {
          method: 'GET',
          url: apiURL,
          needAuth: true
        },
        async (res) => {
          dispatch(changeServiceLoading(false));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

export {
  getServicesList,
  setService,
  setServiceThunk,
  getSessionList,
  setSelectedTerm,
  setHealthConditionChanged,
  setSelectedServiceType,
  setSelectedServiceTab
};
