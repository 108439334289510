import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  margin-top: 10px;

  .content {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
  }

  .rightContent {
    margin-left: 20px;

    .updateProfileButton {
      margin-top: 10px;
    }
  }
`;

export const ProfileContenContainer = styled.div`
  margin-top: 20px;
`;

export const PersonalDetailsWrapper = styled.div`
  padding: 20px;

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .divider {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;
  }

  .editContainer {
    margin-top: 20px;
  }

  .dateOfBirthContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .dateofbirth: {
      display: flex;
      flex: 0.8;
    }

    .age {
      display: flex;
      flex: 0.2;
    }
  }

  .cancelButton {
    margin-right: 10px;
  }

  .saveButton {
    margin-left: 10px;
  }

  .footer {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .disableField {
    background-color: #e2e2e2;
  }
`;
