import React, { FC } from 'react';
import { Div, Typography, Grid } from 'ui-kit';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';

// HELPERS
import { generateAddress } from 'helpers/string/generation';

// DATA
import { programme, products, centerDetails, offers, subCategories, productInfo } from './data';

const ReviewProgrammeItem: FC = () => {
  const { t: locale } = useTranslation(['registerService', 'common']);

  const offerName = offers?.find((v) => v.id === programme.offer)?.name ?? '';

  return (
    <Div className="content">
      <Grid container spacing={2}>
        <Grid item sm={6} testId="reviewProgram" xs={12}>
          <Typography className="textTitle" variant="body1">
            {locale('registerService:programme')}
          </Typography>
          <Typography className="textContent" variant="body1">
            {isArray(subCategories) && subCategories.find((v) => v.id === programme.category)?.name}
          </Typography>
        </Grid>

        <Grid item sm={6} testId="reviewPackage" xs={12}>
          <Typography className="textTitle" variant="body1">
            {locale('registerService:package')}
          </Typography>
          <Typography className="textContent" variant="body1">
            {isArray(products) && products.find((v) => v.id === programme.product)?.name}
          </Typography>
        </Grid>
      </Grid>

      <Div margin={{ top: 20 }}>
        <Grid container spacing={2}>
          <Grid item sm={6} testId="reviewAvailableActivities" xs={12}>
            {productInfo && (
              <>
                <Typography className="textTitle" variant="body1">
                  {productInfo.title}
                </Typography>
                {isArray(productInfo.contents) &&
                  productInfo.contents.map((v, index) => (
                    <Div key={`${v}-${index}`}>
                      {v.split('\n').map((t, i) => (
                        <Div key={`${t}-${i}`}>
                          <Typography variant="body1">{t}</Typography>
                        </Div>
                      ))}
                    </Div>
                  ))}
              </>
            )}
          </Grid>
          <Grid item sm={6} testId="reviewPackageType" xs={12}>
            <Typography className="textTitle" variant="h4">
              {locale('registerService:packageType')}
            </Typography>
            <Typography className="textContent" variant="h4">
              {offerName}
            </Typography>
          </Grid>
        </Grid>
      </Div>

      <Div margin={{ top: 20 }}>
        <Grid container spacing={2}>
          <Grid item sm={6} testId="reviewCenterLocation" xs={12}>
            <Typography className="textTitle" variant="body1">
              {locale('registerService:centreLocation')}
            </Typography>
            <Typography className="textContent" variant="body1">
              {centerDetails?.title}
            </Typography>
            <Typography className="textContent" variant="body1">
              {generateAddress(centerDetails)}
            </Typography>
          </Grid>
        </Grid>
      </Div>
    </Div>
  );
};

export default ReviewProgrammeItem;
