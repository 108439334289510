import styled from 'styled-components';
import { pxToRem } from 'helpers/styles';
import { Button } from 'ui-kit';

export const DismissButton = styled(Button)`
  width: 50px;
  height: 50px;
  border-width: 0px;
  min-width: 50px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 999999;
`;

export const DialogWrapper = styled.div`
  margin-bottom: 20px;

  .customButton {
    font-size: ${pxToRem(12)};
  }
`;
