export const registerService = {
  registerForService: 'Register for Service',
  serviceSelection: 'Service Selection',
  yourDetails: 'Your Details',
  clientDetails: "Client's Details",
  emergencyContact: 'Emergency Contact',
  serviceSelectionGuide:
    'This form will take you about {{minMinutes}} to {{maxMinutes}} minutes to complete. All information that you provide will be kept strictly confidential in accordance with NTUC Health’s',
  personalDataProtectionPolicy: 'Personal Data Protection Policy',
  whoAreYouRegistering: 'Who are you registering the service for?',
  mySelf: 'Myself',
  onBehalfOfMyLoved: 'On behalf of my loved one',
  exitForm: 'Exit form',
  next: 'Next',
  selectPerson: 'Select Person',
  registerCategoryService: 'Which category of service would you like to register for?',
  howDidYouKnowAboutService: 'How did you know about this service?',
  selectCategoryService: 'Select category of service',
  selectOption: 'Select option',
  whichCategoryOfProgramme: 'Which category of programme would you like to register for?',
  learnMoreProgram: 'Learn more about the',
  healthDeclaration: 'Health Declaration',
  programmes: 'programmes',
  weOffer: 'we offer.',
  selectCategory: 'Select category',
  whichProgrammeRegister: 'Which programme(s) would you like to register for {{categoryName}}?',
  packages: 'packages',
  selectProgramme: 'Select programme',
  selectPreferredPackage: 'Select your preferred package for {{productName}}.',
  selectPackage: 'Select package',
  selectPreferredLocation: 'Select your preferred location for {{productName}}.',
  learnMoreAboutOur: 'Learn more about our',
  locations: 'locations',
  selectLocation: 'Select location',
  exitFormTitle: 'Are you sure you want to exit the form',
  exitFormMessage: 'Your changes will not be saved',
  exitNoButton: 'No, Return to form',
  exitYesButton: 'Yes, Exit form',
  welcomeDialogTitle: 'Welcome {{username}}!',
  welcomeDialogBody: 'Welcome to NTUC Health! You may proceed to register for service(s) with us.',
  reviewTitle: 'Please review the information below to ensure that all details are correct before submitting.',
  edit: 'Edit',
  reviewFooterConfirmMessage1:
    'I give consent to provide my personal data to NTUC Health and its affiliated organizations listed in',
  website: 'website',
  ntucHealthPolicy: "NTUC Health's Personal Data Protection Policy",
  reviewFooterConfirmMessage2:
    'All information that you provide will be kept strictly confidential in accordance with the policy.',
  agreePolicy: 'I consent to provide my personal data to NTUC Health and its affiliated organisations.',
  martketingConsent: 'I wish to receive information on promotions and discounts from NTUC Health and its affiliates',
  termsAndConditionsTitle: 'Terms & Conditions for Active Ageing',
  termsAndConditionsAgreeMessage: 'By proceeding, I agree that I have read and accepted the',
  cancellationTermsAndConditions: 'Cancellation Terms & Conditions.',
  termsAndConditions: 'Terms & Conditions',
  newTermsAndConditions: 'Terms and Conditions',
  yourAddress: 'Address',
  selectService: 'Select Services',
  addAnotherProgamme: 'Add another progamme',
  messageNOK:
    'We will only contact your emergency contact if an emergency happens. Please enter their preferred name, replationship to you and mobile number if you intend to include a emergency contact.',
  otherSource: 'Others',
  registeringFor: 'Registering for {{type}}',
  service: 'Service',
  programme: 'Programme',
  package: 'Package',
  centreLocation: 'Centre Location',
  preferredPackage: 'Preferred Package',
  profileDetails: 'Profile Details',
  lovedOneDetails: "Loved one's details",
  addEmergencyContact: 'Add emergency contact',
  packageType: 'Package Type',
  appropriateAddressText:
    'Please fill up the Billing Address. For Home Care service registration, Service Address is also required where the client will receive our service.',
  linkProfile: 'Link Profile',
  confirmExistingCustomer: 'Are you an existing customer NTUC Health?',
  shallBeDeemedToHaveAgreed:
    'Shall be deemed to have agreed to and accepted this agreement (this “Agreement”) which incorporates NTUC Health Co-operative Ltd’s Terms and Conditions',
  programmeTerms: 'Programme Terms and Conditions',
  and: 'and',
  generalTerms: 'General Terms and Conditions',
  onTheDate: 'on {{date}} (the “Commencement Date”): and',
  herebyConfirmThatAllInformation:
    'Hereby confirm that all information provided herein is true, complete and accurate as at the Commencement Date: and',
  herebyAuthoriseTheEmergencyContact:
    'Hereby authorise the emergency contact person(s) named above to give instructions and liaise with NTUC for and on my behalf in relation to the matters arising from or in connection with this Agreement.',
  emptyServiceSelectionMsg: 'No results found, Please check again later',
  footerReviewDenticareMessage:
    'Your personal data will be kept strictly confidential and provided to NTUC Health and its affiliated organisations in accordance to NTUC Health’s',
  registerServiceSuccessfullyTitle: 'Your Registration for service added successfully',
  agreePolicyDenticare1:
    'I hereby consent to provide my personal data to NTUC Health Co-operative Ltd and its affiliated organisations listed in the',
  agreePolicyDenticare2:
    'I wish to receive promotional information and updates from NTUC Health Co-operative Ltd and its affiliates.',
  billingSupportMessage: 'For billing related enquiries, please contact <green>{{contact}}</green>',
  contactMessage: '6590 4276 (Monday to Friday, 8.30am to 5.30pm).'
};
