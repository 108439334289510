import { createSelector } from '@reduxjs/toolkit';

import { KeyResource } from 'interface';
import { RootState } from 'redux/type';
import { ProductType } from 'ui-kit';

// MODEL
import { Service, ServiceByCategory, ServiceTerm, ServiceTabType } from 'model';

// SELECTOR
import { selectSession } from 'redux/sessions/selector';

export const selectServiceLoadingStatus = (state: RootState): boolean => state.serviceReducer.loading;
export const selectService = (state: RootState): Service | undefined => state.serviceReducer.single;
export const selectServiceType = (state: RootState): ProductType | undefined =>
  state.serviceReducer.selectedServiceType;
export const selectManyServices = (state: RootState): KeyResource<Service> => state.serviceReducer.many;
export const selectManyServicesWithStatus = (state: RootState): KeyResource<Service> =>
  state.serviceReducer.manyByStatus;
export const selectManyServicesByProductId = (state: RootState): KeyResource<Service> =>
  state.serviceReducer.manyByProductId;
export const selectServicesByCategory = (state: RootState): ServiceByCategory[] => state.serviceReducer.groupByCategory;
export const selectUpcomingServices = (state: RootState): string[] => state.serviceReducer.upComingServices;
export const selectOnGoingServices = (state: RootState): string[] => state.serviceReducer.onGoingServices;
export const selectCompletedServices = (state: RootState): string[] => state.serviceReducer.completedServices;
export const selectExpiredServices = (state: RootState): string[] => state.serviceReducer.expiredServices;
export const isInTakeService = (state: RootState): boolean => {
  if (!state.serviceReducer.single) return false;

  return state.serviceReducer.single.programContentType === 'INTAKES';
};
export const getSelectedTerm = (state: RootState): ServiceTerm | undefined => state.serviceReducer.selectedTerm;
export const isHealthConditionChanged = (state: RootState): boolean => state.serviceReducer.healthConditionChanged;

// CONDITION
// Stage is PRE_SESSION_BOOKING, start flow with pre-class booking
// Stage is SESSION_BOOKING, start flow with session booking
// Stage is PRE_SESSION_BOOKING_ON_HEALTH_CONDITION and The last Program is changed,
// start flow with pre-class booking
// Stage is PRE_SESSION_BOOKING_ON_HEALTH_CONDITION, and The last Program is not changed,
// start flow with session booking
// Stage is PRE_SESSION_BOOKING_MANDATORY_ON_HEALTH_CONDITION, we always start flow with pre-class booking
// Stage is PRE_SESSION_BOOKING_REMAINDER, we always start flow with pre-class booking
// Stage is BOOKING_COMPLETED, we always start flow with pre-class booking
// Stage is SESSION_BOOKING, selectedSession is Pre_session and intake product,
// we always start flow with pre-class booking
export const isSelectedTermForPreClass = (state: RootState): boolean => {
  const healConditionChanged = isHealthConditionChanged(state);
  const selectedTerm = getSelectedTerm(state);
  const selectedSession = selectSession(state);
  const selectedService = selectService(state);

  if (
    selectedService &&
    selectedTerm &&
    selectedSession &&
    selectedService.programContentType === 'INTAKES' &&
    selectedSession.type === 'PRE_SESSION' &&
    selectedTerm.stage === 'SESSION_BOOKING'
  )
    return true;

  if (
    selectedTerm &&
    (selectedTerm.stage === 'BOOKING_COMPLETED' ||
      selectedTerm.stage === 'PRE_SESSION_BOOKING' ||
      (selectedTerm.stage === 'PRE_SESSION_BOOKING_ON_HEALTH_CONDITION' && healConditionChanged) ||
      selectedTerm.stage === 'PRE_SESSION_BOOKING_MANDATORY_ON_HEALTH_CONDITION' ||
      selectedTerm.stage === 'PRE_SESSION_BOOKING_REMAINDER')
  )
    return true;

  return false;
};

export const isSelectedTermForPostSession = (state: RootState): boolean => {
  const selectedTerm = getSelectedTerm(state);

  if (selectedTerm && selectedTerm.stage === 'POST_SESSION_BOOKING') return true;

  return false;
};

export const selectIsAlreadySelectActiveAgeingService = createSelector(
  selectManyServices,
  (many: KeyResource<Service>): boolean => {
    const services = Object.keys(many).map((key) => many[key]);
    const findActiveAgeingService = services.find((item: Service) => item.categoryName === 'Active Ageing');

    return !!findActiveAgeingService;
  }
);

export const selectSelectedServiceTab = (state: RootState): ServiceTabType | undefined =>
  state.serviceReducer.selectedServiceTab;
