export const profile = {
  profile: 'Profile',
  myProfile: 'My Profile',
  addresses: 'Addresses',
  noBillingAddress: 'There is not any billing address',
  noContact: 'There is not any contact',
  linkAnotherAccount: 'Link your account',
  mapExistingProfile: 'Map existing profile ',
  removeProfile: 'Remove profile',
  confirmDeleteProfile: 'Are you sure you want to remove this profile?',
  removingProfileContent: 'You are removing {{userName}}. You will no longer have access to their information.',
  updateProfilePhoto: 'Update Phofile Photo',
  viewProfileOf: 'View profile of',
  personalDetails: 'Personal Details',
  nextOfKinDetails: 'Next of Kin Details',
  fullName: 'Full Name',
  clientFullName: "Client's Full Name",
  clientDetails: "Client's Details",
  dateOfBirth: 'Date of Birth',
  nationality: 'Nationality',
  otherNationality: 'Other Nationality',
  residentStatus: 'Residential Status',
  otherResidentStatus: 'Other Residential Status',
  nric: 'NRIC / Passport / FIN',
  gender: 'Gender',
  maritalStatus: 'Marital Status',
  languagesSpoken: 'Language(s) Spoken',
  otherLanguage: 'Other Language',
  religion: 'Religion',
  otherReligion: 'Other Religion',
  unionMember: 'Are you an NTUC Union member?',
  mobileNumber: 'Mobile Number',
  emailAddress: 'Email Address',
  homeNumber: 'Home Number',
  chasSubsidyTier: 'CHAS Subsidy Tier',
  chasSubsidyExpiryDate: 'CHAS Subsidy Expiry Date',
  companyName: 'Company Name',
  billingAddress: 'Billing Address',
  addressInNric: 'Address In NRIC',
  addAddress: 'Add Address',
  addAddressMessage: 'You may add another Address.',
  addNextOfKin: 'Add Next of Kin',
  preferredName: 'Preferred Name',
  relationshipToYou: 'Relationship to You',
  otherRelationship: 'Other Relationship',
  isThisContactThePayer: 'Is this contact the Payer',
  editNOK: 'Edit Next of Kin Details',
  editNokMessage:
    'We will only contact your next of kin if an emergency happens. Please enter their preferred name, relationship to you and mobile number if you intend to include a next of kin contact.',
  createNOK: 'Create Next of Kin Details',
  editPersonalDetail: 'Edit Personal Details',
  editClientDetails: "Edit Client's Details",
  addAnotherLanguage: 'Add Another Language',
  age: 'Age',
  changeProfilePicture: 'Change your profile picture',
  changePictureMessage:
    'Maximum file size for profile photos is 2MB. Check the dimensions. Recommended dimensions for profile images are 400x400 pixels.',
  returnToMyProfilePage: 'Return to my profile page',
  uploadPicture: 'Upload Picture',
  fileUploadSizeTooBig: `The file size is too large. Maximum file size is {{maxSizeAllowedInMB}}MB.`,
  fileUploadUnsupportedExtension: 'You can only upload with {{fileExtensionAllowed}} extensions.',
  addAddressDetails: 'Add Address Details',
  editAddressDetails: 'Edit Address Details',
  typeOfResidence: 'Type of residence',
  postalCode: 'Postal Code',
  block: 'Block',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  unit: 'Unit',
  level: 'Level',
  billing: 'Billing',
  residential: 'Residential',
  service: 'Service',
  confirmDeleteAddressTitle: 'Are you sure you want to delete the {{addressType}} address?',
  confirmDeleteContact: "Are you sure you want to delete {{contactName}}'s detail?",
  returnToMyAddressPage: 'Return to my address page',
  othersSpecify: 'Others (Please specify)',
  // PLACE HOLDER FOR ADDRESS
  placeholderDateOfBirth: 'dd/mm/yyyy',
  placeholderFullName: 'Enter full name',
  placeholderNationality: 'Select nationality',
  placeholderOtherNationality: 'Enter other nationality',
  placeholderResidentStatus: 'Select residential status',
  placeholderOtherResidentStatus: 'Enter other residential status',
  placeholderGender: 'Select gender',
  placeholderLanguage: 'Select language',
  placeholderPhoneNumber: 'E.g. 81234567',
  placeholderEmail: 'Enter email',
  placeholderNric: 'E.g. SXXXXXXXA',
  placeholderMaritalStatus: 'Select marital status',
  placeholderReligion: 'Select religion',
  placeholderAddressType: 'E.g. Billing',
  placeholderPostalCode: 'Enter Postal Code',
  placeholderAddressLine1: 'E.g. 42 Jurong East Street 23',
  placeholderAddressLine2: 'Enter building name',
  placeholerUnit: 'Enter unit',
  placeholerLevel: 'Enter level',
  placeholderBlock: 'Enter block',
  // PLACE HOLDER FOR NOK
  placeholderPreferredName: 'Enter your preferred name',
  placeholderRelationshiptoyou: 'Select relationship to you',
  addAnotherAddress: 'You may add another Addeess.',
  emergencyContact: 'Emergency Contact',
  // LINKING PROFILE
  linkAnotherNTUCAccount: 'Link NTUC Health Account',
  haveAClientIdNumber: 'Have a Client ID? ',
  pleaseEnterTheClientIDNumber: 'Please enter the Client ID sent through SMS, email or letter.',
  lostYourClientIdNumber: 'Lost your Client ID? Please Whatsapp to',
  checkOutOur: 'Check out our',
  callCentreOperatingHours: 'call centre operating hours.',
  linkAccount: 'Link your Account',
  linkProfileWith: 'Link profile with',
  careRecipientAccount: 'Your account has been linked successfully!',
  linkAccountSuccessTitle: 'You may view {{userName}}’s personal details at',
  orUpdateYourDetails: 'or update your details at',
  caregiverManagement: 'Caregiver Management.',
  linkingProfileDuplicate: 'The NRIC is already found in the system. Please link your profile',
  linkingProfileLovedOneAlreadyLinked: 'Loved one is already mapped under your profile',
  // LINKING RESEND CODE
  resendCode: {
    title: 'Enter your Details',
    selectOptionTitle: 'Select an option to receive your Client ID',
    continue: 'CONTINUE',
    resend: 'Re-send',
    resendDesc: `Haven't received an SMS/email? To try again, click`
  },
  // NEW ADDRESS FORM
  formAddressContent:
    "Share your address with us to stay informed about interesting activities that are happening near to you. All information that you provide will be kept strictly confidential in accordance with NTUC Health's",
  addBillingAddressDetails: 'Add Billing Address Details',
  addServiceAddressDetails: 'Add Service Address Details',
  addResidentialAddressDetails: 'Add Residential Address Details',
  editBillingAddressDetails: 'Edit Billing Address Details',
  editServiceAddressDetails: 'Edit Service Address Details',
  editResidentialAddressDetails: 'Edit Residential Address Details',
  msgServiceAddressCheckbox: 'Service Address is the same as Billing Address',
  msgResidentialAddressCheckbox: 'Residential Address is the same as Billing Address'
};
