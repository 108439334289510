import { isEmpty } from 'lodash';
import { RootState } from 'redux/type';
import { createSelector } from '@reduxjs/toolkit';

export const selectDenticareClientLoadingStatus = (state: RootState): boolean => state.denticareClientReducer.loading;

export const selectDenticareAssociationId = (state: RootState): string =>
  state.denticareClientReducer.denticareAssociationId ?? '';

export const selectDenticareClientId = (state: RootState): string =>
  state.denticareClientReducer.denticareClientId ?? '';

export const selectDenticareBlackListed = (state: RootState): boolean =>
  state.denticareClientReducer.blackListed ?? false;

export const selectIsAlreadyAssociatedDenticareClient = createSelector(
  selectDenticareAssociationId,
  (denticareAssociatioId: string) => !isEmpty(denticareAssociatioId) && denticareAssociatioId !== null
);

export const selectDenticareSessionId = (state: RootState): string => state.denticareClientReducer.denticareSessionId;
