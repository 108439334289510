import dayJs from 'dayjs';
import { LocalStorageKey, IDStorageWithTimeStamp } from 'model';
import basedIsJsonString from 'helpers/string/objectString';

export function setValue<T>(key: LocalStorageKey, value: T) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export function getValue<T>(key: LocalStorageKey): T | null {
  const value = window.localStorage.getItem(key);

  if (!value) return null;

  const isJsonString = basedIsJsonString(value);

  if (isJsonString) {
    return JSON.parse(value) as T;
  }

  return value as any;
}

export const isIdValid = (key: LocalStorageKey, timeValid: number): boolean => {
  const value = getValue<IDStorageWithTimeStamp>(key);

  if (!value) return false;

  const minutesFromNow = dayJs(new Date()).diff(new Date(value.timeStamp), 'minutes');

  if (minutesFromNow > timeValid) return false;

  return true;
};

export const removeKey = (key: LocalStorageKey) => window.localStorage.removeItem(key);
