export enum EInvoiceStatusCode {
  AlreadyPaid = 'E-INV-S002',
  LinkExpired = 'E-INV-S004'
}

export interface EInvoiceResponse {
  totalPayable: number;
  invoiceNumber: string;
  packageName: string;
  clientId: string;
  document: string;
  statusCode: EInvoiceStatusCode;
}

export interface EInvoiceState {
  loading: boolean;
  data?: EInvoiceResponse;
  showSomethingWentWrong?: boolean;
  linkExpired?: boolean;
  alreadyPaid?: boolean;
}
