const isEnabledProductCatalog = process.env.REACT_APP_ENABLED_PRODUCT_CATALOG === 'true';

/* eslint-disable no-unused-vars */

const RouterWithProductCatalog: { [key: string]: string } = {
  MyService: '/my-service',
  Home: '/',
  ServiceType: '/service-type',
  ServiceTypeDetail: '/service-type/:serviceType',
  ProductDetailWithoutId: '/product',
  ProductDetail: '/product/:id',
  ProductCatalogCartReview: '/cart-review',
  Profile: '/profile',
  RegisterService: '/my-service/register-service',
  BookPreClassAndInTake: '/my-service/book-preclass-intake',
  BookPreClassAndSessions: '/my-service/book-preclass-sessions',
  UserAppointmentsList: '/my-service/appointment-list',
  UserAppointmentsListWithTerm: '/my-service/appointment-list/:termId',
  AppointmentReschedule: '/my-service/appointment-reschedule',
  DentiCareReschedule: '/my-service/my-denticare/reschedule',
  DentiCareAppointmentBooking: '/my-service/my-denticare/schedule',
  AppointmentCancel: '/my-service/appointment-cancel',
  RenewService: '/my-service/renew',
  SessionCancel: '/my-service/session-cancel',
  Payment: '/payment',
  InvoiceList: '/invoice',
  InvoiceDetail: '/invoice/:id',
  PaymentCompletionWithoutId: '/payment-completion',
  PaymentCompletion: '/payment-completion/:id',
  LinkAccount: '/link-account',
  TermAndCondition: '/term-and-condition',
  NewTermAndCondition: '/term-condition',
  ProgramTermAndCondition: '/program-term-and-condition',
  GeneralTermAndCondition: '/general-term-and-condition',
  CardManagement: '/cards',
  CardManagementAddCard: '/cards/add',
  CardVerification: '/cards/verification',
  PaymentVerification: '/payment/verification',
  HealthDeclaration: '/health-declaration',
  MedicalHistory: '/medical-history',
  FAQ: '/faq',
  FAQWithCategory: '/faq/:category',
  FAQWithSlug: '/faq/:category/:slug',
  eGiro: '/egiro',
  eGiroLanding: '/egiro-landing',
  eGiroApply: '/egiro/apply',
  denticare: '/denticare',
  denticareBookAppointment: '/denticare/book-appointment',
  denticareBookAppointmentWithHash: '/denticare/appointment/:id',
  eInvoice: '/inv',
  eInvoiceWithId: '/inv/:id',
  eInvoicePageNotFound: '/inv/page-not-found',
  eInvoicePageSomethingWrong: '/inv/something-went-wrong',
  NotFoundPage: '/page-not-found'
};

/* eslint-disable no-unused-vars */
const RouterWithoutProductCatalog: { [key: string]: string } = {
  MyService: '/',
  Home: '/',
  Profile: '/profile',
  RegisterService: '/register-service',
  BookPreClassAndInTake: '/book-preclass-intake',
  BookPreClassAndSessions: '/book-preclass-sessions',
  UserAppointmentsList: '/appointment-list',
  UserAppointmentsListWithTerm: '/appointment-list/:termId',
  AppointmentReschedule: '/appointment-reschedule',
  AppointmentCancel: '/appointment-cancel',
  DentiCareReschedule: '/my-denticare/reschedule',
  DentiCareAppointmentBooking: '/my-denticare/schedule',
  RenewService: '/renew',
  SessionCancel: '/session-cancel',
  Payment: '/payment',
  InvoiceList: '/invoice',
  InvoiceDetail: '/invoice/:id',
  PaymentCompletionWithoutId: '/payment-completion',
  PaymentCompletion: '/payment-completion/:id',
  LinkAccount: '/link-account',
  TermAndCondition: '/term-and-condition',
  NewTermAndCondition: '/term-condition',
  ProgramTermAndCondition: '/program-term-and-condition',
  GeneralTermAndCondition: '/general-term-and-condition',
  CardManagement: '/cards',
  CardManagementAddCard: '/cards/add',
  CardVerification: '/cards/verification',
  PaymentVerification: '/payment/verification',
  HealthDeclaration: '/health-declaration',
  MedicalHistory: '/medical-history',
  FAQ: '/faq',
  FAQWithCategory: '/faq/:category',
  FAQWithSlug: '/faq/:category/:slug',
  eGiro: '/egiro',
  eGiroLanding: '/egiro-landing',
  eGiroApply: '/egiro/apply',
  denticare: '/denticare',
  denticareBookAppointment: '/denticare/book-appointment',
  denticarePageNotFoundError: '/denticare/page-not-found',
  denticareCommonError: '/denticare/something-went-wrong',
  denticareConfirmOrder: '/denticare/confirm-order',
  denticareRetainedOrder: '/denticare/retained-order',
  denticareHoldOn: '/denticare/hold-on',
  denticareExistingAppointmentFound: '/denticare/existing-appointment-found',
  denticareBookAppointmentWithHash: '/denticare/appointment/:id',
  eInvoice: '/inv',
  eInvoiceWithId: '/inv/:id',
  eInvoicePageNotFound: '/inv/page-not-found',
  eInvoicePageSomethingWrong: '/inv/something-went-wrong',
  NotFoundPage: '/page-not-found',
  appointment: '/appt',
  appointmentWithId: '/appt/:id',
  appointmentPageNotFound: '/appt/page-not-found',
  appointmentPageSomethingWrong: '/appt/something-went-wrong'
};

export const Router = isEnabledProductCatalog ? RouterWithProductCatalog : RouterWithoutProductCatalog;
