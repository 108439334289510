export const MockUIView = {
  create: 'create',
  cancel: 'cancel'
};

export const BankStatus = {
  ONLINE: 'online',
  OFFLINE: 'offline'
};

export const BankSegment = {
  CORPORATE: 'corporate',
  RETAIL: 'retail'
};

export const ApplyEGiroTypeValue = {
  MYSELF: 'my-self',
  DEPENDENT: 'dependent'
};
