import React, { FC } from 'react';
import { Grid, Div, TextField, DatePicker, Typography, Button } from 'ui-kit';
import { useTranslation } from 'react-i18next';
import { isEmpty, capitalize } from 'lodash';
import { useHistory } from 'react-router-dom';

import { isNRIC } from 'helpers/validations';

// CONSTANT
import { Router } from 'constant';
import { nationalities } from 'constant/data';

// PROPS
import { PersolnalDetailInputProps } from './interface';

export const PersonalDetailInputForm: FC<PersolnalDetailInputProps> = ({
  readonly,
  formik,
  hideCHAS,
  disableNRIC,
  isServiceRegScreen,
  disableFullName,
  nricExistingErrorMessage,
  onValidateNric
}) => {
  const { t: locale } = useTranslation(['profile', 'common', 'registerService']);
  const history = useHistory();

  const { values } = formik;

  const genderList = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' }
  ];

  const languageList = [
    { label: 'Cantonese', value: 'Cantonese' },
    { label: 'English', value: 'English' },
    { label: 'Hakka', value: 'Hakka' },
    { label: 'Hokkien', value: 'Hokkien' },
    { label: 'Mandarin', value: 'Mandarin' },
    { label: 'Malay', value: 'Malay' },
    { label: 'Punjabi', value: 'Punjabi' },
    { label: 'Tamil', value: 'Tamil' },
    { label: 'Teochew', value: 'Teochew' },
    { label: 'Others', value: 'Others' }
  ];

  const idTypeList = [
    { label: 'NRIC', value: 'NRIC' },
    { label: 'Passport', value: 'PASSPORT' },
    { label: 'FIN', value: 'FIN' }
  ];

  const maritalStatusList = [
    { label: 'Single', value: 'Single' },
    { label: 'Married', value: 'Married' },
    { label: 'Widowed', value: 'Widowed' },
    { label: 'Separated', value: 'Separated' },
    { label: 'Divorced', value: 'Divorced' }
  ];

  const religionList = [
    { label: 'Buddhism', value: 'Buddhism' },
    { label: 'Roman Catholic', value: 'Roman Catholic' },
    { label: 'Islam', value: 'Islam' },
    { label: 'Hinduism', value: 'Hinduism' },
    { label: 'Taoism', value: 'Taoism' },
    { label: 'Christianity', value: 'Christianity' },
    { label: 'Others', value: 'Others' }
  ];

  const citizenshipList = [
    { label: 'Long term special pass/ ICA letter', value: 'Long term special pass/ ICA letter' },
    { label: 'Singapore PR/ Blue IC', value: 'Singapore PR/ Blue IC' },
    { label: 'Singapore/ Pink IC', value: 'Singapore/ Pink IC' },
    { label: 'Others', value: 'Others' },
    { label: 'Not available', value: 'Not available' }
  ];

  const chasColorList = [
    { label: 'Blue', value: 'Blue' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Green', value: 'Green' }
  ];

  const yesNo: Item<Payer>[] = [
    { label: locale('common:yes'), value: 'yes' },
    { label: locale('common:no'), value: 'no' }
  ];

  const checkToValidateNric = (nric: string) => {
    if (onValidateNric) {
      let isValid = false;

      if (formik.values.idType === 'PASSPORT') {
        isValid = /[A-Z]{1}[0-9]{7}/.test(nric);
      } else {
        isValid = isNRIC(nric, formik.values.idType ? (formik.values.idType.toLowerCase() as any) : 'all');
      }

      if (isValid) {
        onValidateNric(nric);
      } else {
        onValidateNric(undefined);
      }
    }
  };

  const handleDeleteItem = (index: number) => {
    const currentValue = [...(values.language as string[])];

    currentValue.splice(index, 1);

    formik.setFieldValue('language', currentValue);
  };

  return (
    <Div>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            disabled={disableFullName}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.errors.name}
            label={locale('profile:clientFullName')}
            name="name"
            placeholder={locale('placeholderFullName')}
            readOnly={readonly}
            testId="nameDetailField"
            type="text"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DatePicker
                autoOk
                disableFuture
                fullWidth
                required
                autoComplete="off"
                error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                format="dd/MM/yyyy"
                helperText={formik.touched.dateOfBirth === undefined ? '' : formik.errors.dateOfBirth}
                inputProps={{ placeholder: locale('placeholderDateOfBirth') }}
                label={`${locale('dateOfBirth')}`}
                name="dateOfBirth"
                readOnly={readonly}
                testId="dateOfBirthDetailField"
                value={formik.values.dateOfBirth}
                variant="inline"
                onBlur={formik.handleBlur}
                onChange={(v) => {
                  if (!v) {
                    formik.setFieldValue('dateOfBirth', '');
                  } else {
                    formik.setFieldValue(
                      'dateOfBirth',
                      !v ? undefined : v?.toString() === 'Invalid Date' ? 'Invalid Date' : v?.toISOString()
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            error={formik.touched.gender && Boolean(formik.errors.gender)}
            helperText={formik.errors.gender}
            label={locale('gender')}
            name="gender"
            placeholder={locale('placeholderGender')}
            readOnly={readonly}
            selectItems={genderList}
            testId="genderDetailField"
            type="select"
            value={formik.values.gender}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            disabled={disableNRIC}
            dropDownValue={formik.values.idType}
            error={formik.touched.nric && Boolean(formik.errors.nric)}
            helperText={formik.errors.nric}
            label={locale('nric')}
            name="nric"
            placeholder={locale('placeholderNric')}
            readOnly={readonly}
            selectItems={idTypeList}
            testId="nricPassportDetailField"
            type="select-input"
            value={formik.values.nric}
            onBlur={formik.handleBlur}
            onChange={(v) => {
              checkToValidateNric(v.target.value);
              formik.handleChange(v);
            }}
            onSelectDropdownItem={(v) => {
              if (disableNRIC) return;

              formik.setFieldValue('idType', v);
            }}
          />

          {nricExistingErrorMessage && (
            <Div margin={{ top: 10 }}>
              <Typography className="errorMessage" variant="body1">
                * {nricExistingErrorMessage}
              </Typography>
              <Div margin={{ top: 10 }}>
                <Button variant="secondary" onClick={() => history.push(Router.LinkAccount)}>
                  {locale('registerService:linkProfile')}
                </Button>
              </Div>
            </Div>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            disabled={formik.values.email.length > 0}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.errors.email}
            label={locale('common:email')}
            name="email"
            placeholder={locale('placeholderEmail')}
            readOnly={readonly}
            testId="emailDetailField"
            type="text"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            error={formik.touched.homeNumber && Boolean(formik.errors.homeNumber)}
            helperText={formik.errors.homeNumber}
            label={`${locale('homeNumber')} `}
            name="homeNumber"
            placeholder={locale('placeholderPhoneNumber')}
            readOnly={readonly}
            type="phone"
            value={formik.values.homeNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
            helperText={formik.errors.mobileNumber}
            label={locale('mobileNumber')}
            name="mobileNumber"
            placeholder={locale('placeholderPhoneNumber')}
            readOnly={readonly}
            testId="mobileNumberDetailField"
            type="phone"
            value={formik.values.mobileNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        {!isServiceRegScreen && (
          <Grid item sm={6} xs={12}>
            <TextField
              clearAll
              fullWidth
              label={`${locale('maritalStatus')}`}
              name="maritalStatus"
              placeholder={locale('placeholderMaritalStatus')}
              readOnly={readonly}
              selectItems={maritalStatusList}
              type="select"
              value={formik.values.maritalStatus}
              onChange={formik.handleChange}
              onReset={() => formik.setFieldValue('maritalStatus', '')}
            />
          </Grid>
        )}
      </Grid>

      {!isServiceRegScreen && (
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              error={formik.touched.residentStatus && Boolean(formik.errors.residentStatus)}
              helperText={formik.errors.residentStatus}
              inputProps={{ placeholder: locale('placeholderResidentStatus') }}
              label={locale('residentStatus')}
              name="residentStatus"
              placeholder={locale('placeholderResidentStatus')}
              readOnly={readonly}
              selectItems={citizenshipList}
              testId="residentialStatusField"
              type="select"
              value={formik.values.residentStatus}
              onBlur={formik.handleBlur}
              onChange={(v) => {
                if (v.target.value === 'Singapore/ Pink IC') {
                  formik.setFieldValue('nationality', 'SG');
                }

                if (v.target.value !== 'Singapore/ Pink IC') {
                  formik.setFieldValue('nationality', '');
                }

                formik.handleChange(v);
              }}
            />
          </Grid>
          {formik.values.residentStatus === 'Others' && (
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                error={formik.touched.otherResidentStatus && Boolean(formik.errors.otherResidentStatus)}
                helperText={formik.errors.otherResidentStatus}
                label={`${locale('otherResidentStatus')}`}
                name="otherResidentStatus"
                placeholder={locale('placeholderOtherResidentStatus')}
                readOnly={readonly}
                type="text"
                value={formik.values.otherResidentStatus}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
          )}
        </Grid>
      )}

      {!isServiceRegScreen && (
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              error={formik.touched.nationality && Boolean(formik.errors.nationality)}
              helperText={formik.errors.nationality}
              inputProps={{ placeholder: locale('placeholderNationality') }}
              label={locale('nationality')}
              name="nationality"
              placeholder={locale('placeholderNationality')}
              readOnly={readonly || formik.values.residentStatus === 'Singapore/ Pink IC'}
              selectItems={nationalities.map((v) => ({ ...v, label: capitalize(v.label) }))}
              testId="nationalityDetailField"
              type="select"
              value={formik.values.nationality}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            multiple
            required
            actionButtonTitle="Ok"
            error={formik.touched.language && Boolean(formik.errors.language)}
            helperText={formik.errors.language}
            label={`${locale('languagesSpoken')}`}
            name="language"
            // placeholder={locale('placeholderLanguage')}
            readOnly={readonly}
            selectItems={languageList}
            testId="languageDetailField"
            type="select"
            value={formik.values.language}
            onDeleteItem={(v) => handleDeleteItem(v)}
            onSelectItem={(v) => formik.setFieldValue('language', v)}
          />
        </Grid>

        {(formik.values.language as string[]).includes('Others') && (
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              required
              error={formik.touched.otherLanguage && Boolean(formik.errors.otherLanguage)}
              helperText={formik.errors.otherLanguage}
              label={`${locale('otherLanguage')}`}
              name="otherLanguage"
              placeholder={locale('placeholderLanguage')}
              readOnly={readonly}
              type="text"
              value={formik.values.otherLanguage}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        {!isServiceRegScreen && (
          <>
            <Grid item sm={6} xs={12}>
              <TextField
                clearAll
                fullWidth
                label={`${locale('religion')}`}
                name="religion"
                placeholder={locale('placeholderReligion')}
                readOnly={readonly}
                selectItems={religionList}
                type="select"
                value={formik.values.religion}
                onChange={formik.handleChange}
                onReset={() => formik.setFieldValue('religion', '')}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              {formik.values.religion === 'Others' && (
                <TextField
                  fullWidth
                  error={formik.touched.otherReligion && Boolean(formik.errors.otherReligion)}
                  helperText={formik.errors.otherReligion}
                  label={`${locale('otherReligion')}`}
                  name="otherReligion"
                  placeholder={locale('placeholderReligion')}
                  readOnly={readonly}
                  type="text"
                  value={formik.values.otherReligion}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              )}
            </Grid>
          </>
        )}
        <Grid item sm={6} testId="union-member-selection" xs={12}>
          <TextField
            fullWidth
            required
            error={formik.touched.unionMember && Boolean(formik.errors.unionMember)}
            helperText={formik.errors.unionMember}
            label={`${locale('unionMember')}`}
            name="unionMember"
            readOnly={readonly}
            selectItems={yesNo}
            testId="unionMemberDetailField"
            type="select"
            value={formik.values.unionMember ? 'yes' : 'no'}
            onBlur={formik.handleBlur}
            onChange={(v) => formik.setFieldValue('unionMember', v.target.value === 'yes')}
          />
        </Grid>
      </Grid>

      {!hideCHAS && (
        <>
          <Div backgroundColor="grey" className="divider" />
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <TextField
                clearAll
                fullWidth
                label={`${locale('chasSubsidyTier')}`}
                name="chasSubsidyTier"
                placeholder={locale('chasSubsidyTier')}
                readOnly={readonly}
                selectItems={chasColorList}
                type="select"
                value={formik.values.chasSubsidyTier}
                onChange={formik.handleChange}
                onReset={() => formik.setFieldValue('chasSubsidyTier', '')}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <DatePicker
                autoOk
                clearAll
                fullWidth
                autoComplete="off"
                disabled={isEmpty(formik.values.chasSubsidyTier)}
                error={formik.touched.chasExpiryDate && Boolean(formik.errors.chasExpiryDate)}
                format="dd/MM/yyyy"
                helperText={formik.touched.chasExpiryDate === undefined ? '' : formik.errors.chasExpiryDate}
                inputProps={{ placeholder: locale('placeholderDateOfBirth') }}
                label={`${locale('chasSubsidyExpiryDate')} ${isEmpty(formik.values.chasSubsidyTier) ? `` : ''}`}
                name="chasExpiryDate"
                readOnly={readonly}
                required={!isEmpty(formik.values.chasSubsidyTier)}
                value={formik.values.chasExpiryDate}
                onChange={(v) => {
                  if (!v) {
                    formik.setFieldValue('chasExpiryDate', '');
                  } else {
                    formik.setFieldValue(
                      'chasExpiryDate',
                      !v ? undefined : v?.toString() === 'Invalid Date' ? 'Invalid Date' : v?.toISOString()
                    );
                  }
                }}
                onReset={() => formik.setFieldValue('chasExpiryDate', '')}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Div>
  );
};
