import { FAQCategory, FAQ } from 'model';

import cat211 from 'assets/commonFAQ/cat2-1-1.jpg';
import cat212 from 'assets/commonFAQ/cat2-1-2.jpg';
import cat213 from 'assets/commonFAQ/cat2-1-3.jpg';
import cat221 from 'assets/commonFAQ/cat2-2-1.jpg';
import cat222 from 'assets/commonFAQ/cat2-2-2.jpg';
import cat223 from 'assets/commonFAQ/cat2-2-3.jpg';
import cat23 from 'assets/commonFAQ/cat2-3.jpg';
import cat32 from 'assets/commonFAQ/cat3-2.jpg';
import cat33 from 'assets/commonFAQ/cat3-3.jpg';
import cat34 from 'assets/commonFAQ/cat3-4.jpg';
import cat35 from 'assets/commonFAQ/cat3-5.jpg';
import cat41 from 'assets/commonFAQ/cat4-1.jpg';
import cat42 from 'assets/commonFAQ/cat4-2.jpg';
import cat43 from 'assets/commonFAQ/cat4-3.jpg';
import cat44 from 'assets/commonFAQ/cat-4-4.png';
import cat45 from 'assets/commonFAQ/cat-4-5.png';
import cat57 from 'assets/commonFAQ/cat-5-7.png';
import cat55 from 'assets/commonFAQ/cat-5-5.jpg';
import cat54 from 'assets/commonFAQ/cat-5-4.png';
import cat53 from 'assets/commonFAQ/cat5-3-updated.png';
import cat52 from 'assets/commonFAQ/cat-5-2.png';
import cat56 from 'assets/commonFAQ/cat5-6.jpg';
import cat73 from 'assets/commonFAQ/cat7-3.jpg';
import cat74 from 'assets/commonFAQ/cat7-4.jpg';
import cat75 from 'assets/commonFAQ/cat7-5.jpg';

const commonCategories: FAQCategory[] = [
  {
    id: 1,
    title: 'What are the main features for registering an account?',
    slug: 'what-is-ntuc-and-features',
    type: 'COMMON'
  },
  {
    id: 2,
    title: 'How do I log in to my account?',
    slug: 'how-to-login',
    type: 'COMMON'
  },
  {
    id: 3,
    slug: 'how-to-register-profile',
    title: 'How do  I register my profile?',
    type: 'COMMON'
  },
  {
    id: 4,
    slug: 'how-to-reset-password',
    title: 'How do I reset my password?',
    type: 'COMMON'
  },
  {
    id: 5,
    slug: 'how-to-get-client-id',
    title: 'How do I get my Client ID if I did not receive it or if I lost it?',
    type: 'COMMON'
  },
  {
    id: 6,
    slug: 'how-to-link-my-accounts',
    title: 'If I am an existing NTUC Health client, how do I link my records with my account?',
    type: 'COMMON'
  },
  {
    id: 7,
    slug: 'how-to-update-personal-details',
    title: 'How do I update my personal details?',
    type: 'COMMON'
  },
  {
    id: 8,
    slug: 'how-to-deal-with-error',
    title: 'What do I do if an error is encountered in the website?',
    type: 'COMMON'
  }
];
const commonQuestions: FAQ[] = [
  {
    categortId: 1,
    title:
      'You can register for an account to book, manage and make online payment for your appointments for NTUC Health services.',
    content: 'Some features include:',
    type: 'COMMON',
    listContent: [
      // {
      //   id: 1,
      //   title: 'A. Registering for Active Ageing and Denticare services (more to come in future)',
      //   content: '',
      //   imgUrl: '',
      //   step: 1
      // },
      {
        id: 2,
        title: 'A. Managing your appointments (e.g. booking, viewing, rescheduling and cancelling)',
        content: '',
        imgUrl: '',
        step: 2
      },
      {
        id: 3,
        title: 'B. Making online payment for your appointments (for certain services)',
        content: '',
        imgUrl: '',
        step: 3
      },
      {
        id: 4,
        title: 'C. Submitting or updating your health status and conditions',
        content: '',
        imgUrl: '',
        step: 4
      }
    ]
  },
  {
    categortId: 2,
    title: 'Log in using your registered mobile number:',
    type: 'COMMON',
    contentStep: [
      {
        id: 1,
        title:
          'Log onto https://my.ntuchealth.sg and enter the registered mobile number (provided it is the same mobile number when you registered previously).',
        content: '',
        imgUrl: cat211,
        step: 1
      },
      {
        id: 2,
        title:
          'A 6-digit OTP will be sent to your registered mobile number. Enter the OTP and click the ‘Verify’ button.',
        content: '',
        imgUrl: cat212,
        step: 2
      },
      {
        id: 3,
        title:
          'The landing page will display two options - for new services registration and finding your existing appointment records.',
        content:
          'If you are an existing NTUC Health client,  you can immediately link your records by clicking the ‘LINK ACCOUNT’ button.',
        imgUrl: cat213,
        step: 2
      }
    ]
  },
  {
    categortId: 2,
    title: 'Log onto https://my.ntuchealth.sg using registered email address:',
    type: 'COMMON',
    contentStep: [
      {
        id: 1,
        title:
          'Log onto https://my.ntuchealth.sg and enter the registered email address (provided it is the same email address  when you registered previously).',
        content: '',
        imgUrl: cat221,
        step: 1
      },
      {
        id: 2,
        title: 'Enter the correct password and click the ‘Log in’ button.',
        content: '',
        imgUrl: cat222,
        step: 2
      },
      {
        id: 3,
        title:
          'The landing page will display two options - for new services registration and finding your existing appointment records.',
        content:
          'If you are an existing NTUC Health client,  you can immediately link your records by clicking the ‘LINK ACCOUNT’ button.',
        imgUrl: cat223,
        step: 3
      }
    ]
  },
  {
    categortId: 2,
    title: 'Alternative methods to log in to your account',
    content: 'Below are the alternative methods to log in to your account',
    type: 'COMMON',
    listContent: [
      {
        id: 1,
        title: 'A. Continue with Apple',
        content: '',
        imgUrl: '',
        step: 1
      },
      {
        id: 2,
        title: 'B. Continue with Google',
        content: '',
        imgUrl: '',
        step: 2
      },
      {
        id: 3,
        title: 'C. Continue with Singpass',
        imgUrl: cat23,
        content:
          'If you are an existing NTUC Health client,  you can immediately link your records by clicking the ‘LINK ACCOUNT’ button.',
        step: 3
      }
    ]
  },
  {
    categortId: 3,
    title: '',
    content: '',
    type: 'COMMON',
    contentStep: [
      {
        id: 1,
        title: 'Log onto https://my.ntuchealth.sg',
        content: '',
        imgUrl: '',
        step: 1
      },
      {
        id: 2,
        title: 'Enter your registered mobile number  and click the ‘Next’ button.',
        content: '',
        imgUrl: cat32,
        step: 2
      },
      {
        id: 3,
        title:
          'A 6-digit OTP will be sent to your registered mobile number. Enter the OTP and click the ‘Verify’ button.',
        content: '',
        imgUrl: cat33,
        step: 3
      },
      {
        id: 4,
        title: 'Click on ‘Create an account’ and provide the name and email address.',
        content: '',
        imgUrl: cat34,
        step: 4
      },
      {
        id: 5,
        title:
          'Upon clicking ‘Submit’, thelanding page will display two options - for new services registration and finding your existing appointment records.',
        content:
          'Note: Similarly, you can also create an account using other options like email, Google, Apple or Singpass accounts.',
        imgUrl: cat35,
        step: 5
      }
    ]
  },
  {
    categortId: 4,
    title: '',
    content: '',
    type: 'COMMON',
    contentStep: [
      {
        id: 1,
        title: 'Enter your registered email address and click the ‘Next’ button.',
        content: '',
        imgUrl: cat41,
        step: 1
      },
      {
        id: 2,
        title: 'Click the ‘Forgot password’ button.',
        content: '',
        imgUrl: cat42,
        step: 2
      },
      {
        id: 3,
        title: 'Enter your email address and click the ‘Send reset link’ button.',
        content: '',
        imgUrl: cat43,
        step: 3
      },
      {
        id: 4,
        title: 'An email will be sent to the email address with instructions on how to reset your password.',
        content: '',
        imgUrl: cat44,
        step: 4
      },
      {
        id: 5,
        title: 'Provide a new password based on the password requirements and click the ‘Reset password’ button.',
        content: '',
        imgUrl: cat45,
        step: 5
      }
    ]
  },
  {
    categortId: 5,
    title: '',
    content: '',
    type: 'COMMON',
    contentStep: [
      {
        id: 1,
        title: 'Log into your account successfully on https://my.ntuchealth.sg . Click the ‘LINK ACCOUNT’ button.',
        content: '',
        imgUrl: cat223,
        step: 1
      },
      {
        id: 2,
        title: 'Click the ‘Re-send’ button.',
        content: '',
        imgUrl: cat52,
        step: 2
      },
      {
        id: 3,
        title:
          'Enter NRIC/ Passport/ FIN associated with your account and choose Email Address or Mobile Number to receive your Client ID.',
        content: '',
        imgUrl: cat53,
        step: 3
      },
      {
        id: 4,
        title:
          'Click the ‘CONTINUE’ button and the Client ID will be sent to you based on the medium chosen (email address or mobile number).',
        content: '',
        imgUrl: cat54,
        step: 4
      },
      {
        id: 5,
        title: 'A sample SMS will look like the one below.',
        content: '',
        imgUrl: cat55,
        step: 5
      },
      {
        id: 6,
        title: 'Enter the received Client ID and your NRIC.',
        content: '',
        imgUrl: cat56,
        step: 6
      },
      {
        id: 7,
        title:
          'Click the ‘LINK YOUR ACCOUNT’ button and your account will be successfully linked to your existing records.',
        content: '',
        imgUrl: cat57,
        step: 7
      }
    ]
  },
  {
    categortId: 6,
    title: '',
    content: '',
    type: 'COMMON',
    contentStep: [
      {
        id: 1,
        title: 'Log into your account successfully on https://my.ntuchealth.sg.',
        content: '',
        imgUrl: cat223,
        step: 1
      },
      {
        id: 2,
        title:
          'Click the ‘LINK ACCOUNT’ button on the welcome page and enter the Client ID shared with you via SMS/ email/ letter. ',
        content:
          'Note: If you did not receive or lost your Client ID, please refer to How do I get my Client ID if I did not receive it or if I lost it?',
        imgUrl: cat56,
        step: 2
      },
      {
        id: 3,
        title:
          'Click the ‘LINK YOUR ACCOUNT’ button and your account will be successfully linked to your existing records.',
        content: '',
        imgUrl: cat57,
        step: 3
      }
    ]
  },
  {
    categortId: 7,
    title: '',
    content: '',
    type: 'COMMON',
    contentStep: [
      {
        id: 1,
        title: 'Log into your account successfully on https://my.ntuchealth.sg.',
        content: '',
        imgUrl: cat223,
        step: 1
      },
      {
        id: 2,
        title: ' Click on ‘MY PROFILE’ from the drop-down menu.',
        content: '',
        imgUrl: '',
        step: 2
      },
      {
        id: 3,
        title: 'Click the ‘EDIT’ button.',
        content: '',
        imgUrl: cat73,
        step: 3
      },
      {
        id: 4,
        title:
          'Update the details required (please note that fields like Full Name, Email and NRIC are disabled as these cannot be changed).',
        content: '',
        imgUrl: cat74,
        step: 4
      },
      {
        id: 5,
        title: 'Click the ‘SAVE’ button and your details will be saved successfully.',
        content: '',
        imgUrl: cat75,
        step: 5
      }
    ]
  },
  {
    categortId: 8,
    title: 'You can reach out to support@ntuchealth.sg if you encounter an error with your account.',
    content: '',
    type: 'COMMON',
    listContent: [
      {
        id: 1,
        title: '',
        content: '',
        imgUrl: cat223,
        step: 1
      }
    ]
  }
];

export { commonCategories, commonQuestions };
