import styled from 'styled-components';

export const EmergencyContactFormWrapper = styled.div`
  .divider {
    height: 1px;
  }

  .editContainer {
    margin-top: 20px;
  }
`;
