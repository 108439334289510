import { baseUrl as serviceBaseUrl } from './service';

const baseUrl = 'yoda-sessions';

export const ApiSessions = {
  YodaSessions: baseUrl,
  AllSessions: `${serviceBaseUrl}/sessions`,
  VacantSlots: `${baseUrl}/vacant-slots`,
  ScheduleSessions: `${baseUrl}/schedule`,
  RescheduleSessions: `${baseUrl}/reschedule`,
  CancelSession: `${baseUrl}/cancel`,
  ReviewSession: `${baseUrl}/review`
};
