import React, { FC, ReactNode } from 'react';
import { Div, Grid } from 'ui-kit';
import { Box } from '@material-ui/core';
import LogoImg from 'assets/denticare/ntuc-health.svg';
import Footer from 'components/Footer';

import LandingPageLayout from 'components/LandingPageLayout';
import { ErrorScreenWrapper, MainErrorWrapper } from './styles';

const ErrorScreenDesktopLayout: FC<{
  children: ReactNode;
  isGradientBackground?: boolean;
  backgroundColor?: string;
}> = ({ children, isGradientBackground, backgroundColor = '#fff' }) => (
  <LandingPageLayout isGradientBackground={isGradientBackground}>
    <ErrorScreenWrapper>
      <Grid container>
        <Box
          className=""
          style={{
            width: '100%',
            background: backgroundColor,
            borderRadius: '20px',
            marginTop: '30px'
          }}
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <Div className="dc-content-body">{children}</Div>
        </Box>
      </Grid>
    </ErrorScreenWrapper>
  </LandingPageLayout>
);

const ErrorMobileLayout: FC<{ children: ReactNode; isGradientBackground?: boolean }> = ({
  children,
  isGradientBackground
}) => {
  if (isGradientBackground) {
    return (
      <>
        <Grid container className="err-nav">
          <Box alignItems="center" display="flex" style={{ padding: '16px 0' }}>
            <img src={LogoImg} />
          </Box>
        </Grid>
        <Grid
          container
          style={{
            padding: '24px 16px',
            flex: 1,
            backgroundColor: 'rgb(98,210,213)',
            background: 'linear-gradient(180deg, rgba(98,210,213,0.4) 0%, rgba(255,255,255,1) 100%)'
          }}
        >
          <Grid container>
            <Box
              className=""
              style={{ width: '100%' }}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
              {children}
            </Box>
          </Grid>
        </Grid>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Grid container className="err-nav">
        <Box alignItems="center" display="flex" style={{ padding: '16px 0' }}>
          <img src={LogoImg} />
        </Box>
      </Grid>
      <Grid container style={{ padding: '24px 16px', flex: 1 }}>
        <Grid container>
          <Box
            className=""
            style={{ width: '100%' }}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

const ErrorScreenLayout: FC<{
  children: ReactNode;
  isGradientBackground?: boolean;
  backgroundColor?: string;
}> = ({ children, isGradientBackground, backgroundColor }) => (
  <MainErrorWrapper>
    <Div className="desktop-layout" display="flex" flex={1}>
      <ErrorScreenDesktopLayout backgroundColor={backgroundColor} isGradientBackground={isGradientBackground}>
        {children}
      </ErrorScreenDesktopLayout>
    </Div>
    <Div className="mobile-layout" display="flex" flex={1}>
      <ErrorMobileLayout isGradientBackground={isGradientBackground}>{children}</ErrorMobileLayout>
    </Div>
  </MainErrorWrapper>
);

export default ErrorScreenLayout;
