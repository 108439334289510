import React, { FC } from 'react';
import { Div, Button, Typography } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// STYLE
import { PersonalDetailsWrapper } from '../styles';

// PROPS
import { PersonalDetailFormProps } from './interface';

// COMPONENTS
import { PersonalDetailFormik } from './PersonalDetailFormik';

export const PersonalDetailForm: FC<PersonalDetailFormProps> = ({ onCancel, onSubmit, data, readonly }) => {
  const { t: locale } = useTranslation(['profile', 'common']);

  return (
    <PersonalDetailFormik data={data} readonly={readonly} onSubmit={onSubmit}>
      {(formik, formInput) => (
        <PersonalDetailsWrapper>
          <Typography testId="editPersonalDetail" variant="h3">
            {locale('profile:editClientDetails')}
          </Typography>
          <Div className="editContainer">
            {formInput}
            <Div className="footer">
              <Button
                className="cancelButton"
                disabled={formik.isSubmitting}
                testId="cancelButton"
                variant="secondary"
                onClick={onCancel}
              >
                {locale('common:cancel')}
              </Button>
              <Button
                className="saveButton"
                loading={formik.isSubmitting}
                testId="saveButton"
                type="submit"
                variant="primary"
              >
                {locale('common:save')}
              </Button>
            </Div>
          </Div>
        </PersonalDetailsWrapper>
      )}
    </PersonalDetailFormik>
  );
};
