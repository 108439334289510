/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Div, Typography } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// MODEL
import { User } from 'model';

// CONSTANT
import { LINKS } from 'constant';

// COMPONENTS
import { PersonalDetailInputForm } from 'views/PersonalDetail';

// STYLE
import { YourDetailsFormWrapper } from 'views/RegisterService/YourDetails/form/styles';
import { RegisterServiceContainer } from 'page/RegisterService/styles';

// DATA
import { user } from './data';

const YourDetail: FC = () => {
  const { t: locale } = useTranslation(['registerService', 'profile']);

  const formik = useFormik<User>({
    initialValues: {
      ...user
    },
    onSubmit: () => {}
  });

  return (
    <RegisterServiceContainer>
      <form>
        <YourDetailsFormWrapper>
          <Typography className="title" testId="yourDetails" variant="h2">
            2. {locale('registerService:clientDetails')}
          </Typography>
          <Typography className="guideText" testId="serviceSelectionGuide" variant="body1">
            {locale('registerService:serviceSelectionGuide')}{' '}
            <a href={`${LINKS.PDPA}`} target="_blank">
              <b className="linkText">{locale('registerService:personalDataProtectionPolicy')}</b>
            </a>
          </Typography>

          <Div className="editContainer" testId="detail-form">
            <PersonalDetailInputForm disableFullName disableNRIC hideCHAS isServiceRegScreen readonly formik={formik} />
          </Div>
        </YourDetailsFormWrapper>
      </form>
    </RegisterServiceContainer>
  );
};

export default YourDetail;
