import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { InTakeState } from 'model';

// REDUCER ACTION
import { changeInTakeLoading, setInTake, setManyInTakes } from './reducers';

export const initialState: InTakeState = {
  single: undefined,
  many: [],
  loading: false
};

export const inTakeSlice = createSlice({
  name: Domain.InTake,
  initialState,
  reducers: {
    changeInTakeLoading,
    setInTake,
    setManyInTakes
  }
});

export default inTakeSlice.reducer;
