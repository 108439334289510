import React, { FC } from 'react';
import { Grid, Div, Button, Typography } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// STYLE
import { NextOfKinDetailsWrapper } from '../styles';

// PROPS
import { NextOfKinsFormProps } from './interface';

// BASE FORMIK
import { NextOfKinsFormik } from './NextOfKinsFormik';

export const NextOfKinForm: FC<NextOfKinsFormProps> = ({ readOnly, data, onSubmit, onCancel, title }) => {
  const { t: locale } = useTranslation(['profile', 'common']);

  return (
    <NextOfKinsFormik data={data} readOnly={readOnly} onSubmit={onSubmit}>
      {(formik, mainInput) => (
        <NextOfKinDetailsWrapper data-testid="nok-contaniner">
          <Div padding={{ all: 20 }}>
            <Div className="header">
              <Typography variant="h3">{title}</Typography>
            </Div>
            <Div className="content">{mainInput}</Div>
            <Div margin={{ top: 20 }}>
              <Grid container spacing={3}>
                <Grid item>
                  <Button disabled={formik.isSubmitting} testId="nokCancelBtn" variant="secondary" onClick={onCancel}>
                    {locale('common:cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button loading={formik.isSubmitting} testId="nokSaveBtn" type="submit" variant="primary">
                    {locale('common:save')}
                  </Button>
                </Grid>
              </Grid>
            </Div>
          </Div>
        </NextOfKinDetailsWrapper>
      )}
    </NextOfKinsFormik>
  );
};
