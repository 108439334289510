import { ProfileLinkingStatus } from 'model';

// MOCK DATA
export const user = {
  id: '7c9dcc94-19b7-4a93-bf9c-4db5b5cf825a',
  idType: 'PASSPORT',
  nric: 'B773434234',
  name: 'Nhat',
  countryCode: '65',
  mobileNumber: '84559343',
  homeNumber: '',
  email: 'testing_denticare_2@gmail.com',
  dateOfBirth: 1654469880000,
  maritalStatus: '',
  gender: 'Male',
  language: ['Cantonese'],
  otherLanguage: '',
  religion: '',
  otherReligion: '',
  companyName: '',
  nationality: '',
  residentStatus: 'Long term special pass/ ICA letter',
  otherResidentStatus: '',
  chasSubsidyTier: '',
  chasExpiryDate: '',
  marketingConsent: true,
  unionMember: false,
  profileLinkingStatus: 'NOT_APPLICABLE' as ProfileLinkingStatus
};
