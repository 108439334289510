import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft as ArrowLeftIcon } from '@material-ui/icons';

// UIKit
import { Div, Button, isFromMdScreen, Grid } from 'ui-kit';
import DialogExit from 'components/DialogExit';

// STYLE
import { FormNavWrapperFromMdScreen, FormNavWrapperFromXSScreen } from './style';

// PROPS
import { NavigateButtonProps } from './interface';

export const FormNavButton: FC<NavigateButtonProps> = ({
  isShowingBackButton,
  isDisableNextButton,
  isDisableBackButton,
  isDisableExitButton,
  isLoading,
  onExit,
  onNext,
  onBack,
  body,
  submitText,
  closeText,
  title,
  onCancel: onCancelProps,
  onSubmit: onSubmitProps,
  nextTitle,
  nextIcon,
  isEnabledPromp
}) => {
  const { t: locale } = useTranslation(['registerService']);
  const fromMdScreen = isFromMdScreen();

  const onCancel = () => {
    if (onCancelProps) {
      onCancelProps();
    }
  };

  if (fromMdScreen) {
    return (
      <FormNavWrapperFromMdScreen>
        <Div alignItems="center" display="flex" flexDirection="row" justifyContent="center" margin={{ top: 100 }}>
          <Button
            withoutBorder
            className="exitButton"
            disabled={isDisableExitButton}
            variant="secondary"
            onClick={() => onExit && onExit()}
          >
            {locale('registerService:exitForm')}
          </Button>

          <Div className="divider" />

          {isShowingBackButton && (
            <Button
              className="nextButton"
              disabled={isDisableBackButton}
              startIcon={<ArrowLeftIcon />}
              variant="secondary"
              onClick={onBack}
            >
              {locale('registerService:back')}
            </Button>
          )}

          <Button
            className="nextButton"
            disabled={isDisableNextButton}
            endIcon={nextIcon}
            loading={isLoading && !isDisableNextButton}
            onClick={onNext}
          >
            {nextTitle}
          </Button>

          <DialogExit
            body={body}
            closeText={closeText}
            isEnabledPromp={isEnabledPromp}
            submitText={submitText}
            title={title}
            onCancel={onCancel}
            onSubmit={() => {
              if (onSubmitProps) onSubmitProps();
            }}
          />
        </Div>
      </FormNavWrapperFromMdScreen>
    );
  }

  return (
    <FormNavWrapperFromXSScreen>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            fullWidth
            className="nextButton"
            disabled={isDisableNextButton}
            endIcon={nextIcon}
            loading={isLoading && !isDisableNextButton}
            onClick={onNext}
          >
            {nextTitle}
          </Button>
        </Grid>

        {isShowingBackButton && (
          <Grid item xs={12}>
            <Button
              fullWidth
              className="nextButton"
              disabled={isDisableBackButton}
              startIcon={<ArrowLeftIcon />}
              variant="secondary"
              onClick={onBack}
            >
              {locale('registerService:back')}
            </Button>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            fullWidth
            withoutBorder
            className="exitButton"
            disabled={isDisableExitButton}
            variant="secondary"
            onClick={() => onExit && onExit()}
          >
            {locale('registerService:exitForm')}
          </Button>
        </Grid>
      </Grid>
      <DialogExit
        body={body}
        closeText={closeText}
        isEnabledPromp={isEnabledPromp}
        submitText={submitText}
        title={title}
        onCancel={onCancel}
        onSubmit={() => {
          if (onSubmitProps) onSubmitProps();
        }}
      />
    </FormNavWrapperFromXSScreen>
  );
};
