export const emergencyContact = {
  id: 'e5e5ccd6-d490-43a1-b976-7aa36d5c96ad',
  name: 'EmergencyContact',
  relationship: 'Spouse',
  otherRelationship: '',
  mobileNumber: '84545235',
  homeNumber: '',
  email: '',
  payer: false,
  emergency: true
};
