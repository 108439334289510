import React, { FC, ReactNode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import denticareTheme from 'config/denticare';
import { pdfjs } from 'react-pdf';

// STYLES
import { EInvoiceWrapper } from './styles';

interface AppContainerProps {
  children: ReactNode;
}
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const AppContainer: FC<AppContainerProps> = ({ children }) => (
  <EInvoiceWrapper>
    <ChakraProvider theme={denticareTheme}>{children}</ChakraProvider>
  </EInvoiceWrapper>
);

export default AppContainer;
