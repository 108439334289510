// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// MODEL
import { Product } from 'model';

// CONSTANT
import { ApiProduct } from 'constant';

// ACTIONS
import { changeAppLoading } from 'redux/app/actions';

// TYPE
import { Callback } from 'interface';

const getProductDetails =
  (id: string, callback?: Callback<Product>): AppThunk =>
  async (dispatch) => {
    dispatch(changeAppLoading(true));

    dispatch(
      httpAction<unknown, unknown, Product>(
        {
          method: 'GET',
          url: ApiProduct.GetProductDetail(id),
          needAuth: true
        },
        async (res) => {
          dispatch(changeAppLoading(false));

          if (callback) {
            callback(res.data || {});
          }
        }
      )
    );
  };

export { getProductDetails };
