import React, { FC } from 'react';
import LogoImg from 'assets/denticare/ntuc-health.svg';
import { Grid } from 'ui-kit';
import Footer from 'components/Footer';
import { DCBody, DCContent, LandingPageWrapper } from './styles';

const LandingPageLayout: FC<{
  children: React.ReactNode;
  maxWidth?: number;
  center?: boolean;
  isGradientBackground?: boolean;
}> = ({ children, maxWidth = 330, center = true, isGradientBackground }) => {
  if (isGradientBackground) {
    return (
      <>
        <LandingPageWrapper
          container
          style={{
            maxWidth: '100%',
            backgroundColor: 'rgb(98,210,213)',
            background: 'linear-gradient(180deg, rgba(98,210,213,0.4) 0%, rgba(255,255,255,1) 100%)'
          }}
        >
          <Grid container className="nav-bar" spacing={2} style={{ margin: '-8px 0 0' }}>
            <Grid item lg={10} md={12} xl={8} xs={12}>
              <img src={LogoImg} />
            </Grid>
          </Grid>

          <Grid container className="main-content" spacing={2}>
            <DCBody item md={12} style={{ padding: 0 }} xs={12}>
              <DCContent center={center} maxWidth={maxWidth}>
                {children}
              </DCContent>
            </DCBody>
          </Grid>
        </LandingPageWrapper>
        <Footer />
      </>
    );
  }

  return (
    <>
      <LandingPageWrapper
        container
        style={{
          maxWidth: '100%'
        }}
      >
        <Grid container className="nav-bar" spacing={2} style={{ margin: '-8px 0 0' }}>
          <Grid item lg={10} md={12} xl={8} xs={12}>
            <img src={LogoImg} />
          </Grid>
        </Grid>

        <Grid container className="main-content" spacing={2}>
          <DCBody item md={12} style={{ padding: 0 }} xs={12}>
            <DCContent center={center} maxWidth={maxWidth}>
              {children}
            </DCContent>
          </DCBody>
        </Grid>
      </LandingPageWrapper>
      <Footer />
    </>
  );
};

export default LandingPageLayout;
