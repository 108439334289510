import { ConfigurationsApi } from 'constant';
import { Callback } from 'interface';
import { ConfigFeatureResultSet, FeatureFlagState, SetManyConfigPayload } from 'model/featureFlag';
import { httpAction } from 'redux/base/action';
import { AppThunk } from 'redux/type';
import { featureFlagSlice, initialState as ffConfigInitial } from './slice';

const { setFeatureFlags } = featureFlagSlice.actions;

const loadFeatureConfigurations =
  (callback?: Callback<any>): AppThunk =>
  async (dispatch) => {
    dispatch(
      httpAction<unknown, unknown, ConfigFeatureResultSet>(
        {
          method: 'GET',
          url: `${ConfigurationsApi.GetConfigurationsApi}?module=GENERAL&sort=key,asc&size=100`,
          needAuth: true
        },

        async (res) => {
          if (res.data && res.data.content) {
            const configFeatureKeyList = Object.keys(ffConfigInitial);

            const featureFlagMap = res.data.content.reduce((accumulator, configFeat) => {
              if (configFeatureKeyList.includes(configFeat.key)) {
                accumulator[configFeat.key as keyof FeatureFlagState] = configFeat.booleanValue as any;
              }

              return accumulator;
            }, {} as SetManyConfigPayload);

            dispatch(setFeatureFlags(featureFlagMap));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

export { loadFeatureConfigurations };
