export const productCatalog = {
  myBrowser: 'Browse Product',
  purchasedItemBefore: 'You have already purchased this service',
  renewService: 'Renew Service',
  continueOtherServices: 'Continue Other Service',
  welcomeDialogTitle: 'Welcome {{username}}!',
  fees: 'Fees',
  remove: 'Remove',
  productCatalog: 'Product Catalog',
  alreadyHaveOffer: 'You already have an offer of this product in cart. Do you want to change offer?',
  areYouSure: 'Are you sure you want to {{action}} this service?',
  minPerSession: 'mins per session',
  validityPeriodOf: '*The package has a validity period of {{value}} from the date of sign-up.',
  howToBook: 'How to Book',
  home: 'Home',
  location: 'Location & class schedule',
  clickForDirections: 'Click here for directions',
  cartReview: 'Cart Review',
  serviceIsPurchasedAndNotRenew: 'The service is purchased and can not be renewed',
  purchased: 'Purchased',
  renew: 'Renew',
  proceedCheckOut: 'Proceed checkout',
  proceedCheckOutSelectedItem: 'Proceed to check out selected item',
  noSelectedProduct: 'No selected Product to be displayed',
  ourService: 'Our {{service}} services',
  getFirstVisitForFree: 'Get your first visit for free',
  professionalCareTrust: 'Professional care you can trust',
  weAreNTUCSocial:
    'We are an NTUC social enterprise that provides a comprehensive and integrated suite of quality and affordable health and elderly care services.',
  ourTestimonials: 'Our Testimonials'
};
