import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Div, Grid, Typography, isFromMdScreen } from 'ui-kit';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import dayJs from 'dayjs';

// VIEWS
import PackageDetailsTable from 'views/Payment/PackageDetailsTable';

// HELPERS
import { generateAddress } from 'helpers/string/generation';

// REDUX
import { useAppSelector, useAppDispatch } from 'hooks';
import { selectGSTConfig } from 'redux/cart/selector';
import { getGSTConfig } from 'redux/cart/actions';

// DATA
import { checkoutInfo, orderDetail, lessRounding } from './data';

const InvoiceDetail: FC = () => {
  const { t: locale } = useTranslation(['payment', 'common']);
  const dispatch = useAppDispatch();

  const fromMdScreen = isFromMdScreen();
  const gstConfig = useAppSelector(selectGSTConfig);

  useEffect(() => {
    dispatch(getGSTConfig(dayJs().format('YYYY-MM-DDTHH:mm:ss')));
  }, [dispatch]);

  return (
    <Div borderRadius={{ all: 4 }} padding={{ all: 16 }} shadowLevel={4}>
      <Grid container spacing={1}>
        <Grid item md={8} xs={12} />
        <Grid item md={4} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography align={fromMdScreen ? 'right' : 'left'}>
                <Button
                  withoutBorder
                  size="small"
                  startIcon={<CloudDownloadOutlinedIcon color="secondary" />}
                  variant="primary"
                >
                  <Typography color="secondary" variant="body2">
                    {locale('payment:downloadEInvoice')}
                  </Typography>
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align={fromMdScreen ? 'right' : 'left'} variant="h2">
                {locale('payment:taxInvoice')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={7} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">{orderDetail?.customerName}</Typography>
            </Grid>
            <Grid item md={7} xs={12}>
              <Typography variant="body1">{generateAddress(orderDetail?.address)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5} xs={12}>
          <Div padding={{ all: fromMdScreen ? 16 : 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <Typography align="left" fontWeight="semibold" variant="body1">
                      {locale('payment:invoiceNumber')}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography align={fromMdScreen ? 'right' : 'left'} variant="body1">
                      {orderDetail?.orderNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <Typography align="left" fontWeight="semibold" variant="body1">
                      {locale('payment:invoiceDate')}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography align={fromMdScreen ? 'right' : 'left'} variant="body1">
                      {dayJs(orderDetail?.createdDate).format('D MMM YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Div>
          <Div backgroundColor="grey" margin={{ top: fromMdScreen ? 0 : 20 }} padding={{ all: 16 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item md={4} xs={12}>
                    <Typography align="left" fontWeight="semibold" variant="h4">
                      {locale('payment:totalPaid')}
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography align={fromMdScreen ? 'right' : 'left'} variant="h2">
                      ${orderDetail?.payableAmount.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item md={4} xs={12}>
                    <Typography align="left" fontWeight="semibold" variant="body1">
                      {locale('payment:paymentDate')}
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography align={fromMdScreen ? 'right' : 'left'} variant="body1">
                      {dayJs(orderDetail?.createdDate).format('D MMM YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item md={5} xs={12}>
                    <Typography align="left" fontWeight="semibold" variant="body1">
                      {locale('payment:paymentMode')}
                    </Typography>
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <Typography align={fromMdScreen ? 'right' : 'left'} variant="body1">
                      {orderDetail?.paymentTransaction?.paymentType}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid item xs={4} />
              <Grid item xs={8}>
                <Typography align="right" variant="body1">
                  VISA **** 4058
                </Typography>
              </Grid> */}
            </Grid>
          </Div>
        </Grid>
      </Grid>

      <Div margin={{ top: 20, bottom: 20 }}>
        <Grid container>
          <Grid item xs={12}>
            <PackageDetailsTable
              data={checkoutInfo}
              headers={['Items', 'Quantity', 'Amount']}
              title={orderDetail?.customerName}
            />
          </Grid>
        </Grid>
      </Div>

      <Grid container>
        <Grid item md={7} xs={12} />
        <Grid item md={5} xs={12}>
          <Div padding={{ all: fromMdScreen ? 16 : 0 }}>
            <Grid container spacing={1}>
              {checkoutInfo?.totalDiscountAmount !== 0 && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={8} xs={12}>
                          <Typography align="left" fontWeight="semibold" variant="body1">
                            {locale('payment:subTotal')}
                          </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Typography align={fromMdScreen ? 'right' : 'left'} variant="body1">
                            ${checkoutInfo?.subTotalAmount.toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={8} xs={12}>
                          <Typography align="left" fontWeight="semibold" variant="body1">
                            {locale('payment:lessTotalDiscount')}
                          </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Typography align={fromMdScreen ? 'right' : 'left'} variant="body1">
                            -${checkoutInfo?.totalDiscountAmount.toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography align="left" fontWeight="semibold" variant="body1">
                      {locale('payment:total')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography align="right" variant="body1">
                      ${checkoutInfo?.totalAmount.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography align="left" fontWeight="semibold" variant="body1">
                      {locale('payment:gst', { gstPercentage: gstConfig?.gstPercentage })}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography align="right" variant="body1">
                      ${checkoutInfo?.gstAmount.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {lessRounding !== 0 && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography align="left" fontWeight="semibold" variant="body1">
                        Less: Rounding Difference
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography align="right" variant="body1">
                        ${lessRounding}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Div>
          <Div backgroundColor="grey" margin={{ top: fromMdScreen ? 0 : 20 }} padding={{ all: 16 }}>
            <Grid container>
              <Grid item md={6} xs={12}>
                <Typography align="left" fontWeight="semibold" variant="h4">
                  {locale('payment:totalPaid')}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography align={fromMdScreen ? 'right' : 'left'} variant="h2">
                  ${checkoutInfo?.totalPayableAmount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Div>
        </Grid>
      </Grid>
    </Div>
  );
};

export default InvoiceDetail;
