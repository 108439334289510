// BASE
import { MainEntity, KeyResource } from 'interface';
import { ProductType } from 'ui-kit';
import { BaseState } from './base';
import { Session } from './session';

export interface ServiceResponse extends MainEntity {
  clientId: string;
  services: Service[];
}

export enum ServiceStatus {
  UPCOMING = 'UPCOMING',
  ONGOING = 'ONGOING',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED',
  HEALTH_DECLARATION = 'HEALTH_DECLARATION'
}

export interface Service extends MainEntity {
  id: string;
  categoryId: string;
  categoryName: string;
  centerId: string;
  offeringName: string;
  offeringId: string;
  terms: ServiceTerm[];
  serviceStatus: ServiceStatus;
  renewable?: boolean;
  productId: string;
  productName: string;
  programContentType: 'INTAKES' | 'SESSIONS';
  upcomingSession?: Session;
}

export interface ServiceTerm {
  id: string;
  attendedSessions: number;
  bookedSessions: number;
  bookableSessions: number;
  preSessionBooked?: boolean;
  cancelledSessions: number;
  cancelledWithChargeSessions: number;
  endDate: number;
  stage:
    | 'PRE_SESSION_BOOKING'
    | 'SESSION_BOOKING'
    | 'PRE_SESSION_BOOKING_ON_HEALTH_CONDITION'
    | 'PRE_SESSION_BOOKING_MANDATORY_ON_HEALTH_CONDITION'
    | 'PRE_SESSION_BOOKING_REMAINDER'
    | 'WAITING_FOR_ACTIVATION'
    | 'BOOKING_COMPLETED'
    | 'POST_SESSION_BOOKING'
    | 'POST_SESSION_BOOKING_PENDING_SESSION_COMPLETION';
  startDate: number;
  status: string;
  totalSessions: number;
  type: string;
}

export interface ServiceByCategory extends MainEntity {
  categoryName: string;
  services: string[];
}

export interface TermParams {
  termId: string;
}

export interface ServiceState extends Omit<BaseState<Service>, 'many'> {
  selectedTerm?: ServiceTerm;
  upComingServices: string[];
  onGoingServices: string[];
  expiredServices: string[];
  completedServices: string[];
  healthConditionChanged: boolean;
  many: KeyResource<Service>;
  manyByStatus: KeyResource<Service>;
  manyByProductId: KeyResource<Service>;
  groupByCategory: ServiceByCategory[];
  selectedServiceType?: ProductType;
  selectedServiceTab?: ServiceTabType;
}

export type ServiceTabType = 'Active Ageing' | 'Denticare';
