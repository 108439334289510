import { RootState } from 'redux/type';

// MODEL
import { User } from 'model';

// SELECTOR
import { selectServicesByCategory } from 'redux/service/selector';

// INITIAL USER
import { initialSingleUser } from 'redux/user/slice';
import { selectIsAlreadyAssociatedDenticareClient } from 'redux/denticareClient/selector';
import { selectEnabledDenticare, selectEnabledEGiro } from 'redux/featureFlag/selectors';

export const selectUserLoadingStatus = (state: RootState): boolean => state.userReducer.loading;

// FOR LOGIN USER
export const selectLoggedInUser = (state: RootState): User => state.userReducer.loggedInUser || initialSingleUser;

// FOR OTHER USER
export const selectUser = (state: RootState): User => state.userReducer.single || initialSingleUser;
export const selectProfileUrl = (state: RootState): string => state.userReducer.profileUrl;
export const selectManyUsers = (state: RootState): User[] => state.userReducer.many || [];
export const isSelectedUserLogIn = (state: RootState): boolean =>
  !!state.userReducer.single && state.userReducer.single.id?.length === 0;

export const getClientId = (state: RootState): string => {
  const selectedUser = selectUser(state);
  const selectedLoggedInUser = selectLoggedInUser(state);
  const isSelectedUserLogin = isSelectedUserLogIn(state);

  const clientId = isSelectedUserLogin ? selectedLoggedInUser.id! : selectedUser.id!;

  return clientId;
};

// It is to indicate what selected user from user selection
export const getSelectedUser = (state: RootState): User => {
  const selectedUser = selectUser(state);
  const selectedLoggedInUser = selectLoggedInUser(state);
  const isSelectedUserLogin = isSelectedUserLogIn(state);

  const user = isSelectedUserLogin ? selectedLoggedInUser : selectedUser;

  return user;
};

export const selectIsEmptyService = (state: RootState): boolean => {
  const registeredService = selectServicesByCategory(state);
  const isAlreadyAssociatedDenticareClient = selectIsAlreadyAssociatedDenticareClient(state);

  if (registeredService.length === 0 && !isAlreadyAssociatedDenticareClient) return true;

  return false;
};

export const selectIsAbleToLinkProfile = (state: RootState): boolean => {
  const selectedUser = getSelectedUser(state);
  const isEmptyService = selectIsEmptyService(state);
  const registeredService = selectServicesByCategory(state);
  const enabledDenticare = selectEnabledDenticare(state);

  // If denticare is disabled, check AA services
  if (!enabledDenticare && registeredService.length > 0) return false;

  // If denticare is enabled, check both AA and Denticare services
  if (!isEmptyService && enabledDenticare) return false;

  if (selectedUser && selectedUser.profileLinkingStatus === 'PENDING') return true;

  return false;
};

export const selectIsEnableEgiro = selectEnabledEGiro;
