import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { OfferState } from 'model';

// REDUCER ACTION
import { changeOfferLoading, setManyOffers, setOffer, setOffersMaps } from './reducers';

export const initialState: OfferState = {
  single: undefined,
  many: [],
  offersMap: {},
  loading: false
};

export const offerSlice = createSlice({
  name: Domain.Offer,
  initialState,
  reducers: {
    changeOfferLoading,
    setManyOffers,
    setOffer,
    setOffersMaps
  }
});

export default offerSlice.reducer;
