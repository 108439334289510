import React, { FC, useEffect, useMemo } from 'react';
import { Container } from 'ui-kit';
import { TourProvider, useTour } from '@reactour/tour';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

// REDUX
import { useAppSelector, useAppDispatch } from 'hooks';
import { selectAppTourComponent, selectEnabledAppTour } from 'redux/appTour/selector';
import { setAppTourComponent, setEnabledAppTour } from 'redux/appTour/actions';

// MODEL
import { AppTourComponent } from 'model';

// STEPS
import {
  yourServiceSelectionSteps,
  yourDetailSteps,
  yourAddressSteps,
  yourEmergencySteps,
  reviewInformationSteps,
  paymentSelectionSteps,
  registerCompletionSteps
} from 'components/AppTour/RegisterService/data';

// COMPONENTS
import ServiceSelection from './RegisterService/ServiceSelection';
import YourDetail from './RegisterService/YourDetail';
import YourAddress from './RegisterService/YourAddress';
import EmergencyContact from './RegisterService/EmergencyContact';
import ReviewInformation from './RegisterService/ReviewInformation';
import PaymentSelection from './RegisterService/Payment';
import PaymentCompletion from './RegisterService/PaymentCompletion';
import AnimationButton from './RegisterService/Button';

const ButtonOpenTour = () => {
  const isEnabled = useAppSelector(selectEnabledAppTour);
  const selectAppTourComponentRedux = useAppSelector(selectAppTourComponent);

  const { setIsOpen, setSteps, currentStep, setCurrentStep } = useTour();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isEnabled) {
      setIsOpen(true);
    }
  }, [setIsOpen, isEnabled]);

  useEffect(() => {
    // GO TO YOUR DETAIL FROM SERVICE SELECTION
    if (currentStep === 7 && selectAppTourComponentRedux === AppTourComponent.ServiceSelection) {
      dispatch(setAppTourComponent(AppTourComponent.YourDetail));
      setTimeout(() => {
        setSteps(yourDetailSteps);
        setCurrentStep(1);
      });
    }

    // GO BACK SERVICE SELECTION FROM YOUR DETAIL
    if (currentStep === 0 && selectAppTourComponentRedux === AppTourComponent.YourDetail) {
      dispatch(setAppTourComponent(AppTourComponent.ServiceSelection));
      setTimeout(() => {
        setSteps(yourServiceSelectionSteps);
        setCurrentStep(6);
      });
    }

    // GO TO YOUR ADDRESS FROM YOUR DETAIL
    if (currentStep === 3 && selectAppTourComponentRedux === AppTourComponent.YourDetail) {
      dispatch(setAppTourComponent(AppTourComponent.YourAddress));
      setTimeout(() => {
        setSteps(yourAddressSteps);
        setCurrentStep(1);
      });
    }

    // GO BACK YOUR DETAIL FROM YOUR ADDRESS
    if (currentStep === 0 && selectAppTourComponentRedux === AppTourComponent.YourAddress) {
      dispatch(setAppTourComponent(AppTourComponent.YourDetail));
      setTimeout(() => {
        setSteps(yourDetailSteps);
        setCurrentStep(2);
      });
    }

    // GO TO EMERGENCY CONTACT FROM YOUR ADDRESS
    if (currentStep === 4 && selectAppTourComponentRedux === AppTourComponent.YourAddress) {
      dispatch(setAppTourComponent(AppTourComponent.EmergencyContact));
      setTimeout(() => {
        setSteps(yourEmergencySteps);
        setCurrentStep(1);
      });
    }

    // GO BACK YOUR ADDRESS FROM EMERGENY CONTACT
    if (currentStep === 0 && selectAppTourComponentRedux === AppTourComponent.EmergencyContact) {
      dispatch(setAppTourComponent(AppTourComponent.YourAddress));
      setTimeout(() => {
        setSteps(yourAddressSteps);
        setCurrentStep(3);
      });
    }

    // GO TO REVIEW INFORMATION FROM EMERGENCY CONTACT
    if (currentStep === 2 && selectAppTourComponentRedux === AppTourComponent.EmergencyContact) {
      dispatch(setAppTourComponent(AppTourComponent.ReviewInformation));
      setTimeout(() => {
        setSteps(reviewInformationSteps);
        setCurrentStep(1);
      });
    }

    // GO BACK EMERGENCY CONTACT FROM REVIEW INFORMATION
    if (currentStep === 0 && selectAppTourComponentRedux === AppTourComponent.ReviewInformation) {
      dispatch(setAppTourComponent(AppTourComponent.EmergencyContact));
      setTimeout(() => {
        setSteps(yourAddressSteps);
        setCurrentStep(1);
      });
    }

    // GO TO PAYMENT SELECTION FROM REVIEW INFORMATION
    if (currentStep === 9 && selectAppTourComponentRedux === AppTourComponent.ReviewInformation) {
      dispatch(setAppTourComponent(AppTourComponent.PaymentSelection));
      setTimeout(() => {
        setSteps(paymentSelectionSteps);
        setCurrentStep(1);
      });
    }

    // GO BACK REVIEW INFORMATION FROM PAYMENT SELECTION
    if (currentStep === 0 && selectAppTourComponentRedux === AppTourComponent.PaymentSelection) {
      dispatch(setAppTourComponent(AppTourComponent.ReviewInformation));
      setTimeout(() => {
        setSteps(reviewInformationSteps);
        setCurrentStep(8);
      });
    }

    // GO TO PAYMENT COMPLETION FROM PAYMENT SELECTION
    if (currentStep === 4 && selectAppTourComponentRedux === AppTourComponent.PaymentSelection) {
      dispatch(setAppTourComponent(AppTourComponent.PaymentCompletion));
      setTimeout(() => {
        setSteps(registerCompletionSteps);
        setCurrentStep(1);
      });
    }

    // GO BACK PAYMENT SELECTION FROM PAYMENT COMPLETION
    if (currentStep === 0 && selectAppTourComponentRedux === AppTourComponent.PaymentCompletion) {
      dispatch(setAppTourComponent(AppTourComponent.PaymentSelection));
      setTimeout(() => {
        setSteps(paymentSelectionSteps);
        setCurrentStep(3);
      });
    }
  }, [currentStep]);

  return (
    <AnimationButton
      show={!isEnabled}
      style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 10000 }}
      type="button"
      onClick={() => {
        dispatch(setAppTourComponent(AppTourComponent.ServiceSelection));
        dispatch(setEnabledAppTour(true));
        setTimeout(() => {
          setSteps(yourServiceSelectionSteps);
          setCurrentStep(0);
        });
      }}
    >
      <PlayArrowIcon color="primary" fontSize="large" />
    </AnimationButton>
  );
};

const AppTour: FC = () => {
  const isEnabled = useAppSelector(selectEnabledAppTour);
  const selectAppTourComponentRedux = useAppSelector(selectAppTourComponent);
  const dispatch = useAppDispatch();

  const appTourComponent = useMemo(() => {
    let component = null;

    switch (selectAppTourComponentRedux) {
      case AppTourComponent.ServiceSelection:
        component = <ServiceSelection />;
        break;

      case AppTourComponent.YourDetail:
        component = <YourDetail />;
        break;

      case AppTourComponent.YourAddress:
        component = <YourAddress />;
        break;

      case AppTourComponent.EmergencyContact:
        component = <EmergencyContact />;
        break;

      case AppTourComponent.ReviewInformation:
        component = <ReviewInformation />;
        break;

      case AppTourComponent.PaymentSelection:
        component = <PaymentSelection />;
        break;

      case AppTourComponent.PaymentCompletion:
        component = <PaymentCompletion />;
        break;

      default:
        break;
    }

    return component;
  }, [selectAppTourComponentRedux]);

  const beforeClose = () => {
    dispatch(setEnabledAppTour(false));
  };

  return (
    <TourProvider beforeClose={beforeClose} steps={[]}>
      {isEnabled && (
        <div
          style={{
            position: 'absolute',
            top: 120,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#fff'
          }}
        >
          <Container isSmall={false}>{appTourComponent}</Container>
        </div>
      )}
      <ButtonOpenTour />
    </TourProvider>
  );
};

export default AppTour;
