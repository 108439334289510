import { RootState } from 'redux/type';

// MODEL
import { ServiceProductCatalog, ProgramProductCatalog, OffersListProductCatalog } from 'model';

// SELECTOR
import { selectServiceSelection } from 'redux/service-registration/selector';

export const selectProductCatalogLoadingStatus = (state: RootState): boolean => state.productCatalogReducer.loading;
export const selectServicesProductCatalog = (state: RootState): ServiceProductCatalog[] =>
  state.productCatalogReducer.services || [];
export const selectProgramsProductCatalog =
  (serviceId?: string, programTitle?: string) =>
  (state: RootState): ProgramProductCatalog[] => {
    const defaultData = state.productCatalogReducer.programs || [];

    if (!serviceId && programTitle) {
      return defaultData.filter((v) => v.name.includes(programTitle));
    }

    if (serviceId && programTitle) {
      return defaultData.filter((v) => v.name.includes(programTitle) && v.parentCategoryId === serviceId);
    }

    if (serviceId && !programTitle) {
      return defaultData.filter((v) => v.parentCategoryId === serviceId);
    }

    return defaultData;
  };
export const selectOfferListProductCatalog = (state: RootState): OffersListProductCatalog[] =>
  state.productCatalogReducer.offers || [];
export const selectSelectedServiceProductCatalog = (state: RootState): string | undefined =>
  state.productCatalogReducer.selectedService;

export const selectOfferFromId =
  (state: RootState) =>
  (offerId: string): OffersListProductCatalog | undefined => {
    const offersList = selectOfferListProductCatalog(state);

    return offersList.find((v) => v.id === offerId);
  };

export const selectProgramFromId = (programId: string) => (state: RootState) => {
  const programs = state.productCatalogReducer.programs || [];

  return programs.find((p) => p.id === programId);
};

export const selectOffersFromProgramId = (programId: string) => (state: RootState) => {
  const offersList = selectOfferListProductCatalog(state);

  return offersList.filter((o) => o.productId === programId);
};

export const selectCartProductCatalog = (state: RootState) => {
  const serviceSelection = selectServiceSelection(state);

  return serviceSelection.programmes;
};

export const getSelectedServiceInfo = (state: RootState) => {
  const serviceList = selectServicesProductCatalog(state);
  const selectedService = selectSelectedServiceProductCatalog(state);

  return serviceList.find((s) => s.id === selectedService);
};
