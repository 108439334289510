import { Select } from 'ui-kit';
import { KeyResource } from 'interface';
import { RootState } from 'redux/type';

// MODEL
import { Center, Service } from 'model';

export const selectCenterLoadingStatus = (state: RootState): boolean => state.centerReducer.loading;
export const selectCenter = (state: RootState): Center | undefined => state.centerReducer.single;
export const selectMapCenters = (state: RootState): KeyResource<Center> => state.centerReducer.centersMap;
export const selectManyCenters = (state: RootState): Center[] => state.centerReducer.many || [];
export const selectCenterByService =
  (service?: Service) =>
  (state: RootState): Center | undefined => {
    const manyCenter = state.centerReducer.centersMap;

    if (
      !service ||
      Object.keys(manyCenter).length === 0 ||
      (Object.keys(manyCenter).length > 0 && !manyCenter[service.centerId])
    )
      return undefined;

    return manyCenter[service.centerId];
  };
export const transformCentersToSelect = (state: RootState): Select[] =>
  (state.centerReducer.many || []).map<Select>((v) => ({
    value: v.id!,
    label: v.name
  }));
