import React, { FC } from 'react';
import { FormikHelpers } from 'formik';

// COMPONENT
import Form from 'components/Form';

// REDUX
import { initialSingleContact } from 'redux/contact/slice';

// TYPES
import { Contact } from 'model';

// VIEWS
import { NextOfKinsInputForm } from './NextOfKinsInputForm';

// SCHEMA
import { contactSchema } from './schema';

// PROPS
import { NextOfKinsFormikProps } from './interface';

export const NextOfKinsFormik: FC<NextOfKinsFormikProps> = ({ onSubmit, data, children, readOnly }) => {
  const handleSubmit = (values: Contact, formikHeler: FormikHelpers<Contact>) => onSubmit(values, formikHeler);

  return (
    <Form<Contact> data={data} initialValues={initialSingleContact} schema={contactSchema} onSubmit={handleSubmit}>
      {(formik) => children(formik, <NextOfKinsInputForm formik={formik} readOnly={readOnly} />)}
    </Form>
  );
};
