import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { FeedbackState } from 'model';

// REDUCER ACTION
import { setHasSubmitedFeedback, setSelectedFeedback, setfeedbackSuccess, resetState } from './reducers';

export const initialState: FeedbackState = {
  selectedFeedback: undefined,
  hasSubmittedFeedback: false,
  feedbackSuccess: false
};

export const feedBackSlice = createSlice({
  name: Domain.Feedback,
  initialState,
  reducers: {
    setHasSubmitedFeedback,
    setSelectedFeedback,
    setfeedbackSuccess,
    resetState
  }
});

export default feedBackSlice.reducer;
