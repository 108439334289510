/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useMemo, ReactNode, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { ClickAwayListener } from '@material-ui/core';

// ASSETS
import ServiceIcon from 'assets/service.svg';
import WebsiteIcon from 'assets/website.svg';
import HelpIcon from 'assets/help.svg';

// COMPONENTS
import DialogExit from 'components/DialogExit';
import AppTour from 'components/AppTour';
import { selectEnabledAppTour } from 'redux/appTour/selector';
import Dialog from 'components/Dialog';
import { TranslationWithColor } from 'components/TranslationWithStyle';
import EGiroLandingPage from 'page/eGiro/landing/EGiroLandingPage';
import ErrorIcon from '@material-ui/icons/Error';

// REDUX
import { useAppSelector, useAppDispatch } from 'hooks';
import { selectAppMessage, selectOpenModalToRefreshApp, selectAssociationStatus } from 'redux/app/selector';
import {
  setAppMessage,
  generateAssociation,
  forgotPasswordCallback,
  setOpenModalToRefreshApp
} from 'redux/app/actions';
import { setSelectedServiceType, getServicesList } from 'redux/service/action';
import { getAppConfig } from 'redux/firebase/actions';
import { getPersonalDetail } from 'redux/user/actions';
import {
  removeItemFromCartProductCatalog,
  setSelectedServiceProductCatalog,
  storeCartIntoLocalStorage
} from 'redux/productCatalog/action';
import { changeServiceSelection } from 'redux/service-registration/actions';
import { selectLoggedInUser, selectIsEmptyService, selectIsEnableEgiro } from 'redux/user/selector';
import { selectOfferFromId, selectCartProductCatalog } from 'redux/productCatalog/selector';
import { getGSTConfig } from 'redux/cart/actions';

// UI-KIT
import {
  User,
  Toast,
  NavigationLabelMember,
  Div,
  Typography,
  Button,
  ProductType,
  isXSScreen,
  isSmScreen,
  Image,
  Page
} from 'ui-kit';

// HELPERS
import { getValue, removeKey } from 'helpers/localstorage';

// MODEL
import { ServiceSelection } from 'model';

// Routes
import { Router, ApiService, SUPPORT_ERROR_MSG, AppEnv } from 'constant';

// Service
import { authService } from 'config/auth';

// Data
import productDetailData from 'page/ProductCatalog/ProductCatalogDetail/data';
import { faSquareInstagram, faSquareFacebook, faSquareYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { loadFeatureConfigurations } from 'redux/featureFlag/actions';
import {
  selectEnabledEGiroArrengement,
  selectEnabledFaq,
  selectEnabledProductCatalog
} from 'redux/featureFlag/selectors';
import { useSelector } from 'react-redux';
import { WhiteTooltip, WhiteTooltipCustomPosition } from './styles';

interface AppContainerProps {
  children: ReactNode;
}

const AppContainer: FC<AppContainerProps> = ({ children }) => {
  const { t: locale } = useTranslation(['service', 'profile', 'common', 'payment', 'productCatalog', 'faq', 'egiro']);
  const { user: UserFromAuth, isAuthenticated, isLoading } = useAuth0();
  const [openSetDialog, setOpenSetDialog] = useState(false);
  const [openToolTipEGyro, setOpenTooltipEGyro] = useState(false);
  const appMessage = useAppSelector(selectAppMessage);
  const openModalRefreshApp = useAppSelector(selectOpenModalToRefreshApp);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isAssociationCreated = useAppSelector(selectAssociationStatus);
  const isHomePage = window.location.pathname === '/';
  const isEnabledProductCatalog = useSelector(selectEnabledProductCatalog);
  const isEnabledFAQ = useAppSelector(selectEnabledFaq);
  const isDentiCareAppointmentRoute = window.location.pathname === `${Router.UserAppointmentsList}/my-denticare`;
  const isProductCatalogDetail = window.location.pathname.includes(Router.ProductDetailWithoutId);
  const isAppointmentList = window.location.pathname.includes(Router.UserAppointmentsList);
  const isServiceType = window.location.pathname.includes(Router.ServiceType);
  const isRegisterService = window.location.pathname.includes(Router.RegisterService);
  const enabledAppTour = useAppSelector(selectEnabledAppTour);
  const isUATQA = process.env.REACT_APP_ENV === AppEnv.Uat || process.env.REACT_APP_ENV === AppEnv.Qa;
  const cart = useAppSelector(selectCartProductCatalog);
  const selectOffer = useAppSelector(selectOfferFromId);
  const xsScreen = isXSScreen();
  const smScreen = isSmScreen();
  const loggedInUser = useAppSelector(selectLoggedInUser);
  const isEnabledAppTour = useAppSelector(selectEnabledAppTour);
  const disableBody = (target: any) => disableBodyScroll(target);
  const enableBody = (target: any) => enableBodyScroll(target);
  const [isShowCustomerSupportDialog, setIsShowCustomerSupportDialog] = useState<boolean>(false);
  const isEmptyService = useAppSelector(selectIsEmptyService);
  const isEnabledEGiro = useAppSelector(selectIsEnableEgiro);
  const isEGiroPage = window.location.pathname.includes('egiro');
  const isEGiroLandingPage = window.location.pathname.includes(Router.eGiroLanding);
  const isEnabledEGyroArrangement = useAppSelector(selectEnabledEGiroArrengement);

  const historyMenus: NavigationLabelMember[] = [
    {
      label: locale('payment:paymentHistory').toUpperCase(),
      onClick: () => history.push(Router.InvoiceList)
    },
    {
      label: locale('payment:cardManagement').toUpperCase(),
      onClick: () => history.push(Router.CardManagement)
    }
  ];

  const eGiroMenus: NavigationLabelMember[] = [
    {
      label: locale('egiro:finance').toUpperCase(),
      subItems: [
        {
          label: locale('payment:paymentHistory').toUpperCase(),
          onClick: () => history.push(Router.InvoiceList)
        },
        {
          label: locale('payment:cardManagement').toUpperCase(),
          onClick: () => history.push(Router.CardManagement)
        },
        {
          label: locale('egiro:eGiroArrangements').toUpperCase(),
          onClick: () => history.push(Router.eGiro),
          disabled: !isEnabledEGyroArrangement,
          extraComponent: isEnabledEGyroArrangement ? null : (
            <Div margin={{ left: 5 }}>
              {smScreen || xsScreen ? (
                <ClickAwayListener onClickAway={() => setOpenTooltipEGyro(false)}>
                  <WhiteTooltipCustomPosition
                    arrow
                    open={openToolTipEGyro}
                    title={
                      <Typography variant="body2">
                        eGIRO arrangement is temporarily under maintenance. Thank you for your patience.
                      </Typography>
                    }
                  >
                    <span onClick={() => setOpenTooltipEGyro(true)}>
                      <ErrorIcon color="disabled" fontSize="small" />
                    </span>
                  </WhiteTooltipCustomPosition>
                </ClickAwayListener>
              ) : (
                <WhiteTooltip
                  arrow
                  title={
                    <Typography variant="body2">
                      eGIRO arrangement is temporarily under maintenance. Thank you for your patience.
                    </Typography>
                  }
                >
                  <ErrorIcon color="disabled" fontSize="small" />
                </WhiteTooltip>
              )}
            </Div>
          )
        }
      ]
    }
  ];

  const isShowNavBarSupportButton = useMemo<boolean>(() => {
    if (!isHomePage) {
      return true;
    }

    return !isEmptyService;
  }, [isEmptyService, isHomePage]);

  const calculateAlpha = useMemo(() => {
    if (xsScreen) {
      return {
        start: 150,
        end: 230
      };
    }

    if (smScreen) {
      return {
        start: 230,
        end: 360
      };
    }

    return {
      start: 400,
      end: 530
    };
  }, [xsScreen, smScreen]);

  const cartItems = useMemo(
    () =>
      cart.map((item) => {
        const itemInfo = selectOffer(item.offer);
        const itemAdditionalData = productDetailData.find((product) => product.id === item.product);

        return {
          title: itemInfo?.name || '',
          content: (
            <div>
              <img src={itemAdditionalData?.iconImage || ''} style={{ width: '25%', marginBlock: 5 }} />
              <Typography>{itemInfo?.pricing.sessions} session*</Typography>
              <Typography>(45 mins per session)</Typography>
            </div>
          )
        };
      }),
    [cart]
  );

  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    if (!loggedInUser) return;

    fetch(`https://ui-avatars.com/api/?name=${loggedInUser.name.substring(0, 2)}&background=random`).then(
      async (res) => {
        const imageUrl = URL.createObjectURL(await res.blob());

        setImgUrl(imageUrl);
      }
    );
  }, [loggedInUser]);

  const user = useMemo<User | undefined>(() => {
    if (UserFromAuth && loggedInUser) {
      return {
        id: UserFromAuth['sci:uid'],
        fullName: loggedInUser.name!,
        avatar: imgUrl,
        userMembers: []
      };
    }

    return undefined;
  }, [UserFromAuth, loggedInUser, imgUrl]);

  useEffect(() => {
    dispatch(loadFeatureConfigurations());
  }, [UserFromAuth]);

  const paddingTop = useMemo(() => {
    if (isHomePage || isAppointmentList) return 0;

    return (isEnabledProductCatalog && (isProductCatalogDetail || isServiceType)) || isEGiroPage ? 0 : 50;
  }, [window.location.pathname]);

  const onBrandClick = () => {
    history.push(Router.Home);
  };

  const onUserClick = () => history.push(Router.MyService);

  const onLogin = () => authService.logInWithRedirect(Router.MyService);

  const onClickCategory = useCallback(
    (v: ProductType | undefined) => {
      dispatch(setSelectedServiceType(v));
      dispatch(setSelectedServiceProductCatalog(v));

      if (v) {
        window.scroll({ top: calculateAlpha.end, behavior: 'smooth' });

        return history.push(`${Router.ServiceType}/${v}`);
      }

      return history.push(Router.Home);
    },
    [calculateAlpha]
  );

  const onLogout = () => {
    if (
      window.location.pathname === Router.RegisterService ||
      window.location.pathname === Router.BookPreClassAndInTake ||
      window.location.pathname === Router.BookPreClassAndSessions ||
      window.location.pathname === Router.AppointmentReschedule ||
      window.location.pathname === Router.AppointmentCancel ||
      window.location.pathname === Router.SessionCancel ||
      window.location.pathname === Router.Payment
    ) {
      return setOpenSetDialog(true);
    }

    return authService.logOut();
  };

  const onRemoveItemCart = useCallback(
    (index: number) => {
      if (!cart[index]) return;

      const removedItemId = cart[index].offer;

      if (removedItemId) {
        dispatch(removeItemFromCartProductCatalog(removedItemId));
      }
    },
    [cart]
  );

  const onCheckout = useCallback(() => {
    if (!isAuthenticated) {
      dispatch(storeCartIntoLocalStorage());
      authService.logInWithRedirect(Router.ProductCatalogCartReview);
    } else {
      history.push(Router.RegisterService);
    }
  }, [isAuthenticated]);

  const userMenu: NavigationLabelMember[] = useMemo(() => {
    if (isEnabledProductCatalog) {
      return [
        {
          label: locale('productCatalog:myBrowser').toUpperCase(),
          onClick: () => history.push(Router.Home)
        },
        {
          label: locale('service:myService').toUpperCase(),
          onClick: () => history.push(Router.MyService)
        },
        {
          label: locale('profile:myProfile').toUpperCase(),
          onClick: () => history.push(Router.Profile)
        },
        ...(isEnabledEGiro ? eGiroMenus : historyMenus),
        {
          label: locale('common:linkPass').toUpperCase(),
          onClick: () => window.open(isUATQA ? 'https://pass.preprod.link.sg/' : 'https://pass.link.sg/', '__blank')
        },
        {
          label: locale('common:logOut').toUpperCase(),
          onClick: () => onLogout()
        }
      ];
    }

    if (isEnabledFAQ) {
      return [
        {
          label: locale('service:myService').toUpperCase(),
          onClick: () => history.push(Router.MyService)
        },
        {
          label: locale('profile:myProfile').toUpperCase(),
          onClick: () => history.push(Router.Profile)
        },
        ...(isEnabledEGiro ? eGiroMenus : historyMenus),
        {
          label: locale('faq:helpCentre').toUpperCase(),
          onClick: () => history.push(Router.FAQ)
        },
        {
          label: locale('common:linkPass').toUpperCase(),
          onClick: () => window.open(isUATQA ? 'https://pass.preprod.link.sg/' : 'https://pass.link.sg/', '__blank')
        },
        {
          label: locale('common:logOut').toUpperCase(),
          onClick: () => onLogout()
        }
      ];
    }

    return [
      {
        label: locale('service:myService').toUpperCase(),
        onClick: () => history.push(Router.MyService)
      },
      {
        label: locale('profile:myProfile').toUpperCase(),
        onClick: () => history.push(Router.Profile)
      },
      ...(isEnabledEGiro ? eGiroMenus : historyMenus),
      {
        label: locale('common:linkPass').toUpperCase(),
        onClick: () => window.open(isUATQA ? 'https://pass.preprod.link.sg/' : 'https://pass.link.sg/', '__blank')
      },
      {
        label: locale('common:logOut').toUpperCase(),
        onClick: () => onLogout()
      }
    ];
  }, [isUATQA, isEnabledEGiro, smScreen, xsScreen, openToolTipEGyro]);

  useEffect(() => {
    if (isEnabledAppTour) {
      disableBody(window);
    }

    if (!isEnabledAppTour) {
      enableBody(window);
    }
  }, [isEnabledAppTour]);

  useEffect(() => {
    dispatch(forgotPasswordCallback());
    dispatch(getAppConfig());
    // CHECK PRODUCT CATALOG CART
    const cartLocalstorage = getValue<ServiceSelection>('@productCatalogCartKey');

    if (!cartLocalstorage) return;

    dispatch(changeServiceSelection(cartLocalstorage));

    removeKey('@productCatalogCartKey');
  }, []);

  useEffect(() => {
    if (openModalRefreshApp) {
      setTimeout(() => {
        dispatch(setOpenModalToRefreshApp(false));
      }, 10000);
    }
  }, [openModalRefreshApp]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(generateAssociation());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAssociationCreated) {
      dispatch(getGSTConfig(dayjs().format('YYYY-MM-DDTHH:mm:ss')));
      dispatch(
        getPersonalDetail((data) => {
          if (data && (isDentiCareAppointmentRoute || isRegisterService)) {
            dispatch(getServicesList(ApiService.serviceByClient));
          }
        })
      );
    }
  }, [dispatch, isAssociationCreated]);

  const htmlMsgTypes = ['error', 'support'];
  const prepareErrorMsg = (msg: String, type: string) => {
    if (!htmlMsgTypes.includes(type)) return msg;

    let errMsg = `${SUPPORT_ERROR_MSG(appMessage?.content || '')}`;

    if (appMessage?.ignoreEmailSupportingMessage) {
      errMsg = appMessage?.content;
    }

    return <div dangerouslySetInnerHTML={{ __html: errMsg }} />;
  };

  if (isLoading) return null;

  if (isEGiroLandingPage) {
    return (
      <EGiroLandingPage
        isAuthenticated={isAuthenticated}
        userAvatar={user?.avatar}
        userName={user?.fullName}
        onBrandClick={onBrandClick}
        onLogin={onLogin}
        onUserClick={onUserClick}
      />
    );
  }

  if (!isLoading && (isAuthenticated || isHomePage || isProductCatalogDetail || isServiceType)) {
    return (
      <>
        {appMessage && (
          <Toast
            autoHideDuration={htmlMsgTypes.includes(appMessage.type) ? 1000000 : 6000}
            message={prepareErrorMsg(appMessage.content, appMessage.type)}
            open={!!appMessage}
            variant={htmlMsgTypes.includes(appMessage.type) ? 'support' : appMessage.type}
            onClose={() => dispatch(setAppMessage())}
          />
        )}

        <Page
          catalogNavigation={isEnabledProductCatalog && (isHomePage || isProductCatalogDetail || isServiceType)}
          catalogNavigationProps={{
            isAuthenticated: !!user,
            user,
            showHeroBg: !isProductCatalogDetail,
            showCategoryItems: !isProductCatalogDetail,
            isTransparent: !isProductCatalogDetail,
            onLogin,
            onLogout,
            onUserClick,
            onBrandClick,
            onClickCategory,
            cartItems,
            cartQuantity: cartItems?.length || 0,
            onRemoveItemCart,
            onCheckout,
            alphaEnd: calculateAlpha.end,
            alphaStart: calculateAlpha.start
          }}
          containerSmall={false}
          footerProps={{
            labels: [],
            footerSocials: [
              {
                icon: <FontAwesomeIcon icon={faSquareFacebook} size="lg" />,
                link: 'https://www.facebook.com/NTUCHealth/'
              },
              {
                icon: <FontAwesomeIcon icon={faSquareInstagram} size="lg" />,
                link: 'https://www.instagram.com/ntuchealth/?hl=en'
              },
              {
                icon: <FontAwesomeIcon icon={faSquareYoutube} size="lg" />,
                link: 'https://www.youtube.com/channel/UCY2uyWiWYyBqGWz3J58uaSQ'
              },
              {
                icon: <FontAwesomeIcon icon={faLinkedin} size="lg" />,
                link: 'https://sg.linkedin.com/company/ntuc-health-co-operative-limited'
              }
            ],
            address: '',
            phone: '',
            workingDay: '',
            workingHour: '',
            brandName: 'Homepage',
            onBrandClick: () => window.open('https://ntuchealth.sg/', '_blank'),
            onPrivacyPolicyClick: () => window.open('https://ntuchealth.sg/privacy-policy/', '_blank'),
            FAQText: 'FAQ',
            onFAQClick: () => window.open('/faq', '_blank')
          }}
          fullWidth={isEnabledProductCatalog && (isHomePage || isProductCatalogDetail || isServiceType)}
          hasContactUs={isProductCatalogDetail}
          navigationProps={{
            onBrandClick,
            user,
            isAuthenticated: !!user,
            notifications: 0,
            onLogin,
            onLogout,
            userMenu,
            labels: [],
            CustomeView: isShowNavBarSupportButton && (
              <Button disableRipple withoutBorder onClick={() => setIsShowCustomerSupportDialog(true)}>
                <Image src={HelpIcon} />
              </Button>
            )
          }}
        >
          <Div
            padding={{
              top: paddingTop
            }}
          >
            {children}
          </Div>
        </Page>

        <DialogExit
          isEnabledPromp={false}
          open={openSetDialog}
          onCancel={() => setOpenSetDialog(false)}
          onSubmit={() => {
            authService.logOut();
          }}
        />
        <Dialog
          isShowDismissButton
          body={
            <Div alignItems="center" display="flex" flexDirection="row" justifyContent="center">
              <Div
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                margin={{ top: 20 }}
                maxWidth={500}
              >
                <Div
                  alignItems="center"
                  borderColor={{ all: 'grey' }}
                  borderRadius={{ all: 4 }}
                  borderWidth={{ all: 1 }}
                  display="flex"
                  flexDirection="row"
                  padding={{ top: 10, bottom: 10, left: 20, right: 20 }}
                  onCLick={() => window.open('mailto:support@ntuchealth.sg')}
                >
                  <Div width={80}>
                    <Image src={WebsiteIcon} />
                  </Div>
                  <Div margin={{ left: 20 }}>
                    <Typography color="teal" variant="h3">
                      {locale('service:supportWebsiteTitle')}
                    </Typography>
                    <Div margin={{ top: 10 }}>
                      <Typography variant="body1">
                        <TranslationWithColor
                          i18key="service:supportWebsiteMessage"
                          value={{ support: 'support@ntuchealth.sg' }}
                        />
                      </Typography>
                    </Div>
                  </Div>
                </Div>
                <Div
                  alignItems="center"
                  borderColor={{ all: 'grey' }}
                  borderRadius={{ all: 4 }}
                  borderWidth={{ all: 1 }}
                  display="flex"
                  flexDirection="row"
                  margin={{ top: 40 }}
                  padding={{ top: 10, bottom: 10, left: 20, right: 20 }}
                  onCLick={() => window.open('https://ntuchealth.sg/contact-us', '_blank')}
                >
                  <Div width={80}>
                    <Image src={ServiceIcon} />
                  </Div>
                  <Div margin={{ left: 20 }}>
                    <Typography color="teal" variant="h3">
                      {locale('service:supportServiceTitle')}
                    </Typography>
                    <Div margin={{ top: 10 }}>
                      <TranslationWithColor
                        i18key="service:supportServiceMessage"
                        value={{ support: 'www.ntuchealth.sg' }}
                      />
                    </Div>
                  </Div>
                </Div>
              </Div>
            </Div>
          }
          data={null}
          isShowCloseButton={false}
          isShowSubmitButton={false}
          open={isShowCustomerSupportDialog}
          title={locale('service:customerSupport')}
          onCancel={() => {}}
          onDismiss={() => setIsShowCustomerSupportDialog(false)}
          onSubmit={() => {}}
        />

        <Toast
          message={
            <Div alignItems="center" display="flex" flexDirection="row">
              <Typography color="white" variant="body2">
                {locale('common:appNewVersionBody')}
              </Typography>
              <Div margin={{ left: 10 }}>
                <Button
                  isGreenBg
                  size="small"
                  variant="secondary"
                  onClick={() => {
                    window.location.href = `${window.location.origin}`;
                  }}
                >
                  {locale('common:refresh')}
                </Button>
              </Div>
            </Div>
          }
          open={openModalRefreshApp}
          showIcon={false}
          variant="info"
          onClose={() => dispatch(setOpenModalToRefreshApp(false))}
        />
        {isRegisterService && enabledAppTour && <AppTour />}
      </>
    );
  }

  return <>{children}</>;
};

export default AppContainer;
