import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { FaqState } from 'model';

// REDUCER ACTION
import { changeActiveTab, changeSearchText } from './reducers';

export const initialState: FaqState = {
  currentActiveTab: 'COMMON',
  searchText: ''
};

export const faqSlice = createSlice({
  name: Domain.FAQ,
  initialState,
  reducers: {
    changeActiveTab,
    changeSearchText
  }
});

export default faqSlice.reducer;
