import feedbackLvl1Img from 'assets/feedback/1.svg';
import feedbackLvl2Img from 'assets/feedback/2.svg';
import feedbackLvl3Img from 'assets/feedback/3.svg';
import feedbackLvl4Img from 'assets/feedback/4.svg';
import feedbackLvl5Img from 'assets/feedback/5.svg';
import feedbackLvl6Img from 'assets/feedback/6.svg';
import selectedFdbkLvl1Img from 'assets/feedback/1s.svg';
import selectedFdbkLvl2Img from 'assets/feedback/2s.svg';
import selectedFdbkLvl3Img from 'assets/feedback/3s.svg';
import selectedFdbkLvl4Img from 'assets/feedback/4s.svg';
import selectedFdbkLvl5Img from 'assets/feedback/5s.svg';
import selectedFdbkLvl6Img from 'assets/feedback/6s.svg';

export const RatingConfig = [
  {
    rating: '1',
    icon: feedbackLvl1Img,
    iconOnSelect: selectedFdbkLvl1Img
  },
  {
    rating: '2',
    icon: feedbackLvl2Img,
    iconOnSelect: selectedFdbkLvl2Img
  },
  {
    rating: '3',
    icon: feedbackLvl3Img,
    iconOnSelect: selectedFdbkLvl3Img
  },
  {
    rating: '4',
    icon: feedbackLvl4Img,
    iconOnSelect: selectedFdbkLvl4Img
  },
  {
    rating: '5',
    icon: feedbackLvl5Img,
    iconOnSelect: selectedFdbkLvl5Img
  },
  {
    rating: '6',
    icon: feedbackLvl6Img,
    iconOnSelect: selectedFdbkLvl6Img
  }
];
