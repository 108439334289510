export const baseUrl = 'egiro';
export const baseMockUrl = 'egiro-mock';

export const ApiEGiro = {
  History: `${baseUrl}/history`,
  Retrieve: `${baseUrl}/retrieve`,
  BankList: `${baseUrl}/bank-list`,
  CreateNewClientEGiro: (applicantBankCode: string, ddaRefNo: string, nric: string) =>
    `${baseUrl}/create?applicantBankCode=${applicantBankCode}&ddaRefNo=${ddaRefNo}&nric=${nric}`,
  CreateNewLovedOneEGiro: (applicantBankCode: string, ddaRefNo: string, lovedOneId: string, relationShip: string) =>
    `${baseUrl}/create?applicantBankCode=${applicantBankCode}&ddaRefNo=${ddaRefNo}&lovedOneId=${lovedOneId}&relationship=${relationShip}`,
  Cancel: (ddaRefNo: string, boTransactionRefNo: string) =>
    `${baseUrl}/cancel?boDDARefNo=${ddaRefNo}&boTransactionRefNo=${boTransactionRefNo}`,
  CreateNewClientEGiroTriggerMock: (
    consent: boolean,
    clientID: string,
    requestID: string,
    boDDARefNo: string,
    boTransactionRefNo: string
  ) =>
    `${baseMockUrl}/create/trigger?consent=${consent}&requestID=${requestID}&clientID=${clientID}&boTransactionRefNo=${boTransactionRefNo}&boDDARefNo=${boDDARefNo}`,
  CancelEGiroTriggerMock: (
    consent: boolean,
    clientID: string,
    requestID: string,
    boDDARefNo: string,
    boTransactionRefNo: string
  ) =>
    `${baseMockUrl}/cancel/trigger?consent=${consent}&requestID=${requestID}&clientID=${clientID}&boTransactionRefNo=${boTransactionRefNo}&boDDARefNo=${boDDARefNo}`,
  GenerateDDARefNo: (nric: string) => `${baseUrl}/generateDDARefNo?nric=${nric}`,
  UpdateStatus: (boDDARefNo: string, boTransactionRefNo: string) =>
    `${baseUrl}/status?boDDARefNo=${boDDARefNo}&boTransactionRefNo=${boTransactionRefNo}`
};
