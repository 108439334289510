// MOCK DATA
export const addresses = [
  {
    id: 'fc33549f-2763-49d8-8d02-1ba28d5b775d',
    block: '55',
    level: '',
    unit: '',
    street: 'COMPASSVALE BOW',
    building: 'THE QUARTZ',
    postalCode: '544986',
    type: 'BILLING'
  },
  {
    id: '073a5490-04e6-43c7-91e1-c86f2984a544',
    block: '55',
    level: '',
    unit: '',
    street: 'COMPASSVALE BOW',
    building: 'THE QUARTZ',
    postalCode: '544986',
    type: 'RESIDENTIAL'
  },
  {
    id: '31132616-3247-4b8a-acb3-c08f61e214a7',
    block: '55',
    level: '',
    unit: '',
    street: 'COMPASSVALE BOW',
    building: 'THE QUARTZ',
    postalCode: '544986',
    type: 'SERVICE'
  }
];
