// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// MODEL
import { Order, OrderStatus, OrderListResponse, CheckoutInfo } from 'model';

// TYPE
import { Callback } from 'interface';

// ROUTER
import { OrderApi } from 'constant';

// REDUCER ACTION
import { setCartCheckoutInfo } from 'redux/cart/actions';
import { orderSlice } from './slice';

const { setOrder, setManyOrders, changeOrderLoading, setOrderStatus } = orderSlice.actions;

const checkOrderStatus =
  (id: string, callback?: Callback<OrderStatus>): AppThunk =>
  async (dispatch) => {
    dispatch(changeOrderLoading(true));

    dispatch(
      httpAction<unknown, unknown, OrderStatus>(
        {
          method: 'GET',
          url: `${OrderApi.order}/${id}/status`,
          needAuth: true
        },
        async (res) => {
          dispatch(changeOrderLoading(false));

          if (res.data) {
            dispatch(setOrderStatus(res.data));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const getOrderDetail =
  (id: string, callback?: Callback<Order>): AppThunk =>
  async (dispatch) => {
    dispatch(changeOrderLoading(true));

    dispatch(
      httpAction<unknown, unknown, Order>(
        {
          method: 'GET',
          url: `${OrderApi.order}/${id}`,
          needAuth: true
        },
        async (res) => {
          dispatch(changeOrderLoading(false));

          if (res.data) {
            dispatch(setOrder(res.data));

            const checkoutInfo: CheckoutInfo = {
              gstAmount: res.data.gstAmount,
              totalAmount: res.data.totalAmount,
              subTotalAmount: res.data.subTotalAmount,
              totalDiscountAmount: res.data.discountAmount,
              totalPayableAmount: res.data.payableAmount,
              items: res.data.items.map((v) => {
                const result = {
                  id: v.id!,
                  itemDescription: v.description,
                  unitPrice: 0,
                  quantity: v.quantity,
                  amount: v.amount
                };

                if (v.discount) {
                  return {
                    ...result,
                    discount: {
                      description: `Less - ${v.discount.description}`,
                      amount: v.discount.amount
                    }
                  };
                }

                return result;
              })
            };

            dispatch(setCartCheckoutInfo(checkoutInfo));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const getOrderQRImage =
  (id: string, callback?: Callback<string>): AppThunk =>
  async (dispatch) => {
    dispatch(changeOrderLoading(true));

    dispatch(
      httpAction<unknown, unknown, string>(
        {
          method: 'GET',
          url: `${OrderApi.order}/${id}/qr`,
          needAuth: true
        },
        async (res) => {
          dispatch(changeOrderLoading(false));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const getOrderQRString =
  (id: string, callback?: Callback<string>): AppThunk =>
  async (dispatch) => {
    dispatch(changeOrderLoading(true));

    dispatch(
      httpAction<unknown, unknown, string>(
        {
          method: 'GET',
          url: `${OrderApi.order}/${id}/qr-string`,
          needAuth: true
        },
        async (res) => {
          dispatch(changeOrderLoading(false));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const simulatePayment =
  (id: string, amount: number): AppThunk =>
  async (dispatch) => {
    dispatch(
      httpAction<unknown, unknown, string>(
        {
          method: 'POST',
          url: `payment/dbs-pay`,
          data: { billReferenceNumber: id, amount },
          needAuth: true
        },
        async () => {
          dispatch(changeOrderLoading(false));
        }
      )
    );
  };

export const getOrders =
  (page: number, size: number, sort: string, callback?: Callback<OrderListResponse>): AppThunk =>
  async (dispatch) => {
    dispatch(changeOrderLoading(true));

    dispatch(
      httpAction<unknown, unknown, OrderListResponse>(
        {
          method: 'GET',
          url: `${OrderApi.order}?page=${page}&size=${size}&sort=${sort}`,
          needAuth: true
        },
        async (res) => {
          dispatch(changeOrderLoading(false));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const getRewardCodeByOrderId =
  (orderId: string, callback?: Callback<string>): AppThunk =>
  async (dispatch) => {
    dispatch(changeOrderLoading(true));

    dispatch(
      httpAction<unknown, unknown, string>(
        {
          method: 'GET',
          url: OrderApi.getRewardCode(orderId),
          needAuth: true
        },
        async (res) => {
          dispatch(changeOrderLoading(false));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

export {
  checkOrderStatus,
  getOrderDetail,
  getOrderQRImage,
  getOrderQRString,
  simulatePayment,
  setOrder,
  setManyOrders,
  getRewardCodeByOrderId
};
