// TYPES
import { AppThunk } from 'redux/type';

// BASE ACTION
import { httpAction } from 'redux/base/action';

// MODEL
import { Event } from 'model';

// CONSTANT
import { ApiEvent } from 'constant';

// TYPE
import { Callback } from 'interface';

// HELPERS
import { generateMapObjFromArr } from 'helpers/array';

// SLICE
import { eventSlice } from './slice';

// ACTIONS
const { changeEventLoading, setEvent, setManyEvents, setEventsMaps } = eventSlice.actions;

const getEventByProduct =
  (id: string, callback?: Callback<Event[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeEventLoading(true));

    dispatch(
      httpAction<unknown, unknown, Event[]>(
        {
          method: 'GET',
          url: ApiEvent.GetEventByProduct(id),
          needAuth: true
        },
        async (res) => {
          dispatch(changeEventLoading(false));

          if (res.data) {
            const mapEvents = generateMapObjFromArr<Event>(res.data, 'id');

            dispatch(setManyEvents(res.data));
            dispatch(setEventsMaps(mapEvents));
          }

          if (callback) {
            callback(res.data || []);
          }
        }
      )
    );
  };

export { setEvent, setManyEvents, setEventsMaps, getEventByProduct };
