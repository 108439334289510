import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { InTake, InTakeState } from 'model';

// ACTION TO CHANGE STATE
export const changeInTakeLoading: CaseReducer<InTakeState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const setInTake: CaseReducer<InTakeState, PayloadAction<InTake | undefined>> = (state, action) => {
  state.single = action.payload;
};

export const setManyInTakes: CaseReducer<InTakeState, PayloadAction<InTake[]>> = (state, action) => {
  state.many = action.payload;
};
