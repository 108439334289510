// TYPE
import { RootState } from 'redux/type';
import { AppMessage, AppConfiguration } from 'model';

export const selectAppLoadingStatus = (state: RootState): boolean => state.appReducer.loading;
export const selectAppMessage = (state: RootState): AppMessage | undefined => state.appReducer.message;
export const selectAssociationStatus = (state: RootState): boolean => state.appReducer.associationCreated;
export const selectAppConfiguration = (state: RootState): AppConfiguration => state.appReducer.appConfiguration;
export const selectOpenModalToRefreshApp = (state: RootState): boolean => state.appReducer.openModalToRefreshApp;
export const isDisabledLovedOne = (state: RootState): boolean => state.appReducer.appConfiguration.disabledLovedOne;
