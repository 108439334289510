import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { CardState } from 'model';

// REDUCER ACTION
import { changeCardLoading, setCard, setManyCards, setPaymentDefault, setValidCards, setIsRefresh } from './reducers';

export const initialState: CardState = {
  single: undefined,
  many: [],
  validCards: [],
  loading: false,
  isDefaultPayment: false,
  isRefresh: false
};

export const cardSlice = createSlice({
  name: Domain.Card,
  initialState,
  reducers: {
    changeCardLoading,
    setCard,
    setManyCards,
    setPaymentDefault,
    setValidCards,
    setIsRefresh
  }
});

export default cardSlice.reducer;
