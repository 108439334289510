import React, { FC } from 'react';
import { FormikHelpers } from 'formik';

// COMPONENT
import Form from 'components/Form';

// HOOKS
import { useAppSelector } from 'hooks/redux';

// REDUX
import { initialSingleUser } from 'redux/user/slice';
import { selectIsAlreadySelectActiveAgeingService } from 'redux/service/selector';
import { selectIsAlreadyAssociatedDenticareClient } from 'redux/denticareClient/selector';

// TYPES
import { User } from 'model';

// SCHEMA
import { getSelectedUser } from 'redux/user/selector';
import { profileSchema } from './schema';

// PROPS
import { PersonalDetailFormikProps } from './interface';

// VIEWS
import { PersonalDetailInputForm } from './PersonalDetailInputForm';

export const PersonalDetailFormik: FC<PersonalDetailFormikProps> = ({ onSubmit, data, children, readonly }) => {
  const handleSubmit = (values: User, formikHeler: FormikHelpers<User>) => onSubmit(values, formikHeler);
  const isAlreadySelectActiveAgeingService = useAppSelector(selectIsAlreadySelectActiveAgeingService);
  const isAlreadyAssociatedDenticareClient = useAppSelector(selectIsAlreadyAssociatedDenticareClient);
  const selectedUser = useAppSelector(getSelectedUser);

  return (
    <Form<User> data={data} initialValues={initialSingleUser} schema={profileSchema} onSubmit={handleSubmit}>
      {(formik) =>
        children(
          formik,
          <PersonalDetailInputForm
            hideCHAS
            disableFullName={
              isAlreadySelectActiveAgeingService ||
              isAlreadyAssociatedDenticareClient ||
              selectedUser.profileLinkingStatus === 'LINKED'
            }
            disableNRIC={data && !!data.nric && data.nric.length > 0}
            formik={formik}
            isServiceRegScreen={false}
            readonly={readonly}
          />
        )
      }
    </Form>
  );
};
