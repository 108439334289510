import { NHColor } from 'ui-kit';
import styled from 'styled-components';

export const NextOfKinDetailsWrapper = styled.div`
  .withoutBorderContainer {
    padding: 20px;
    border-bottom: 2px solid ${NHColor.grey200};
  }

  .cardContainer {
    margin-top: 20px;
    border-radius: 3.5px;
    border: solid 1px #d3dae6;
  }

  .rightContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .editButton {
    margin-right: 10px;
  }

  .headerCard {
    padding: 20px;
    border-bottom: 1px solid ${NHColor.grey200};
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    p {
      margin-top: 10px;
    }
  }

  .content {
    margin-top: 20px;
  }

  .contentCard {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .addButton {
    // margin-top: 10px;
    display: flex;
    align-items: center;

    // svg {
    //   margin-right: 5px;
    // }
  }

  .cancelButton {
    margin-right: 10px;
  }

  .saveButton {
    margin-left: 10px;
  }

  .footer {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
