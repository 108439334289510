import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { EInvoiceState } from 'model';

// REDUCER ACTION
import {
  changeEInvoiceLoading,
  setEInvoiceData,
  changeShowLinkExpired,
  changeShowSomethingWentWrong,
  changeShowAlreadyPaid
} from './reducers';

export const initialState: EInvoiceState = {
  loading: true,
  data: undefined,
  showSomethingWentWrong: false,
  linkExpired: false,
  alreadyPaid: false
};

export const eInvoiceSlice = createSlice({
  name: Domain.EInvoice,
  initialState,
  reducers: {
    changeEInvoiceLoading,
    setEInvoiceData,
    changeShowSomethingWentWrong,
    changeShowLinkExpired,
    changeShowAlreadyPaid
  }
});

export default eInvoiceSlice.reducer;
