import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

// ROUTE
import { Router } from 'constant';

// COMPONENT
import { baseLoader } from 'components/Loading';
import SomethingWentWrongPage from 'components/SomethingWentWrongPage';

const LandingPage = baseLoader(() => import('page/Appointment/LandingPage'));

const AppAppointmentRoute: FC = () => (
  <Switch>
    <Route exact component={SomethingWentWrongPage} path={Router.appointmentPageSomethingWrong} />
    <Route exact component={LandingPage} path={Router.appointmentWithId} />
  </Switch>
);

export default AppAppointmentRoute;
