import React, { FC, useMemo } from 'react';
import { Grid, Div, Button, isXSScreen } from 'ui-kit';
import { useTranslation } from 'react-i18next';
import { isEmpty, capitalize } from 'lodash';

// COMPONENTS
import InfoItem from 'components/InfoItem';

// CONSTANT
import { nationalities } from 'constant/data';

// HELPER
import { toDate } from 'helpers/date';
import { display } from 'helpers/string/generation';

// STYLE
import { PersonalDetailsWrapper } from '../styles';

// PROPS
import { PersonalDetailItemProps } from './interface';

export const PersonalDetailItem: FC<PersonalDetailItemProps> = ({ item, onSelect, hideCHAS }) => {
  const { t: locale } = useTranslation(['profile', 'common']);
  const isMobile = isXSScreen();

  const renderEditButton = () =>
    onSelect && (
      <Grid item xs={2}>
        <Div className="buttonContainer">
          <Button
            className="updateProfileButton"
            size="small"
            testId="editDetailBtn"
            variant="secondary"
            onClick={() => onSelect(item)}
          >
            {locale('common:edit')}
          </Button>
        </Div>
      </Grid>
    );

  const nationality = useMemo(
    () => nationalities.find((v) => v.value === item?.nationality)?.label,
    [item.nationality]
  );

  return (
    <PersonalDetailsWrapper data-testid="personal-detail-item">
      <Grid container spacing={3}>
        <Grid item sm={5} testId="reviewName" xs={12}>
          <InfoItem content={display(item.name)} title={locale('profile:clientFullName')} />
        </Grid>
        <Grid item sm={5} testId="reviewDoB" xs={12}>
          <InfoItem content={display(toDate(item.dateOfBirth))} title={locale('dateOfBirth')} />
        </Grid>
        {!isMobile && renderEditButton()}
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={5} testId="reviewGender" xs={12}>
          <InfoItem content={display(item.gender)} title={locale('profile:gender')} />
        </Grid>
        <Grid item sm={5} testId="reviewNRIC" xs={12}>
          <InfoItem content={display(item.nric)} title={locale('profile:nric')} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={5} testId="reviewEmail" xs={12}>
          <InfoItem content={display(item.email)} title={locale('common:email')} />
        </Grid>
        <Grid item sm={5} testId="reviewMaritalStatus" xs={12}>
          <InfoItem content={display(item.maritalStatus)} title={locale('profile:maritalStatus')} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={5} testId="reviewMobileNumber" xs={12}>
          <InfoItem content={display(item.mobileNumber)} title={locale('profile:mobileNumber')} />
        </Grid>
        <Grid item testId="reviewHomeNumber" xs={5}>
          <InfoItem content={display(item.homeNumber ?? '')} title={`${locale('profile:homeNumber')} `} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={5} testId="reviewNationality" xs={12}>
          <InfoItem content={display(item.residentStatus)} title={locale('profile:residentStatus')} />
        </Grid>
        <Grid item sm={5} xs={12}>
          {!isEmpty(item.otherResidentStatus) && (
            <InfoItem content={display(item.otherResidentStatus)} title={`${locale('profile:otherResidentStatus')} `} />
          )}
        </Grid>
        <Grid item sm={5} xs={12}>
          <InfoItem content={display(capitalize(nationality))} title={`${locale('profile:nationality')} `} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={5} testId="reviewLanguage" xs={12}>
          <InfoItem
            content={display((item.language as string[]).join(', '))}
            title={`${locale('profile:languagesSpoken')} `}
          />
        </Grid>
        <Grid item sm={5} xs={12}>
          {!isEmpty(item.otherLanguage) && (
            <InfoItem content={display(item.otherLanguage)} title={`${locale('profile:otherLanguage')}`} />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={5} testId="reviewReligion" xs={12}>
          <InfoItem content={display(item.religion)} title={`${locale('profile:religion')} `} />
        </Grid>
        <Grid item sm={5} xs={12}>
          {!isEmpty(item.otherReligion) && (
            <InfoItem content={display(item.otherReligion)} title={`${locale('profile:otherReligion')}`} />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={5} testId="reviewUnionMember" xs={12}>
          <InfoItem
            content={item.unionMember ? locale('common:yes') : locale('common:no')}
            title={locale('unionMember')}
          />
        </Grid>
      </Grid>

      {!hideCHAS && (
        <>
          <Div backgroundColor="grey" className="divider" />
          <Grid container spacing={3}>
            <Grid item sm={5} xs={12}>
              <InfoItem content={display(item.chasSubsidyTier)} title={`${locale('profile:chasSubsidyTier')} `} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InfoItem
                content={display(toDate(item.chasExpiryDate))}
                title={`${locale('profile:chasSubsidyExpiryDate')} `}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={5} xs={12}>
              <InfoItem content={display(item.companyName)} title={`${locale('profile:companyName')} `} />
            </Grid>
            <Grid item sm={6} xs={12} />
          </Grid>
        </>
      )}
      {isMobile && <Div margin={{ bottom: 20, left: 15 }}>{renderEditButton()}</Div>}
    </PersonalDetailsWrapper>
  );
};
