import styled from 'styled-components';
import { NHColor, Button, TableCell as BaseTableCell, Button as BaseButton } from 'ui-kit';

import { spacing } from 'helpers/styles';

export const ServiceSelectionContainer = styled.div`
  .inputContainer {
    width: 320px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .bigInputContainer {
    width: 660px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .lineDivider {
    border: 1px solid #e2e2e2;
    width: 100%;
    height: 0px;
    margin-top: ${spacing(4)};
  }

  .expandButton {
    color: ${NHColor.charcoal700};
  }

  .btn-add-programme {
    margin-top: ${spacing(3)};
  }
`;

export const DismissButton = styled(Button)`
  width: 50px;
  height: 50px;
  border-width: 0px;
  min-width: 50px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 999999;
  color: ${NHColor.charcoal700};

  &:hover {
    background-color: transparent;
    border: none;
  }
`;

export const CustomTableCell = styled(BaseTableCell)`
  padding: 12px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  background-color: ${NHColor.charcoal700} !important;
  color: ${NHColor.white} !important;
`;

export const CustomTableCellBody = styled(BaseTableCell)`
  font-weight: 400 !important;
  font-size: 12px !important;
  background-color: ${NHColor.white} !important;
`;

export const CustomButton = styled(BaseButton)`
  width: 132px;
`;
