import React, { FC } from 'react';
import { Div, Button, Typography } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// STYLES
import { ReviewItemWrapper } from 'views/RegisterService/ReviewInformation/styles';

// COMPONENTS
import ReviewProgrammeItem from './ReviewProgrammeItem';

const ReviewServiceSelection: FC = () => {
  const { t: locale } = useTranslation(['registerService', 'common']);

  return (
    <ReviewItemWrapper>
      <Div className="header" testId="reviewServiceSelectionTitle">
        <Typography variant="h3">1. {locale('registerService:serviceSelection')}</Typography>
        <Button variant="secondary">{locale('registerService:edit')}</Button>
      </Div>

      <ReviewProgrammeItem />
    </ReviewItemWrapper>
  );
};

export default ReviewServiceSelection;
