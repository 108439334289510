import styled from 'styled-components';
import { NHColor } from 'ui-kit';

export const FormNavWrapperFromMdScreen = styled.div`
  .exitButton {
    color: ${NHColor.green600};
    text-transform: capitalize;
    font-weight: normal;
  }

  .nextButton {
    width: 230px;
    margin-left: 20px;
  }
`;

export const FormNavWrapperFromXSScreen = styled.div`
  margin-top: 100px;

  .exitButton {
    color: ${NHColor.green600};
    text-transform: capitalize;
    font-weight: normal;
  }
`;
