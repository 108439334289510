// TRANSLATIONS
import {
  profile,
  common,
  registerService,
  service,
  payment,
  productCatalog,
  denticare,
  medicalHistory,
  healthDeclaration,
  faq,
  egiro
} from './en';

export const resources = {
  en: {
    profile,
    common,
    registerService,
    service,
    payment,
    productCatalog,
    denticare,
    medicalHistory,
    healthDeclaration,
    faq,
    egiro
  }
};
