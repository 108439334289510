import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

// ROUTE
import { Router } from 'constant';

// COMPONENT
import { baseLoader } from 'components/Loading';

const UrlNotFoundPage = baseLoader(() => import('components/UrlNotFoundPage'));

const AppErrorRoute: FC = () => (
  <Switch>
    <Route exact component={UrlNotFoundPage} path={Router.NotFoundPage} />
  </Switch>
);

export default AppErrorRoute;
