import React, { FC, ReactNode } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import denticareTheme from 'config/denticare';

// STYLES
import { AppointmentWrapper } from './styles';

interface AppContainerProps {
  children: ReactNode;
}

const AppContainer: FC<AppContainerProps> = ({ children }) => (
  <AppointmentWrapper>
    <ChakraProvider theme={denticareTheme}>{children}</ChakraProvider>
  </AppointmentWrapper>
);

export default AppContainer;
