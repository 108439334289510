import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { FeedbackState, FeedBackRating } from 'model';
import { initialState } from './slice';

export const setSelectedFeedback: CaseReducer<FeedbackState, PayloadAction<FeedBackRating | undefined>> = (
  state,
  action
) => {
  state.selectedFeedback = action.payload;
};

export const setHasSubmitedFeedback: CaseReducer<FeedbackState, PayloadAction<boolean>> = (state, action) => {
  state.hasSubmittedFeedback = action.payload;
};

export const setfeedbackSuccess: CaseReducer<FeedbackState, PayloadAction<boolean>> = (state, action) => {
  state.feedbackSuccess = action.payload;
};

export const resetState: CaseReducer<FeedbackState> = (state) => {
  state.selectedFeedback = initialState.selectedFeedback;
  state.hasSubmittedFeedback = initialState.hasSubmittedFeedback;
  state.feedbackSuccess = initialState.feedbackSuccess;
};
