import { string, object } from 'yup';
import { isEmpty } from 'lodash';

import { isPhoneNumber, stringEmptyTransform } from 'helpers/validations';

import { Contact } from 'model';

export const contactSchema = object<Contact>({
  id: string().optional(),
  name: string().trim().required('Name is required'),
  email: string().email('Email is invalid').optional(),
  mobileNumber: string()
    .required('Mobile number is required')
    .transform(stringEmptyTransform)
    .test('is-phone', 'Mobile number is invalid ', (value) =>
      value && !isEmpty(value) ? isPhoneNumber(value) : false
    ),
  relationship: string().required('Relationship is required'),
  homeNumber: string()
    .optional()
    .transform(stringEmptyTransform)
    .test('is-phone', 'Home number is invalid', (value) => (value ? isPhoneNumber(value) : true)),
  otherRelationship: string().when('relationship', {
    is: (relationship) => relationship === 'Others',
    then: string().trim().required('Other relationship is required')
  })
}).defined();
