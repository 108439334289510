import styled from 'styled-components';
import { Div, Grid } from 'ui-kit';
import MainImg from 'assets/denticare/bg-desktop.png';
import MobileCoverImg from 'assets/denticare/mobile-cover.png';
import { Box } from '@material-ui/core';

export const LandingPageWrapper = styled(Grid)`
  color: var(--gray-700, #344054);
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: unset !important;
  flex-direction: column;
  background: #f9fafb;

  /* Text lg - 18/Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #344054;

  .bg-gradient {
    min-height: 400px;
  }

  .nav-bar {
    width: 100%;
    // padding: 0 100px;
    background: #ffffff;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // flex-shrink: 0;
    border-bottom: 1px solid var(--gray-100, #eff1f5);
    background: var(--White, #fff);

    /* Shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  }

  .nav-bar img {
    margin: 15px 0px 10px 0px;
  }

  .xl-placeholder {
    display: none;
  }

  .learn-more-btn {
    text-transform: none;
    text-decoration: underline;
    font-family: Inter;
    font-size: 16px;
    color: #344054;
    padding-top: 0px;
    font-weight: 400;
    cursor: pointer;
  }

  .main-content {
    padding: 0;
    margin: 0;
    flex: 1;
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    .nav-bar {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    .nav-bar {
      padding-left: 20px;
    }
  }

  @media screen and (min-width: 1280px) {
    .xl-placeholder {
      display: flex;
    }

    .nav-bar {
      padding-left: 0;
    }
  }

  @media screen and (min-width: 768px) {
    .nav-bar {
      // padding: 0 20px;
    }
  }

  @media screen and (min-width: 992px) {
    .main-content {
      max-width: 970px;
      flex: 1;
    }

    .nav-bar {
      padding-left: 70px;
    }
  }

  @media screen and (min-width: 1200px) {
    .nav-bar {
      // padding: 0 80px;
    }
  }

  @media screen and (min-width: 1226px) {
    .main-content {
      max-width: 1226px;
      flex: 1;
    }
  }
`;

export const DCBody = styled(Grid)`
  padding: 0 100px;

  @media screen and (min-width: 768px) {
    padding: 0 0px;
  }

  @media screen and (min-width: 992px) {
    padding: 0 60px;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const DCContent = styled<any>(Grid)`
  padding: 0 100px;
  position: relative;
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'unset')};

  .btn-back {
    border-radius: var(--XL, 24px) 32px 0px 0px;
    background: var(--primary-600, #007d81);
    color: #ffffff;
    padding: 6px 32px 60px 32px;
    max-width: ${(props) => `${props.maxWidth}px`};
    font-weight: 500;
  }

  .p-title {
    font-size: 24px;
    font-weight: 600;
    color: #101828;
    margin: 42px 0 0 0;
    font-family: Inter;
  }

  .p-description {
    margin: 12px 0 0 0;
    max-width: 800px;
  }

  .info-icon {
    float: left;
    position: absolute;
    margin-top: -3px;
    width: 16px;
    height: 16px;
  }

  .tooth-img {
    height: 35.15px;
    width: 35.15px;
    margin: 15px;
  }

  .tooth-wrapper {
    border-radius: 32px;
    background: var(--primary-100, #dcf6f7);
    height: 64px;
    width: 64px;
  }

  .success-btn {
    display: flex;
    padding: 12px 32px;
    justify-content: center;
    font-family: Inter;
    align-items: center;
    font-family: Inter;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--primary-400, #62d2d5);
    background: var(--primary-400, #62d2d5);
    width: 100%;
  }

  .content-c {
    display: flex;
    padding: 32px;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    border-radius: 16px;
    border: 0px solid var(--primary-400, #62d2d5);
    background: var(--White, #fff);
    box-shadow: 0px 4px 6px 0px rgba(16, 24, 40, 0.03), 0px 7px 11px 0px rgba(16, 24, 40, 0.08),
      0px -1px 3px 0px rgba(0, 0, 0, 0.1);
  }

  .checkoutBtn {
    color: #101828;
    font-weight: 600;
    margin-top: 1px;
  }

  .p-check-faq {
    color: var(--gray-900, #101828);

    /* Text md/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    text-transform: none;
  }

  #scalingDiv-mobile {
    display: none;
  }

  @media screen and (min-width: 768px) {
    padding: 0 20px;
    .dc-content-body {
      padding: 56px 30px 72px 30px;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 0 60px;
    .dc-content-body {
      padding: 56px 70px 72px 70px;
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 0 40px;

    .dc-content-body {
      padding: 56px 90px 72px 90px;
    }
  }

  @media screen and (max-width: 820px) {
    #scalingDiv-desktop {
      display: none;
    }

    #scalingDiv-mobile {
      display: block !important;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    top: -95px;
    margin-bottom: -95px;

    .btn-back {
      padding: 0px var(--XL, 24px) 36px 16px;
      align-items: center;
      background: var(--primary-600, #007d81);
    }

    .p-title {
      font-size: 20px;
      margin: 24px 0 0 0;
    }

    .p-description {
      font-size: 16px;
      margin: 8px 0 0 0;
    }

    .tooth-img {
      height: 20px;
      width: 20px;
      margin: 8px;
    }

    .tooth-wrapper {
      border-radius: 32px;
      background: var(--primary-100, #dcf6f7);
      height: 36px;
      width: 36px;
    }

    .content-c {
      padding: 3px 16px 16px 16px;
    }

    .success-btn {
      width: 100%;
    }

    .checkoutBtn {
      width: 100%;
    }

    .faqBtn {
      width: 100%;
    }
  }
`;

export const DCCover = styled(Div)`
  background-image: linear-gradient(90deg, rgba(16, 24, 40, 0.55) 0%, rgba(16, 24, 40, 0) 84.44%), url(${MainImg});
  background-position: center;
  background-size: cover;
  min-height: 400px;
  padding: 82px 0 0 100px;

  p {
    color: var(--White, var(--colour-base-white, #fff));

    /* Text xl - 20/Medium */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
  }

  .cover-title {
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .cover-content {
    max-width: 460px;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    padding: 44px 0 0 16px !important;
    background-image: linear-gradient(90deg, rgba(16, 24, 40, 0.55) 0%, rgba(16, 24, 40, 0) 84.44%),
      url(${MobileCoverImg});

    min-height: 240px !important;
    height: 240px !important;

    .cover-des {
      display: none;
    }

    .cover-content {
      font-size: 16px;
    }

    .cover-title {
      width: 90px;
      line-height: 28px;
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 82px 0 0 20px;
  }

  @media screen and (min-width: 992px) {
    padding: 82px 0 0 70px;
  }

  @media screen and (min-width: 1200px) {
    padding: 82px 0 0 130px;
  }
`;

export const Header = styled(Box)`
  background: var(--White, #fff);

  /* Shadow/md */
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  padding: 4px 16px;
`;
