// TYPES
import { AppThunk } from 'redux/type';

// HTTPS
import { httpService, Response, CustomAxiosRequest } from 'config/http';

// AUTH SERVICE
import { authService } from 'config/auth';

// SLICE
import { appSlice } from 'redux/app/slice';
import { ApiAuth, ApiProfile, ApiLinkPay, Router } from 'constant';
import { selectEnabledErrorMessageForLinkPass } from 'redux/featureFlag/selectors';

// ACTIONS
const { setAppMessage } = appSlice.actions;

// L: Body Type
// K: Params Type
// M: Response Type
// Http action for all apis
export function httpAction<L, K, M>(
  requestConfig: CustomAxiosRequest<L, K>,
  callback: (response: Response<M>) => void
): AppThunk {
  return async (dispatch, getState) => {
    const response = await httpService.execute<L, K, M>({
      ...requestConfig
    });
    const isDenticareRevampRoute = window.location.pathname.includes(Router.denticare);

    const logoutStatusCode = [1002, 1004];

    if (response.httpCode === 500 && isDenticareRevampRoute) {
      window.location.href = Router.denticareCommonError;

      return;
    }

    // Logout user if the status code is 1002,1004
    if (response.httpCode === 401 && response.statusCode && logoutStatusCode.includes(response.statusCode)) {
      dispatch(
        setAppMessage({
          type: 'error',
          content: 'Your login token is invalid, Please re-login'
        })
      );

      setTimeout(async () => {
        await authService.logOut();
      }, 2000);

      return;
    }

    // Logout user when status code is 1003 and api is not association api
    if (
      response.httpCode === 400 &&
      response.statusCode &&
      response.statusCode === 1003 &&
      requestConfig.url !== ApiAuth.GenerateAssociation
    ) {
      dispatch(
        setAppMessage({
          type: 'error',
          content: 'Your login token is expired, Please re-login!'
        })
      );

      setTimeout(async () => {
        await authService.logOut();
      }, 2000);

      return;
    }

    // Call action to store message for this 2 cases
    // first case, httpCode is either 404 or 500 and url is not /auth/validate
    // second case, statusCode is 0
    const errorStatusCode = [400, 404, 409, 500];

    const enabledLinkPassErrorMessage = selectEnabledErrorMessageForLinkPass(getState());

    if (
      errorStatusCode.includes(response.httpCode) &&
      requestConfig.url !== ApiAuth.GenerateAssociation &&
      response.statusCode &&
      response.statusCode !== 1002
    ) {
      if (!requestConfig.hideToast && !enabledLinkPassErrorMessage) {
        const msg =
          requestConfig.url === ApiProfile.ProfileLinking
            ? 'The client ID entered is incorrect. Please note that it is case sensitive. (E.g. "i" and I, or the letter "o" and number "0")'
            : requestConfig.url === ApiLinkPay.AddCard
            ? response.message ?? ''
            : response.error!;

        dispatch(
          setAppMessage({
            type: 'support',
            content: msg
          })
        );
      }

      if (
        enabledLinkPassErrorMessage &&
        !requestConfig.hideToast &&
        (requestConfig.url !== ApiProfile.ProfileLinking || requestConfig.url !== ApiLinkPay.AddCard)
      ) {
        dispatch(
          setAppMessage({
            type: 'support',
            content: response.error || ''
          })
        );
      }

      if (callback) {
        callback(response);
      }

      return;
    }

    // When the request returns success
    callback(response);
  };
}
