import { RootState } from 'redux/type';

// MODEL
import { Session, VacantSlot } from 'model';

export const selectSessionLoadingStatus = (state: RootState): boolean => state.sessionsReducer.loading;
export const selectSession = (state: RootState): Session | undefined => state.sessionsReducer.single;
export const selectManySessions = (state: RootState): Session[] => state.sessionsReducer.many || [];
export const selectCompletedSessions = (state: RootState): Session[] => state.sessionsReducer.completedSessions || [];
export const selectScheduledSessions = (state: RootState): Session[] => state.sessionsReducer.scheduledSessions || [];
export const selectCancelledSessions = (state: RootState): Session[] => state.sessionsReducer.cancelledSessions || [];
export const selectCancelledWithChargeSessions = (state: RootState): Session[] =>
  state.sessionsReducer.cancelledWithChargeSessions || [];
export const selectCancelledWithoutChargeSessions = (state: RootState): Session[] =>
  state.sessionsReducer.cancelledWithoutChargeSessions || [];
export const selectPreClassSessions = (state: RootState): Session[] => state.sessionsReducer.preClassSessions || [];
export const isPreClassSession = (session?: Session): boolean => {
  if (!session) return false;

  return session.type === 'PRE_SESSION';
};
export const sessionTitle = (session?: Session): string => {
  if (!session) return '';

  if (session.type === 'PRE_SESSION') {
    return 'Pre-assessment';
  }

  return session.eventName || session.title || '';
};

export const selectTotalAvailableSlots = (state: RootState): number => state.sessionsReducer.totalAvailableSlots;

export const selectTotalTimeSlots = (state: RootState): number => state.sessionsReducer.totalTimeSlots;

export const selectVacantSlots = (state: RootState): VacantSlot[] => state.sessionsReducer.vacantSlots || [];
