import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { AppTourComponent, AppTourState } from 'model';

export const setEnabledAppTour: CaseReducer<AppTourState, PayloadAction<boolean>> = (state, action) => {
  state.enabled = action.payload;
};

export const setAppTourComponent: CaseReducer<AppTourState, PayloadAction<AppTourComponent | undefined>> = (
  state,
  action
) => {
  state.component = action.payload;
};
