const order = {
  createdDate: '2022-06-12T00:10:00.743+0000',
  lastModifiedDate: '2022-06-12T00:10:00.743+0000',
  createdBy: 'System',
  lastModifiedBy: 'System',
  id: '3017bfdb-a6d9-443c-afab-7ea8b63c42d3',
  customerId: '7c9dcc94-19b7-4a93-bf9c-4db5b5cf825a',
  customerName: 'Nhat',
  payerId: '7c9dcc94-19b7-4a93-bf9c-4db5b5cf825a',
  payerName: 'Nhat',
  orderNumber: 'foeofnepcifgzsa',
  subTotalAmount: 320,
  discountAmount: 0,
  gstAmount: 22.4,
  totalAmount: 320,
  payableAmount: 342.4,
  status: 'PENDING',
  address: {
    block: '55',
    level: '',
    unit: '',
    street1: 'COMPASSVALE BOW',
    street2: 'THE QUARTZ',
    postalCode: '544986'
  },
  paymentTransaction: null,
  items: [
    {
      createdDate: '2022-06-12T00:10:00.747+0000',
      lastModifiedDate: '2022-06-12T00:10:00.747+0000',
      createdBy: 'System',
      lastModifiedBy: 'System',
      id: 'eef41c24-9ef9-49d7-a67b-8c36e4eaad63',
      quantity: 1,
      productId: '228e4262-6e53-4ad7-832e-e6789c1a831d',
      offeringId: '698ec57a-fd68-4d2e-9ce3-9bbefc7c8778',
      centerId: 'be21fe61-0081-e511-8117-c4346badc5f4',
      description: 'Circuit Training- launch promo',
      amount: 320,
      discount: null,
      location: {
        title: 'NTUC HEALTH SENIOR DAY CARE (HENDERSON)',
        block: '95B',
        level: '01',
        unit: '22',
        street: '95C Henderson Road',
        building: null,
        postalCode: '152095'
      }
    }
  ]
};

export { order };
