import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import { GetAppointmentResponse, AppointmentState } from 'model';

export const changeAppointmentLoading: CaseReducer<AppointmentState, PayloadAction<boolean>> = (state, action) => {
  state.loading = action.payload;
};

export const changeShowSomethingWentWrong: CaseReducer<AppointmentState, PayloadAction<boolean>> = (state, action) => {
  state.showSomethingWentWrong = action.payload;
};

export const setAppointmentData: CaseReducer<AppointmentState, PayloadAction<GetAppointmentResponse | undefined>> = (
  state,
  action
) => {
  state.data = action.payload;
};
