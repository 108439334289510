import React, { FC } from 'react';
import { Div, Button, Typography, Grid } from 'ui-kit';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import { TranslationWithColor } from 'components/TranslationWithStyle';

// VIEWS
import InvoiceDetail from './InvoiceDetail';

const PaymentCompletion: FC = () => {
  const { t: locale } = useTranslation(['common', 'service', 'payment']);

  return (
    <Grid container spacing={3} testId="payment-completion-register">
      <Grid item xs={12}>
        <Typography variant="h2">{locale('payment:thankYourForYourPayment')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <InvoiceDetail />
      </Grid>
      <Grid item xs={12}>
        <Div backgroundColor="grey" height={2} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <TranslationWithColor i18key="common:youMayReturnToPage" value={{ page: 'My Services' }} />
        </Typography>
        <Div padding={{ left: 10 }}>
          <Typography variant="body1">&#x2022; {locale('service:bookYourSession')}</Typography>
        </Div>
      </Grid>
      <Grid item xs={12}>
        <Button>{locale('common:returnToMyServices')}</Button>
      </Grid>
    </Grid>
  );
};

export default PaymentCompletion;
