import React, { FC, ReactNode, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import denticareTheme from 'config/denticare';
import { createDenticareSessionId } from 'redux/denticareClient/actions';
import { isEmpty } from 'lodash';
import ReactGA from 'react-ga4';

// REDUX
import { useAppSelector, useAppDispatch } from 'hooks';
import { selectDenticareSessionId } from 'redux/denticareClient/selector';
import { selectHasOneAppointment, selectFinishLoadingData } from 'redux/denticareBookAppointment/selector';
import {
  getRevampCenter,
  getRevampDentist,
  getRevampDates,
  getRevampMonth,
  getRevampAvailableSlots,
  setFinishLoadingData
} from 'redux/denticareBookAppointment/action';

// STYLES
import { DenticareWrapper } from './styles';

interface AppContainerProps {
  children: ReactNode;
}

const AppContainer: FC<AppContainerProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const denticareSessionId = useAppSelector(selectDenticareSessionId);
  const hasOneAppointment = useAppSelector(selectHasOneAppointment);
  const finishLoadingData = useAppSelector(selectFinishLoadingData);

  useEffect(() => {
    if (!isEmpty(denticareSessionId)) {
      ReactGA.initialize(process.env.REACT_APP_GA!, {
        gaOptions: {
          userId: denticareSessionId
        }
      });
    }
  }, [denticareSessionId]);

  useEffect(() => {
    dispatch(createDenticareSessionId());
    dispatch(setFinishLoadingData(false));
  }, []);

  useEffect(() => {
    if (denticareSessionId && typeof hasOneAppointment === 'boolean' && hasOneAppointment && !finishLoadingData) {
      dispatch(
        getRevampCenter(() => {
          dispatch(
            getRevampDentist(() => {
              dispatch(
                getRevampMonth(() => {
                  dispatch(
                    getRevampDates(() => {
                      dispatch(
                        getRevampAvailableSlots(() => {
                          dispatch(setFinishLoadingData(true));
                        })
                      );
                    })
                  );
                })
              );
            })
          );
        })
      );
    }
  }, [dispatch, denticareSessionId, hasOneAppointment, finishLoadingData]);

  useEffect(() => {
    if (denticareSessionId) {
      dispatch(getRevampCenter());
    }
  }, [dispatch, denticareSessionId, hasOneAppointment]);

  if (isEmpty(denticareSessionId)) return null;

  return (
    <DenticareWrapper>
      <ChakraProvider theme={denticareTheme}>{children}</ChakraProvider>
    </DenticareWrapper>
  );
};

export default AppContainer;
