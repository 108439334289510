import styled from 'styled-components';
import { Grid } from 'ui-kit';

export const FooterWrapper = styled(Grid)`
  // padding-left: 200px !important;
  padding-top: 64px !important;
  padding-bottom: 64px !important;
  background: var(--primary-600, #007d81) !important;
  margin-top: -90px;
  text-transform: none;
  // max-width: 100%;
  width: unset !important;
  margin-right: 0px !important;
  display: flex;
  justify-content: center;

  color: var(--White, var(--colour-base-white, #fff));

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  .MuiButton-label {
    color: #ffffff !important;
  }

  .footer-content {
    max-width: 970px;
  }

  .footer-item {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-transform: none;
    padding: 0px;
    min-width: unset;
    color: #ffffff;
  }

  @media screen and (max-width: 768px) {
    margin-top: -150px;
    padding-left: 16px !important;
    padding-top: 16px !important;
    padding-bottom: 32px !important;
    font-size: 14px;

    .footer-item {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 768px) {
    padding-left: 20px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 20px;
  }

  .footer-menu-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
`;
