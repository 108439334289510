import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { EventState } from 'model';

// REDUCER ACTION
import { changeEventLoading, setEvent, setEventsMaps, setManyEvents } from './reducers';

export const initialState: EventState = {
  single: undefined,
  many: [],
  eventsMap: {},
  loading: false
};

export const eventSlice = createSlice({
  name: Domain.Event,
  initialState,
  reducers: {
    changeEventLoading,
    setEvent,
    setEventsMaps,
    setManyEvents
  }
});

export default eventSlice.reducer;
