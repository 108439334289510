import React, { FC, useState } from 'react';
import { FormikHelpers } from 'formik';

// COMPONENT
import Form from 'components/Form';

// REDUX
import { initialSingleAddress } from 'redux/address/slice';

// TYPES
import { Address } from 'model';

// SCHEMA
import { addressSchema } from './schema';

// PROPS
import { AddressFormikProps } from './interface';

// COMPONETNS
import { AddressInputForm } from './AddressInputForm';

export const AddressFormik: FC<AddressFormikProps> = ({
  onSubmit,
  data,
  children,
  addresses,
  onChangeAddress,
  viewType = 'userProfile',
  readOnly
}) => {
  const [isInvalidPostalCode, setIsInvalidPostalCode] = useState(false);

  const handleSubmitForm = (data: Address, formilHelper: FormikHelpers<Address>) => {
    if (viewType === 'userProfile') {
      if (isInvalidPostalCode) {
        formilHelper.setSubmitting(false);

        return;
      }

      if (onSubmit) {
        onSubmit(data, formilHelper);
      }
    } else if (onSubmit) {
      onSubmit({ ...data, isValidForm: !isInvalidPostalCode }, formilHelper);
    }
  };

  return (
    <Form<Address> data={data} initialValues={initialSingleAddress} schema={addressSchema} onSubmit={handleSubmitForm}>
      {(formik) =>
        children(
          formik,
          <AddressInputForm
            addresses={addresses}
            data={data}
            formik={formik}
            isInvalidPostalCode={isInvalidPostalCode}
            readOnly={readOnly}
            viewType={viewType}
            onCallbackChangeValidateStatusForm={(data) => {
              if (onChangeAddress) onChangeAddress(data!);
            }}
            onCallbackPostalCode={(v) => setIsInvalidPostalCode(v!)}
          />
        )
      }
    </Form>
  );
};
