import React, { FC, useMemo } from 'react';
import { Div, Typography } from 'ui-kit';
import { Add as AddIcon, ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

// IMAGES
import PaynowLogo from 'assets/paynow.png';
import MasterCard from 'assets/MasterCard.svg';
import Visa from 'assets/Visa.png';
import AmericanExpress from 'assets/AmericanExpress.svg';

// PROPS
import { PaymentCardProps } from './interface';

// STYLE
import { PaymentCardWrapper, CardNumberWrapper } from './style';

const PaymentCard: FC<PaymentCardProps> = ({ type, selected, onClick, card, showAddMoreCards, showAll }) => {
  const { t: locale } = useTranslation('payment');

  const cardLogo = useMemo(() => {
    if (type === 'PayNow') {
      return <img src={PaynowLogo} />;
    }

    if (type === 'MasterCard') {
      return <img height={23} src={MasterCard} />;
    }

    if (type === 'VISA' || type === 'VISAPLUS') {
      return <img height={23} src={Visa} width={70} />;
    }

    if (type === 'AMEX') {
      return <img src={AmericanExpress} />;
    }

    return (
      <Div alignItems="center" display="flex" flexDirection="row" justifyContent="center">
        <AddIcon color="primary" />
        <Div margin={{ left: 10 }}>
          <Typography color="teal" fontWeight="bold" variant="body1">
            {locale('addCardType', { value: locale('debitCard') }).toUpperCase()} /
          </Typography>
          <Typography color="teal" fontWeight="bold" variant="body1">
            {locale('cardType', { value: locale('creditCard') }).toUpperCase()}
          </Typography>
        </Div>
      </Div>
    );
  }, [locale, type]);

  const cardNumber = card && (
    <CardNumberWrapper variant="body1">
      {locale('endingIn', { value: card.number.slice(card.number.length - 4, card.number.length) })}
    </CardNumberWrapper>
  );

  if (showAddMoreCards) {
    return (
      <PaymentCardWrapper selected={false} shadowLevel={3} onCLick={onClick}>
        <Div alignItems="center" display="flex" justifyContent="center">
          {showAll ? (
            <ArrowForwardIos color="primary" fontSize="medium" />
          ) : (
            <ArrowBackIos color="primary" fontSize="medium" />
          )}
          <CardNumberWrapper variant="body1">{showAll ? locale('showAll') : locale('showLess')}</CardNumberWrapper>
        </Div>
      </PaymentCardWrapper>
    );
  }

  return (
    <PaymentCardWrapper selected={!!selected} shadowLevel={3} onCLick={onClick}>
      {cardLogo}
      {cardNumber}
    </PaymentCardWrapper>
  );
};

export default PaymentCard;
