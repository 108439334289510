import { Auth0ProviderOptions } from '@auth0/auth0-react';

// HISTORY
import { history } from './history';

const onRedirectCallback = (appState: any) => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const LinkIDConfiguration = {
  domain: process.env.REACT_APP_LINK_ID_DOMAIN,
  clientId: process.env.REACT_APP_LINK_ID_CLIENT_ID,
  scope: process.env.REACT_APP_SCOPE,
  audience: process.env.REACT_APP_AUDIENCE
};

const providerConfig: Auth0ProviderOptions = {
  domain: LinkIDConfiguration.domain!,
  clientId: LinkIDConfiguration.clientId!,
  audience: LinkIDConfiguration.audience,
  scope: LinkIDConfiguration.scope,
  ...(LinkIDConfiguration.audience ? { audience: LinkIDConfiguration.audience } : null),
  redirectUri: window.location.origin,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  onRedirectCallback
};

export default providerConfig;
