import React, { FC } from 'react';
import { Trans } from 'react-i18next';

// STYLE
import { TranslationWithColorWrapper, TranslationWithBoldWrapper } from './style';

// INTERFACE
import { TranslationWithStyleProps } from './interface';

export const TranslationWithColor: FC<TranslationWithStyleProps> = ({ i18key, value, onClick }) => (
  <Trans components={{ green: <TranslationWithColorWrapper onClick={onClick} /> }} i18nKey={i18key} values={value} />
);

export const TranslationWithBold: FC<TranslationWithStyleProps> = ({ i18key, value, onClick }) => (
  <Trans components={{ green: <TranslationWithBoldWrapper onClick={onClick} /> }} i18nKey={i18key} values={value} />
);
