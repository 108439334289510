import { isEmpty } from 'lodash';

import { DenticareBookAppointmentAPI, Router } from 'constant';
import { Callback } from 'interface';
import {
  AppointmentBooked,
  BookAppointment,
  Clinic,
  Dentist,
  Treatment,
  CancelledDenticareAppointment,
  RetrieveEmptySlot,
  RetrieveEmptySlotResponse,
  DenticareAppointmentHistoryResponse,
  DenticareAppointment,
  RevampDenticareCenter,
  RevampDentist,
  RevampAvailableMonths,
  RevampAvailableDates,
  RevampAvailableSlots,
  RevampDenticareClient,
  ReviewConfirmResponse,
  CancellationData,
  BookingResponseType
} from 'model';
import { setAppMessage } from 'redux/app/actions';
import { httpAction } from 'redux/base/action';
import { AppThunk } from 'redux/type';
import { createDenticareSessionId } from 'redux/denticareClient/actions';
import { selectDenticareSessionId } from 'redux/denticareClient/selector';
import {
  selectSelectedCenter,
  selectSelectedDentist,
  selectSelectedMonth,
  selectSelectedDate,
  selectSelectedSlot,
  selectIsExistingClient,
  selectScalingServiceId,
  selectHasOneAppointment,
  selectCancellationData,
  selectNewDentist
} from 'redux/denticareBookAppointment/selector';
import { trackEvent } from 'helpers/ga';
import { denticareBookAppointmentSlice } from './slice';

const {
  changeDenticareLoading,
  setTreatmentsDenticare,
  setClinicsDenticare,
  setDentistsDenticare,
  setDenticareAppointmentHistory,
  setSelectedDenticareAppointment,
  setUpcomingAppointment,
  setRevampCenters,
  setRevampDates,
  setRevampDentist,
  setRevampMonths,
  setRevampSlots,
  setSelectedCenter,
  setSelectedDate,
  setSelectedDentist,
  setSelectedMonth,
  setSelectedSlot,
  setSelectedDenticareClient,
  setExistingClient,
  setHasOneAppointment,
  setScalingServiceId,
  setLoadingFetchingCenter,
  setLoadingFetchingDate,
  setLoadingFetchingDentist,
  setLoadingFetchingMonth,
  setLoadingFetchingSlot,
  setCancellationData,
  setFinishLoadingData,
  resetCancellationData
} = denticareBookAppointmentSlice.actions;

const getAllTreatments =
  (callback?: Callback<Treatment[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareLoading(true));

    dispatch(
      httpAction<unknown, unknown, Treatment[]>(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.getTreatmentsList,
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(changeDenticareLoading(false));

          dispatch(setTreatmentsDenticare(res.data || []));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const getAllClinics =
  (treatmentId = '', callback?: Callback<Clinic[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareLoading(true));

    dispatch(
      httpAction<unknown, unknown, Clinic[]>(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.getClinicsList(treatmentId),
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(changeDenticareLoading(false));

          dispatch(setClinicsDenticare(res.data || []));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const getAllDentists =
  (treatmentId = '', clinicId = '', callback?: Callback<Dentist[]>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareLoading(true));

    dispatch(
      httpAction<unknown, unknown, Dentist[]>(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.getDentistsList(treatmentId, clinicId),
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(changeDenticareLoading(false));

          dispatch(setDentistsDenticare(res.data || []));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const bookDenticareAppointment =
  (body: BookAppointment, callback?: Callback<AppointmentBooked>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareLoading(true));

    dispatch(
      httpAction<BookAppointment, unknown, AppointmentBooked>(
        {
          method: 'POST',
          url: DenticareBookAppointmentAPI.bookAppointment,
          data: body,
          needAuth: true
        },
        async (res) => {
          dispatch(changeDenticareLoading(false));

          if (res.httpCode === 200) {
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Book appointment successfully'
              })
            );
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const retrieveAvailableSlot =
  (body: RetrieveEmptySlot, callback?: Callback<RetrieveEmptySlotResponse>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareLoading(true));

    dispatch(
      httpAction<RetrieveEmptySlot, unknown, RetrieveEmptySlotResponse>(
        {
          method: 'POST',
          url: DenticareBookAppointmentAPI.retrieveEmptySlot,
          data: body,
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(changeDenticareLoading(false));

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const rescheduleDenticareAppointment =
  (appointmentId?: string, body?: BookAppointment, callback?: Callback<AppointmentBooked>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareLoading(true));

    if (!isEmpty(appointmentId)) {
      dispatch(
        httpAction<BookAppointment, unknown, AppointmentBooked>(
          {
            method: 'PUT',
            url: DenticareBookAppointmentAPI.rescheduleAppointment(appointmentId ?? ''),
            data: body,
            needAuth: true
          },
          async (res) => {
            dispatch(changeDenticareLoading(false));

            if (res.httpCode === 200) {
              dispatch(
                setAppMessage({
                  type: 'success',
                  content: 'Reschedule appointment successfully'
                })
              );
            }

            if (callback) {
              callback(res.data);
            }
          }
        )
      );
    }
  };

const getDenticareUpcomingAppointment =
  (callback?: Callback<DenticareAppointment>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareLoading(true));

    dispatch(
      httpAction<unknown, unknown, DenticareAppointment>(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.getUpcomingAppointment,
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(changeDenticareLoading(false));

          if (res.httpCode === 200) {
            dispatch(setUpcomingAppointment(res.data ? res.data : undefined));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const getDenticareAppointmentHistory =
  (callback?: Callback<DenticareAppointmentHistoryResponse>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareLoading(true));

    dispatch(
      httpAction<unknown, unknown, DenticareAppointmentHistoryResponse>(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.getDenticareAppointmentHistory,
          needAuth: true,
          hideToast: true
        },
        async (res) => {
          dispatch(changeDenticareLoading(false));

          if (res.data) {
            dispatch(setDenticareAppointmentHistory(res.data));
          }

          if (callback) {
            callback(res.data);
          }
        }
      )
    );
  };

const cancelDenticareAppointment =
  (appointmentId: string, data: CancelledDenticareAppointment, callback?: Callback<boolean>): AppThunk =>
  async (dispatch) => {
    dispatch(changeDenticareLoading(true));

    dispatch(
      httpAction<CancelledDenticareAppointment, unknown, boolean>(
        {
          method: 'DELETE',
          url: DenticareBookAppointmentAPI.cancelAppointment(appointmentId),
          needAuth: true,
          data
        },
        async (res) => {
          dispatch(changeDenticareLoading(false));

          let isSuccess = false;

          if (res.httpCode === 200) {
            isSuccess = true;
            dispatch(
              setAppMessage({
                type: 'success',
                content: 'Cancelled appointment successfully'
              })
            );
          }

          if (callback) {
            callback(isSuccess);
          }
        }
      )
    );
  };

// REVAMP
const getRevampCenter =
  (callback?: Callback<any>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setRevampCenters([]));
    const denticareSessionId = selectDenticareSessionId(getState());

    dispatch(setLoadingFetchingCenter(true));

    if (isEmpty(denticareSessionId)) return;

    dispatch(
      httpAction<unknown, unknown, { centers: RevampDenticareCenter[]; serviceId: string }>(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.newGetCenters(denticareSessionId),
          needAuth: false
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            dispatch(setScalingServiceId(res.data.serviceId));
            dispatch(setRevampCenters(res.data.centers));
          } else {
            dispatch(setScalingServiceId(''));
            dispatch(setRevampCenters([]));
          }

          dispatch(setLoadingFetchingCenter(false));

          if (callback) {
            callback();
          }
        }
      )
    );
  };

const getRevampMonth =
  (callback?: Callback<any>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setRevampMonths([]));
    dispatch(setLoadingFetchingMonth(true));

    const denticareSessionId = selectDenticareSessionId(getState());
    const selectedCenter = selectSelectedCenter(getState());
    const selectedResource = selectSelectedDentist(getState());
    const scalingServiceId = selectScalingServiceId(getState());

    if (isEmpty(denticareSessionId)) return;

    dispatch(
      httpAction<unknown, unknown, { availableMonths: string[] }>(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.newGetMonths(
            denticareSessionId,
            selectedCenter,
            scalingServiceId,
            selectedResource
          ),
          needAuth: false
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            const reformatedData = res.data.availableMonths.map<RevampAvailableMonths>((v) => ({
              value: v,
              label: v
            }));

            dispatch(setRevampMonths(reformatedData));
          } else {
            dispatch(setRevampMonths([]));
          }

          dispatch(setLoadingFetchingMonth(false));

          if (callback) {
            callback();
          }
        }
      )
    );
  };

const getRevampDentist =
  (callback?: Callback<any>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setRevampDentist([]));
    dispatch(setLoadingFetchingDentist(true));

    const denticareSessionId = selectDenticareSessionId(getState());
    const selectedCenter = selectSelectedCenter(getState());
    const scalingServiceId = selectScalingServiceId(getState());
    const hasOneAppointment = selectHasOneAppointment(getState());

    if (isEmpty(denticareSessionId)) return;

    dispatch(
      httpAction<unknown, unknown, { resources: RevampDentist[] }>(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.newGetDoctors(denticareSessionId, selectedCenter, scalingServiceId),
          needAuth: false
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            const noPreferenceDentist = res.data.resources.find((v) => v.resourceName === 'No preference');

            if (noPreferenceDentist && !hasOneAppointment) {
              dispatch(setSelectedDentist(noPreferenceDentist.resourceId!));

              if (typeof hasOneAppointment === 'boolean' && !hasOneAppointment) {
                dispatch(getRevampMonth());
              }
            }

            dispatch(setRevampDentist(res.data.resources));
          } else {
            dispatch(setRevampDentist([]));
          }

          dispatch(setLoadingFetchingDentist(false));

          if (callback) {
            callback();
          }
        }
      )
    );
  };

const getRevampDates =
  (callback?: Callback<any>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setRevampDates([]));
    dispatch(setLoadingFetchingDate(true));

    const denticareSessionId = selectDenticareSessionId(getState());
    const selectedCenter = selectSelectedCenter(getState());
    const selectedResource = selectSelectedDentist(getState());
    const selectedMonth = selectSelectedMonth(getState());
    const scalingServiceId = selectScalingServiceId(getState());

    if (isEmpty(denticareSessionId)) return;

    dispatch(
      httpAction<unknown, unknown, { availableDates: string[] }>(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.newGetDates(
            denticareSessionId,
            selectedCenter,
            scalingServiceId,
            selectedResource,
            selectedMonth
          ),
          needAuth: false
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            const reformatedData = res.data.availableDates.map<RevampAvailableDates>((v) => ({
              value: v,
              label: v
            }));

            dispatch(setRevampDates(reformatedData));
          } else {
            dispatch(setRevampDates([]));
          }

          dispatch(setLoadingFetchingDate(false));

          if (callback) {
            callback();
          }
        }
      )
    );
  };

const getRevampAvailableSlots =
  (callback?: Callback<any>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setRevampSlots([]));
    dispatch(setLoadingFetchingSlot(true));

    const denticareSessionId = selectDenticareSessionId(getState());
    const selectedCenter = selectSelectedCenter(getState());
    const selectedResource = selectSelectedDentist(getState());
    const selectedMonth = selectSelectedMonth(getState());
    const selectedDates = selectSelectedDate(getState());
    const scalingServiceId = selectScalingServiceId(getState());

    if (isEmpty(denticareSessionId)) return;

    dispatch(
      httpAction<unknown, unknown, { availableTimes: Record<string, any> }>(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.newGetAvailableSlots(
            denticareSessionId,
            selectedCenter,
            scalingServiceId,
            selectedResource,
            selectedMonth,
            selectedDates
          ),
          needAuth: false
        },
        async (res) => {
          if (res.httpCode === 200 && res.data && res.data.availableTimes) {
            const reformatedData = Object.keys(res.data.availableTimes).map<RevampAvailableSlots>((v) => ({
              value: v.toLowerCase(),
              label: v.toLowerCase()
            }));

            dispatch(setRevampSlots(reformatedData));
          } else {
            dispatch(setRevampSlots([]));
          }

          dispatch(setLoadingFetchingSlot(false));

          if (callback) {
            callback();
          }

          dispatch(setLoadingFetchingSlot(false));
        }
      )
    );
  };

const createOrUpdatePublicClient =
  (data: RevampDenticareClient, callback?: Callback<any>): AppThunk =>
  async (dispatch, getState) => {
    const denticareSessionId = selectDenticareSessionId(getState());
    const isExistingClient = selectIsExistingClient(getState());

    if (isEmpty(denticareSessionId)) return;

    trackEvent({
      action: 'Click',
      category: 'E-appointment-Booking-step2',
      label: `booking_type:${data.bookingForYourself ? 'self' : 'others'}`
    });

    dispatch(
      httpAction<RevampDenticareClient & { clientSessionId: string }, unknown, unknown>(
        {
          method: isExistingClient ? 'PUT' : 'POST',
          url: isExistingClient
            ? DenticareBookAppointmentAPI.updatePublicClient
            : DenticareBookAppointmentAPI.createPublicClient,
          needAuth: false,
          data: {
            ...data,
            clientSessionId: denticareSessionId
          }
        },
        async () => {
          if (callback) {
            callback();
          }
        }
      )
    );
  };

const getPublicAppointment =
  (backFromUnvailableSlot?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const denticareSessionId = selectDenticareSessionId(getState());

    if (isEmpty(denticareSessionId)) return;

    dispatch(
      httpAction<
        unknown,
        unknown,
        {
          monthAndYear: string;
          dayOfMonth: string;
          timeSlot: string;
          dentist: string;
          clinicName: string;
          centerId: string;
          resourceId: string;
        }
      >(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.getPublicAppointment(denticareSessionId),
          needAuth: false
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            dispatch(setSelectedCenter(res.data.centerId));
            dispatch(setSelectedDentist(res.data.resourceId));
            dispatch(setSelectedDate(`${res.data.dayOfMonth}`));
            dispatch(setSelectedMonth(res.data.monthAndYear));

            if (backFromUnvailableSlot) {
              dispatch(setSelectedSlot(''));
            } else {
              dispatch(setSelectedSlot(res.data.timeSlot));
            }

            dispatch(setHasOneAppointment(true));
          } else {
            dispatch(setHasOneAppointment(false));
          }
        }
      )
    );
  };

const createOrUpdatePublicAppointment =
  (callback?: Callback<any>): AppThunk =>
  async (dispatch, getState) => {
    const denticareSessionId = selectDenticareSessionId(getState());
    const selectedCenter = selectSelectedCenter(getState());
    const selectedResource = selectSelectedDentist(getState());
    const selectedMonth = selectSelectedMonth(getState());
    const selectedDates = selectSelectedDate(getState());
    const selectedTimeSlot = selectSelectedSlot(getState());
    const scalingServiceId = selectScalingServiceId(getState());
    const dentistes = selectNewDentist(getState());

    if (isEmpty(denticareSessionId)) return;

    const isNoPreference = dentistes.find((v) => v.resourceId === selectedResource)?.resourceName === 'No preference';

    trackEvent({
      action: 'Click',
      category: 'E-appointment-Booking-step1',
      label: `Next | preferred_dentist:{${isNoPreference ? 'no' : 'yes'}}`
    });

    dispatch(
      httpAction<
        {
          clientSessionId: string;
          centerId: string;
          resourceId?: string;
          serviceId: string;
          monthAndYear: string;
          dayOfMonth: string;
          timeSlot: string;
        },
        unknown,
        unknown
      >(
        {
          method: 'POST',
          url: DenticareBookAppointmentAPI.createPublicAppointment,
          needAuth: false,
          data: {
            clientSessionId: denticareSessionId,
            centerId: selectedCenter,
            resourceId: selectedResource,
            serviceId: scalingServiceId,
            monthAndYear: selectedMonth,
            dayOfMonth: selectedDates,
            timeSlot: selectedTimeSlot
          }
        },
        async () => {
          dispatch(setFinishLoadingData(true));

          if (callback) {
            callback();
          }
        }
      )
    );
  };

const getPublicClient = (): AppThunk => async (dispatch, getState) => {
  const denticareSessionId = selectDenticareSessionId(getState());

  if (isEmpty(denticareSessionId)) return;

  dispatch(
    httpAction<unknown, unknown, RevampDenticareClient & { denticarePublicAppointmentId: string }>(
      {
        method: 'GET',
        url: DenticareBookAppointmentAPI.getPublicClient(denticareSessionId),
        needAuth: false
      },
      async (res) => {
        if (res.httpCode === 200 && res.data) {
          dispatch(
            setSelectedDenticareClient({
              name: res.data.name,
              nric: res.data.nric,
              mobileNumber: res.data.mobileNumber,
              isBookingForYourSelf: !!res.data.bookingForYourself,
              email: res.data.email,
              clientIdType: res.data.clientIdType,
              countryCode: res.data.countryCode
            })
          );

          dispatch(setExistingClient(true));
        } else {
          dispatch(setExistingClient(false));
        }
      }
    )
  );
};

const reviewAndConfirmAppointment =
  (callback?: Callback<any>): AppThunk =>
  async (dispatch, getState) => {
    const denticareSessionId = selectDenticareSessionId(getState());

    if (isEmpty(denticareSessionId)) return;

    trackEvent({
      action: 'Click',
      category: 'E-appointment-Booking-step3',
      label: `confirm | consent:yes`
    });

    dispatch(
      httpAction<
        {
          marketingConsentAgreed: boolean;
          clientSessionId: string;
        },
        unknown,
        ReviewConfirmResponse
      >(
        {
          method: 'POST',
          url: DenticareBookAppointmentAPI.reviewConfirm,
          needAuth: false,
          data: {
            clientSessionId: denticareSessionId,
            marketingConsentAgreed: true
          }
        },
        async (res) => {
          if (res.httpCode === 200) {
            dispatch(createDenticareSessionId(true));
          }

          if (callback) {
            callback(res?.data);
          }
        }
      )
    );
  };

const getCacellationData =
  (hashId: string, callback?: Callback<BookingResponseType>): AppThunk =>
  async (dispatch, getState) => {
    const denticareSessionId = selectDenticareSessionId(getState());

    if (isEmpty(denticareSessionId)) return;

    dispatch(
      httpAction<
        {
          marketingConsentAgreed: boolean;
          clientSessionId: string;
        },
        unknown,
        CancellationData & {
          status: BookingResponseType;
        }
      >(
        {
          method: 'GET',
          url: DenticareBookAppointmentAPI.getCancellationData(hashId),
          needAuth: false
        },
        async (res) => {
          if (res.httpCode === 200 && res.data) {
            dispatch(
              setCancellationData({
                appointmentDate: res.data.appointmentDate,
                nric: res.data.nric,
                serviceName: res.data.serviceName,
                clientSessionId: res.data.clientSessionId,
                appointmentId: res.data.appointmentId
              })
            );
          }

          if (callback) {
            callback(isEmpty(res?.data?.status) ? 'APPOINTMENT_NOT_AVAILABLE' : res?.data?.status);
          }
        }
      )
    );
  };

const cancelAppointmentRevamp =
  (callback?: Callback<BookingResponseType>): AppThunk =>
  async (dispatch, getState) => {
    const cancellationData = selectCancellationData(getState());

    if (!cancellationData) return;

    dispatch(
      httpAction<
        {
          clientSessionId: string;
          appointmentId: string;
        },
        unknown,
        CancellationData & {
          status: BookingResponseType;
        }
      >(
        {
          method: 'PUT',
          url: DenticareBookAppointmentAPI.cancelAppointmentRevamp,
          needAuth: false,
          data: {
            clientSessionId: cancellationData.clientSessionId,
            appointmentId: cancellationData.appointmentId
          }
        },
        async (res) => {
          if (callback && res.httpCode !== 200 && res.httpCode === 500) {
            window.location.href = Router.denticareCommonError;
          }

          if (callback && res.httpCode !== 200 && res.httpCode !== 500) {
            callback('CANCELLATION_FAILED');
          }

          if (callback && res.httpCode === 200) {
            callback('CANCELLATION_SUCCESS');
          }
        }
      )
    );
  };

export {
  getAllTreatments,
  getAllClinics,
  getAllDentists,
  bookDenticareAppointment,
  retrieveAvailableSlot,
  rescheduleDenticareAppointment,
  getDenticareAppointmentHistory,
  cancelDenticareAppointment,
  setSelectedDenticareAppointment,
  getDenticareUpcomingAppointment,
  getRevampCenter,
  getPublicAppointment,
  getPublicClient,
  createOrUpdatePublicAppointment,
  createOrUpdatePublicClient,
  getRevampAvailableSlots,
  getRevampDates,
  getRevampMonth,
  getRevampDentist,
  setSelectedCenter,
  setSelectedDate,
  setSelectedDentist,
  setSelectedMonth,
  setSelectedSlot,
  setSelectedDenticareClient,
  reviewAndConfirmAppointment,
  getCacellationData,
  cancelAppointmentRevamp,
  setFinishLoadingData,
  resetCancellationData,
  setLoadingFetchingCenter,
  setLoadingFetchingDate,
  setLoadingFetchingDentist,
  setLoadingFetchingMonth,
  setLoadingFetchingSlot
};
